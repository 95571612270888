import { createSlice } from "@reduxjs/toolkit";
import { fetchConnections } from "../actions/connectionActions";

const initialState = {
  connections: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const connectionsSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetch connections
      .addCase(fetchConnections.pending, (state) => {
        state.connections.isLoading = true;
        state.connections.error = null;
      })
      .addCase(fetchConnections.fulfilled, (state, action) => {
        state.connections.isLoading = false;
        if (action.payload?.success) {
          state.connections.data = action.payload?.connections;
        }
      })
      .addCase(fetchConnections.rejected, (state, action) => {
        state.connections.isLoading = false;
        state.connections.error = action.error;
      });
  },
});

export default connectionsSlice.reducer;
