import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Grid,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import { useParams } from "react-router-dom";
import CustomDrawer from "../../../composite/CustomDrawer";
import { fetchReviewSetsByCaseId } from "../../../../redux/actions/reviewSets/reviewSetsAction";
import {
  reviewsetExportOptions,
  reviewsetExportStructures,
} from "../../../../constants/predefinedData";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const ExportForm = ({ openDrawer, toggleDrawer, queryId }) => {
  const { control, register, handleSubmit, getValues } = useForm({
    shouldUnregister: false,
  });

  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [selectedReviewSet, setSelectedReviewSet] = useState(null);
  const params = useParams();

  useEffect(() => {
    dispatch(fetchReviewSetsByCaseId(params.caseId));
  }, [params.caseId]);

  const { reviewSetList } = useSelector((state) => state.reviewSets);

  const handleCheckboxChange = (option) => (e) => {
    if (e.target.checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };
  const submit = async (data) => {
    setError("");
    setSuccess("");

    if (!data.exportOptions || data.exportOptions?.length === 0) {
      setError("Please provide export options");
    } else {
      if (!queryId || queryId === "" || queryId === "ReviewsSet") {
        setIsLoading(true);
        axiosInstance
          .post(api.export.reviewSet(params.caseId, selectedReviewSet.id), data)
          .then((response) => {
            setIsLoading(false);
            setError("");
            setSuccess("Exported successfully");
            setTimeout(() => toggleDrawer(null, false), 1000);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error?.response?.data?.message || error?.message);
            setSuccess("");
          });
      } else {
        setIsLoading(true);
        axiosInstance
          .post(
            api.export.querySet(params.caseId, selectedReviewSet.id, queryId),
            data
          )
          .then((response) => {
            setIsLoading(false);
            setError("");
            setSuccess("Exported successfully");
            setTimeout(() => toggleDrawer(null, false), 1000);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error?.response?.data?.message || error?.message);
            setSuccess("");
          });
      }
    }
  };

  const handleReviewSetChange = (e, value) => {
    setSelectedReviewSet(value);
  };
  return (
    <CustomDrawer
      className={"add-export-drawer"}
      isDrawerOpen={openDrawer}
      toggleDrawer={toggleDrawer}
      loading={isloading}
      title={"New export"}
      wrapWithForm={true}
      onFormSubmit={handleSubmit(submit)}
    >
      <Grid container spacing={1}>
        {error ? <Alert severity="error">{error}</Alert> : <></>}
        {success ? <Alert severity="success">{success}</Alert> : <></>}
        <Grid item xs={12}>
          <label htmlFor="outputName" style={{ fontWeight: 600 }}>
            Name:
          </label>
          <input
            id="outputName"
            {...register(`outputName`)}
            style={{
              height: "36px",
              padding: "10px",
              width: "100%",
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="description">
            <strong>Description:</strong> (Optional)
          </label>
          <textarea
            id="description"
            {...register(`description`)}
            style={{
              padding: "10px",
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <label style={{ fontWeight: 600 }}>Reviewset:</label>
          <Autocomplete
            onChange={handleReviewSetChange}
            size="small"
            disablePortal
            options={reviewSetList?.data.map((query) => {
              return { label: query.displayName, id: query.id };
            })}
            getOptionLabel={(option) => option.label}
            value={selectedReviewSet}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Select a collection" />
            )}
          />
        </Grid>
        <Grid item xs={12} id="export-options-container">
          <label style={{ fontWeight: 600 }}>Export options:</label>
          {reviewsetExportOptions.map((option) => (
            <Grid item key={option.id}>
              <input
                style={{ marginRight: "5px" }}
                type="checkbox"
                id={option.id}
                value={option.value}
                {...register("exportOptions")}
                checked={selectedOptions.includes(option.id)}
                onChange={handleCheckboxChange(option.id)}
              />
              <label style={{ marginBottom: 0 }} htmlFor={option.id}>
                {option.label}
              </label>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="exportStructure"
            control={control}
            defaultValue="Directory"
            rules={{ required: "true" }}
            render={({ field }) => (
              <>
                <RadioGroup
                  defaultValue=""
                  id="exportStructure"
                  {...register(`exportStructure`)}
                  {...field}
                  onChange={(value) => field.onChange(value)}
                >
                  <FormLabel style={{ fontWeight: 600, color: "black" }}>
                    Export structure:
                  </FormLabel>
                  {reviewsetExportStructures.map((option) => (
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      className="export-structure"
                    />
                  ))}
                </RadioGroup>
              </>
            )}
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={isloading} variant="contained" type="submit">
          Submit
        </Button>
        <Button
          disabled={isloading}
          variant="outlined"
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default ExportForm;
