import { createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const fetchConnections = createAsyncThunk(
  "connections/fetchConnections",
  async () => {
    const response = await axios.get("/connections");
    return response.data;
  }
);
