import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sortDataByCreatedDateTime } from "../../utils/sorting";
import api from "../../utils/api";
import {
  FETCH_SUBJECTRIGHTS,
  FETCH_SUBJECTRIGHTS_BY_ID,
  CREATE_SUBJECTRIGHTS,
  UPDATE_SUBJECTRIGHTS,
  DELETE_SUBJECTRIGHTS,
  FETCH_FINALREPORT_BY_ID,
  FETCH_SRNOTES_BY_ID,
  FETCH_FINALATTACHMENT_BY_ID
} from "../actionTypes/subjectRightsTypes";

//fetch SubjectRights
export const fetchSubjectRights = createAsyncThunk(FETCH_SUBJECTRIGHTS, async () => {
  const response = await axios.get(api.subjectRights.get());
  return sortDataByCreatedDateTime(response.data);
});

//fetch SubjectRights by id
export const fetchSubjectRightsById = createAsyncThunk(
  FETCH_SUBJECTRIGHTS_BY_ID,
  async (srId) => {
    const response = await axios.get(api.subjectRights.getById(srId));
    return response.data;
  }
);

//create SubjectRights
export const createSubjectRights = createAsyncThunk(CREATE_SUBJECTRIGHTS, async (data) => {
  const response = await axios.post(api.subjectRights.create(), data);
  return response.data;
});

//update SubjectRights
export const updateSubjectRights = createAsyncThunk(UPDATE_SUBJECTRIGHTS, async (data) => {
  const { srId, formData } = data;
  const response = await axios.patch(api.subjectRights.update(srId), formData);
  return response.data;
});

//delete SubjectRights
export const deleteSubjectRights = createAsyncThunk(DELETE_SUBJECTRIGHTS, async (srId) => {
  const response = await axios.delete(api.subjectRights.delete(srId));
  return response;
});

//fetch SubjectRights Final Report by id
export const fetchFinalReportById = createAsyncThunk(
  FETCH_FINALREPORT_BY_ID,
  async (srId) => {
    const response = await axios.get(api.subjectRights.getSRByIdFinalReport(srId));
    return response.data;
  }
);
//fetch SubjectRights Final Attachment by id
export const fetchFinalAttachmentById = createAsyncThunk(
  FETCH_FINALATTACHMENT_BY_ID,
  async (srId) => {
    const response = await axios.get(api.subjectRights.getSRByIdFinalAttachment(srId));
    return response.data;
  }
);

//fetch SubjectRights Notes by id
export const fetchSRNotesById = createAsyncThunk(
  FETCH_SRNOTES_BY_ID,
  async (srId) => {
    const response = await axios.get(api.subjectRights.getSubjectRightsNotes(srId));
    return response.data;
  }
);