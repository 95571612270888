import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { showApiError } from "../../utils/functions";

//reset ui alert
export const resetUIAlert = createAsyncThunk("resetUIAlert", async () => {
  return -1;
});

export const fetchSearchCollections = createAsyncThunk(
  "fetchSearchCollections",
  async () => {
    const response = await axios.get("/collections");
    return response.data;
  }
);

export const deleteSearchCollection = createAsyncThunk(
  "SearchCollections",
  async (data) => {
    if (!data) {
      return -1;
    } else {
      await axios.delete(`/collections/${data.CollectionId}`);
      return 13;
    }
  }
);

export const changeCollectionHold = createAsyncThunk(
  "holdSearchCollections",
  async (data) => {
    if (!data) {
      return -1;
    } else {
      const response = await axios
        .patch(`/collections/${data.collectionId}/change-hold`, data)
        .then(() => {
          return 12;
        });
      return response;
    }
  }
);

export const loadDashboard = createAsyncThunk("loadDashboard", async () => {
  const response = await axios.get("/dashboard");
  return response.data.dashboard;
});

export const loadConnections = createAsyncThunk("loadCollections", async () => {
  const response = await axios.get("/connections");
  return response.data.connections;
});

export const createCollection = createAsyncThunk(
  "createCollection",
  async (data) => {
    await axios.post("/collections", data);
    return 10;
  }
);

export const updateCollection = createAsyncThunk(
  "updateCollection",
  async (data) => {
    await axios.patch(`/collections/${data.collectionId}`, data);
    return 16;
  }
);

export const deleteConnection = createAsyncThunk(
  "deleteConnection",
  async (data) => {
    if (!data) {
      return -1;
    } else {
      await axios.delete(`/connections/${data.ConnectionId}`);
      return 14;
    }
  }
);

export const createServerConnection = createAsyncThunk(
  "createServerConnection",
  async (data) => {
    const response = await axios.post("/connections", data);
    return {
      component: !data ? -1 : 11,
      createdConnectionId: response.connectionId,
    };
  }
);

export const loadConnectionInfo = createAsyncThunk(
  "loadConnectionInfo",
  async (data) => {
    const response = await axios.get(`/connections/${data.connectionId}`);
    return response.data.connectionInfo;
  }
);

export const loadConnectionLog = createAsyncThunk(
  "loadConnectionLog",
  async (data) => {
    const response = await axios.get(`/connections/${data.connectionId}/logs`);
    return response.data.logs;
  }
);

export const updateServerConnection = createAsyncThunk(
  "updateServerConnection",
  async (data) => {
    const response = await axios.patch(`/connections/${data.id}`, data);
    return {
      component: !data ? -1 : 12,
      createdConnectionId: response.data.connectionId,
    };
  }
);

export const changeCrawlerStatus = createAsyncThunk(
  "changeCrawlerStatus",
  async (data) => {
    const response = await axios.get(
      "/crawlers/change-crawler-status?status=" +
        data.status +
        "&connectionId=" +
        data.connectionId
    );
    return true;
  }
);

export const loadCollectionInfo = createAsyncThunk(
  "loadCollectionInfo",
  async (data) => {
    const response = await axios.get(`/collections/${data.collectionId}`);
    return response.data.collectionInfo;
  }
);

export const searchCollection = createAsyncThunk(
  "searchCollection",
  async (data) => {
    const response = await axios
      .post(`/collections/${data.collectionId}/search`, data)
      .catch(showApiError);
    return {
      component: 3,
      searchResult: response.data,
    };
  }
);

export const requestCollectionExport = createAsyncThunk(
  "requestCollectionExport",
  async (data) => {
    const response = await axios.post(
      `/collections/${data.collectionId}/export`,
      data
    );
    return response.data;
  }
);

export const loadExportedCollections = createAsyncThunk(
  "loadExportedCollections",
  async () => {
    const response = await axios.get("/exports");
    return response.data.collections;
  }
);

export const loadCollectionsOnHold = createAsyncThunk(
  "loadCollectionsOnHold",
  async () => {
    const response = await axios.get("/collections/on-hold");
    return response.data.collections;
  }
);

export const loadExportedCollectionFiles = createAsyncThunk(
  "loadExportedCollectionFiles",
  async (id) => {
    const response = await axios.get(`/exports/${id}/files`);
    return {
      exportResults: response.data.exportResults,
      connections: [],
    };
  }
);
