import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import api from "../../../utils/api";

export const fetchQuerySetByReviewSetId = createAsyncThunk(
  "query/fetchByReviewSetId",
  async ( {caseId,reviewSetId} , { dispatch }) => {
    try {
      const res = await axios.get(api.querySet.get(caseId,reviewSetId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const retrieveQuerySet = createAsyncThunk(
  "query/retrieveByQueryId",
  async ( {caseId,reviewSetId,queryId} , { dispatch }) => {
    try {
      const res = await axios.get(api.querySet.retrieve(caseId, reviewSetId,queryId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const createQuerySet = createAsyncThunk(
  "query/createByReviewSetId",
  async ({data,caseId,reviewSetId}, { dispatch }) => {
    try {
      const res = await axios.post(api.querySet.create(caseId,reviewSetId), data);
      await dispatch(fetchQuerySetByReviewSetId({caseId,reviewSetId}));
    } catch (error) {
      throw error;
    }
  }
);

export const updateQuerySet = createAsyncThunk(
  "query/updateQuery",
  async ({ data, caseId, reviewSetId,queryId }, { dispatch }) => {
    try {
      const res = await axios.patch(
        api.querySet.update(caseId, reviewSetId,queryId), data
      );
      await dispatch(fetchQuerySetByReviewSetId({caseId,reviewSetId}));
    } catch (error) {
      throw error;
    }
  }
);

export const deleteQuerySet = createAsyncThunk(
  "query/deleteQuery",
  async ({caseId,reviewSetId,queryId}, { dispatch }) => {
    try {
      await axios.delete(api.querySet.delete(caseId, reviewSetId, queryId));
      await dispatch(fetchQuerySetByReviewSetId({caseId,reviewSetId}));
    } catch (error) {
      throw error;
    }
  }
);
