import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import CustomTableHead from "./CustomTableHead";
import { renderCell } from "./renderCell";
import CustomTableToolbar from "./CustomTableToolbar";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomTable({
  children,
  rows = [],
  headCells = [],
  onClickActionEvent,
  onClickEditEvent, // handle edit event for cell type "DELETE_EDIT_ACTION_CELL_TYPE"
  onClickDeleteEvent, // handle delete event for cell type "DELETE_EDIT_ACTION_CELL_TYPE"
  isLoading = false, // Boolean indicating whether the table is in a loading state
  rowsCount = 20, // The number of rows to display per page in pagination
  shadow = true, // Boolean indicating whether to show a shadow effect on the table
  isPagination = true, // Boolean indicating whether to show pagination controls
  showHeader = true, // Boolean indicating whether to show the table header
  showSerialNo = false, // Boolean indicating whether to display a serial number column
  checkOption = false, // Boolean indicating whether to show checkboxes in each row for selection
  onCheckRows = null, // Event handler for row selection changes
  isSearch = false, // Boolean indicating whether to show a search input field in table header
  className,
  selectorFn, // method for checking row is already checked on not
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selectedCount, setSelectedCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsCount);
  const [searchTerm, setSearchTerm] = useState("");
  const [updatedRows, setUpdatedRows] = useState(rows);
  const [searchedRows, setSearchedRows] = useState(rows);

  // Reset page number when rows change
  useEffect(() => {
    setPage(0);
  }, [rows.length]);

  useEffect(() => {
    setSearchedRows(rows);
  }, [rows]);

  useEffect(() => {
    if (checkOption && selectorFn) {
      setUpdatedRows(rows.map((x) => ({ ...x, checked: selectorFn(x) })));
    } else {
      setUpdatedRows(rows);
    }
  }, [updatedRows.length === 0 && rows]);

  useEffect(() => {
    if (checkOption) {
      const checkedRows = updatedRows.filter((x) => !!x.checked);
      setSelectedCount(checkedRows.length);
    } else {
      setSelectedCount(0);
    }
  }, [updatedRows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // Handle click on select all checkbox
  const handleSelectAllClick = (event) => {
    const rowsWithChecks = rows.map((x) => ({
      ...x,
      checked: event.target.checked,
    }));
    setUpdatedRows(rowsWithChecks);
    const checkedRows = rowsWithChecks.filter((x) => !!x.checked);
    setSelectedCount(checkedRows.length);
    if (onCheckRows) {
      onCheckRows(checkedRows);
    }
  };
  // Handle click on row checkbox
  const handleToggleCheck = (event, index) => {
    const checked = event.target.checked;
    const rowList = [
      ...updatedRows.slice(0, index),
      { ...updatedRows[index], checked },
      ...updatedRows.slice(index + 1),
    ];
    setUpdatedRows(rowList);
    const checkedRows = rowList.filter((x) => !!x.checked);
    setSelectedCount(checkedRows.length);
    if (onCheckRows) {
      onCheckRows(checkedRows);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const srcText = event.target.value;
    setSearchTerm(srcText);
    let filteredResult = [];
    if (!srcText || srcText.length === 0) filteredResult = rows;
    else {
      filteredResult = rows.filter((item) => {
        const rowCellValues = headCells
          .map((x) => {
            const props = x.id?.split(".") || "";
            return props.length === 1
              ? item[props[0]]
              : props.length === 2
              ? item[props[0]][props[1]]
              : undefined;
          })
          .filter((x) => !!x);
        const index = rowCellValues.findIndex(
          (x) =>
            x?.toLowerCase && x.toLowerCase().includes(srcText.toLowerCase())
        );
        return index > -1;
      });
    }
    setUpdatedRows(filteredResult);
    setSearchedRows(filteredResult);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const childArray = React.Children.toArray(children);
  const customTableToolbar = childArray.find(
    (x) => x.type.displayName === "CustomTableToolbar"
  );

  return (
    <>
      {customTableToolbar ? (
        <CustomTableToolbar
          searchTerm={searchTerm}
          onSearchEvent={isSearch ? handleSearchChange : undefined}
          checkOption={checkOption}
          numSelected={selectedCount}
          {...customTableToolbar.props}
        />
      ) : isSearch || checkOption ? (
        <CustomTableToolbar
          searchTerm={searchTerm}
          onSearchEvent={isSearch ? handleSearchChange : undefined}
          checkOption={checkOption}
          numSelected={selectedCount}
        />
      ) : (
        <></>
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, boxShadow: shadow ? "" : "None" }}>
          <TableContainer className={`custom-table ${className}`}>
            <Table aria-labelledby="tableTitle" size={"small"}>
              {showHeader ? (
                <CustomTableHead
                  numSelected={selectedCount}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                  checkOption={checkOption}
                  showSerialNo={showSerialNo}
                />
              ) : (
                <></>
              )}
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={headCells.length}
                      align="center"
                      sx={{ padding: "25px" }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  stableSort(
                    isSearch ? searchedRows : rows,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          {showSerialNo && (
                            <TableCell
                              align="left"
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "5px",
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                          )}
                          {checkOption && (
                            <TableCell
                              align="left"
                              padding="checkbox"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              <Checkbox
                                color="primary"
                                // checked={!!row.checked}
                                checked={
                                  updatedRows.find((x) => x.id === row.id)
                                    ?.checked || false
                                }
                                onChange={(event) =>
                                  handleToggleCheck(event, index)
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          )}

                          {headCells?.map((cell, index) => {
                            return (
                              <TableCell
                                key={index}
                                align={cell.align || "left"}
                                className={cell.id}
                                sx={cell.style}
                              >
                                {renderCell(
                                  row,
                                  cell,
                                  onClickActionEvent,
                                  onClickDeleteEvent,
                                  onClickEditEvent
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell align="left" colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {isPagination && (
            <TablePagination
              className={"custom-table-pagination"}
              rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Box>
    </>
  );
}
