import React, { useState, useCallback } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  IconButton,
} from "@mui/material";
import swal from "sweetalert";
import CardContent from "@mui/material/CardContent";
import { format } from "date-fns";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SubjectRightsForm from "./form/SubjectRightsForm";
import { isObjectEmptyOrNull } from "../../../utils/validation";
import { fetchSubjectRightsById, updateSubjectRights } from "../../../redux/actions/subjectRightsActions";

const SROverview = ({ details, isLoading }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { srId } = params;

  const [isSRFormDrawerOpen, setIsSRFormDrawerOpen] = useState(false);
  
  const toggleDrawer = (event, open) => {
    setIsSRFormDrawerOpen(open);
  };

  const handleSRForm = useCallback(
    (formData) => {
      dispatch(updateSubjectRights({ srId, formData })).then((res) => {
        if (res.hasOwnProperty("error")) {
          setIsSRFormDrawerOpen(false);
        } else {
          swal("Success!", `Subject Rights Request updated successfully!`, "success").then(() => {
            dispatch(fetchSubjectRightsById(srId));
            setIsSRFormDrawerOpen(false);
          });
        }
      });
    },[srId]
  );

  return isObjectEmptyOrNull(details) ? (
    <Grid>Loading....</Grid>
  ) : (
    <div>
      <Grid>
        <Box mb={3}>
          <Typography component={"span"} variant="h5" fontWeight="bold">
            {isLoading ? "Loading..." : details.displayName}
          </Typography>
        </Box>
      </Grid>
      <Grid mb={2}>
        <Card sx={{ border: "1px solid #C8C8C8" }}>
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography component={"span"} variant="h6" fontWeight="bold">
                  Summary
                </Typography>
                <IconButton
                  disabled={isLoading}
                  onClick={(e) => toggleDrawer(e, true)}
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
            }
            sx={{ borderBottom: "1px solid #C8C8C8", paddingY: "7px" }}
          />
          <CardContent>
            {isLoading ? (
              "Loading..."
            ) : (
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" flexDirection="column">
                    <Box fontWeight="bold">Description:</Box>
                    <Box>{details?.description || "---"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Status:
                    </Box>
                    <Box>{details?.status}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Created By:
                    </Box>
                    <Box>{details?.createdBy.displayName || "N/A"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Created Date:
                    </Box>
                    <Box>
                      {details?.createdDateTime
                        ? format(
                            new Date(details.createdDateTime),
                            "dd MMM yyyy"
                          )
                        : ""}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Last Modified By:
                    </Box>
                    <Box>{details?.lastModifiedBy?.displayName || "N/A"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Last Modified Date:
                    </Box>
                    <Box>
                      {details?.lastModifiedDateTime
                        ? format(
                            new Date(details.lastModifiedDateTime),
                            "dd MMM yyyy"
                          )
                        : ""}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>

      <SubjectRightsForm
        subjectRightsDetails={details}
        toggleDrawer={toggleDrawer}
        handleSRForm={handleSRForm}
        isSubjectRightsFormDrawerOpen={isSRFormDrawerOpen}
        isEdit={true}
      />
    </div>
  );
};

export default SROverview;
