import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { fetchHolds } from "../../../../redux/actions/holdActions";
import holdsTableHeadCells from "./holdsTableHeadCells";
import CustomTable from "../../../composite/table/CustomTable";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import CustomTableToolbar from "../../../composite/table/CustomTableToolbar";

const Holds = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseId } = params;

  useEffect(() => {
    dispatch(fetchHolds(caseId));
  }, [dispatch, caseId]);

  const { holdList } = useSelector((state) => state.holds);
  const isLoading = holdList.isLoading;
  const holdRows = holdList.data.map((item) => ({
    ...item,
    parentId: caseId,
  }));

  const handleDeleteHold = (e, holdId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this hold?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        axiosInstance.delete(api.holds.delete(caseId, holdId)).then((res) => {
          swal("Success!", "Deleted Successfully", "success");
          dispatch(fetchHolds(params.caseId));
        });
      }
    });
  };

  return (
    <CustomTable
      showSerialNo={true}
      rows={holdRows}
      headCells={holdsTableHeadCells()}
      onClickActionEvent={handleDeleteHold}
      isLoading={isLoading}
      isSearch={true}
    >
      <CustomTableToolbar
        title={"LEGAL HOLDS"}
        onRefreshEvent={() => dispatch(fetchHolds(params.caseId))}
        addButtonText={"Create new hold"}
        onAddEvent={() => navigate(`/cases/${params.caseId}/holds/create`)}
      />
    </CustomTable>
  );
};

export default Holds;
