import swal from "@sweetalert/with-react";

const validateConnectionInputs = (connection) => {
  if (
    !connection.connectionName ||
    connection.connectionName.trim().length === 0
  ) {
    swal("Validation failed", "Invalid connection name", "warning");
    return false;
  }

  if (
    !connection.searchLocations ||
    connection.searchLocations.trim().length === 0
  ) {
    swal("Validation failed", "Invalid directory paths", "warning");
    return false;
  }
  const locations = connection.searchLocations.split(/\r?\n/);
  let message = null;
  for (const loc of locations) {
    if (!loc || loc.trim().length === 0) {
      message = "Blank line is not allowed in directory paths";
      break;
    } else if (!loc.startsWith("//") && !loc.startsWith("\\\\")) {
      message = `Local drive paths are not allowed, use file server path starting with \\\\ or //`;
      break;
    }
  }
  if (message) {
    swal("Validation failed", message, "warning");
    return false;
  }

  return true;
};

export default validateConnectionInputs;