import React, { useState } from "react";
import { Button } from "@mui/material";
import siteTableHeadCells from "./siteTableHeadCells";
import CustomTable from "../../../../../composite/table/CustomTable";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";
import CustomTableToolbar from "../../../../../composite/table/CustomTableToolbar";

const SiteSelection = ({
  toggleSiteDrawer,
  isSiteDrawerOpen,
  tenantSitesList,
  handleSelectedSiteParent,
}) => {
  const { isLoading, data } = tenantSitesList;
  const [selectedSiteList, setSelectedSiteList] = useState([]);

  const handleSelectedRows = (rows) => {
    setSelectedSiteList(rows);
  };
  const handleSelectedSite = () => {
    handleSelectedSiteParent(selectedSiteList);
  };

  return (
    <CustomDrawer
      className={"add-site-drawer"}
      isDrawerOpen={isSiteDrawerOpen}
      toggleDrawer={toggleSiteDrawer}
      title={"SharePoint sites"}
      subtitle={"Please type minimum 3 characters to get the site list."}
    >
      <CustomTable
        rows={data}
        rowsCount={100}
        headCells={siteTableHeadCells()}
        isLoading={isLoading}
        checkOption={true}
        onCheckRows={handleSelectedRows}
        isSearch={true}
      >
        <CustomTableToolbar title={"Sites"}></CustomTableToolbar>
      </CustomTable>
      <CustomDrawerFooter>
        <Button variant="contained" onClick={handleSelectedSite}>
          Add
        </Button>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={(e) => toggleSiteDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default SiteSelection;
