import { IconButton } from "@mui/material";
import { format } from "date-fns";
import { CUSTOM } from "../../../constants/table";
import DeleteIcon from "@mui/icons-material/Delete";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export default function (onClickEditEvent, onClickDeleteEvent) {
  const connectionsTableHeadCells = [
    {
      id: "id",
      label: "ID",
      tableCellType: CUSTOM,
      fn: (cell) => (
        <div className="guid-cell" title={cell["id"]}>
          {cell["id"]}
        </div>
      ),
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "createdBy",
      label: "Created By",
    },
    {
      id: "createdTime",
      tableCellType: CUSTOM,
      fn: (cell) => format(new Date(cell["createdTime"]), "dd MMM yyyy"),
      label: "Created",
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "left",
      fn: (cell) => (
        <>
          <AuthorizeGuard module={"CONNECTIONS"} action={"Edit"}>
            <IconButton
              size="small"
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickEditEvent(e, cell["id"])}
            >
              <FontAwesomeIcon icon={faCog} />
            </IconButton>
          </AuthorizeGuard>
          <AuthorizeGuard module={"CONNECTIONS"} action={"Delete"}>
            <IconButton
              size="small"
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickDeleteEvent(e, cell["id"])}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </AuthorizeGuard>
        </>
      ),
    },
  ];
  return connectionsTableHeadCells;
}
