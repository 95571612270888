import React from "react";
import { Controller } from "react-hook-form";
import FieldArray from "./fieldArray";
import { MuiChipsInput } from "mui-chips-input";
import { Box } from "@mui/material";

/* const defaultValues = {
  displayName: "string",
  test: ["a"],
  conditions: {
    kql: true,
    keywords: ["string"],
    filters: [
      {
        logicalOperator: "AND",
        propertyName: "Type",
        operator: "EqualAnyOf",
        value: ["string"],
      },
    ],
  },
};
{
  test: [
    {
      name: "useFieldArray1",
      nestedArray: [{ field1: "field1", field2: "field2" }],
    },
    {
      name: "useFieldArray2",
      nestedArray: [{ field1: "field1", field2: "field2" }],
    },
  ];
} */

function ConditionBuilder({
  register,
  control,
  defaultValues,
  getValues,
  setValue,
  errors,
  watch,
  reset,
}) {
  return (
    <Box>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <form>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="keywords">Keyword(s):</label>
          <Controller
            name="conditions.keywords"
            control={control}
            render={({ field }) => (
              <MuiChipsInput
                id="keywords"
                {...register(`conditions.keywords`)}
                {...field}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>

        <FieldArray
          {...{
            control,
            register,
            defaultValues,
            getValues,
            setValue,
            errors,
            watch,
          }}
        />

        {/* <button
          type="button"
          onClick={() => reset(defaultValues)}
          style={{ height: "36px", width: "100px", margin: "20px" }}
        >
          Reset
        </button> */}

        {/* <input
          type="submit"
          style={{ height: "36px", margin: "20px", width: "100px" }}
        /> */}
      </form>
    </Box>
  );
}
export default ConditionBuilder;
