import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import api from "../../../utils/api";

export const fetchReviewSetsByCaseId = createAsyncThunk(
  "reviewSets/fetchByCaseId",
  async (caseId, { dispatch }) => {
    try {
      const res = await axios.get(api.reviewSets.get(caseId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const createReviewSetByCaseId = createAsyncThunk(
  "reviewSets/createByCaseId",
  async ({ data, caseId }, { dispatch }) => {
    try {
      const res = await axios.post(api.reviewSets.create(caseId), data);
      await dispatch(fetchReviewSetsByCaseId(caseId));
    } catch (error) {
      throw error;
    }
  }
);

export const fetchReviewSetDetails = createAsyncThunk(
  "reviewSets/fetchDetails",
  async ({ caseId, reviewSetId }, { dispatch }) => {
    try {
      const res = await axios.get(
        api.reviewSets.details(caseId, reviewSetId)
      );
      return { reviewSetDetails: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const updateReviewSet = createAsyncThunk(
  "reviewSets/update",
  async ({ data, caseId, reviewSetId }, { dispatch }) => {
    try {
      await axios.patch(api.reviewSets.update(caseId, reviewSetId), data);
      // await dispatch(fetchCollectionsByCaseId(caseId));
    } catch (error) {
      throw error;
    }
  }
);
