import { createSlice } from "@reduxjs/toolkit";
import { handleBreadcrumb } from "../actions/utilsActions";

const initialState = {
  breadcrumb: null,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleBreadcrumb.pending, (state, action) => {
        state.breadcrumb = null;
      })
      .addCase(handleBreadcrumb.fulfilled, (state, action) => {
        state.breadcrumb = action.payload;
      });
  },
});

export default utilsSlice.reducer;
