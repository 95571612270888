import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import EditReviewSet from "./editReviewSets";
import swal from "sweetalert";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { showApiError } from "../../../../utils/functions";

const ReviewSetActions = ({ cell }) => {
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const params = useParams();
  const handleDelete = () => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this reviewset?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        axiosInstance
          .delete(api.reviewSets.delete(params.caseId, cell["id"]))
          .then((response) => {
            swal("Success!", "Deleted Successfully", "success");
          })
          .catch(showApiError);
      }
    });
  };

  const toggleDrawer = (event, open) => {
    setIsEditDrawerOpen(open);
  };

  return (
    <td>
      <EditReviewSet
        isDrawerOpen={isEditDrawerOpen}
        toggleDrawer={toggleDrawer}
        reviewSetid={cell["id"]}
        displayName={cell["displayName"]}
      />
      <Link
        className="btn btn-sm reviewset-search-icon"
        to={`/cases/${cell["parentId"]}/review-sets/${cell["id"]}?rs=${cell["displayName"]}`}
      >
        <SearchIcon />
      </Link>

      <IconButton
        size="small"
        color="default"
        onClick={(e) => {
          toggleDrawer(e, true);
        }}
      >
        <EditIcon />
      </IconButton>

      <IconButton size="small" color="default" onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </td>
  );
};

export default ReviewSetActions;
