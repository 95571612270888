import React, { Component } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { Circles } from "react-loader-spinner";
import { Form, Button, Modal } from "react-bootstrap";
import {
  IconButton,
  Box,
  Grid,
  Typography,
  Drawer,
} from "@mui/material";
import DatePicker from "react-datepicker";
import {
  faL,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../../assets/css/main.css";
import "../../assets/css/searchEmail.css";
import "react-datepicker/dist/react-datepicker.css";
import { Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchAccessGuard from "../common/SearchAccessGuard";

export class SearchEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      startDate: null,
      endDate: null,
      fromValue: "",
      toValue: "",
      ccValue: "",
      bccValue: "",
      subjectValue: "",
      folderValue: "",
      isAttchment: false,
      importanceValue: "Normal",
      isRead: false,
      documentModalIsOpen: false,
      searched: false,
      documentModalTitle: "",
      documentModalText: "",
      searchIsActive: true,
      oldResult: "",
      currentPage: 1,
      userTimeZone: "",
      isExportEnabled: true,
      anchorEl: "",
      searchSidePanelOpenEmail: true,
      selectedEmails: [],
      sentFromValue: null,
      sentToValue: null,
      receivedFromValue: null,
      receivedToValue: null,
      isAdvanced: false,
      opVal: "or",
      fieldSpecificVal: "subject",
      phraseVal : "",
      proximityTerm1 : "",
      proximityTerm2 : "",
      wildcardsVal:"",
      fuzzyVal:"",
      customQueryVal: ""
    };

    this.state.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.typingTimeout = null;
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  onFieldChange(event) {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout);
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout((x) => {
      this.searchEmail(true);
    }, 1000);

    // Setting value of the search box to a state.
    this.setState({ [event.target.name]: event.target.value });
  }

  resetPage = () => {
    this.state.currentPage = 1;
  };

  searchNextPage = () => {
    this.state.currentPage += 1;
    this.searchEmail(false);
  };

  searchPreviousPage = () => {
    if (this.state.currentPage > 1) this.state.currentPage -= 1;

    this.searchEmail(false);
  };

  generateEmails = () => {
    if (
      this.props.ui.searchEmailResult === undefined ||
      this.props.ui.searchEmailResult.emails === undefined
    ) {
      return;
    }
    const steps = this.props.ui.searchEmailResult.emails.map((step, index) => (
      <div key={step.id} className="tab-pane active">
        <div className="row">
          <div className="col-md-11">
            <div className="search-item">
              <div className="row">
                <div className="email-description">
                  <div className="row">
                    <div className="col-12 text-left">
                      <div className="row col-12">
                          <a
                            href="#"
                            onClick={(x) =>
                              this.openEmail(
                                step.subject
                              )
                            }
                            className="link-primary file-link"
                            title={step.subject}
                          >
                            Subject: <b>{step.subject}</b>
                          </a>
                          <br />
                          <div className="Email-label">
                            From: <span className="Email-label-val">{step.from?.name}  {step.from?.address}</span>
                            <br />
                            To: <span className="Email-label-val">{step.to?.name}  {step.to?.address}</span>
                            <br />
                            CC: <span className="Email-label-val">{step.cc?.name}  {step.cc?.address}</span>
                            <br />
                            Sent DateTime:  <span className="time-label">
                            {format(
                              new Date(step.sent_time),
                              "dd MMM yyyy H:mm a"
                            )}
                          </span> <br />  
                          Received DateTime:  <span className="time-label">
                            {format(
                              new Date(step.received_time),
                              "dd MMM yyyy H:mm a"
                            )}
                          </span>
                          </div>
                      </div>
                    </div>
                    <div className="col-12 text-left">
                      <div
                        className="mt-1"
                        style={{
                          maxWidth: "100%",
                          wordWrap: "break-word",
                          paddingLeft: "13px",
                        }}
                      >
                         <div className="Email-Body-label">Body Snippet: </div>
                        {step.message && step.message.length > 300
                          ? step.message.slice(0, 300) + " ....."
                          : step.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    ));

    return <div>{steps}</div>;
  };

  searchEmail = (reset) => {
    this.setState({ searchIsActive: false });
    var reachedEmailsLimit;
    if (reset) {
      this.resetPage();
      reachedEmailsLimit = false;
    } else {
      if (this.props.ui.searchEmailResult !== null) {
        reachedEmailsLimit = this.props.ui.searchEmailResult.reachedEmailLimit;
      }
    }

    const search = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      fromValue: this.state.fromValue,
      toValue: this.state.toValue,
      ccValue: this.state.ccValue,
      bccValue: this.state.bccValue,
      subjectValue: this.state.subjectValue,
      folderValue: this.state.folderValue,
      isAttchment: this.state.isAttchment,
      importanceValue: this.state.importanceValue,
      isRead: this.state.isRead,
      searchValue: this.state.searchValue,
      pageNumber: this.state.currentPage,
      reachedLimit: reachedEmailsLimit,
      userTimezoneName: this.state.userTimeZone,
      selectedEmails : this.state.selectedEmails,
      opVal: this.state.opVal,
      sentFromValue : this.state.sentFromValue,
      sentToValue : this.state.sentToValue,
      receivedFromValue : this.state.receivedFromValue,
      receivedToValue : this.state.receivedToValue,
      pageSize: 0,
      isAdvanced: this.state.isAdvanced,
      fieldSpecificVal : this.state.fieldSpecificVal,
      customQueryVal : this.state.customQueryVal,
      phraseVal : this.state.phraseVal,
      proximityTerm1: this.state.proximityTerm1,
      proximityTerm2 : this.state.proximityTerm2,
      wildcardsVal: this.state.wildcardsVal,
      fuzzyVal: this.state.fuzzyVal,
    };

    this.state.searched = false;
    this.props.searchEmail(search);
  };

  generateSideBar = () => {
    return (<div>
                  <div> 
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: "1.25rem",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                      >
                        Emails Search
                      </Typography>

                      <IconButton
                        sx={{ color: "white" }}
                        onClick={() =>
                          this.setState({
                            searchSidePanelOpenEmail: !this.state.searchSidePanelOpenEmail,
                          })
                        }
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                    </div>
                    </div>
                    <hr style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }} />
                    <div className="switch">
                      <span>
                        <input
                          type="checkbox"
                          id="toggleInput"
                          ref="toggleInput"
                          checked={this.state.isAdvanced}
                          onChange={this.handleChangeSwitch.bind(this)}
                        />
                        <button
                          className="slider"
                          type="button"
                          onClick={this.handleChangeSwitch.bind(this)}>
                        </button>
                      </span>
                      <label
                        htmlFor="toggleInput">
                        Advanced Search
                      </label>


                    </div>
                    <div id="divSearch" style={{ display: !this.state.isAdvanced ? "block" : "none" }}>
                      <div className="label" style={{ textAlign: "left" }}>
                        Date Range
                      </div>
                      <div className="label-sm">From</div>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDate}
                        name="startDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />

                      <div className="label-sm mt-2">To</div>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDate}
                        name="endDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />
                      <div className="padding"></div>
                      <div className="padding"></div>

                      <div className="label-sm">From</div>
                      <input
                        type="text"
                        id="txtFrom"
                        name=""
                        className="form-control"
                        value={this.state.fromValue}
                        onChange={this.handleFromChange}
                      />

                      <div className="label-sm">To</div>
                      <input
                        type="text"
                        id="txtTo"
                        name=""
                        className="form-control"
                        value={this.state.toValue}
                        onChange={this.handleToChange}
                      />

                      <div className="label-sm">CC</div>
                      <input
                        type="text"
                        id="txtCC"
                        name=""
                        className="form-control"
                        value={this.state.ccValue}
                        onChange={this.handleCCChange}
                      />

                      <div className="label-sm">BCC</div>
                      <input
                        type="text"
                        id="txtBCC"
                        name=""
                        className="form-control"
                        value={this.state.bccValue}
                        onChange={this.handleBCCChange}
                      />

                      <div className="label-sm">Subject</div>
                      <input
                        type="text"
                        id="txtSubject"
                        name=""
                        className="form-control"
                        value={this.state.subjectValue}
                        onChange={this.handleSubjectChange}
                      />

                      <br />
                      <div>
                        <Form.Check
                          reverse="true"
                          label="With Attachment"
                          name="Attachment"
                          type="radio"
                          id="rbAtt1"
                          checked={this.state.isAttchment}
                          onChange={this.handleIsAttchmentCheck}
                        />
                        <Form.Check
                          reverse="true"
                          label="Without Attachment"
                          name="Attachment"
                          type="radio"
                          id="rbAtt2"
                          checked={!this.state.isAttchment}
                          onChange={this.handleIsAttchmentCheck}
                        />
                      </div>

                      <div className="label-sm">Folder</div>
                      <input
                        type="text"
                        id="txtFolder"
                        name=""
                        className="form-control"
                        value={this.state.folderValue}
                        onChange={this.handleFolderChange}
                      />

                      <div className="label-sm">Importance</div>
                      <div style={{ display: "flex", padding: "5px" }}>
                        <Form.Check
                          reverse="true"
                          label="Low"
                          name="Importance-Status"
                          type="radio"
                          id={`title`}
                          data-value="Low"
                          checked={this.state.importanceValue === "Low" ? true : false}
                          onChange={this.handleImportanceChange}
                        />
                        <Form.Check
                          reverse="true"
                          label="Normal"
                          name="Importance-Status"
                          type="radio"
                          id={`title`}
                          data-value="Normal"
                          checked={this.state.importanceValue === "Normal" ? true : false}
                          onChange={this.handleImportanceChange}
                        />
                        <Form.Check
                          reverse="true"
                          label="High"
                          name="Importance-Status"
                          type="radio"
                          id={`title`}
                          data-value="High"
                          checked={this.state.importanceValue === "High" ? true : false}
                          onChange={this.handleImportanceChange}
                        />
                      </div>

                      <div className="label-sm">Read Status</div>
                      <div style={{ display: "flex", padding: "5px" }}>
                        <Form.Check
                          reverse="true"
                          label="Read"
                          name="Read-Status"
                          type="radio"
                          id="rbRead"
                          checked={this.state.isRead}
                          onChange={this.handleIsReadChange}
                        />

                        <Form.Check
                          reverse="true"
                          label="UnRead"
                          name="Read-Status"
                          type="radio"
                          id="rbUnRead"
                          checked={!this.state.isRead}
                          onChange={this.handleIsReadChange}
                        />
                      </div>

                      <div className="label-sm">Sent From</div>
                      <DatePicker
                        selected={this.state.sentFromValue}
                        onChange={this.handleSentFromChange}
                        name="sentFromDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />

                      <div className="label-sm">Sent To</div>
                      <DatePicker
                        selected={this.state.sentToValue}
                        onChange={this.handleSentToChange}
                        name="sentToDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />
                      
                      <div className="label-sm">Received From</div>
                      <DatePicker
                        selected={this.state.receivedFromValue}
                        onChange={this.handleReceivedFromChange}
                        name="receivedFromDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />

                      <div className="label-sm">Received To</div>
                      <DatePicker
                        selected={this.state.receivedToValue}
                        onChange={this.handleReceivedToChange}
                        name="receivedToDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />


                    </div>
                    <div id="divAdvancedSearch" style={{ display: this.state.isAdvanced ? "block" : "none" }}>
                      <div className="label" style={{ textAlign: "left" }}>
                        Boolean Operators
                        <div style={{ textAlign: "left", display: "flex" }}>
                          <Form.Check
                            key="chkAnd"
                            type="checkbox"
                            id="chkAnd"
                            label="AND"
                            data-value="and"
                            size="small"
                            onChange={this.handleOpChange}
                            checked={this.state.opVal === "and" ? true : false}
                          />
                          <Form.Check
                            key="chkOr"
                            type="checkbox"
                            id="chkOr"
                            label="OR"
                            data-value="or"
                            size="small"
                            onChange={this.handleOpChange}
                            checked={this.state.opVal === "or" ? true : false}
                          />
                          {/* <Form.Check
                            key="chkNot"
                            type="checkbox"
                            id="chkNot"
                            label="NOT"
                            data-value="not"
                            size="small"
                            onChange={this.handleOpChange}
                            checked={this.state.opVal === "not" ? true : false}
                          /> */}
                        </div>
                      </div>

                      <div className="label" style={{ textAlign: "left" }}>
                        Field-Specific Search
                        <Form.Check
                          key="rbSubject"
                          type="radio"
                          id="rbSubject"
                          label="Subject"
                          data-value="subject"
                          size="small"
                          name="FieldSearch"
                          checked={this.state.fieldSpecificVal == "subject" ? true: false}
                          onChange={this.handleFieldSpecificChange}
                        />
                        <Form.Check
                          key="rbBody"
                          type="radio"
                          id="rbBody"
                          label="Body"
                          data-value="message"
                          size="small"
                          name="FieldSearch"
                          checked={this.state.fieldSpecificVal == "message" ? true: false}
                          onChange={this.handleFieldSpecificChange}
                        />
                        <Form.Check
                          key="rbAtt"
                          type="radio"
                          id="rbAtt"
                          label="Attachments"
                          data-value="attachments"
                          size="small"
                          name="FieldSearch"
                          checked={this.state.fieldSpecificVal == "attachments" ? true: false}
                          onChange={this.handleFieldSpecificChange}
                        />

                      </div>

                      <div className="label" style={{ textAlign: "left" }}>
                        Phrase Search
                        <input
                          type="text"
                          id="txtPhrase"
                          name=""
                          placeholder="Phrase"
                          className="form-control"
                          value={this.state.phraseVal}
                          onChange={this.handlePhraseChange}
                        />
                      </div>

                      <div className="label" style={{ textAlign: "left" }}>
                        Proximity Search<br />
                        <div style={{ textAlign: "left", display: "flex" }}>
                          <input
                            type="text"
                            id="txtTerm1"
                            name=""
                            placeholder="Term 1"
                            className="form-control"
                            value={this.state.proximityTerm1}
                            onChange={this.handleProximityTerm1Change}
                          />

                          <input
                            type="text"
                            id="txtTerm2"
                            name=""
                            placeholder="Term 2"
                            className="form-control"
                            value={this.state.proximityTerm2}
                            onChange={this.handleProximityTerm2Change}
                          />
                        </div> </div>

                      <div className="label" style={{ textAlign: "left" }}>
                        Wildcards<br />
                        <input
                          type="text"
                          id="txtWildcards"
                          name=""
                          placeholder="Term *"
                          className="form-control"
                          value={this.state.wildcardsVal}
                          onChange={this.handleWildcardsChange }
                        />
                      </div>

                      <div className="label" style={{ textAlign: "left" }}>
                        Fuzzy Search<br />
                        <input
                          type="text"
                          id="txtFuzzy"
                          name=""
                          placeholder="Term ~"
                          className="form-control"
                          value={this.state.fuzzyVal}
                          onChange={this.handleFuzzyChange}
                        />
                      </div>

                      <div className="label" style={{ textAlign: "left" }}>
                        Date Range
                      </div>
                      <div className="label-sm">From</div>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDate}
                        name="startDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />

                      <div className="label-sm mt-2">To</div>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDate}
                        name="endDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />


                      <div className="label-sm">From</div>
                      <input
                        type="text"
                        id="txtAdFrom"
                        name=""
                        className="form-control"
                        value={this.state.fromValue}
                        onChange={this.handleFromChange}
                      />

                      <div className="label-sm">To/CC/BCC</div>
                      <input
                        type="text"
                        id="txtAdvanceTo"
                        name=""
                        className="form-control"
                        value={this.state.toValue + "," + this.state.ccValue + "," +this.state.bccValue }
                        onChange={this.handleAdvanceToChange}
                      />

                      <div className="label-sm">Importance</div>
                      <div style={{ display: "flex", padding: "5px" }}>
                        <Form.Check
                          reverse="true"
                          label="Low"
                          name="Importance-Status"
                          type="radio"
                          id="Low"
                          data-value="Low"
                          checked={this.state.importanceValue === "Low" ? true : false}
                          onChange={this.handleImportanceChange}
                        />
                        <Form.Check
                          reverse="true"
                          label="Normal"
                          name="Importance-Status"
                          type="radio"
                          id="Normal"
                          data-value="Normal"
                          checked={this.state.selectedSearchFieldType == "Normal" ? true : false}
                          onChange={this.handleImportanceChange}
                        />
                        <Form.Check
                          reverse="true"
                          label="High"
                          name="Importance-Status"
                          type="radio"
                          id="High"
                          data-value="High"
                          checked={this.state.selectedSearchFieldType == "High" ? true : false}
                          onChange={this.handleImportanceChange}
                        />
                      </div>
                      <div className="label-sm">Read Status</div>
                      <div style={{ display: "flex", padding: "5px" }}>
                        <Form.Check
                          reverse="true"
                          label="Read"
                          name="Read-Status"
                          type="radio"
                          id="rbReadAd"
                          data-value="Read"
                          checked={this.state.isRead}
                          onChange={this.handleIsReadChange}
                        />

                        <Form.Check
                          reverse="true"
                          label="UnRead"
                          name="Read-Status"
                          type="radio"
                          id="rbUnReadAd"
                          data-value="UnRead"
                          checked={!this.state.isRead}
                          onChange={this.handleIsReadChange}
                        />
                      </div>

                      <div className="label" style={{ textAlign: "left" }}>Custom Query</div>
                      <input
                        type="text"
                        id="txtCustomQuery"
                        name=""
                        className="form-control"
                        value={this.state.customQueryVal}
                        onChange={this.handleCustomQueryChange}
                      />
                      < br />
                      <div className="input-group-append">
                        <Button
                          id="btnAdvanceSearch"
                          type="button"
                          variant="info"
                          className="btn waves-effect waves-light"
                          onClick={(x) => this.searchEmail(false)}
                        >
                          <FontAwesomeIcon
                            icon={faSearch}
                            size="1x"
                            className="mr-2"
                          />
                          Search
                        </Button>
                      </div>
                    </div>
    </div>)
  }
  componentDidUpdate(prevProps) {
    if (this.props.ui.searchEmailResult !== prevProps.ui.searchEmailResult) {
      this.setState({ searchIsActive: false });
    }
  }


  handleIsAttchmentCheck = (e) => {
    this.setState({ isAttchment: !this.state.isAttchment });
  };
  handleIsReadChange = (e) => {
    this.setState({ isRead: !this.state.isRead });
  };
  handleImportanceChange = (e) => {
    this.setState({ importanceValue: e.target.attributes["data-value"].value });
  };
  handleChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  handleFromChange = (e) => {
    this.setState({ fromValue: e.target.value });
  };
  handleToChange = (e) => {
    this.setState({ toValue: e.target.value });
  };
  handleCCChange = (e) => {
    this.setState({ ccValue: e.target.value });
  };
  handleBCCChange = (e) => {
    this.setState({ bccValue: e.target.value });
  };
  handleAdvanceToChange = (e) => {
    this.setState({ toValue: e.target.value,ccValue: e.target.value, bccValue: e.target.value  });
  };
  handleSubjectChange = (e) => {
    this.setState({ subjectValue: e.target.value });
  };
  handleFolderChange = (e) => {
    this.setState({ folderValue: e.target.value });
  };
  handleStartDate = (e) => {
    this.setState({ startDate: e });
  };

  handleEndDate = (e) => {
    this.setState({ endDate: e });
  };
  handleOpChange = (e) => {
    var value = e.target.attributes["data-value"].value;
    this.setState({ opVal: value });
  };
  static displayName = SearchEmail.name;

  handleSentFromChange = (e) => {
    this.setState({ sentFromValue: e });
  };
  handleSentToChange = (e) => {
    this.setState({ sentToValue: e });
  };

  handleReceivedFromChange= (e) => {
    this.setState({ receivedFromValue: e });
  };
  handleReceivedToChange = (e) => {
    this.setState({ receivedToValue: e });
  };
  handleFieldSpecificChange = (e) => {
    var value = e.target.attributes["data-value"].value;
    this.setState({ fieldSpecificVal: value });
  };
  handleCustomQueryChange = (e) => {
    this.setState({ customQueryVal : e.target.value });
  };
  handlePhraseChange = (e) => {
    this.setState({ phraseVal : e.target.value });
  };
  handleProximityTerm1Change = (e) => {
    this.setState({ proximityTerm1 : e.target.value });
  };
  handleProximityTerm2Change = (e) => {
    this.setState({ proximityTerm2 : e.target.value });
  };
  handleWildcardsChange = (e) => {
    this.setState({ wildcardsVal : e.target.value });
  };
  handleFuzzyChange = (e) => {
    this.setState({ fuzzyVal : e.target.value });
  };
  openEmail = (subject) => {
    this.setState({ documentModalTitle: subject });
    this.setState({ documentModalIsOpen: true });
  };
  closeDocumentModal = () => this.setState({ documentModalIsOpen: false });

  handleChangeSwitch = (e) => {
    this.setState({ isAdvanced: !this.state.isAdvanced });
  };

  render() {
    return (
      <div className="content">
        <div className="container max-container">
          <div className="row max-height">
            <Box
              sx={{
                position: "fixed",
                top: "13px",
                left: "0px",
                zIndex: "9999",
              }}
            >
              <IconButton
                onClick={() =>
                  this.setState({
                    searchSidePanelOpenEmail: !this.state.searchSidePanelOpenEmail,
                  })
                }
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Grid container>
              {this.state.searchSidePanelOpenEmail && (
                 <Grid item xs={2}>
                  <Drawer
                    sx={{
                      width: 240,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: 240,
                        boxSizing: "border-box",
                      },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={this.state.searchSidePanelOpenEmail}
                    PaperProps={{
                      style: {
                        backgroundColor: "#3C3C3C",
                        color: "rgba(255, 255, 255, 0.8)",
                        paddingLeft: "24px",
                        paddingTop: "20px",
                        paddingBottom: "50px",
                        zIndex: "9999",
                      },
                    }}
                  >
                  <div
                      className="sidebar-container"
                      style={{
                        paddingLeft: "0px",
                        paddingTop: "2px",
                      }}
                    >
                      {this.generateSideBar()}
                    </div>
                </Drawer>
                </Grid>
              )}

            <Grid item xs={this.state.searchSidePanelOpenEmail ? 10 : 12} pl={2}>  
                <Grid item xs={12}>
                  <Box
                    className={`app-breadcrumbs-container`}
                    sx={{
                      width: "100%",
                      paddingBottom: "5px",
                      borderBottom: "1px dotted #ccc",
                      marginBottom: "15px",
                    }}
                  >
                    <Breadcrumbs
                      className={"connections-breadcrumbs"}
                      aria-label="breadcrumb"
                      separator={<NavigateNextIcon fontSize="small" />}
                    >
                      <Typography color="text.primary">Dashboard</Typography>
                      <Typography color="text.primary">Emails</Typography>
                      <Typography color="text.primary">Search</Typography>
                    </Breadcrumbs>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="row"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="col-md-12">
                      <div className="pt-3 pb-4">
                        <div className="input-group">
                          <input
                            type="text"
                            id=""
                            name=""
                            className="form-control search-input"
                            value={this.state.searchValue}
                            onChange={this.handleChange}
                          />
                          <div className="input-group-append">
                            <Button
                              type="button"
                              variant="info"
                              className="btn waves-effect waves-light search-button"
                              onClick={(x) => this.searchEmail(false)}
                            >
                              <FontAwesomeIcon
                                icon={faSearch}
                                size="1x"
                                className="mr-2"
                              />
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div style={{ height: "100%" }}>
                    <div className="border-element max-content-height search-result-body">
                      <div className="search-result-box card-box">
                        {this.state.searchIsActive ? (
                          <div className="row">
                            <div className="col-lg-12 text-center">
                              <div className="loader-panel">
                                <Circles
                                  color="#61a098"
                                  height={80}
                                  width={80}
                                />
                              </div>
                            </div>
                          </div>
                        ) : this.props.ui.searchEmailResult.emails  &&
                          this.props.ui.searchEmailResult.emails.length > 0 ? (
                          <div
                            className="result-label"
                            style={{ marginLeft: "31px" }}
                          >
                            <span>
                              {`Search result 1 - ${this.props.ui.searchEmailResult.emails.length} of Page #${this.state.currentPage} for "`}
                              <b style={{ color: "#007bff" }}>
                                {this.state.searchValue}
                              </b>
                              "
                            </span>
                            {this.state.startDate && this.state.endDate ? (
                              <span>
                                {" Date between: "}
                                {format(
                                  new Date(this.state.startDate),
                                  "dd/MM/yyyy"
                                )}{" "}
                                -{" "}
                                {format(
                                  new Date(this.state.endDate),
                                  "dd/MM/yyyy"
                                )}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <div className="font-md mt-3">
                            <b>
                              {this.state.searched
                                ? "Nothing found!"
                                : "Use search to find the Emails you want"}
                            </b>
                          </div>
                        )}

                        <div className="tab-content search-container">
                          {this.state.searchIsActive
                            ? ""
                            : this.generateEmails()}
                        </div>
                      </div>

                      <div className="text-center bottom-panel">
                        <div
                          className="btn-group button-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          {this.state.currentPage > 1 ? (
                            <a
                              onClick={(x) => this.searchPreviousPage()}
                              className="button-left"
                            >
                              Previous page
                            </a>
                          ) : (
                            <a className="button-left"></a>
                          )}
                          {this.props.ui.searchEmailResult !== "" &&
                            this.props.ui.searchEmailResult.isLastPage !== true ? (
                            <a
                              onClick={(x) => this.searchNextPage()}
                              className="button-right"
                            >
                              Next page
                            </a>
                          ) : (
                            <a className="button-right"></a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Modal
              show={this.state.documentModalIsOpen}
              onHide={this.closeDocumentModal}
              size="lg"
              centered
              style={{ zIndex: "9999" }}
            >
              <Modal.Title>
                <label className="main-title ml-3 mt-3">
                  {this.state.documentModalTitle}
                </label>
              </Modal.Title>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6"></div>
                        </div>
                        <div className="card shadow-none mt-3 border border-light">
                          <div
                            className="card-body"
                            style={{ maxHeight: "60vh", overflow: "auto" }}
                          >
                            {this.state.documentModalContentType?.startsWith(
                              "image/"
                            ) !== true ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.state.documentModalText,
                                }}
                              />
                            ) : (
                              <img
                                style={{ maxWidth: "100%" }}
                                src={`data:${this.state.documentModalContentType};base64,${this.state.documentModalAttachment}`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeDocumentModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
};

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const collection = props?.ui?.collectionInfo;
  let members = collection?.searchMembers || [];
  if (collection?.createdByUserId) {
    members = [...members, collection.createdByUserId];
  }

  return (
    <SearchAccessGuard members={members} showDeniedPage={true}>
      <Component
        {...props}
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </SearchAccessGuard>
  );
};

export const SearchEmailNav = withNavigate(SearchEmail);
