import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomTable from "../../composite/table/CustomTable";
import { fetchRoles } from "../../../redux/actions/settings/settingsActions";
import rolesTableHeadCells from "./rolesTableHeadCells";
import swal from "sweetalert";
import api from "../../../utils/api";
import axios from "../../../utils/axios";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { showApiError } from "../../../utils/functions";
import RolesForm from "./form/RolesForm";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const Roles = () => {
  const dispatch = useDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  const toggleDrawer = (event, open) => {
    setIsDrawerOpen(open);
  };

  const { roleList } = useSelector((state) => state.settings);

  const handleDeleteRole = (e, roleId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this role?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        axios
          .delete(api.role.delete(roleId))
          .then((response) => {
            swal("Success!", "Deleted Successfully", "success");
            dispatch(fetchRoles());
          })
          .catch(showApiError);
      }
    });
  };

  const handleEditRole = (e, roleId) => {
    setIsAdd(false);
    setIsDrawerOpen(!isDrawerOpen);
    const selectedRole = roleList.data.filter((item) => item.id === roleId);
    setCurrentRole(selectedRole[0]);
  };

  return (
    <>
      <CustomTable
        showSerialNo={true}
        rows={roleList.data}
        headCells={rolesTableHeadCells(handleEditRole, handleDeleteRole)}
        isLoading={roleList.isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"ROLES"}
          onRefreshEvent={() => dispatch(fetchRoles())}
          addButton={
            <AuthorizeGuard module={"ROLES"} action={"Add"}>
              <Button
                onClick={(e) => {
                  toggleDrawer(e, true);
                  setCurrentRole(null);
                  setIsAdd(true);
                }}
              >
                <AddIcon />
                Add new role
              </Button>
            </AuthorizeGuard>
          }
        />
      </CustomTable>
      <RolesForm
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
        currentRole={currentRole}
        isAdd={isAdd}
      />
    </>
  );
};

export default Roles;
