import swal from "sweetalert";

export const showApiError = (error, title, callback) => {
  const errorTitle = title || "Error!";
  const errorMessage =
    error?.response?.data?.message || error?.message || "Something went wrong";
  try {
    var parsedError = JSON.parse(errorMessage);
    if (parsedError.hasOwnProperty("message")) {
      swal(errorTitle, parsedError.message, "error");
    } else {
      swal(errorTitle, JSON.stringify(parsedError), "error");
    }
  } catch (error) {
    swal(errorTitle, errorMessage, "error");
  }
  if (callback) callback(error);
};
