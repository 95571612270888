export const operators = {
      "date": [
        { value: "Between", label: "Between",property:[
            {name:"Start Date",type:"date"},
            {name:"End Date",type:"date"},
        ] },
        { value: "After", label: "After" ,property:[
            {name:"Date",type:"date"},
        ] },
        { value: "Before", label: "Before",property:[
            {name:"Date",type:"date"},
        ] },
      ],
      "strings": [
        { value: "EqualAnyOf", label: "Equals any of",property:[
            {name:"Number",type:"MUIchipset"},
        ] },
        { value: "EqualNoneOf", label: "Equals none of",property:[
            {name:"Number",type:"MUIchipset"},
        ] },
        { value: "ContainsAnyOf", label: "Contains any of",property:[
            {name:"Number",type:"MUIchipset"},
        ] },
        { value: "ContainsNoneOf", label: "Contains none of",property:[
            {name:"Number",type:"MUIchipset"},
        ] },
      ],
      "number": [
        { value: "GreaterThan", label: "Greater than",property:[
            {name:"Number",type:"text"},
        ]  },
        { value: "GreaterOrEqual", label: "Greater or equal",property:[
            {name:"Number",type:"text"},
        ]  },
        { value: "LessThan", label: "Less than",property:[
            {name:"Number",type:"text"},
        ]  },
        { value: "LessOrEqual", label: "Less or equal",property:[
            {name:"Number",type:"text"},
        ]  },
        { value: "Between", label: "Between",property:[
            {name:"Start",type:"text"},
            {name:"End",type:"text"},
        ]  },
        { value: "Equal", label: "Equal",property:[
            {name:"Number",type:"text"},
        ]  },
      ],
      "enum": [
        { value: "EqualAnyOf", label: "Equals any of",property:[
            {name:"Number",type:"MUIchipset"},
        ]  },
        { value: "EqualNoneOf", label: "Equals none of",property:[
            {name:"Number",type:"MUIchipset"},
        ]  },
      ]
    };
export const PropertyOperatorMapping = {
    "Date":operators["date"],
    "SenderOrAuthor": operators['strings'],
    "Size":operators['number'],
    "SubjectOrTitle":operators['strings'],
   "RetentionLabel": operators['strings'],
   "MessageKind": operators['enum'],
    "Participants": operators['strings'],
     "Type": operators['enum'],
      "Received": operators['date'],
      "Recipients": operators['strings'],
      "Sender": operators['strings'],
      "Sent": operators['date'],
       "Subject": operators['strings'],
      "To": operators['strings'],
      "Title": operators['strings'],
      "Created":operators['date'],
      "LastModified": operators['date'],
    "FileType": operators['enum'],

}
  
export const PropertyList = [
      {
        propertyLabel: "Date",
        propertyName: "Date",
      },
      {
        propertyLabel: "Sender/Author",
        propertyName: "SenderOrAuthor",
      },
      {
        propertyLabel: "Size (in bytes)",
        propertyName: "Size",
      },
      {
        propertyLabel: "Subject/Title",
        propertyName: "SubjectOrTitle",
      },
      {
        propertyLabel: "Retention label",
        propertyName: "RetentionLabel",
      },
      {
        propertyLabel: "Message Kind",
        propertyName: "MessageKind",
      },
      {
        propertyLabel: "Participants",
        propertyName: "Participants",
      },
      {
        propertyLabel: "Type",
        propertyName: "Type",
      },
      {
        propertyLabel: "Received",
        propertyName: "Received",
      },
      {
        propertyLabel: "Recipients",
        propertyName: "Recipients",
      },
      {
        propertyLabel: "Sender",
        propertyName: "Sender",
      },
      {
        propertyLabel: "Sent",
        propertyName: "Sent",
      },
      {
        propertyLabel: "Subject",
        propertyName: "Subject",
      },
      {
        propertyLabel: "To",
        propertyName: "To",
      },
      {
        propertyLabel: "Title",
        propertyName: "Title",
      },
      {
        propertyLabel: "Created",
        propertyName: "Created",
      },
      {
        propertyLabel: "Last modified",
        propertyName: "LastModified",
      },
      {
        propertyLabel: "File type",
        propertyName: "FileType",
      }
    ];

  