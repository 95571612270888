import { createSlice } from "@reduxjs/toolkit";
import { fetchReviewSetsContentsByReviewSetsId,createReviewSetsContentsByReviewSetsId } from "../actions/reviewSets/reviewSetsContentsAction";
const reviewSetsContentsSlice = createSlice({
  name: "reviewSetsContents",
  initialState: {
    reviewSetContents: {
      isLoading: false,
      caseId: null,
      data: [],
      error: null,
    },
    createdReviewSetContents: {
      isLoading: false,
      status: null,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewSetsContentsByReviewSetsId.pending, (state) => {
        state.reviewSetContents.isLoading = true;
        state.reviewSetContents.error = null;
      })
      .addCase(fetchReviewSetsContentsByReviewSetsId.fulfilled, (state, action) => {
        state.reviewSetContents.isLoading = false;
        state.reviewSetContents.caseId = action.payload.caseId;
        state.reviewSetContents.data = action.payload.data;
      })
      .addCase(fetchReviewSetsContentsByReviewSetsId.rejected, (state, action) => {
        state.reviewSetContents.isLoading = false;
        state.reviewSetContents.error = action.error;
        state.reviewSetContents.data = [];

      })

      .addCase(createReviewSetsContentsByReviewSetsId.pending, (state) => {
        state.createdReviewSetContents.isLoading = true;
        state.createdReviewSetContents.error = null;
      })
      .addCase(createReviewSetsContentsByReviewSetsId.fulfilled, (state) => {
        state.createdReviewSetContents.isLoading = false;
        state.createdReviewSetContents.status = "success";
      })
      .addCase(createReviewSetsContentsByReviewSetsId.rejected, (state, action) => {
        console.log(action, "col action");
        state.createdReviewSetContents.isLoading = false;
        state.createdReviewSetContents.error = action.error;
      })
  },
});

export default reviewSetsContentsSlice.reducer;
