import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";

const CustomTableToolbar = ({
  children,
  title,
  searchTerm,
  searchBoxLabel,
  onSearchEvent,
  refreshButtonText,
  onRefreshEvent,
  addButton,
  addButtonText,
  onAddEvent,
  checkOption = false,
  numSelected = 0,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        {title ? (
          <Typography
            variant="h6"
            fontWeight="bold"
            style={{ marginRight: "auto" }}
          >
            {title}
          </Typography>
        ) : (
          <></>
        )}
        {checkOption && numSelected > 0 && (
          <Typography
            mr={2}
            color="inherit"
            variant="subtitle1"
            component="span"
          >
            {numSelected} selected
          </Typography>
        )}

        {children}

        {onSearchEvent ? (
          <TextField
            label={searchBoxLabel || "Search"}
            size="small"
            value={searchTerm}
            onChange={onSearchEvent}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        ) : (
          <> </>
        )}

        {onRefreshEvent ? (
          <Button onClick={onRefreshEvent}>
            <RefreshIcon /> {refreshButtonText || "Refresh"}
          </Button>
        ) : (
          <></>
        )}
        {addButton ? (
          <>{addButton}</>
        ) : !addButton && onAddEvent ? (
          <Button onClick={onAddEvent}>
            <AddIcon />
            {addButtonText || "Add new"}
          </Button>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

CustomTableToolbar.displayName = "CustomTableToolbar";
export default CustomTableToolbar;
