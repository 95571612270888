import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  TextField,
  InputAdornment,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useParams } from "react-router-dom";
import { fetchReviewSetsContentsByReviewSetsId } from "../../../../../redux/actions/reviewSets/reviewSetsContentsAction";
import CustomTable from "../../../../composite/table/CustomTable";
import reviewSetsContentsTableHeadCells from "./reviewSetsContentsTableHeadCells";
import { fetchReviewSetsContentsByQuerySetId } from "../../../../../redux/actions/reviewSets/querySetResultAction";
import { fetchQuerySetByReviewSetId } from "../../../../../redux/actions/reviewSets/querySetAction";
import ExportDrawer from "./reviewSetsContentsExport";
import { fetchCaseById } from "../../../../../redux/actions/caseActions";
import { handleBreadcrumb } from "../../../../../redux/actions/utilsActions";
import CaseRunningJobs from "../../../../common/CaseRunningJobs";

const ReviewSetsContents = () => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const queryString = new URLSearchParams(location.search);
  const reviewSetName = queryString.get("rs");
  const queryName = queryString.get("query");
  const [query, setQuery] = useState("Reviewset");
  const [exportDrawerOpen, setExportDrawerOpen] = useState(false);
  const [extension, setExtension] = useState("all");
  const [sourceType, setSourceType] = useState("all");
  const [status, setStatus] = useState("all");
  const [isFilter, setIsFilter] = useState(false);
  const [filteredContents, setFilteredContents] = useState([]);
  const [alert, setAlert] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState(null);

  useEffect(() => {
    if (location.state && "query" in location.state) {
      setQuery(location.state.query);
      dispatch(
        fetchReviewSetsContentsByQuerySetId({
          caseId: params.caseId,
          reviewSetId: params.reviewSetId,
          querySetId: location.state.query,
        })
      );
    } else {
      dispatch(
        fetchReviewSetsContentsByReviewSetsId({
          caseId: params.caseId,
          reviewSetId: params.reviewSetId,
        })
      );
    }
  }, [location?.state?.query]);

  useEffect(() => {
    dispatch(
      fetchQuerySetByReviewSetId({
        caseId: params.caseId,
        reviewSetId: params.reviewSetId,
      })
    );
    dispatch(fetchCaseById(params.caseId));
  }, []);

  const { reviewSetContents } = useSelector(
    (state) => state.reviewSetsContents
  );
  const { queryList } = useSelector((state) => state.queries);
  const { currentCase } = useSelector((state) => state.cases);

  const getBreadcrumbs = (qId, qName) => {
    const breadcrumbs = {
      route: location.pathname,
      className: "review-sets-detail-breadcumbs",
      links: [
        { url: "/cases", text: "Cases" },
        {
          url: `/cases/${currentCase?.details.id}?tab=0`,
          text: currentCase?.details.displayName,
        },
        {
          url: `/cases/${currentCase?.details.id}?tab=2`,
          text: "Reviewsets",
        },
        { text: reviewSetName },
      ],
    };
    if (qId) {
      breadcrumbs.links.push({
        url: `/cases/${currentCase?.details.id}/review-sets/${params.reviewSetId}/queries?rs=${reviewSetName}`,
        text: "Queries",
      });
      breadcrumbs.links.push({
        text: qName,
      });
    }
    breadcrumbs.links.push({ text: "Files" });
    dispatch(handleBreadcrumb(breadcrumbs));
  };

  useEffect(() => {
    if (reviewSetContents.error) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, [reviewSetContents.error]);

  useEffect(() => {
    getBreadcrumbs(location?.state?.query, queryName);
  }, [currentCase]);

  const isLoading = reviewSetContents.isLoading;
  const reviewSetRows = reviewSetContents?.data?.files
    ? reviewSetContents.data.files.map((item) => ({
        ...item,
        caseId: params.caseId,
        reviewSetId: params.reviewSetId,
      }))
    : [];
  const extensions = reviewSetContents.data.extensions;
  const sourceTypes = reviewSetContents.data.sourceTypes;
  const statuses = reviewSetContents.data.statuses;

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value === "Reviewset") {
      dispatch(
        fetchReviewSetsContentsByReviewSetsId({
          caseId: params.caseId,
          reviewSetId: params.reviewSetId,
        })
      );
      getBreadcrumbs(null, null);
    } else {
      const selectedQueryId = e.target.value;
      dispatch(
        fetchReviewSetsContentsByQuerySetId({
          caseId: params.caseId,
          reviewSetId: params.reviewSetId,
          querySetId: selectedQueryId,
        })
      );
      const selectedQueryObj = queryList?.data
        ? queryList.data.find((q) => q.id === selectedQueryId)
        : null;
      setSelectedQuery(selectedQueryObj);
      getBreadcrumbs(selectedQueryId, selectedQueryObj?.displayName);
    }
  };

  const handleExtensionChange = (event) => {
    setExtension(event.target.value);
    setIsFilter(true);
    setFilteredContents(
      reviewSetRows.filter(
        (item) =>
          (event.target.value === "all" || item.extension === event.target.value
            ? true
            : false) &&
          (sourceType === "all" || item.type === sourceType) &&
          (status === "all" || item.processingStatus === status)
      )
    );
  };

  const handleSourceTypeChange = (event) => {
    setSourceType(event.target.value);
    setIsFilter(true);
    setFilteredContents(
      reviewSetRows.filter(
        (item) =>
          (event.target.value === "all" || item.type === event.target.value
            ? true
            : false) &&
          (extension === "all" || item.extension === extension) &&
          (status === "all" || item.processingStatus === status)
      )
    );
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setIsFilter(true);
    setFilteredContents(
      reviewSetRows.filter(
        (item) =>
          (event.target.value === "all" ||
          item.processingStatus === event.target.value
            ? true
            : false) &&
          (extension === "all" || item.extension === extension) &&
          (sourceType === "all" || item.type === sourceType)
      )
    );
  };

  const handleSearch = (event) => {
    setExtension("");
    setSourceType("");
    setStatus("");
    const searchText = event.target.value.trim().toLowerCase();
    if (!searchText) {
      setFilteredContents([]);
    } else {
      const filteredData = reviewSetRows?.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText) ||
          item.processingStatus.toLowerCase().includes(searchText) ||
          item.sourceType.toLowerCase().includes(searchText) ||
          item.id.toLowerCase().includes(searchText)
      );
      setFilteredContents(filteredData);
    }
  };

  const toggleExportDrawer = (event, open) => {
    setExportDrawerOpen(open);
  };

  const handleSelectedRows = (rows) => {
    const ids = rows ? rows.map((x) => x.id) : [];
    setSelectedItemIds(ids);
  };

  return (
    <>
      <ExportDrawer
        key={query}
        openDrawer={exportDrawerOpen}
        toggleDrawer={toggleExportDrawer}
        queryId={query}
        selectedItemIds={selectedItemIds}
        name={query !== "Reviewset" ? selectedQuery?.displayName : reviewSetName}
      />
      {params.caseId ? <CaseRunningJobs caseId={params.caseId} /> : <></>}
      <Grid container>
        <Grid item xs={12}>
          {alert && (
            <Alert
              sx={{ marginTop: "10px" }}
              severity="error"
              onClose={() => setAlert(false)}
            >
              Something went wrong!
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="queries-select-label"
                  id="queries-select"
                  value={query}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleQueryChange}
                  sx={{
                    marginBottom: 2,
                    marginTop: 2,
                    width: "100%",
                  }}
                >
                  <MenuItem value={"Reviewset"}>
                    {reviewSetName || "Reviewset"}
                  </MenuItem>
                  {queryList &&
                    queryList.data.map((query, index) => {
                      return (
                        <MenuItem key={index} value={query.id}>
                          {query.displayName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            {queryList && query && query !== "" && query !== "Reviewset" ? (
              <Grid item xs={9}>
                <Typography
                  style={{
                    color: "black",
                    fontWeight: 600,
                    marginLeft: "20px",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "700px",
                  }}
                >
                  {queryList.data.find((q) => q.id === query)?.query}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={9}></Grid>
            )}
            <Grid item xs={1} display="flex" justifyContent="flex-end">
              <Button onClick={(e) => toggleExportDrawer(e, true)}>
                <AddIcon /> Export
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          marginBottom={2}
        >
          <Grid item xs={12} display="flex" flexDirection="row">
            <Box sx={{ minWidth: 220, marginRight: "10px", width: "16.67%" }}>
              <TextField
                style={{ width: "100%" }}
                onChange={handleSearch}
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ minWidth: 150, marginRight: "10px", width: "16.67%" }}>
              <FormControl fullWidth>
                <InputLabel size="small" id="extensions-filter-label">
                  Extension
                </InputLabel>
                <Select
                  labelId="extensions-filter-label"
                  id="extensions-filter"
                  value={extension}
                  label="Extension"
                  onChange={handleExtensionChange}
                  size="small"
                >
                  <MenuItem value="all">Extension</MenuItem>
                  {extensions?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                    >{`${item.name}(${item.count})`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 150, marginRight: "10px", width: "16.67%" }}>
              <FormControl fullWidth>
                <InputLabel size="small" id="source-type-filter-label">
                  Source
                </InputLabel>
                <Select
                  labelId="source-type-filter-label"
                  id="source-type-filter"
                  value={sourceType}
                  label="Source"
                  onChange={handleSourceTypeChange}
                  size="small"
                >
                  <MenuItem value="all">Source</MenuItem>
                  {sourceTypes?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                    >{`${item.name}(${item.count})`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 150, marginRight: "10px", width: "16.67%" }}>
              <FormControl fullWidth>
                <InputLabel size="small" id="stat-filter-label">
                  Status
                </InputLabel>
                <Select
                  labelId="stat-filter-label"
                  id="stat-filter"
                  value={status}
                  label="Status"
                  onChange={handleStatusChange}
                  size="small"
                >
                  <MenuItem value="all">Status</MenuItem>
                  {statuses?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                    >{`${item.name}(${item.count})`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <CustomTable
            showSerialNo={true}
            rows={isFilter ? filteredContents : reviewSetRows}
            headCells={reviewSetsContentsTableHeadCells()}
            isLoading={isLoading}
            checkOption={true}
            onCheckRows={handleSelectedRows}
            isSearch={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewSetsContents;
