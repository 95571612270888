import PreviewContentButton from "./previewContentButton";
import { format } from "date-fns";

export default function () {
  const exportTableHeadCells = [
    {
      id: "preview",
      tableCellType: "CUSTOM",
      label: "",
      fn: (cell) => { return (<PreviewContentButton key={cell["id"]} row={cell} />) },
      align: "left",
      style: {width: '20px'}
    },
    {
      id: "displayName",
      label: "Name",
    },
    {
      id: "description",
      label: "Description",
    },
    // {
    //   id: "reviewSetName",
    //   label: "Reviewset Name",
    // },
    // {
    //   id: "queryName",
    //   label: "Query Name",
    // },
    {
      id: "createdTime",
      tableCellType: "CUSTOM",
      fn: (cell) => format(new Date(cell["createdTime"]), 'dd MMM yyyy'),
      label: "Created",
    },
  ];
  return exportTableHeadCells;
}
