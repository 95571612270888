import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteQuerySet } from "../../../../../redux/actions/reviewSets/querySetAction";
import EditConditionDrawer from "./editQuery";
import { IconButton } from "@mui/material";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";

const QueryTableActionCell = ({ id, reviewSetName, queryName }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [addQueryDrawerOpen, setAddQueryDrawerOpen] = useState(false);

  const handleDelete = () => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this query?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleteing", "warning");
        dispatch(
          deleteQuerySet({
            caseId: params.caseId,
            reviewSetId: params.reviewSetId,
            queryId: id,
          })
        ).then((res) => {
          if (res.hasOwnProperty("error")) {
          } else {
            swal("Success!", `Query has been deleted!`, "success");
          }
        });
      }
    });
  };

  const handleSearch = () => {
    history(
      `/cases/${params.caseId}/review-sets/${params.reviewSetId}?rs=${reviewSetName}&query=${queryName}`,
      {
        state: {
          query: id,
        },
      }
    );
  };

  const toggleQueryDrawer = (event, open) => {
    setAddQueryDrawerOpen(open);
  };

  return (
    <div style={{ minWidth: "200px" }}>
      {addQueryDrawerOpen && (
        <EditConditionDrawer
          isQueryDrawerOpen={addQueryDrawerOpen}
          toggleQueryDrawer={toggleQueryDrawer}
          queryId={id}
        />
      )}
      <IconButton
        size="small"
        color="default"
        onClick={(e) => {
          handleSearch();
        }}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        size="small"
        color="default"
        onClick={(e) => {
          toggleQueryDrawer(e, true);
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton size="small" color="default" onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default QueryTableActionCell;
