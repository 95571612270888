import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCopy,
  faPlay,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import { Popover, Typography } from "@mui/material";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { format } from "date-fns";
import { Circles } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../../assets/css/sb-admin-2.css";
import "../../../assets/css/main.css";
import swal from "sweetalert";
import "react-datepicker/dist/react-datepicker.css";
import validateConnectionInputs from "./Validator";
import AuthorizeGuard from "../../common/AuthorizeGuard";

import { Box, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export class EditFileConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      searchLocations: null,
      connectionId: this.props.searchParams.get("id"),
      isConnected: false,
      messagesEndRef: React.createRef(),
      changingStatusIsInProcess: false,
      dataInterval: "",
      isConfirmationOpen: false,
      hasValuesChanged: false,
      anchorEl: "",
      renderCount: 0,
    };
    this.enableUpdateButton = this.enableUpdateButton.bind(this);
  }

  static displayName = EditFileConnection.name;
  state = {
    isOpen: false,
  };

  closeConfirmationModal = () => {
    this.setState({ isConfirmationOpen: false });
  };

  openConfirmationModal = () => {
    if (
      !validateConnectionInputs({
        connectionName: this.state.name,
        searchLocations: this.state.searchLocations,
      })
    )
      return;
    this.setState({ isConfirmationOpen: true });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
    this.enableUpdateButton(this.state.searchLocations, e.target.value);
  };

  handleLocationChange = (e) => {
    this.setState({ searchLocations: e.target.value });
    this.enableUpdateButton(e.target.value, this.state.name);
  };

  enableUpdateButton = (locations, name) => {
    if (this.props.ui.connectionInfo !== null) {
      var searchLocationsChanged =
        this.props.ui.connectionInfo.connection.searchLocations !== locations;
      var nameChanged = this.props.ui.connectionInfo.connection.name !== name;
      if (nameChanged || searchLocationsChanged) {
        this.setState({ hasValuesChanged: true });
        return;
      }
    }
    this.setState({ hasValuesChanged: false });
  };

  generateLogs = () => {
    if (this.props.ui.connectionInfo === undefined) {
      return <p>Loading....</p>;
    }
    

    const steps = this.props.ui.connectionInfo?.logs.map((step, index) => (
      <div className="log-container">
        <FontAwesomeIcon icon={faFile} className="fa-2x log-icon" />
        <div className="log-text">
          <div>{step.message}</div>
          <div className="log-date">
            {format(new Date(step.createdDate), "dd/MM/yyyy H:mma")}
          </div>
        </div>
        <br />
      </div>
    ));

    return (
      <div className="logs-block">
        {steps}
        <div ref={this.state.messagesEndRef} />
      </div>
    );
  };

  scrollToBottom = () => {
    this.state.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  changeCrawlerStatus = (status) => {
    var collection = {
      status: status,
      connectionId: this.state.connectionId,
    };
    this.setState({ changingStatusIsInProcess: true });
    this.props.changeCrawlerStatus(collection);
    // this.state.changingStatusIsInProcess = false;
    // setTimeout(() => {
    //   this.setState({ changingStatusIsInProcess: false });
    // }, 5000);
  };

  componentDidUpdate(prevProps) {
    if (this.props.ui.connectionInfo !== prevProps.ui.connectionInfo) {
      this.setState({ changingStatusIsInProcess: false });
      if (this.state.renderCount === 0) {
        this.setState({ name: null, searchLocations: null });
      }
      this.setState({ renderCount: this.state.renderCount + 1 });
    }
  }

  displaySuccess = () => {
    // this.props.ui.component = -1;
    this.props.resetUIAlert();

    swal(
      "Success!",
      `Connection ${this.state.name} has been updated!`,
      "success"
    ).then(() => {
      window.location.reload(false);
    });
  };

  componentDidMount() {
    this.getConnectionInfo();
    this.state.dataInterval = setInterval(() => {
      // this.getConnectionInfo();
      this.props.loadConnectionLog({
        connectionId: this.state.connectionId,
      });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.state.dataInterval);
  }

  getConnectionInfo() {
    var data = {
      connectionId: this.state.connectionId,
    };
    this.props.loadConnectionInfo(data);
  }

  updateServerConnection = () => {
    var connection = {
      id: this.state.connectionId,
      connectionName: this.state.name,
      searchLocations: this.state.searchLocations,
    };
    this.props.updateServerConnection(connection);
  };

  render() {
    {
      this.state.searchLocations =
        this.state.searchLocations === null &&
        this.props.ui.connectionInfo !== null
          ? this.props.ui.connectionInfo.connection.searchLocations
          : this.state.searchLocations;

      this.state.name =
        this.state.name === null && this.props.ui.connectionInfo !== null
          ? this.props.ui.connectionInfo.connection.name
          : this.state.name;
    }

    let connectionName =
      this.props.ui.connectionInfo !== null
        ? this.props.ui.connectionInfo.connection.name
        : "";
    let isSearching =
      this.props.ui.connectionInfo !== null
        ? this.props.ui.connectionInfo.connection.crawlerStatus
        : false;
    let isCrawlerConnected =
      this.props.ui.connectionInfo !== null
        ? this.props.ui.connectionInfo.connection.isCrawlerConnected
        : false;

    return (
      <>
        <Box
          className={`app-breadcrumbs-container`}
          sx={{
            width: "100%",
            paddingBottom: "5px",
            borderBottom: "1px dotted #ccc",
            marginBottom: "15px",
          }}
        >
          <Breadcrumbs
            className={"connections-breadcrumbs"}
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              key={"link_dashboard"}
              underline="hover"
              color="inherit"
              to={"/dashboard"}
            >
              Dashboard
            </Link>

            <Link
              key={"link_dashboard"}
              underline="hover"
              color="inherit"
              to={"/connections"}
            >
              Connections
            </Link>

            <Typography color="text.primary">{connectionName}</Typography>
          </Breadcrumbs>
        </Box>
        <div>
          {this.props.ui.component === 12 ? this.displaySuccess() : null}
          <h3 className="main-title">Connection - {connectionName}</h3>
          <hr className="my-4" />

          <div className="row mt-5">
            <div className="col-12">
              <div class="row">
                <div class="col-6">
                  <div className="panel-area">
                    <div class="form-group">
                      <label className="input-label" for="email">
                        Connection Name
                      </label>
                      <input
                        className="form-control"
                        placeholder={
                          !this.props.ui.connectionInfo
                            ? "Loading...."
                            : "Enter Connection Name"
                        }
                        type="text"
                        value={
                          this.props.ui.connectionInfo !== null
                            ? this.state.name
                            : ""
                        }
                        onChange={(x) => this.handleNameChange(x)}
                      />
                      <small id="emailHelp" class="form-text text-muted">
                        The connection name should be unique and cannot be
                        changed once created.
                      </small>
                    </div>

                    <div class="form-group">
                      <label className="input-label" for="email">
                        Directory Paths
                      </label>
                      <textarea
                        type="email"
                        class="form-control"
                        rows="10"
                        id="email"
                        placeholder={
                          !this.props.ui.connectionInfo
                            ? "Loading...."
                            : "Enter Directory Paths"
                        }
                        value={
                          this.state.searchLocations !== null
                            ? this.state.searchLocations
                            : ""
                        }
                        onChange={(x) => this.handleLocationChange(x)}
                      />
                      <small id="emailHelp" class="form-text text-muted">
                        The Directory path is the starting path for the crawler.
                        The crawler recursively indexes content within the
                        folders. Enter at least one directory path for the
                        collection.
                      </small>
                    </div>

                    <div class="form-group text-right mt-4">
                      <AuthorizeGuard module={"CONNECTIONS"} action={"Edit"}>
                        {this.state.hasValuesChanged ? (
                          <input
                            type="submit"
                            class="btn btn-secondary"
                            value="Update"
                            onClick={() => this.openConfirmationModal()}
                          />
                        ) : (
                          <input
                            type="submit"
                            class="btn btn-secondary disabled"
                            value="Update"
                          />
                        )}
                      </AuthorizeGuard>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div className="panel-area">
                    <div class="form-group">
                      <div className="text-center">
                        <label className="input-label">
                          {connectionName} - Crawler Info
                        </label>
                        <br />
                        <img
                          src="/static/image/pc-icon.png"
                          width="96"
                          height="96"
                          alt="PC"
                        />
                        <span
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          title={"Last crawled device name"}
                        >
                          {this.props.ui.connectionInfo?.connection?.deviceName}
                        </span>
                        <br />
                        <div className="status-container">
                          {isCrawlerConnected ? (
                            <div>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="status-active"
                              />
                              <label className="status-label">Connected</label>
                            </div>
                          ) : (
                            <div>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="status-inactive"
                              />
                              <label className="status-label">
                                Not Connected
                              </label>
                            </div>
                          )}
                          {isSearching ? (
                            <div>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="status-active"
                              />
                              <label className="status-label">Searching</label>
                            </div>
                          ) : (
                            <div>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="status-inactive"
                              />
                              <label className="status-label">Paused</label>
                            </div>
                          )}
                        </div>

                        <div className="mt-3">
                          {!isSearching ? (
                            <div>
                              <AuthorizeGuard
                                module={"CONNECTIONS"}
                                action={"Edit"}
                              >
                                <div
                                  className={
                                    !isCrawlerConnected
                                      ? "disabled btn crawler-btn"
                                      : "btn crawler-btn"
                                  }
                                  onClick={(x) =>
                                    this.changeCrawlerStatus(true)
                                  }
                                >
                                  {this.state.changingStatusIsInProcess ? (
                                    <div className="status-loader-panel">
                                      <Circles
                                        color="#61a098"
                                        height={30}
                                        width={30}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faPlay}
                                        className="status-active"
                                      />
                                      <label className="status-label">
                                        Start
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </AuthorizeGuard>
                            </div>
                          ) : (
                            <div>
                              <AuthorizeGuard
                                module={"CONNECTIONS"}
                                action={"Edit"}
                              >
                                <div
                                  className={
                                    !isCrawlerConnected
                                      ? "disabled btn crawler-btn"
                                      : "btn crawler-btn"
                                  }
                                  onClick={(x) =>
                                    this.changeCrawlerStatus(false)
                                  }
                                >
                                  {this.state.changingStatusIsInProcess ? (
                                    <div className="status-loader-panel">
                                      <Circles
                                        color="#61a098"
                                        height={30}
                                        width={30}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faStop}
                                        className="status-inactive"
                                      />
                                      <label className="status-label">
                                        Stop
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </AuthorizeGuard>
                            </div>
                          )}
                        </div>
                      </div>

                      <hr />
                      <label className="input-label">Connection Code</label>
                      <div className="connection-code-box">
                        {this.props.ui.connectionInfo !== null
                          ? this.props.ui.connectionInfo.connection
                              .connectionCode
                          : "Loading...."}
                        <Button
                          onClick={(event) => {
                            if (
                              this.props.ui.connectionInfo?.connection
                                ?.connectionCode
                            ) {
                              navigator.clipboard.writeText(
                                this.props.ui.connectionInfo.connection
                                  .connectionCode
                              );
                            }
                            this.setState({
                              anchorEl: event.currentTarget,
                            });
                          }}
                          title="Copy"
                          style={{
                            float: "right",
                            marginRight: "10px",
                            padding: "0 5px",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ color: "gray" }}
                          />
                        </Button>
                        <Popover
                          open={Boolean(this.state.anchorEl)}
                          anchorEl={this.state.anchorEl}
                          onClose={() => {
                            this.setState({ anchorEl: null });
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}> Copied </Typography>
                        </Popover>
                      </div>
                      <small id="emailHelp" class="form-text text-muted">
                        Use the code to connect your crawler to the directory{" "}
                        <br />
                        If you have a desktop crawler app,{" "}
                        <a
                          href={
                            window.APP_CONFIG.DESKTOP_APP_URL_PROTOCOL +
                            "://connection/?code=" +
                            this.props.ui?.connectionInfo?.connection
                              ?.connectionCode
                          }
                        >
                          click here
                        </a>{" "}
                        to open OR{" "}
                        <a href={window.APP_CONFIG.DESKTOP_APP_DOWNLOAD_URL}>
                          download
                        </a>{" "}
                        the app and install
                      </small>
                    </div>

                    <div class="form-group">
                      <label className="input-label" for="email">
                        Logs
                      </label>
                      {/* <textarea type="email" class="form-control" rows="10" id="email" placeholder="Enter Directory Paths" disabled="disabled"
                                            value={this.generateLogs()}
                                            onChange={this.handleLocationChange} /> */}
                      {this.generateLogs()}
                      <small id="emailHelp" class="form-text text-muted">
                        Here you will see everything that the crawler does
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={this.state.isConfirmationOpen}
            onHide={this.closeConfirmationModal}
            size="lg"
            className="mt-5"
          >
            <Modal.Title>
              <h4 className="mt-3">Confirmation</h4>
            </Modal.Title>
            <Modal.Body className="ml-2">
              Do you want to update the connection?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={this.updateServerConnection}>
                Yes
              </Button>
              <Button variant="secondary" onClick={this.closeConfirmationModal}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <AuthorizeGuard
      module={"CONNECTIONS"}
      action="Edit"
      showDeniedPage={true}
    >
      <Component
        {...props}
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </AuthorizeGuard>
  );
};

export const EditFileConnectionNav = withNavigate(EditFileConnection);
