import React, { useState } from "react";
import { Button } from "@mui/material";
import mailboxTableHeadCells from "./mailboxTableHeadCells";
import CustomTable from "../../../../../composite/table/CustomTable";
import CustomTableToolbar from "../../../../../composite/table/CustomTableToolbar";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";

const MailboxSelection = ({
  toggleMailboxDrawer,
  isMailboxDrawerOpen,
  tenantUsersList,
  handleSelectedMailboxParent,
}) => {
  const { isLoading, data } = tenantUsersList;
  const [selectedMailboxList, setSelectedMailboxList] = useState([]);

  const handleSelectedRows = (rows) => {
    setSelectedMailboxList(rows);
  };
  const handleSelectedMailbox = () => {
    handleSelectedMailboxParent(selectedMailboxList);
  };
  return (
    <CustomDrawer
      className={"add-mailboxes-drawer"}
      isDrawerOpen={isMailboxDrawerOpen}
      toggleDrawer={toggleMailboxDrawer}
      title={"Exchange mailboxes"}
      subtitle={"Please type minimum 3 characters to get the mailbox list."}
    >
      <CustomTable
        rows={data}
        rowsCount={100}
        headCells={mailboxTableHeadCells()}
        isLoading={isLoading}
        checkOption={true}
        onCheckRows={handleSelectedRows}
        isSearch={true}
      >
        <CustomTableToolbar title={"Mailboxes"}></CustomTableToolbar>
      </CustomTable>
      <CustomDrawerFooter>
        <Button variant="contained" onClick={handleSelectedMailbox}>
          Add
        </Button>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={(e) => toggleMailboxDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default MailboxSelection;
