import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import api from "../../utils/api";
import {
  FETCH_HOLDS,
  CREATE_HOLD,
  UPDATE_HOLD,
  DELETE_HOLD,
  FETCH_HOLD_DETAILS,
  FETCH_USER_SOURCES_By_HOLD,
  FETCH_SITE_SOURCES_By_HOLD,
  ADD_USER_SOURCE,
  ADD_SITE_SOURCE,
  DELETE_USER_SOURCE,
  DELETE_SITE_SOURCE,
} from "../actionTypes/holdTypes";

export const fetchHolds = createAsyncThunk(FETCH_HOLDS, async (caseId) => {
  const response = await axios.get(api.holds.get(caseId));
  return response.data;
});

export const createHold = createAsyncThunk(CREATE_HOLD, async (holdData) => {
  const { caseId, data } = holdData;
  await axios.post(api.holds.create(caseId), data);
});

export const fetchHoldDetails = createAsyncThunk(
  FETCH_HOLD_DETAILS,
  async (data) => {
    const { caseId, holdId } = data;
    const response = await axios.get(api.holds.details(caseId, holdId));
    return response.data;
  }
);

export const fetchUserSourcesByHold = createAsyncThunk(
  FETCH_USER_SOURCES_By_HOLD,
  async (data) => {
    const { caseId, holdId } = data;
    const response = await axios.get(api.holds.userSources(caseId, holdId));
    return response.data;
  }
);

export const fetchSiteSourcesByHold = createAsyncThunk(
  FETCH_SITE_SOURCES_By_HOLD,
  async (data) => {
    const { caseId, holdId } = data;
    const response = await axios.get(api.holds.siteSources(caseId, holdId));
    return response.data;
  }
);

export const updateHold = createAsyncThunk(UPDATE_HOLD, async (data) => {
  const { formData, caseId, holdId } = data;
  await axios.patch(api.holds.update(caseId, holdId), formData);
});

export const deleteHold = createAsyncThunk(DELETE_HOLD, async (data) => {
  const { caseId, holdId } = data;
  const response = await axios.delete(api.holds.delete(caseId, holdId));
  return response;
});

export const addUserSource = createAsyncThunk(ADD_USER_SOURCE, async (data) => {
  const { updatedData, caseId, holdId } = data;
  return await axios.post(api.holds.userSources(caseId, holdId), updatedData);
});

export const deleteUserSource = createAsyncThunk(
  DELETE_USER_SOURCE,
  async (data) => {
    const { caseId, holdId, userSourceId } = data;
    const response = await axios.delete(
      api.holds.deleteUserSource(caseId, holdId, userSourceId)
    );
    return response;
  }
);

export const addSiteSource = createAsyncThunk(ADD_SITE_SOURCE, async (data) => {
  const { updatedData, caseId, holdId } = data;
  const response = await axios.post(
    api.holds.siteSources(caseId, holdId),
    updatedData
  );
  return response;
});

export const deleteSiteSource = createAsyncThunk(
  DELETE_SITE_SOURCE,
  async (data) => {
    const { caseId, holdId, siteSourceId } = data;
    const response = await axios.delete(
      api.holds.deleteSiteSource(caseId, holdId, siteSourceId)
    );
    return response;
  }
);
