export const reviewsetExportOptions = [
  { id: 1, label: "Original Files", value: "OriginalFiles" },
  { id: 2, label: "Text", value: "Text" },
  { id: 3, label: "Pdf Replacement", value: "PdfReplacement" },
  { id: 4, label: "Tags", value: "Tags" },
];

export const reviewsetExportStructures = [
  { id: 1, label: "Directory", value: "Directory" },
  { id: 2, label: "Pst", value: "Pst" },
];

export const REGION_TYPES = [
  { name: "Global", label: "Global (Standard)", description: "" },
  { name: "Germany", label: "Germany", description: "" },
  { name: "China", label: "China", description: "" },
  { name: "Government", label: "USGovernment", description: "" },
];

export const RETENTION_TYPES = [
  {
    name: "SnapshotBased",
    label: "Snapshot-based",
    description: `Each restore point represents the snapshot (actual state) of each mailbox, library or folder at that time of backup, items will be deleted from backup once the last restore point they are contained with leaves the retention period.`,
  },
  {
    name: "ItemLevel",
    label: "Item-level",
    description: `Individual items will be deleted from backup once their creation or last modification date exceeds the data retention period. This is similar to how classic documents archive works, and is useful if you need to ensure that items are not stored in backup longer than required. Using this option increases egress charges when using object storage.`,
  },
];

export const CLOUD_ACCOUNT_TYPES = [
  {
    name: "azureBlobAccount",
    label: "Azure Blob Storage",
    description: "",
  },
  {
    name: "amazonS3Account",
    label: "Amazon S3",
    description: "",
  },
  {
    name: "amazonS3CompatibleAccount",
    label: "Amazon S3 Compatible",
    description: "",
  },
];

export const OBJECT_STORAGE_TYPES = [
  {
    name: "Local",
    label: "Local Storage",
    description:
      "Keeps backups data to server's local disk or network path.",
  },
  {
    name: "AzureBlob",
    label: "Azure Blob Storage",
    description:
      "Keeps backups data to Microsoft Azure Blob Storage of hot and cool tiers.",
  },
  {
    name: "AzureBlobArchive",
    label: "Azure Archive Storage",
    description:
      "Keeps backups data to Microsoft Azure Archive Storage. Use this option for cost-efficient long-term storage of your backup copies. ",
  },
  {
    name: "AmazonS3Compatible",
    label: "S3 Compatible",
    description:
      "Keeps backups data to a cloud object storage provider, or an on-premises object storage system. ",
  },
  {
    name: "AmazonS3",
    label: "Amazon S3",
    description:
      "Keeps backups data to Amazon S3 storage of Standard and Infrequent Access (IA) storage classes. Use this option for your primary backups.",
  },
  {
    name: "AmazonS3Glacier",
    label: "Amazon S3 Glacier",
    description:
      "Keeps backups data to Amazon S3 Glacier storage. S3 Glacier Instant Retrieval, S3 Glacier Flexible Retrieval and S3 Glacier Deep Archive are supported. Use this option for cost-efficient long-term storage of your backup copies.",
  },
  {
    name: "IBMCloud",
    label: "IBM Cloud Object Stoarge",
    description:
      "Keeps backups data to IBM Cloud object storage. S3 compatible versions of both on-premises and IBM Cloud storage offerings are supported.",
  },
  {
    name: "WasabiCloud",
    label: "Wasabi Cloud Storage",
    description: "Keeps backups data to Wasabi cloud object storage.",
  },
];

export const ORG_DEPLOYMENT_TYPES = [
  { name: "Office365", label: "Microsoft 365" },
  { name: "Hybrid", label: "Hybrid" },
  { name: "OnPremises", label: "On-premises" },
];

export const ORG_REGION_TYPES = [
  { name: "Worldwide", label: "Default", description: "" },
  { name: "Germany", label: "Germany", description: "" },
  { name: "China", label: "China", description: "" },
  { name: "USGovernment", label: "Government", description: "" },
  { name: "USDefence", label: "US Defence", description: "" },
];

export const JOB_PERIODICALLY_TIMES = [
  { name: "Minutes5", label: "5 Minutes" },
  { name: "Minutes10", label: "10 Minutes" },
  { name: "Minutes15", label: "15 Minutes" },
  { name: "Minutes30", label: "30 Minutes" },
  { name: "Hours1", label: "1 Hour" },
  { name: "Hours2", label: "2 Hours" },
  { name: "Hours4", label: "4 Hours" },
  { name: "Hours8", label: "8 Hours" },
];

export const JOB_PERIOD_YEARS = [
  { name: "Year1", label: "1 year" },
  { name: "Years2", label: "2 years" },
  { name: "Years3", label: "3 years" },
  { name: "Years5", label: "5 years" },
  { name: "Years7", label: "7 years" },
  { name: "Years10", label: "10 years" },
  { name: "Years25", label: "25years" },
  { name: "Keep", label: "Keep forever" },
];

export const JOB_MONTHLY_DAY_NUMBERS = [
  { name: "First", label: "First" },
  { name: "Second", label: "Second" },
  { name: "Third", label: "Third" },
  { name: "Fourth", label: "Fourth" },
  { name: "Last", label: "Last" },
];

export const JOB_DAY_TYPES = [
  { name: "Everyday", label: "Everyday" },
  { name: "Workdays", label: "Workdays" },
  { name: "Weekends", label: "Weekends" },
  { name: "Saturday", label: "Saturday" },
  { name: "Sunday", label: "Sunday" },
  { name: "Monday", label: "Monday" },
  { name: "Tuesday", label: "Tuesday" },
  { name: "Wednesday", label: "Wednesday" },
  { name: "Thursday", label: "Thursday" },
  { name: "Friday", label: "Friday" },
];

export const WEEK_DAYS = [
  { name: "Saturday", label: "Saturday" },
  { name: "Sunday", label: "Sunday" },
  { name: "Monday", label: "Monday" },
  { name: "Tuesday", label: "Tuesday" },
  { name: "Wednesday", label: "Wednesday" },
  { name: "Thursday", label: "Thursday" },
  { name: "Friday", label: "Friday" },
];

export const MAILBOX = "mailbox";
export const MAILBOX_FOLDER = "mailbox-folder";
export const SITE = "site";
export const SITE_LIST = "site-list";
export const ONEDRIVE = "onedrive";
export const ONEDRIVE_FOLDER = "onedrive-folder";
