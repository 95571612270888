import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import api from "../../utils/api";

export const resolveUser = createAsyncThunk("tenant/resolveUser", async () => {
  await axios.post(api.me.resolveUser());
  console.log("user resolved");
  const response = await axios.get(api.me.myPermissions());
  return response.data;
});
