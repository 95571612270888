import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  styled,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { signInClickHandler, signOutClickHandler } from "../../utils/auth";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header({ handleDrawerOpen, open }) {
  const { instance } = useMsal();
  const location = useLocation();
  const routeName = location.pathname;

  const currentUser = useSelector((state) => ({
    name: state.user?.profile?.name,
    email: state.user?.profile?.email,
  }));

  return (
    <>
      <AppBar
        position="fixed"
        open={location.pathname === "/search" ? false : open}
        style={{ background: "#fff" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {routeName === "/search" ? (
            <></>
          ) : (
            <IconButton
              color="dark"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: "black", marginLeft: "15px" }}
          >
            SearchOne
          </Typography>

          <AuthenticatedTemplate>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color: "black",
                  textAlign: "right",
                  lineHeight: "1.2",
                }}
                paddingRight={2}
                className={"cuurent-user-info"}
              >
                <div className="name" style={{ fontSize: "1.2rem" }}>
                  {currentUser.name}
                </div>
                <div className="email" style={{ fontSize: "1rem" }}>
                  {currentUser.email}
                </div>
              </Typography>
              <Button
                variant="outlined"
                onClick={() => signOutClickHandler(instance)}
              >
                Sign Out
              </Button>
            </Grid>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              variant="outlined"
              onClick={() => signInClickHandler(instance)}
            >
              Sign In
            </Button>
          </UnauthenticatedTemplate>
        </Toolbar>
      </AppBar>
    </>
  );
}
