import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import swal from "sweetalert";
import {
  addSiteSource,
  fetchSiteSourcesByHold,
} from "../../../../../../redux/actions/holdActions";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  formControl: {
    width: 300,
    marginBottom: 20,
  },
}));

const SiteSourceSelection = ({
  toggleSiteSourceDrawer,
  isSiteSourceDrawerOpen = false,
  siteSources,
  tenantSitesList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const { caseId, holdId } = params;
  const { data } = tenantSitesList;

  const [selected, setSelected] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const selectedItem = data.find((item) => item.id === event.target.value);
    setSelected(event.target.value);
    setSelectedData(selectedItem);
  };

  const handleSelectedSiteSource = () => {
    setLoading(true);

    var updatedData = {
      siteWebUrl: selectedData.webUrl,
    };

    dispatch(addSiteSource({ updatedData, caseId, holdId })).then((res) => {
      if (!res?.error) {
        swal("Success!", `Site source added successfully`, "success");
        dispatch(fetchSiteSourcesByHold({ caseId, holdId }));
      }
      setLoading(false);
    });
  };

  return (
    <CustomDrawer
      className={"add-sitesource-hold-drawer"}
      isDrawerOpen={isSiteSourceDrawerOpen}
      toggleDrawer={toggleSiteSourceDrawer}
      loading={loading}
      title={"Add site source to hold"}
      wrapWithForm={false}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
          >
            <InputLabel>Select a site</InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              label="Select a site"
            >
              {data.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {selectedData && Object.keys(selectedData).length > 0 ? (
            <>
              <b>Site url:</b> {selectedData.webUrl}
            </>
          ) : (
            <>-</>
          )}
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleSelectedSiteSource}
        >
          Submit
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleSiteSourceDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default SiteSourceSelection;
