import { useState } from "react";
import ExportDetailDrawer from "./exportDetail";
import { Button } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";

const PreviewContentButton = ({ row }) => {
  const [openState, setOpenState] = useState(false);

  const toggleDrawer = (event, open) => {
    setOpenState(open);
  };

  return (
    <>
      <Button
        onClick={(e) => {
          toggleDrawer(e, true);
        }}
      >
        <CheckBox
          defaultChecked={false}
          sx={{
            color: blueGrey[100],
            "&.Mui-checked": {
              color: blueGrey[600],
            },
          }}
        />
      </Button>
      {openState && (
        <ExportDetailDrawer
          key={row.id}
          openState={openState}
          toggleDrawer={toggleDrawer}
          exportId={row.id}
        />
      )}
    </>
  );
};

export default PreviewContentButton;
