import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CustomTable from "../../composite/table/CustomTable";
import {
  fetchAuditLogEvents,
  fetchFilteredAuditLogs,
  fetchUsers,
} from "../../../redux/actions/settings/settingsActions";
import auditsTableHeadCells from "./auditsTableHeadCells";
import AuditDetails from "./AuditDetails";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AuditLogs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilteredAuditLogs({}));
    dispatch(fetchAuditLogEvents());
    dispatch(fetchUsers());
  }, []);

  const { userList, auditLogs, auditLogEvents } = useSelector(
    (state) => state.settings
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentLog, setCurrentLog] = useState(null);
  const [logTypes, setLogTypes] = useState([]);
  const [logEvents, setLogEvents] = useState([]);
  const [searchData, setSearchData] = useState({
    top: 100,
    startDate: null,
    endDate: null,
    logTypes: null,
    logEvents: null,
    userId: null,
  });
  const toggleDrawer = (event, open) => {
    setIsDrawerOpen(open);
  };

  const handleLogTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setLogTypes(typeof value === "string" ? value.split(",") : value);
  };

  const handleLogEventChange = (event) => {
    const {
      target: { value },
    } = event;
    setLogEvents(typeof value === "string" ? value.split(",") : value);
  };

  const handleAuditFilter = (data) => {
    data.preventDefault();
    console.log(data);
    dispatch(
      fetchFilteredAuditLogs({
        ...searchData,
        logTypes: logTypes,
        logEvents: logEvents,
      })
    );
    console.log({ ...searchData, logTypes: logTypes, logEvents: logEvents });
  };

  return (
    <>
      <form onSubmit={handleAuditFilter} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Typography component={"span"} variant="h6" fontWeight="bold">
              AUDIT LOGS
            </Typography>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"wrap"}
              pl={2}
            >
              <Grid item sx={{ width: "90px" }} pr={1} pt={1}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  label="Top"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchData.top}
                  variant="outlined"
                  onChange={(e) =>
                    setSearchData({ ...searchData, top: e.target.value })
                  }
                />
              </Grid>
              <Grid item sx={{ width: "150px" }} pr={1} pt={1}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Log date from"
                  variant="outlined"
                  value={searchData.startDate}
                  onChange={(e) =>
                    setSearchData({ ...searchData, startDate: e.target.value })
                  }
                />
              </Grid>
              <Grid item sx={{ width: "150px" }} pr={1} pt={1}>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Log date to"
                  value={searchData.endDate}
                  onChange={(e) =>
                    setSearchData({ ...searchData, endDate: e.target.value })
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item sx={{ width: "120px" }} pr={1} pt={1}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    size="small"
                    id="logTypes"
                    multiple
                    value={logTypes}
                    onChange={handleLogTypeChange}
                    renderValue={(selected) => <>{selected.join()}</>}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="Info">Info</MenuItem>
                    <MenuItem value="Error">Error</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ width: "180px" }} pr={1} pt={1}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    size="small"
                    id="logEvents"
                    multiple
                    value={logEvents}
                    onChange={handleLogEventChange}
                    renderValue={(selected) => <>{selected.join()}</>}
                    MenuProps={MenuProps}
                  >
                    {auditLogEvents.data.map((option) => (
                      <MenuItem value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid sx={{ width: "200px" }} pr={1} pt={1}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  select
                  label="User"
                  onChange={(e) =>
                    setSearchData({ ...searchData, userId: e.target.value })
                  }
                >
                  <MenuItem value={null}>&nbsp;</MenuItem>
                  {userList.data.map((option) => (
                    <MenuItem value={option.id}>{option.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item pt={1}>
                <Button
                  type="submit"
                  sx={{ width: "100%" }}
                  variant="contained"
                >
                  <SearchIcon />
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rows={auditLogs.data}
              headCells={auditsTableHeadCells((cell) => {
                toggleDrawer(null, true);
                setCurrentLog(cell);
              })}
              isLoading={auditLogs.isLoading}
              showHeader={false}
            />
          </Grid>
        </Grid>
        <AuditDetails
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          data={currentLog}
        />
      </form>
    </>
  );
};

export default AuditLogs;
