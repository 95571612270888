import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import ConditionBuilder from "./conditionBuilderEditor";
import KqlEditor from "./kqlEditor";
import axiosInstance from "../../../../../../utils/axios";
import api from "../../../../../../utils/api";

const ConditionForm = forwardRef(
  (
    {
      jsonObject = { conditions: { kql: true, keywords: [], filters: [] } },
      queryString = { kqlString: "" },
    },
    _ref
  ) => {
    const [formType, setFormType] = useState("Condition card builder");
    const [cardBuilderData, setCardBuilderData] = useState({
      conditions: {
        keywords:
          jsonObject?.conditions?.keywords === null ||
          !Array.isArray(jsonObject?.conditions?.keywords) ||
          jsonObject?.conditions?.keywords.length === 0
            ? []
            : jsonObject?.conditions?.keywords,
        filters:
          jsonObject?.conditions?.filters === null ||
          !Array.isArray(jsonObject?.conditions?.filters) ||
          jsonObject?.conditions?.filters.length === 0
            ? []
            : jsonObject?.conditions?.filters,
      },
    });
    const [queryStringData, setQueryStringData] = useState(queryString);
    const memoizedCardBuilderData = useMemo(
      () => cardBuilderData,
      [cardBuilderData]
    );
    const memoizedQueryStringData = useMemo(
      () => queryStringData,
      [queryStringData]
      // []
    );

    const [error, setError] = useState("");
    const [parseError, setParseError] = useState("");

    const {
      control: control1,
      register: register1,
      handleSubmit: handleSubmit1,
      getValues: getValues1,
      errors: errors1,
      reset: reset1,
      setValue: setValue1,
      watch: watch1,
    } = useForm({
      cardBuilderData,
      shouldUnregister: false,
    });

    const {
      control: control2,
      register: register2,
      handleSubmit: handleSubmit2,
      getValues: getValues2,
      errors: errors2,
      reset: reset2,
      setValue: setValue2,
      watch: watch2,
    } = useForm({
      queryStringData,
      shouldUnregister: false,
    });

    useEffect(() => {
      if (formType === "Condition card builder") {
        let currentQueryString = getValues2();
        if ("kqlString" in currentQueryString) {
          axiosInstance
            .post(api.queryParse.stringToObject(), currentQueryString)
            .then((response) => {
              setError("");
              if (response.data.success === true) {
                setParseError("");
                setCardBuilderData((prevState) => {
                  return {
                    ...prevState,
                    conditions: {
                      ...response.data.conditions,
                      keywords: response.data.conditions.keywords
                        ? response.data.conditions.keywords
                        : [],
                    },
                  };
                });
              } else {
                setFormType("KQL editor");
                setParseError("Condition parsing failed");
              }
            })
            .catch((error) => {
              setError(error);
            });
        }
      } else {
        const currentCardData = getValues1();
        axiosInstance
          .post(api.queryParse.objectToString(), currentCardData.conditions)
          .then((response) => {
            setError("");
            setQueryStringData({ kqlString: response.data.kqlString });
          })
          .catch((error) => {
            setError(error);
          });
      }
    }, [formType]);

    useEffect(() => {
      reset1(cardBuilderData);
    }, [memoizedCardBuilderData]);

    useEffect(() => {
      reset2(queryStringData);
    }, [memoizedQueryStringData]);

    const handleChange = (event) => {
      setFormType(event.target.value);
    };

    useImperativeHandle(_ref, () => ({
      getData: async () => {
        try {
          let jsonObject;
          let queryString;
          if (formType === "Condition card builder") {
            jsonObject = getValues1();
            const response = await axiosInstance.post(
              api.queryParse.objectToString(),
              jsonObject.conditions
            );
            queryString = { kqlString: response.data.kqlString };
            return { jsonObject, queryString };
          } else {
            queryString = getValues2();
            const response = await axiosInstance.post(
              api.queryParse.stringToObject(),
              queryString
            );
            if (response.data.success === true) {
              jsonObject = {
                conditions: {
                  ...response.data.conditions,
                  keywords: response.data.conditions.keywords
                    ? response.data.conditions.keywords
                    : [],
                },
              };
              return { jsonObject, queryString };
            } else {
              jsonObject = { conditions: null };
              return { jsonObject, queryString };
            }
          }
        } catch (error) {
          setError(error);
          throw error;
        }
      },
    }));

    return (
      <Grid container >
        {error ? (
          <Alert severity="error">{error.response?.data?.title}</Alert>
        ) : (
          <></>
        )}
        {parseError && (
          <Alert severity="error" onClose={() => setParseError("")}>
            {parseError}
          </Alert>
        )}
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="Bold">
            Define your search conditions
          </Typography>
          <Typography paragraph>
            Enter keywords or use the keyword list. You can also add conditions
            to narrow your results.
          </Typography>
        </Grid>
        <FormControl>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={formType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Condition card builder"
              control={<Radio />}
              label="Condition card builder"
            />
            <FormControlLabel
              value="KQL editor"
              control={<Radio />}
              label="KQL editor"
            />
          </RadioGroup>
        </FormControl>
        <Grid item xs={12}>
          {formType === "Condition card builder" ? (
            <ConditionBuilder
              {...{
                register: register1,
                control: control1,
                defaultValues: cardBuilderData,
                getValues: getValues1,
                setValue: setValue1,
                errors: errors1,
                watch: watch1,
                reset: reset1,
              }}
            />
          ) : (
            <KqlEditor
              {...{
                register: register2,
                control: control2,
                defaultValues: queryStringData,
                getValues: getValues2,
                setValue: setValue2,
                errors: errors2,
                watch: watch2,
                reset: reset2,
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }
);

export default ConditionForm;
