import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRoles,
  fetchUsers,
  fetchSettings,
  fetchAuditLogs,
  fetchAuditLogEvents,
  fetchFilteredAuditLogs,
  fetchRolesPermissions
} from "../actions/settings/settingsActions";

const initialState = {
  globalSettings: {
    isLoading: false,
    data: {},
    error: null,
  },
  roleList: {
    isLoading: false,
    data: [],
    error: null,
  },
  rolePermissions: {
    isLoading: false,
    data: [],
    error: null,
  },
  userList: {
    isLoading: false,
    data: [],
    error: null,
  },
  auditLogs: {
    isLoading: false,
    data: [],
    error: null,
  },
  auditLogEvents: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetch roles
      .addCase(fetchRoles.pending, (state) => {
        state.roleList.isLoading = true;
        state.roleList.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roleList.isLoading = false;
        state.roleList.data = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.roleList.isLoading = false;
        state.roleList.error = action.error;
      })

      //fetch role Permissions
      .addCase(fetchRolesPermissions.pending, (state) => {
        state.rolePermissions.isLoading = true;
        state.rolePermissions.error = null;
      })
      .addCase(fetchRolesPermissions.fulfilled, (state, action) => {
        state.rolePermissions.isLoading = false;
        state.rolePermissions.data = action.payload;
      })
      .addCase(fetchRolesPermissions.rejected, (state, action) => {
        state.rolePermissions.isLoading = false;
        state.rolePermissions.error = action.error;
      })


      //fetch users
      .addCase(fetchUsers.pending, (state) => {
        state.userList.isLoading = true;
        state.userList.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.userList.isLoading = false;
        state.userList.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.userList.isLoading = false;
        state.userList.error = action.error;
      })

      //fetch settings
      .addCase(fetchSettings.pending, (state) => {
        state.globalSettings.isLoading = true;
        state.globalSettings.error = null;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.globalSettings.isLoading = false;
        state.globalSettings.data = action.payload;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.globalSettings.isLoading = false;
        state.globalSettings.error = action.error;
      })

      //fetch audit logs
      .addCase(fetchAuditLogs.pending, (state) => {
        state.auditLogs.isLoading = true;
        state.auditLogs.error = null;
      })
      .addCase(fetchAuditLogs.fulfilled, (state, action) => {
        state.auditLogs.isLoading = false;
        state.auditLogs.data = action.payload;
      })
      .addCase(fetchAuditLogs.rejected, (state, action) => {
        state.auditLogs.isLoading = false;
        state.auditLogs.error = action.error;
      })

      //fetch filtered audit logs
      .addCase(fetchFilteredAuditLogs.pending, (state) => {
        state.auditLogs.isLoading = true;
        state.auditLogs.error = null;
      })
      .addCase(fetchFilteredAuditLogs.fulfilled, (state, action) => {
        state.auditLogs.isLoading = false;
        state.auditLogs.data = action.payload;
      })
      .addCase(fetchFilteredAuditLogs.rejected, (state, action) => {
        state.auditLogs.isLoading = false;
        state.auditLogs.error = action.error;
      })

      //fetch audit logs
      .addCase(fetchAuditLogEvents.pending, (state) => {
        state.auditLogEvents.isLoading = true;
        state.auditLogEvents.error = null;
      })
      .addCase(fetchAuditLogEvents.fulfilled, (state, action) => {
        state.auditLogEvents.isLoading = false;
        state.auditLogEvents.data = action.payload;
      })
      .addCase(fetchAuditLogEvents.rejected, (state, action) => {
        state.auditLogEvents.isLoading = false;
        state.auditLogEvents.error = action.error;
      });
  },
});

export default settingsSlice.reducer;
