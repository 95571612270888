import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { Field } from "redux-form";
import CustomSwitch from "../../../../../composite/CustomSwitch";
import MailboxSelection from "./MailboxSelection";
import SiteSelection from "./SitesSelection";

const LocationCollectionsForm = ({
  tenantUsersList,
  tenantSitesList,
  handleMailboxItems,
  handleSiteUrls,
}) => {
  const [mailbox, setMailbox] = useState([]);
  const [isMailboxActive, setIsMailboxActive] = useState(false);

  const [site, setSite] = useState([]);
  const [isSiteActive, setIsSiteActive] = useState(false);

  const [publicFolders, setPublicFolders] = useState([]);
  const [isPublicFoldersActive, setIsPublicFoldersActive] = useState(false);

  const [isMailboxDrawerOpen, setIsMailboxDrawerOpen] = useState(false);
  const [isSiteDrawerOpen, setIsSiteDrawerOpen] = useState(false);
  const handleMailBoxSwitch = (value) => {
    setIsMailboxActive(value);
  };
  const handleSites = (value) => {
    setIsSiteActive(value);
  };
  const handlePublicFolders = (value) => {
    setIsPublicFoldersActive(value);
  };

  const handleSelectedMailboxParent = (rows) => {
    setMailbox(rows);
    handleMailboxItems(rows);
    setIsMailboxDrawerOpen(false);
  };

  const handleSelectedSiteParent = (rows) => {
    setSite(rows);
    handleSiteUrls(rows);

    setIsSiteDrawerOpen(false);
  };

  const toggleMailboxDrawer = (event, open) => {
    setIsMailboxDrawerOpen(open);
  };

  const toggleSiteDrawer = (event, open) => {
    setIsSiteDrawerOpen(open);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="Bold">
            Additional locations
          </Typography>
          <Typography paragraph>
            Choose additional locations to search. An additional location is a
            data source that isn't associated with the custodians. Note: these
            additional locations will not include advanced indexing unless they
            are added as data sources in the case
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "Bold" }}>
                    Status
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "Bold" }}>
                    Location
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "Bold" }}>
                    Included
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "Bold" }}>
                    Excluded
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="mailbox"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <Field
                      name="additionalLocations.exchangeMailBoxes"
                      component={CustomSwitch}
                      onChange={handleMailBoxSwitch}
                    />
                  </TableCell>
                  <TableCell align="left" padding="none">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <ContactMailIcon sx={{ marginRight: "8px" }} />
                      Exchange mailboxes
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {isMailboxActive && (
                      <>
                        <Box>{mailbox.length > 0 ? mailbox.length : "All"}</Box>
                        <Box>
                          <Link
                            href="#"
                            underline="none"
                            variant="body2"
                            onClick={(e) => toggleMailboxDrawer(e, true)}
                          >
                            Choose users, groups, or teams
                          </Link>
                        </Box>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {isMailboxActive && "None"}
                  </TableCell>
                </TableRow>

                <TableRow
                  key="mailbox"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <Field
                      name="additionalLocations.sharePointSites"
                      component={CustomSwitch}
                      onChange={handleSites}
                    />
                  </TableCell>
                  <TableCell align="left" padding="none">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <CloudSyncIcon sx={{ marginRight: "8px" }} />
                      SharePoint sites
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {isSiteActive && (
                      <>
                        <Box>{site.length > 0 ? site.length : "All"}</Box>
                        <Box>
                          <Link
                            href="#"
                            underline="none"
                            variant="body2"
                            onClick={(e) => toggleSiteDrawer(e, true)}
                          >
                            Choose sites
                          </Link>
                        </Box>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left"> {isSiteActive && "None"}</TableCell>
                </TableRow>

                <TableRow
                  key="mailbox"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <Field
                      name="additionalLocations.exchangePublicFolders"
                      component={CustomSwitch}
                      onChange={handlePublicFolders}
                    />
                  </TableCell>
                  <TableCell align="left" padding="none">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <DriveFileMoveIcon sx={{ marginRight: "8px" }} />
                      Exchange public folders
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {isPublicFoldersActive && "All"}
                  </TableCell>
                  <TableCell align="left">
                    {isPublicFoldersActive && "None"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <MailboxSelection
        handleSelectedMailboxParent={handleSelectedMailboxParent}
        toggleMailboxDrawer={toggleMailboxDrawer}
        isMailboxDrawerOpen={isMailboxDrawerOpen}
        tenantUsersList={tenantUsersList}
      />
      <SiteSelection
        handleSelectedSiteParent={handleSelectedSiteParent}
        toggleSiteDrawer={toggleSiteDrawer}
        isSiteDrawerOpen={isSiteDrawerOpen}
        tenantSitesList={tenantSitesList}
      />
    </>
  );
};

export default LocationCollectionsForm;
