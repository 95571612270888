import { Alert, Button, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ConditionForm from "./form/conditionForm";
import {
  retrieveQuerySet,
  updateQuerySet,
} from "../../../../../redux/actions/reviewSets/querySetAction";
import swal from "sweetalert";
import CustomDrawer from "../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../composite/table/CustomDrawerFooter";

const EditConditionDrawer = ({
  isQueryDrawerOpen,
  toggleQueryDrawer,
  queryId,
}) => {
  const conditionDataRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    dispatch(
      retrieveQuerySet({
        caseId: params.caseId,
        reviewSetId: params.reviewSetId,
        queryId: queryId,
      })
    );
  }, []);
  const { retrievedQuery } = useSelector((state) => state.queries);

  useEffect(() => {
    setName(retrievedQuery?.data?.displayName);
  }, [retrievedQuery?.data?.displayName]);

  const handleSubmit = async (e) => {
    try {
      setErrorMessage("");
      setIsLoading(true);
      const conditionData = await conditionDataRef.current.getData();
      const formData = {
        ...conditionData.jsonObject,
        contentQuery: conditionData.queryString.kqlString,
        displayName: name,
      };
      dispatch(
        updateQuerySet({
          data: formData,
          caseId: params.caseId,
          reviewSetId: params.reviewSetId,
          queryId: queryId,
        })
      ).then((res) => {
        setIsLoading(false);
        if (res.hasOwnProperty("error")) {
          setErrorMessage(res.error.message);
        } else {
          swal("Success!", `Query has been updated!`, "success").then(() => {
            toggleQueryDrawer(e, false);
          });
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomDrawer
      className={"edit-query-drawer"}
      isDrawerOpen={isQueryDrawerOpen}
      toggleDrawer={toggleQueryDrawer}
      loading={isLoading}
      title={"Edit reviewset query"}
      wrapWithForm={false}
    >
      <Grid container spacing={3}>
        {errorMessage ? (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <label htmlFor="display-name">Name:</label>
          <input
            id="display-name"
            name="displayName"
            style={{
              height: "36px",
              padding: "10px",
              marginBottom: "20px",
              width: "100%",
            }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Grid item xs={12}>
            {"query" in retrievedQuery?.data && (
              <ConditionForm
                jsonObject={
                  retrievedQuery.data.jsonObject
                    ? JSON.parse(retrievedQuery.data.jsonObject)
                    : {}
                }
                queryString={{ kqlString: retrievedQuery?.data?.query }}
                ref={conditionDataRef}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={isLoading} variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={(e) => {
            toggleQueryDrawer(e, false);
          }}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default EditConditionDrawer;
