import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import api from "../../../utils/api";

export const fetchReviewSetsContentsByReviewSetsId = createAsyncThunk(
  "reviewSetsContents/fetchByReviewSetsId",
  async ({caseId,reviewSetId}, { dispatch }) => {
    try {
      const res = await axios.get(api.reviewSetsContents.get(caseId,reviewSetId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const createReviewSetsContentsByReviewSetsId = createAsyncThunk(
  "reviewSetsContents/createByReviewSetsId",
  async ({ data, caseId, reviewSetId }, { dispatch }) => {
    try {
      const res = await axios.post(api.reviewSetsContents.create(caseId,reviewSetId), data);
      await dispatch(fetchReviewSetsContentsByReviewSetsId(caseId,reviewSetId));
    } catch (error) {
      throw error;
    }
  }
);

