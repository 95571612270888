import React from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";

export default function SearchAccessGuard({
  children,
  members,
  canNotAccessType,
  showDeniedPage,
}) {
  const loggedInUserId = useSelector((state) => state.user?.profile?.userId);
  
  if (!members?.length) return children;
  const permission = members.includes(loggedInUserId);
  //const permission = false;
  if (!permission) {
    if (canNotAccessType === "show") {
      const child = React.Children.only(children);
      const cloneChild = React.cloneElement(child, {
        onClick: undefined,
      });
      return cloneChild;
    } else if (showDeniedPage) {
      return (
        <>
          <h2>Authorization Failed</h2>
          <p>You do not have access to view this page</p>
        </>
      ); 
      } else {
        const child = React.Children.only(children);
        const cloneChild = React.cloneElement(child, {
          onClick: () => {
            swal(
              "Authorization Failed",
              "You do not have access to search this collection"
            );
            return false;
          },
        });
        return cloneChild;
      }
  } else {
    return children;
  }
}
