import { createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const fetchCollections = createAsyncThunk(
  "collections/fetchCollections",
  async () => {
    const response = await axios.get("/collections");
    return response.data;
  }
);

export const fetchCollectionExports = createAsyncThunk(
  "collections/fetchCollectionExports",
  async () => {
    const response = await axios.get("/exports");
    return response.data;
  }
);

export const fetchCollectionExportFiles = createAsyncThunk(
  "collections/fetchCollectionExportFiles",
  async (id) => {
    const response = await axios.get(`/exports/${id}/files`);
    return response.data;
  }
);

export const fetchHoldCollections = createAsyncThunk(
  "collections/fetchHoldCollections",
  async () => {
    const response = await axios.get("/collections/on-hold");
    return response.data;
  }
);