import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSubjectRights,
  fetchSubjectRightsById,
  createSubjectRights,
  deleteSubjectRights,
  fetchFinalReportById,
  fetchSRNotesById,
  fetchFinalAttachmentById
} from "../actions/subjectRightsActions";

const initialState = {
  subjectRightList: {
    isLoading: false,
    data: [],
    error: null,
  },
  currentSubjectRight: {
    isLoading: false,
    details: {},
    error: null,
  },
  createdSubjectRight: {
    isLoading: false,
    status: null,
    error: null,
  },
  currentFinalReport: {
    isLoading: false,
    status: null,
    error: null,
  },
  currentFinalAttachment: {
    isLoading: false,
    status: null,
    error: null,
  },
  currentNote: {
    isLoading: false,
    status: null,
    error: null,
  },
};

export const SubjectRightSlice = createSlice({
  name: "SubjectRights",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // delete SubjectRight
      .addCase(deleteSubjectRights.pending, (state) => {
        state.subjectRightList.isLoading = true;
        state.subjectRightList.error = null;
      })
      .addCase(deleteSubjectRights.fulfilled, (state) => {
        state.subjectRightList.isLoading = false;
      })
      //fetch SubjectRights
      .addCase(fetchSubjectRights.pending, (state) => {
        state.subjectRightList.isLoading = true;
        state.subjectRightList.error = null;
      })
      .addCase(fetchSubjectRights.fulfilled, (state, action) => {
        state.subjectRightList.isLoading = false;
        state.subjectRightList.data = action.payload;
      })
      .addCase(fetchSubjectRights.rejected, (state, action) => {
        state.subjectRightList.isLoading = false;
        state.subjectRightList.error = action.error;
      })

      .addCase(createSubjectRights.pending, (state) => {
        state.createSubjectRight.isLoading = true;
        state.createSubjectRight.status = null;
        state.createSubjectRight.error = null;
      })
      .addCase(createSubjectRights.fulfilled, (state) => {
        state.createSubjectRight.isLoading = false;
        state.createSubjectRight.status = "success";
      })
      .addCase(createSubjectRights.rejected, (state, action) => {
        state.createSubjectRight.isLoading = false;
        state.createSubjectRight.status = "error";
        state.createSubjectRight.error = action.error;
      })

      .addCase(fetchSubjectRightsById.pending, (state) => {
        state.currentSubjectRight.isLoading = true;
        state.currentSubjectRight.error = null;
      })
      .addCase(fetchSubjectRightsById.fulfilled, (state, action) => {
        state.currentSubjectRight.isLoading = false;
        state.currentSubjectRight.details = action.payload;
      })
      .addCase(fetchSubjectRightsById.rejected, (state, action) => {
        state.currentSubjectRight.isLoading = false;
        state.currentSubjectRight.error = action.error;
      })

      .addCase(fetchFinalReportById.pending, (state) => {
        state.currentFinalReport.isLoading = true;
        state.currentFinalReport.error = null;
      })
      .addCase(fetchFinalReportById.fulfilled, (state, action) => {
        state.currentFinalReport.isLoading = false;
        state.currentFinalReport.details = action.payload;
      })
      .addCase(fetchFinalReportById.rejected, (state, action) => {
        state.currentFinalReport.isLoading = false;
        state.currentFinalReport.error = action.error;
      })

      .addCase(fetchFinalAttachmentById.pending, (state) => {
        state.currentFinalAttachment.isLoading = true;
        state.currentFinalAttachment.error = null;
      })
      .addCase(fetchFinalAttachmentById.fulfilled, (state, action) => {
        state.currentFinalAttachment.isLoading = false;
        state.currentFinalAttachment.details = action.payload;
      })
      .addCase(fetchFinalAttachmentById.rejected, (state, action) => {
        state.currentFinalAttachment.isLoading = false;
        state.currentFinalAttachment.error = action.error;
      })

      .addCase(fetchSRNotesById.pending, (state) => {
        state.currentNote.isLoading = true;
        state.currentNote.error = null;
      })
      .addCase(fetchSRNotesById.fulfilled, (state, action) => {
        state.currentNote.isLoading = false;
        state.currentNote.details = action.payload;
      })
      .addCase(fetchSRNotesById.rejected, (state, action) => {
        state.currentNote.isLoading = false;
        state.currentNote.error = action.error;
      })
  },
});

export default SubjectRightSlice.reducer;
