import { CheckBox } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { blueGrey, green } from "@mui/material/colors";
// import ArticleIcon from '@mui/icons-material/Article';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useState } from "react";
import { Button } from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilePreviewDrawer from "./previewContent";
export const fileIcon = {
  csv: (
    <img
      src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/csv_16x1.svg"
      height={24}
      width={24}
    />
  ),
  zip: (
    <img
      src="https://static2.sharepointonline.com/files/fabric/assets/item-types/16/zip.svg"
      height={24}
      width={24}
    />
  ),
  jpg: <ImageIcon />,
  png: <ImageIcon />,
  msg: <MailOutlineIcon />,
  unknown: <QuestionMarkIcon />,
  doc: (
    <img
      src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/docx_16x1.svg"
      height={24}
      width={24}
    />
  ),
  docx: (
    <img
      src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/docx_16x1.svg"
      height={20}
      width={20}
    />
  ),
  xls: (
    <img
      src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/csv_16x1.svg"
      height={24}
      width={24}
    />
  ),
  xlsx: (
    <img
      src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/csv_16x1.svg"
      height={24}
      width={24}
    />
  ),
};

export const PreviewContentButton = ({ row }) => {
  const [openState, setOpenState] = useState(false);
  return (
    <>
      <Button
        onClick={(e) => {
          setOpenState(true);
        }}
      >
        <VisibilityIcon
          sx={{
            color: blueGrey[100],
            "&.Mui-checked": {
              color: blueGrey[600],
            },
          }}
        />
      </Button>
      {openState && <FilePreviewDrawer {...{ openState, setOpenState, row }} />}
    </>
  );
};

export function flattenObject(obj, prefix = "") {
  const flattened = {};
  for (let key in obj) {
    const newPrefix = prefix ? prefix + " " + key : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      const flatObj = flattenObject(obj[key], newPrefix);
      Object.assign(flattened, flatObj);
    } else {
      flattened[newPrefix] = obj[key];
    }
  }
  return flattened;
}
