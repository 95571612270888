import React from "react";
import { Typography } from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";

export default function DownloadApp() {
  return (
    <div className="downloads" style={{margin: '50px 20%', border: '1px solid #ccc', height: 'calc(100vh - 100px)', maxWidth: '900px'}}>
      <Typography variant="h3" fontWeight="bold" padding={2} textAlign="center">
        SearchOne
      </Typography>
      <Typography variant="h4" paddingTop={20} textAlign="center">
        To download the <b>SearchOne Crawler</b> desktop app
        <br />
        <a
          href={window.APP_CONFIG.DESKTOP_APP_DOWNLOAD_URL}
          style={{ paddingLeft: "5px" }}
        >
          Click here <CallMadeIcon />
        </a>
      </Typography>
      </div>
  );
}
