import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";
import axios from "../../../utils/axios";

export const fetchCustodiansByCaseId = createAsyncThunk(
  "dataSources/fetchCustodiansByCaseId",
  async (caseId) => {
    const response = await axios.get(
      api.dataSources.getCustodiansByCaseId(caseId)
    );
    return { caseId, data: response.data };
  }
);

export const fetchNonCustodiansByCaseId = createAsyncThunk(
  "dataSources/fetchNonCustodiansByCaseId",
  async (caseId) => {
    const response = await axios.get(
      api.dataSources.getNonCustodiansByCaseId(caseId)
    );
    return { caseId, data: response.data };
  }
);
