import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import Roles from "./Roles";
import Users from "./Users";
import Settings from "./Settings";
import AuditLogs from "./AuditLogs";
import RolePermission from "./RolePermission";
import AuthorizeGuard from "../../common/AuthorizeGuard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "15px 0 0 0" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}

export default function AppSettings() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab")
    ? parseInt(searchParams.get("tab"))
    : 0;
  const [value, setValue] = useState(selectedTab);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "settings-breadcumbs",
      links: [
        { url: "/dashboard", text: "Dashboard" },
        { text: "Settings", url: selectedTab !== 0 ? "/settings?tab=0" : null },
      ],
    };
    if (selectedTab === 0) {
      handleChange(null, 0);
    }
    if (selectedTab === 1) {
      breadcrumbs.links.push({ text: "Roles" });
    } else if (selectedTab === 2) {
      breadcrumbs.links.push({ text: "Users" });
    } else if (selectedTab === 3) {
      breadcrumbs.links.push({ text: "Permission" });
    } else if (selectedTab === 4) {
      breadcrumbs.links.push({ text: "Audit" });
    }
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [selectedTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <AuthorizeGuard module={"SETTINGS"} action={"View"} showDeniedPage={true}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="settings tabs"
          className="settings-tabs"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Roles" {...a11yProps(1)} />
          <Tab label="Users" {...a11yProps(2)} />
          <Tab label="Permission" {...a11yProps(3)} />
          <Tab label="Audit" {...a11yProps(4)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Settings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Roles />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Users />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RolePermission />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AuditLogs />
        </TabPanel>
      </Box>
    </AuthorizeGuard>
  );
}
