import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../../assets/css/sb-admin-2.css";
import "../../assets/css/main.css";
import AuthorizeGuard from "../common/AuthorizeGuard";

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  static displayName = Dashboard.name;

  componentDidMount = () => {
    this.props.loadDashboard();
  };

  render() {
    return (
      <div>
        <h3 className="main-title">Dashboard</h3>
        <hr className="my-4" />

        <div className="row mt-5">
          <div
            className="col-xl-4 col-md-6 mb-4"
            onClick={() => this.props.navigate("/cases")}
          >
            <div className="card border-left-primary shadow h-100 py-2 redirect-card">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className="card-redirect"
                  />
                  <div className="col mr-2 text-center">
                    <div className="text-sm font-weight-bold text-primary text-uppercase mb-1 ml-3">
                      eDiscovery Cases
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2 ml-3">
                      {this.props.ui?.dashboard?.cases
                        ? this.props.ui.dashboard.cases
                        : "..."}
                    </div>
                    <div className="text-sm font-weight-bold text-gray-xs mt-2 mb-1 ml-3">
                      Total number of eDiscovery cases
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AuthorizeGuard
            module={"CONNECTIONS"}
            action={"View"}
            canNotViewType={"Show"}
          >
            <div
              className="col-xl-4 col-md-6 mb-4"
              onClick={() => this.props.navigate("/connections")}
            >
              <div className="card border-left-primary shadow h-100 py-2 redirect-card">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className="card-redirect"
                    />
                    <div className="col mr-2 text-center">
                      <div className="text-sm font-weight-bold text-primary text-uppercase mb-1 ml-3">
                        Connections
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2 ml-3">
                        {this.props.ui?.dashboard?.connections
                          ? this.props.ui.dashboard.connections
                          : "..."}
                      </div>
                      <div className="text-sm font-weight-bold text-gray-xs mt-2 mb-1 ml-3">
                        Total number of connections
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AuthorizeGuard>

          <AuthorizeGuard
            module={"COLLECTIONS"}
            action={"View"}
            canNotViewType={"Show"}
          >
            <div
              className="col-xl-4 col-md-6 mb-4"
              onClick={() => this.props.navigate("/collections")}
            >
              <div className="card border-left-primary shadow h-100 py-2 redirect-card">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className="card-redirect"
                    />
                    <div className="col mr-2 text-center">
                      <div className="text-sm font-weight-bold text-primary text-uppercase mb-1 ml-3">
                        Collections
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2 ml-3">
                        {this.props.ui?.dashboard?.collections
                          ? this.props.ui.dashboard.collections
                          : "..."}
                      </div>
                      <div className="text-sm font-weight-bold text-gray-xs mt-2 mb-1 ml-3">
                        Total number of collections
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AuthorizeGuard>
          <AuthorizeGuard
            module={"HOLDS"}
            action={"View"}
            canNotViewType={"Show"}
          >
            <div
              className="col-xl-4 col-md-6 mb-4"
              onClick={() => this.props.navigate("/collections-on-hold")}
            >
              <div className="card border-left-primary shadow h-100 py-2 redirect-card">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className="card-redirect"
                    />
                    <div className="col mr-2 text-center">
                      <div className="text-sm font-weight-bold text-primary text-uppercase mb-1 ml-3">
                        Collections On Hold
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2 ml-3">
                        {this.props.ui?.dashboard?.collectionsOnHold
                          ? this.props.ui.dashboard.collectionsOnHold
                          : "..."}
                      </div>
                      <div className="text-sm font-weight-bold text-gray-xs mt-2 mb-1 ml-3">
                        Total number of collections put on hold
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AuthorizeGuard>
          <AuthorizeGuard
            module={"SETTINGS"}
            action={"View"}
            canNotViewType={"Show"}
          >
            <div
              className="col-xl-4 col-md-6 mb-4"
              onClick={() => this.props.navigate("/settings?tab=2")}
            >
              <div className="card border-left-primary shadow h-100 py-2 redirect-card">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className="card-redirect"
                    />
                    <div className="col mr-2 text-center">
                      <div className="text-sm font-weight-bold text-primary text-uppercase mb-1 ml-3">
                        Login Users
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2 ml-3">
                        {this.props.ui?.dashboard?.users
                          ? this.props.ui.dashboard.users
                          : "..."}
                      </div>
                      <div className="text-sm font-weight-bold text-gray-xs mt-2 mb-1 ml-3">
                        Total number of users using the application
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AuthorizeGuard>
        </div>
      </div>
    );
  }
}

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();

  return <Component {...props} navigate={navigate} />;
};

export const DashboardNav = withNavigate(Dashboard);
