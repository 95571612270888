import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  isLoggedIn: false,
  profile: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setUserProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

export const { setUserAccessToken, setUserProfile } = userSlice.actions;

export default userSlice.reducer;
