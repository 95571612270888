import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Grid, Box, FormControlLabel, Checkbox } from "@mui/material";
import swal from "sweetalert";
import CustomDrawer from "../../../composite/CustomDrawer";
import { useState } from "react";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";
import {
  fetchTenantExchangeMails,
  fetchTenantSites,
} from "../../../../redux/actions/tenantActions";
import CustomTable from "../../../composite/table/CustomTable";
import tenantMailboxTableHeadCells from "./tenantMailboxTableHeadCells";
import tenantSitesTableHeadCells from "./tenantSitesTableHeadCells";
import CustomTableToolbar from "../../../composite/table/CustomTableToolbar";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import { getCaseCustodians } from "../../../../redux/actions/caseActions";

function DataSourceCell({ cell }) {
  const params = useParams();
  const dispatch = useDispatch();
  const { caseId } = params;

  const [isMailboxDrawerOpen, setIsMailboxDrawerOpen] = useState(false);
  const [isExchangeDrawerOpen, setIsExchangeDrawerOpen] = useState(false);
  const [isSharePointDrawerOpen, setIsSharePointDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ownSource, setOwnSource] = useState(
    cell.dataSources.find((x) => x.type === "Own")
  );
  const [mailboxChecked, setMailboxChecked] = useState(
    ownSource?.includedSources?.includes("mailbox") || false
  );
  const [onedriveChecked, setOnedriveChecked] = useState(
    ownSource?.includedSources?.includes("site") || false
  );
  const [selectedMailbox, setSelectedMailbox] = useState(
    cell.dataSources.filter((x) => x.type === "UserSource")
  );
  const [selectedSites, setSelectedSites] = useState(
    cell.dataSources.filter((x) => x.type === "SiteSource")
  );

  useEffect(() => {
    dispatch(fetchTenantExchangeMails());
    dispatch(fetchTenantSites());
  }, []);
  const { tenantExchangeMailList, tenantSitesList } = useSelector(
    (state) => state.tenant
  );

  const toggleMailboxDrawer = (event, open) => {
    setIsMailboxDrawerOpen(open);
  };
  const toggleExchangeDrawer = (event, open) => {
    setIsExchangeDrawerOpen(open);
    setSelectedMailbox(cell.dataSources.filter((x) => x.type === "UserSource"))
  };
  const toggleSharePointDrawer = (event, open) => {
    setIsSharePointDrawerOpen(open);
    setSelectedSites(cell.dataSources.filter((x) => x.type === "SiteSource"))
  };

  const handleMailboxForm = (e) => {
    e.preventDefault();
    setLoading(true);

    var data = {
      id: ownSource?.id,
      email: cell.email,
      mailbox: mailboxChecked,
      oneDrive: onedriveChecked,
    };

    axiosInstance
      .post(api.dataSources.addOwnSource(caseId, cell.id), data)
      .then((res) => {
        swal("Success!", `Custodian updated successfully!`, "success").then(
          () => {
            setLoading(false);
            toggleMailboxDrawer(null, false);
          }
        );
      })
      .catch((err) => {
        setLoading(false);
        toggleMailboxDrawer(null, false);
      });
  };
  const handleExchangeForm = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
      custodianEmail: cell.email,
      exchangeEmails: selectedMailbox.map((item) =>
        item.hasOwnProperty("mail") ? item.mail : item.email
      ),
    };
    axiosInstance
      .post(api.dataSources.addExchangeSources(caseId, cell.id), data)
      .then((res) => {
        swal("Success!", `Custodian updated successfully!`, "success").then(
          () => {
            setLoading(false);
            toggleExchangeDrawer(null, false);
            dispatch(getCaseCustodians(caseId));
          }
        );
      })
      .catch((err) => {
        setLoading(false);
        toggleExchangeDrawer(null, false);
      });
  };
  const handleSharePointForm = (e) => {
    e.preventDefault();
    setLoading(true);

    var data = selectedSites.map((item) => {
      return item.hasOwnProperty("webUrl") ? item.webUrl : item.siteWebUrl;
    });
    axiosInstance
      .post(api.dataSources.addSiteSources(caseId, cell.id), data)
      .then((res) => {
        swal("Success!", `Custodian updated successfully!`, "success").then(
          () => {
            setLoading(false);
            toggleSharePointDrawer(null, false);
            dispatch(getCaseCustodians(caseId));
          }
        );
      })
      .catch((err) => {
        setLoading(false);
        toggleSharePointDrawer(null, false);
      });
  };

  const handleSelectedMailbox = (rows) => {
    setSelectedMailbox(rows);
  };
  const handleSelectedSites = (rows) => {
    setSelectedSites(rows);
  };

  return (
    <>
      <div>
        <Button
          className="mailbox-onedrive"
          onClick={(event) => toggleMailboxDrawer(event, true)}
          size="small"
          style={{ textTransform: "none" }}
          variant="text"
        >
          {mailboxChecked || onedriveChecked
            ? "Mailbox/OneDrive(1)"
            : "Mailbox/OneDrive"}
        </Button>
        <Button
          className="exchanges"
          onClick={(event) => toggleExchangeDrawer(event, true)}
          size="small"
          sx={{ textDecoration: "none" }}
          variant="text"
          style={{ textTransform: "none" }}
        >
          {selectedMailbox.length > 0
            ? `Exchange(${selectedMailbox.length})`
            : "Exchange"}
        </Button>

        <Button
          className="sharepoint-sites"
          onClick={(event) => toggleSharePointDrawer(event, true)}
          size="small"
          sx={{ textDecoration: "none" }}
          variant="text"
          style={{ textTransform: "none" }}
        >
          {selectedSites.length > 0
            ? `SharePoint Sites(${selectedSites.length})`
            : "SharePoint Sites"}
        </Button>
      </div>

      <CustomDrawer
        isDrawerOpen={isMailboxDrawerOpen}
        toggleDrawer={toggleMailboxDrawer}
        title={"Mailbox or OneDrive"}
        subtitle={"Custodian own mailbox, OneDrive"}
        wrapWithForm={true}
        onFormSubmit={handleMailboxForm}
        loading={loading}
      >
        <Grid>
          <Box marginTop={3}>
            <FormControlLabel
              label="Mailbox"
              control={
                <Checkbox
                  checked={mailboxChecked}
                  onChange={(e) => setMailboxChecked(e.target.checked)}
                />
              }
            />
          </Box>
          <Box>
            <FormControlLabel
              label="OneDrive"
              control={
                <Checkbox
                  checked={onedriveChecked}
                  onChange={(e) => setOnedriveChecked(e.target.checked)}
                />
              }
            />
          </Box>
        </Grid>
        <CustomDrawerFooter>
          <Button variant="contained" type="submit" disabled={loading}>
            Submit
          </Button>
          <Button
            sx={{ marginLeft: "10px" }}
            variant="outlined"
            onClick={(event) => toggleMailboxDrawer(event, false)}
          >
            Cancel
          </Button>
        </CustomDrawerFooter>
      </CustomDrawer>

      <CustomDrawer
        isDrawerOpen={isExchangeDrawerOpen}
        toggleDrawer={toggleExchangeDrawer}
        title={"Exchange"}
        subtitle={"Custodian additional mailboxes"}
        wrapWithForm={true}
        onFormSubmit={handleExchangeForm}
        loading={loading}
      >
        <Grid>
          <CustomTable
            rows={tenantExchangeMailList.data}
            rowsCount={5000}
            headCells={tenantMailboxTableHeadCells()}
            isLoading={tenantExchangeMailList.isLoading}
            checkOption={true}
            onCheckRows={handleSelectedMailbox}
            selectorFn={(row) => {
              return (
                selectedMailbox.findIndex(
                  (x) =>
                    (x.hasOwnProperty("email")
                      ? x["email"]?.toLowerCase()
                      : x["mail"]?.toLowerCase()) ===
                    (row.hasOwnProperty("email")
                      ? row["email"]?.toLowerCase()
                      : row["mail"]?.toLowerCase())
                ) !== -1
              );
            }}
            isSearch={true}
            isPagination={false}
          >
            <CustomTableToolbar title={" "} />
          </CustomTable>
        </Grid>

        <CustomDrawerFooter>
          <Button variant="contained" type="submit" disabled={loading}>
            Submit
          </Button>
          <Button
            sx={{ marginLeft: "10px" }}
            variant="outlined"
            onClick={(event) => toggleExchangeDrawer(event, false)}
          >
            Cancel
          </Button>
        </CustomDrawerFooter>
      </CustomDrawer>

      <CustomDrawer
        isDrawerOpen={isSharePointDrawerOpen}
        toggleDrawer={toggleSharePointDrawer}
        title={"Sharepoint sites"}
        subtitle={"Custodian additional sites"}
        wrapWithForm={true}
        onFormSubmit={handleSharePointForm}
        loading={loading}
      >
        <Grid>
          <CustomTable
            rows={tenantSitesList.data}
            rowsCount={5000}
            headCells={tenantSitesTableHeadCells()}
            isLoading={tenantSitesList.isLoading}
            checkOption={true}
            onCheckRows={handleSelectedSites}
            isSearch={true}
            isPagination={false}
            selectorFn={(row) =>
              selectedSites.findIndex(
                (x) =>
                  (x.hasOwnProperty("siteWebUrl")
                    ? x["siteWebUrl"]?.toLowerCase()
                    : x["webUrl"]?.toLowerCase()) ===
                  (row.hasOwnProperty("siteWebUrl")
                    ? row["siteWebUrl"]?.toLowerCase()
                    : row["webUrl"]?.toLowerCase())
              ) !== -1
            }
          >
            <CustomTableToolbar title={" "} />
          </CustomTable>
        </Grid>
        <CustomDrawerFooter>
          <Button variant="contained" type="submit" disabled={loading}>
            Submit
          </Button>
          <Button
            sx={{ marginLeft: "10px" }}
            variant="outlined"
            onClick={(event) => toggleSharePointDrawer(event, false)}
          >
            Cancel
          </Button>
        </CustomDrawerFooter>
      </CustomDrawer>
    </>
  );
}

export default DataSourceCell;
