import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import swal from "sweetalert";
import axios from "../../../../utils/axios";
import api from "../../../../utils/api";
import { fetchUsers } from "../../../../redux/actions/settings/settingsActions";
import AuthorizeGuard from "../../../common/AuthorizeGuard";
import CustomDrawer from "../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserEditForm = ({
  toggleUserEditDrawer,
  isUserEditDrawerOpen,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isUserEditDrawerOpen);
  const [rolesData, setRolesData] = useState([]);
  const [role, setRole] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setOpen(isUserEditDrawerOpen);
  }, [isUserEditDrawerOpen]);

  useEffect(() => {
    if (currentUser) {
      setRole(currentUser.roles.map((item) => item.name));
      setRolesData(currentUser.roles.map((item) => item.id));
      setIsActive(currentUser.isActive);
    }
  }, [currentUser]);

  const { roleList } = useSelector((state) => state.settings);

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRole(typeof value === "string" ? value.split(",") : value);
    var selectedRole = roleList.data
      .map((item) => ({
        id: item.id,
        name: item.name,
      }))
      .filter((item) => {
        return event.target.value.includes(item.name);
      });
    setRolesData(selectedRole.map((item) => item.id));
  };

  const handleUser = (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = {};
    formData.isActive = isActive;
    formData.roles = rolesData;
    axios
      .patch(api.user.update(currentUser.id), formData)
      .then((res) => {
        swal("Success!", `User updated successfully!`, "success").then(() => {
          dispatch(fetchUsers());
          setLoading(false);
          toggleUserEditDrawer(e, false);
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <CustomDrawer
      className={"edit-user-drawer"}
      isDrawerOpen={isUserEditDrawerOpen}
      toggleDrawer={toggleUserEditDrawer}
      loading={loading}
      title={"Edit user"}
      wrapWithForm={true}
      onFormSubmit={handleUser}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            User
          </Typography>
          <Typography sx={{ width: "100%" }}>{currentUser?.name}</Typography>
          <Typography sx={{ width: "100%" }}>{currentUser?.email}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Select Role(s)
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              required
              size="small"
              id="demo-multiple-chip"
              multiple
              value={role}
              onChange={handleRoleChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {roleList.data.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} marginBottom={2}>
          <FormControlLabel
            style={{ marginBottom: "0px" }}
            control={
              <Checkbox
                style={{ padding: "0px 5px 0px 9px" }}
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            }
            label="Active"
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <AuthorizeGuard module={"USERS"} action={"Edit"}>
          <Button disabled={loading} type="submit" variant="contained">
            Submit
          </Button>
        </AuthorizeGuard>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleUserEditDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default UserEditForm;
