import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import swal from "sweetalert";
import {
  addUserSource,
  fetchUserSourcesByHold,
} from "../../../../../../redux/actions/holdActions";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  formControl: {
    width: 300,
    marginBottom: 20,
  },
}));

const UserSourceSelection = ({
  toggleUserSourceDrawer,
  isUserSourceDrawerOpen = false,
  tenantUsersList,
  userSources,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const { caseId, holdId } = params;
  const { data } = tenantUsersList;

  const [selected, setSelected] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [isMailChecked, setIsMailChecked] = useState(false);
  const [isSiteChecked, setIsSiteChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMailCheckboxChange = (event) => {
    setIsMailChecked(event.target.checked);
  };
  const handleSiteCheckboxChange = (event) => {
    setIsSiteChecked(event.target.checked);
  };
  const handleChange = (event) => {
    const selectedItem = data.find((item) => item.id === event.target.value);
    setSelected(event.target.value);
    setSelectedData(selectedItem);
  };
  const handleSelectedUserSource = (e) => {
    setLoading(true);
    var updatedData = {};
    var includedSources = [];
    if (isMailChecked) {
      includedSources.push("Mailbox");
    }
    if (isSiteChecked) {
      includedSources.push("Site");
    }
    if (includedSources.length === 0) {
      swal("Error", `Please include at aleast one source`, "error");
      setLoading(false);
      return;
    }
    updatedData.includedSources = includedSources;
    updatedData.email = selectedData.mail;
    dispatch(addUserSource({ updatedData, caseId, holdId }))
      .then((res) => {
        setLoading(false);
        if (!res?.error) {
          swal("Success!", `User source added successfully`, "success");
          dispatch(fetchUserSourcesByHold({ caseId, holdId }));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <CustomDrawer
      className={"add-usersource-hold-drawer"}
      isDrawerOpen={isUserSourceDrawerOpen}
      toggleDrawer={toggleUserSourceDrawer}
      loading={loading}
      title={"Add user source to hold"}
      wrapWithForm={false}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <label>User</label>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <InputLabel>Select an user</InputLabel>
            <Select
              value={selected}
              onChange={handleChange}
              label="Select an user"
              required
            >
              {data.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {`${item.displayName} (${item.mail})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {selectedData && Object.keys(selectedData).length > 0 ? (
            <>
              <Checkbox
                checked={isMailChecked}
                onChange={handleMailCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                className={"include-source-checkbox"}
              />
              <label>Include mailbox</label>
            </>
          ) : (
            <>-</>
          )}
        </Grid>
        <Grid item xs={12}>
          {selectedData && Object.keys(selectedData).length > 0 ? (
            <>
              <Checkbox
                checked={isSiteChecked}
                onChange={handleSiteCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                className={"include-source-checkbox"}
              />
              <label> Include site</label>
            </>
          ) : (
            <>-</>
          )}
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleSelectedUserSource}
        >
          Submit
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleUserSourceDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default UserSourceSelection;
