import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../composite/table/CustomTable";
import exportTableHeadCells from "./exportTableHeadCells";
import { useParams } from "react-router-dom";
import { fetchExportList } from "../../../../redux/actions/export/exportAction";
import ExportForm from "./exportForm";
import CustomTableToolbar from "../../../composite/table/CustomTableToolbar";

const Export = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isExportDrawerOpen, setIsExportDrawerOpen] = useState(false);

  const { exportList } = useSelector((state) => state.export);
  useEffect(() => {
    dispatch(fetchExportList({ caseId: params.caseId }));
  }, []);
  const isLoading = exportList.isLoading;
  const exportRows = exportList.data.map((item) => ({
    ...item,
    parentId: params.caseId,
  }));

  const toggleDrawer = (event, open) => {
    setIsExportDrawerOpen(open);
  };

  return (
    <>
      <CustomTable
        showSerialNo={true}
        rows={exportRows}
        headCells={exportTableHeadCells()}
        isLoading={isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"EXPORTS"}
          onRefreshEvent={() =>
            dispatch(fetchExportList({ caseId: params.caseId }))
          }
          addButtonText={"Create new export"}
          onAddEvent={(e) => toggleDrawer(e, true)}
        />
      </CustomTable>

      <ExportForm openDrawer={isExportDrawerOpen} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Export;
