import { format } from "date-fns";

export default function () {
  const holdsTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
      tableCellType: "LINK_CELL_TYPE",
      meta: {
        link: function (holdId, caseId) {
          return `/case/${caseId}/hold/${holdId}`;
        },
      },
    },
    // {
    //   id: "contentQuery",
    //   label: "Query Text",
    // },
    {
      id: "description",
      label: "Description"
    },
    // {
    //   id: "status",
    //   label: "Status",
    // },
    {
      id: "createdDateTime",
      tableCellType: "CUSTOM",
      fn: (cell) => format(new Date(cell["createdDateTime"]), 'dd MMM yyyy'),
      label: "Created",
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];
  return holdsTableHeadCells;
}
