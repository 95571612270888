export const isObjectEmptyOrNull = (obj) => {
  if (obj === undefined || obj === null) {
    return true;
  }
  return Object.keys(obj).length === 0;
};

export const getApiErrorResponse = (error) => {
  if (error.hasOwnProperty("response")) {
    return error.response.data.message;
  } else {
    return error.message;
  }
};
