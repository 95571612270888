import React from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";

export default function AuthorizeGuard({
  children,
  module,
  action,
  canNotViewType,
  showDeniedPage,
}) {
  const { data } = useSelector((state) => state.userPermissions);
  if (!module || !action) return children; // If module or action is not provided, render the children as is

  const userPermissions = data || [];
  const userModulePermission = userPermissions.find(
    (x) => x.moduleCode === module
  );
  const propName = action.startsWith("can") ? action : `can${action}`;
  const permission = userModulePermission?.[propName] || false;
  //const permission = false;
  if (!permission && propName === "canView") {
    // User does not have view permission
    if (canNotViewType === "Show") {
      const child = React.Children.only(children);
      const cloneChild = React.cloneElement(child, {
        onClick: undefined,
      });
      return cloneChild;
    } else if (showDeniedPage) {
      return (
        <>
          <h2>Authorization Failed</h2>
          <p>You do not have access to view this page</p>
        </>
      );
    } else return <></>;
  } else if (!permission) {
    if (showDeniedPage) {
      return (
        <>
          <h2>Authorization Failed</h2>
          <p>You do not have access to this page</p>
        </>
      );
    }
    const child = React.Children.only(children);
    const cloneChild = React.cloneElement(child, {
      onClick: () => {
        swal(
          "Authorization Failed",
          "You do not have access to perform this action"
        );
        return false;
      },
    });
    return cloneChild;
  } else {
    return children;
  }
}
