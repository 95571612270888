
const SubjectRightsRequestTypes = [
    {
      name: 'Data export',
      value: "export"
    },
    {
      name: 'Data deletion​',
       value: "delete"
    },
    {
      name: 'Data tagged for further action​',
       value: "tagForAction"
    },
    {
      name: 'Custom​',
       value: "unknownFutureValue"
    },
  ];
  export default SubjectRightsRequestTypes;
