import { IconButton, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import {
  BOOLEAN_CELL_TYPE,
  CUSTOM,
  DELETE_EDIT_ACTION_CELL_TYPE,
  LINK_CELL_TYPE,
} from "../../../constants/table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const renderCell = (
  row,
  cell,
  onClickActionEvent,
  onClickDeleteEvent,
  onClickEditEvent
) => {
  if (cell.id === "action") {
    if (cell?.tableCellType === DELETE_EDIT_ACTION_CELL_TYPE) {
      return (
        <Box>
          <IconButton size="small" onClick={(e) => onClickEditEvent(e, row.id)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => onClickDeleteEvent(e, row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      );
    } else {
      return (
        <IconButton size="small" onClick={(e) => onClickActionEvent(e, row.id)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      );
    }
  } else if (cell?.tableCellType === LINK_CELL_TYPE) {
    return (
      <Link
        to={
          row.hasOwnProperty("parentId")
            ? cell.meta.link(row.id, row.parentId, row[cell.id])
            : cell.meta.link(row.id, row[cell.id])
        }
      >
        {row[cell.id]}
      </Link>
    );
  } else if (cell?.tableCellType === BOOLEAN_CELL_TYPE) {
    return (
      <span style={cell.contentStyle}>
        {row[cell.id] ? <CheckCircleIcon /> : <CancelIcon />}
      </span>
    );
  } else if (cell?.tableCellType === CUSTOM) {
    return <>{cell.fn(row)}</>;
  } else {
    return <>{row[cell.id]}</>;
  }
};
