import React from "react";
import { Field } from "redux-form";
import { Grid, Typography } from "@mui/material";
import CustomTextField from "../../../../composite/CustomTextField";
import CustomTextArea from "../../../../composite/CustomTextArea";

const InitialCollectionsForm = () => {
  const inputContainerStyle = {
    marginBottom: "25px",
  };
  const inputLabelStyle = {
    marginBottom: "10px",
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight="Bold">
          Name and description
        </Typography>
        <Typography paragraph>
          To create new collection, you will need to name the collection,
          identity the collection scope and search conditions. Collections are
          unique searches that will constitute this reviewset.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={inputContainerStyle}>
        <Typography fontWeight="bold" sx={inputLabelStyle}>
          Collection Name
        </Typography>
        <Field name="displayName" component={CustomTextField} type="text" />
      </Grid>
      <Grid item xs={12} sx={inputContainerStyle}>
        <Typography variant="body1">
          <strong>Description </strong> (Optional)
        </Typography>
        <Field name="description" component={CustomTextArea} type="text" />
      </Grid>
    </Grid>
  );
};

export default InitialCollectionsForm;
