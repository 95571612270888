import { createSlice } from "@reduxjs/toolkit";
import { fetchConnections } from "../actions/connectionActions";
import {
  fetchSearchCollections,
  changeCollectionHold,
  deleteSearchCollection,
  loadDashboard,
  loadConnections,
  createCollection,
  deleteConnection,
  createServerConnection,
  loadConnectionInfo,
  updateServerConnection,
  loadCollectionInfo,
  searchCollection,
  loadExportedCollections,
  loadCollectionsOnHold,
  loadExportedCollectionFiles,
  resetUIAlert,
  updateCollection,
  loadConnectionLog,
} from "../actions/searchCollectionActions";
import { searchEmailReq } from "../actions/searchEmailActions";

const initialState = {
  component: 1,
  componentError: -1,
  connectionExists: false,
  connections: [],
  collections: [],
  searchResult: [],
  searchEmailResult:[],
  collectionsOnHold: [],
  dashboard: {
    connections: 0,
    collections: 0,
    collectionsOnHold: 0,
    cases: 0,
  },
  collectionInfo: null,
  connectionInfo: null,
  emailMessage: "",
  createdConnectionId: "",
  exportedCollections: [],
  exportedCollectionFilesResponse: null,
};

export const connectionSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //reset ui alert
      .addCase(resetUIAlert.fulfilled, (state, action) => {
        state.component = action.payload;
      })
      .addCase(fetchConnections.pending, (state) => {})
      .addCase(fetchConnections.fulfilled, (state, action) => {
        state.connections = action.payload.connections;
      })
      .addCase(fetchConnections.rejected, (state, action) => {})

      .addCase(fetchSearchCollections.fulfilled, (state, action) => {
        state.collections = action.payload.collections;
      })

      .addCase(deleteSearchCollection.fulfilled, (state, action) => {
        state.component = action.payload;
      })
      .addCase(deleteSearchCollection.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      .addCase(changeCollectionHold.fulfilled, (state, action) => {
        state.component = action.payload;
      })
      .addCase(changeCollectionHold.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      .addCase(loadDashboard.fulfilled, (state, action) => {
        state.dashboard = action.payload;
      })
      .addCase(loadDashboard.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      .addCase(loadConnections.fulfilled, (state, action) => {
        state.connections = action.payload;
      })
      .addCase(loadConnections.rejected, (state, action) => {
        state.componentError = action.error.message;
      })
      // create collection
      .addCase(createCollection.pending, (state) => {
        state.component = -1;
      })
      .addCase(createCollection.fulfilled, (state, action) => {
        state.component = action.payload;
      })
      .addCase(createCollection.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      // update collection
      .addCase(updateCollection.pending, (state) => {
        state.component = -1;
      })
      .addCase(updateCollection.fulfilled, (state, action) => {
        state.component = action.payload;
      })
      .addCase(updateCollection.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      // delete connection
      .addCase(deleteConnection.pending, (state) => {
        state.component = -1;
      })
      .addCase(deleteConnection.fulfilled, (state, action) => {
        state.component = action.payload;
      })
      .addCase(deleteConnection.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      // creat connection
      .addCase(createServerConnection.pending, (state) => {
        state.component = -1;
      })
      .addCase(createServerConnection.fulfilled, (state, action) => {
        state.createdConnectionId = action.payload.createdConnectionId;
        state.component = action.payload.component;
      })
      .addCase(createServerConnection.rejected, (state, action) => {
        state.componentError = action.error.message;
      })
      //load connection info

      .addCase(loadConnectionInfo.fulfilled, (state, action) => {
        state.connectionInfo = action.payload;
      })
      .addCase(loadConnectionInfo.rejected, (state, action) => {
        state.componentError = action.error.message;
      })
      //load connection logs
      .addCase(loadConnectionLog.fulfilled, (state, action) => {
        state.connectionInfo.logs = action.payload;
      })
      .addCase(loadConnectionLog.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      .addCase(updateServerConnection.fulfilled, (state, action) => {
        state.component = action.payload.component;
        state.createdConnectionId = action.payload.createdConnectionId;
      })
      .addCase(updateServerConnection.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      .addCase(loadCollectionInfo.fulfilled, (state, action) => {
        state.collectionInfo = action.payload;
      })
      .addCase(loadCollectionInfo.rejected, (state, action) => {
        state.componentError = action.error.message;
      })

      .addCase(searchCollection.fulfilled, (state, action) => {
        state.component = action.payload.component;
        state.searchResult = action.payload.searchResult;
      })

      //Exported Collections
      .addCase(loadExportedCollections.fulfilled, (state, action) => {
        state.exportedCollections = action.payload;
      })
      //collection on hold
      .addCase(loadCollectionsOnHold.fulfilled, (state, action) => {
        state.collectionsOnHold = action.payload;
      })
      //Exported Collection Files
      .addCase(loadExportedCollectionFiles.fulfilled, (state, action) => {
        state.exportedCollectionFilesResponse = action.payload.exportResults;
        state.connections = action.payload.connections;
      })

      .addCase(searchEmailReq.fulfilled, (state, action) => {
        state.searchEmailResult = action.payload.searchEmailResult;
      })
  },
});

export default connectionSlice.reducer;
