import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import api from "../../utils/api";

export const fetchTenantUsers = createAsyncThunk(
  "tenant/fetchTenantUsers",
  async () => {
    const response = await axios.get(api.tenant.getUsers());
    return response.data;
  }
);

export const fetchTenantSites = createAsyncThunk(
  "tenant/fetchTenantSites",
  async () => {
    const response = await axios.get(api.tenant.getSites());
    return response.data;
  }
);

export const fetchTenantExchangeMails = createAsyncThunk(
  "tenant/fetchTenantExchangeMails",
  async () => {
    const response = await axios.get(api.tenant.getExchangeMails());
    return response.data;
  }
);
