import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../../../../utils/axios";
import api from "../../../../../utils/api";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import CustomDrawer from "../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../composite/table/CustomDrawerFooter";

const AddToReviewSet = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [addType, setAddType] = React.useState("New");
  const params = useParams();
  const [reviewSets, setReviewSets] = useState([]);
  const [reviewSetName, setReviewSetName] = useState();
  const [selectedReviewSet, setSelectedReviewSet] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const toggleDrawer = (event, open) => {
    setIsDrawerOpen(open);
  };

  const handleAddTypeChange = (event) => {
    setAddType(event.target.value);
  };

  const handleReviewSetNameChange = (e) => {
    setReviewSetName(e.target.value);
  };

  const handleSelectReviewSetNameChange = (e, selectedValue) => {
    setSelectedReviewSet(selectedValue.value);
  };

  useEffect(() => {
    axiosInstance
      .get(api.reviewSets.get(params.caseId))
      .then((res) => {
        const reviewSetsOptions = res.data.map((item) => {
          return {
            label: item.displayName,
            value: item.id,
          };
        });
        setReviewSets(reviewSetsOptions);
      })
      .catch((error) => {
        setIsDrawerOpen(false);
      });
  }, []);

  const onAdd = (e) => {
    e.preventDefault();
    setErrorMessage("");
    const formData = {};
    if (addType === "New") {
      if (!reviewSetName) {
        setErrorMessage("Please provide reviewset name!");
      } else {
        setIsLoading(true);
        formData["reviewSetName"] = reviewSetName;
        axiosInstance
          .post(
            api.addToReviewSet.add(params.caseId, params.collectionId),
            formData
          )
          .then((res) => {
            setIsLoading(false);
            swal(
              "Success!",
              "Items have been added to reviewset",
              "success"
            ).then(() => {
              setIsDrawerOpen(false);
            });
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    } else {
      if (!selectedReviewSet) {
        setErrorMessage("Please select a reviewset!");
      } else {
        setIsLoading(true);
        formData["reviewSetId"] = selectedReviewSet.value;
        axiosInstance
          .post(
            api.addToReviewSet.add(params.caseId, params.collectionId),
            formData
          )
          .then((res) => {
            setIsLoading(false);
            swal(
              "Success!",
              "Collection items has been added to reviewset",
              "success"
            ).then(() => {
              setIsDrawerOpen(false);
            });
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <CustomDrawer
      className={"add-to-reviewset-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={isLoading}
      title={"Add collection to reviewset"}
      subtitle={
        "Collection will immediately gather items and add them to the reviewset."
      }
      wrapWithForm={true}
      onFormSubmit={onAdd}
    >
      {errorMessage ? (
        <Alert style={{ width: "100%" }} severity="error">
          {errorMessage}
        </Alert>
      ) : (
        <></>
      )}

      <FormControl style={{ width: "100%" }}>
        <RadioGroup
          name="controlled-radio-buttons-group"
          value={addType}
          onChange={handleAddTypeChange}
        >
          <FormControlLabel
            value="New"
            control={<Radio />}
            label="Add to new reviewset"
          />
          {addType === "New" ? (
            <TextField
              required
              size="small"
              id="review-set-name"
              label="Reviewset name"
              variant="outlined"
              onChange={handleReviewSetNameChange}
            />
          ) : (
            <></>
          )}
          <FormControlLabel
            value="Old"
            control={<Radio />}
            label="Add to existing reviewset"
          />
          {addType === "Old" ? (
            <>
              <Autocomplete
                options={reviewSets.map((option) => {
                  return {
                    label: option.label,
                    value: option.value,
                  };
                })}
                size="small"
                getOptionLabel={(option) => option.label}
                value={selectedReviewSet}
                onChange={(event, newValue) => {
                  setSelectedReviewSet(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </>
          ) : (
            <></>
          )}
        </RadioGroup>
      </FormControl>
      <CustomDrawerFooter>
        <Button disabled={isLoading} variant="contained" type="submit">
          Submit
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={(event) => toggleDrawer(event, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default AddToReviewSet;
