import React, { Component } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { Circles } from "react-loader-spinner";
import swal from "sweetalert";
import { Form, Button, Modal } from "react-bootstrap";
import {
  IconButton,
  Box,
  Grid,
  Popover,
  Typography,
  Drawer,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DatePicker from "react-datepicker";
import {
  faCopy,
  faDownload,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import IosShareIcon from "@mui/icons-material/IosShare";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../../../assets/css/main.css";
import "react-datepicker/dist/react-datepicker.css";
import { config } from "../../../config";
import axiosInstance from "../../../utils/axios";
import { Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchAccessGuard from "../../common/SearchAccessGuard";

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      collectionId: this.props.searchParams.get("id"),
      startDate: null,
      endDate: null,
      documentModalIsOpen: false,
      searched: false,
      documentModalTitle: "",
      documentModalText: "",
      documentModalContentType: "",
      documentModalAttachment: "",
      selectedExts: [],
      searchIsActive: false,
      oldResult: "",
      selectedSearchFieldType: "Both",
      currentPage: 1,
      userTimeZone: "",
      extensions: [
        "txt",
        "msg",
        "docx",
        "doc",
        "xlsx",
        "xls",
        "csv",
        "pdf",
        "png",
        "jpg",
        "log"
      ],
      isExportEnabled: true,
      anchorEl: "",
      searchSidePanelOpen: true,
      selectedItems: [],
    };

    try {
      var searchState = localStorage.getItem("searchState");
      searchState = JSON.parse(searchState);
      this.state.selectedExts = searchState.selectedExts || [];
      this.state.searchValue = searchState.searchValue;
      this.state.selectedSearchFieldType = searchState.selectedSearchFieldType;
      this.state.startDate = searchState.startDate
        ? new Date(searchState.startDate)
        : null;
      this.state.endDate = searchState.endDate
        ? new Date(searchState.endDate)
        : null;
    } catch {
      console.log("couldnt load data");
    }

    this.state.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.typingTimeout = null;
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount = () => {
    setTimeout((x) => {
      this.getCollectionInfo();
    }, 500);
  };

  getCollectionInfo() {
    var data = {
      collectionId: this.state.collectionId,
    };

    this.props.loadCollectionInfo(data);
  }

  onFieldChange(event) {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout);
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout((x) => {
      this.searchCollection(true);
    }, 1000);

    // Setting value of the search box to a state.
    this.setState({ [event.target.name]: event.target.value });
  }

  resetPage = () => {
    this.state.currentPage = 1;
  };

  searchNextPage = () => {
    this.state.currentPage += 1;
    this.searchCollection(false);
  };

  searchPreviousPage = () => {
    if (this.state.currentPage > 1) this.state.currentPage -= 1;

    this.searchCollection(false);
  };

  handleCheckboxChange = (event, step) => {
    const { selectedItems } = this.state;
    if (event.target.checked) {
      this.setState({
        selectedItems: [
          ...selectedItems,
          { id: step.itemId, index: step.indexId },
        ],
      });
    } else {
      this.setState({
        selectedItems: selectedItems.filter((x) => x.id !== step.itemId),
      });
    }
  };

  handleCheckAll = (event) => {
    if (event.target.checked) {
      this.setState({
        selectedItems: this.props.ui.searchResult.files.map((step, index) => ({
          id: step.itemId,
          index: step.indexId,
        })),
      });
    } else {
      this.setState({
        selectedItems: [],
      });
    }
  };

  handleDownload = (e) => {
    if (this.state.selectedItems?.length === 0) return;
    swal({
      title: "Confirmation",
      text: `Do you really want to download the selected ${this.state.selectedItems.length} documents?`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: false,
    }).then((yes) => {
      if (!yes) return;
      if (this.state.selectedItems?.length === 1) {
        window.open(
          `${config.apiServerUrl}/collections/${
            this.state.selectedItems[0].index
          }/documents/${
            this.state.selectedItems[0].id
          }?token=${localStorage.getItem("accessToken")}`,
          "_blank"
        );
      } else {
        axiosInstance
          .post(
            `/collections/${this.state.collectionId}/documents/download`,
            { docs: this.state.selectedItems },
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = `${this.props?.ui?.collectionInfo?.name}_documents.zip`;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
      }
    });
  };

  generateExtensions = () => {
    if (this.state.extensions.length > 0) {
      this.state.oldResult = this.props.ui.searchResult;

      const steps = this.state.extensions.map((step, index) => (
        <Form.Check
          key={`chk-${index}`}
          type="checkbox"
          id={`ext-checkbox-${index}`}
          label={step}
          data-value={step}
          defaultChecked={this.state.selectedExts.includes(step)}
          onChange={this.handleExtensionsCheck}
          style={{ display: "inline-block", width: "45%" }}
        />
      ));

      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "1.25rem",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {this.props.ui?.collectionInfo?.name}
            </Typography>

            <IconButton
              sx={{ color: "white" }}
              onClick={() =>
                this.setState({
                  searchSidePanelOpen: !this.state.searchSidePanelOpen,
                })
              }
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <hr style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }} />

          <div className="label" style={{ textAlign: "left" }}>
            Search In
          </div>
          <div>
            <Form.Check
              reverse="true"
              label="File Name"
              name="search-location"
              type="radio"
              id={`title`}
              data-value={"Title"}
              checked={this.state.selectedSearchFieldType === "Title"}
              onChange={this.handleSearchFieldTypeCheck}
            />

            <Form.Check
              reverse="true"
              label="File Content"
              name="search-location"
              type="radio"
              id={`content`}
              data-value={"Content"}
              checked={this.state.selectedSearchFieldType === "Content"}
              onChange={this.handleSearchFieldTypeCheck}
            />

            <Form.Check
              reverse="true"
              label="Both"
              name="search-location"
              type="radio"
              id={`both`}
              data-value={"Both"}
              checked={this.state.selectedSearchFieldType === "Both"}
              onChange={this.handleSearchFieldTypeCheck}
            />
          </div>
          <hr style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }} />
          <div className="label" style={{ textAlign: "left" }}>
            File Extensions
          </div>
          <div>{steps}</div>
          <div className="padding"></div>

          <hr style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }} />
        </div>
      );
    }
  };

  getFileIcon = (ext) => {
    return ext?.length <= 3 ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{ height: "45px" }}
      >
        <g>
          <g>
            <path
              d="M497,269.3c0,45,0,85.2,0,130.2c0,19.4-9.3,32.7-34.9,32.7c-9.3,0-28.6-0.1-37.9-0.1c0,0,0,0.4,0,6.7
                c0,14.7-0.3,33.5,0,48.2c0.3,13.9-10.3,24.9-24,24.9c-124.3,0-235.2,0-359.5,0C24.1,512,15,500.6,15,487.3c0-155.3,0-309,0-464.3
                c0-12.8,9.2-23,25-23c85.7,0,156.2,0,241.9,0c8.7,0,12.8,2.5,16,5.7c39,39.5,81.1,82.1,120.2,121.5c4.2,4.3,6.2,8.8,6.2,15.1
                c-0.3,29.5-0.1,59-0.2,88.4c0,4.5,0,6.1,0,6.1c11.2-0.2,27,0,38.2,0c11.1,0,18.6,2.3,25,7.9C493.8,250.5,497,258.3,497,269.3z
                M48.1,255.8c0,72.2,0,144.3,0,216.5c0,7.1,0.4,7.4,7.3,7.4c109.5,0,219,0,328.5,0c6.5,0,7.2-0.7,7.2-7.1c0.1-10.5,0-21,0-31.5
                c0-8.9,0-8.9,0-8.9c-78.8,0-166.5,0-245.4,0c-7.8,0-17.6,0.1-25.4-0.1c-19.1-0.5-30.7-14.3-30.7-33.2c0-43,0-86,0-129
                c0-2.5,0.1-3.7,0.6-7.4c2.1-16,16.3-25.7,33.5-25.7c86.5,0,180.8,0,267.3,0c0,0,0-0.2,0-7.9c0-21.7,0-43.3,0-65
                c0-7.3-0.3-7.6-7.6-7.6c-25.5,0-51,0.1-76.5,0c-19.6-0.1-33.6-10.6-37.8-27.9c-1-4.4-1.4-8.9-1.4-13.4c0-25,0-50,0-75
                c0-7.6,0-7.6-7.5-7.6c-67.8,0-135.7,0-203.5,0c-8.8,0-8.8,0-8.8,8.9C48.1,112.8,48.1,184.3,48.1,255.8z M382.9,126.9
                c0.8,0,1,0,1.8,0c-28.1-28.4-58.8-59.6-86.9-88.1c0,3,0.2,5.3,0.2,6.9c0,22.5,0,45,0,67.4c0,10.7,3,13.7,13.6,13.8
                c20.3,0,40.6,0,60.9,0C376,126.9,379.5,126.9,382.9,126.9z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 137.0944 382.8222)"
            style={{
              fill: "#FFFFFF",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              fontSize: "136px",
            }}
          >
            {ext?.toUpperCase()}
          </text>
        </g>
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{ height: "45px" }}
      >
        <g>
          <g>
            <path
              d="M497,269.3c0,45,0,85.2,0,130.2c0,19.4-9.3,32.7-34.9,32.7c-9.3,0-28.6-0.1-37.9-0.1c0,0,0,0.4,0,6.7
                c0,14.7-0.3,33.5,0,48.2c0.3,13.9-10.3,24.9-24,24.9c-124.3,0-235.2,0-359.5,0C24.1,512,15,500.6,15,487.3c0-155.3,0-309,0-464.3
                c0-12.8,9.2-23,25-23c85.7,0,156.2,0,241.9,0c8.7,0,12.8,2.5,16,5.7c39,39.5,81.1,82.1,120.2,121.5c4.2,4.3,6.2,8.8,6.2,15.1
                c-0.3,29.5-0.1,59-0.2,88.4c0,4.5,0,6.1,0,6.1c11.2-0.2,27,0,38.2,0c11.1,0,18.6,2.3,25,7.9C493.8,250.5,497,258.3,497,269.3z
                M48.1,255.8c0,72.2,0,144.3,0,216.5c0,7.1,0.4,7.4,7.3,7.4c109.5,0,219,0,328.5,0c6.5,0,7.2-0.7,7.2-7.1c0.1-10.5,0-21,0-31.5
                c0-8.9,0-8.9,0-8.9c-78.8,0-166.5,0-245.4,0c-7.8,0-17.6,0.1-25.4-0.1c-19.1-0.5-30.7-14.3-30.7-33.2c0-43,0-86,0-129
                c0-2.5,0.1-3.7,0.6-7.4c2.1-16,16.3-25.7,33.5-25.7c86.5,0,180.8,0,267.3,0c0,0,0-0.2,0-7.9c0-21.7,0-43.3,0-65
                c0-7.3-0.3-7.6-7.6-7.6c-25.5,0-51,0.1-76.5,0c-19.6-0.1-33.6-10.6-37.8-27.9c-1-4.4-1.4-8.9-1.4-13.4c0-25,0-50,0-75
                c0-7.6,0-7.6-7.5-7.6c-67.8,0-135.7,0-203.5,0c-8.8,0-8.8,0-8.8,8.9C48.1,112.8,48.1,184.3,48.1,255.8z M382.9,126.9
                c0.8,0,1,0,1.8,0c-28.1-28.4-58.8-59.6-86.9-88.1c0,3,0.2,5.3,0.2,6.9c0,22.5,0,45,0,67.4c0,10.7,3,13.7,13.6,13.8
                c20.3,0,40.6,0,60.9,0C376,126.9,379.5,126.9,382.9,126.9z"
            />
          </g>
          <text
            transform="matrix(0.8 0 0 1 115.2638 382.8222)"
            style={{
              fill: "#FFFFFF",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              fontSize: "136px",
              textAlign: "center",
            }}
          >
            {ext?.toUpperCase()}
          </text>
        </g>
      </svg>
    );
  };

  generateFiles = () => {
    if (
      this.props.ui.searchResult === undefined ||
      this.props.ui.searchResult.files === undefined
    ) {
      return;
    }

    const steps = this.props.ui.searchResult.files.map((step, index) => (
      <div key={step.id} className="tab-pane active">
        <div className="row">
          <div className="col-md-1" style={{ maxWidth: "45px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={
                    this.state.selectedItems.findIndex(
                      (x) => x.id === step.itemId && x.index === step.indexId
                    ) >= 0
                  }
                  onChange={(event) => this.handleCheckboxChange(event, step)}
                />
              }
            />
          </div>
          <div className="col-md-11">
            <div className="search-item">
              <div className="row">
                <div className="file-icon-block">
                  {this.getFileIcon(step.extension)}
                </div>
                <div className="file-description">
                  <div className="row">
                    <div className="col-12 text-left">
                      <div className="row">
                        <div className="col-12">
                          <a
                            href="#"
                            onClick={(x) =>
                              this.openDocumentModal(
                                step.content,
                                step.name,
                                step.contentType,
                                step.attachment
                              )
                            }
                            className="link-primary file-link"
                            title={step.name}
                          >
                            {step.name}
                          </a>
                          <div className="owned-label">
                            Owned by: <b>{step.author || "Organization"}</b>
                          </div>
                          <div className="time-label">
                            {format(
                              new Date(step.createdDateTime),
                              "dd MMM yyyy H:mm a"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="size-label">
                            {Math.round((step.size / 1024) * 100) / 100} KB
                          </div>
                          <div className="path-label">
                            {"Path: "}
                            <a
                              className="path-link"
                              href={`${config.apiServerUrl}/collections/${
                                step.indexId
                              }/documents/${
                                step.itemId
                              }?token=${localStorage.getItem("accessToken")}`}
                              title={step.path}
                            >
                              {step.path}
                            </a>
                            <Button
                              onClick={(event) => {
                                navigator.clipboard.writeText(step.path);
                                this.setState({
                                  anchorEl: event.currentTarget,
                                });
                              }}
                              style={{
                                marginLeft: "3px",
                                padding: "0 5px",
                                cursor: "pointer",
                              }}
                              title="Copy"
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                style={{ color: "gray" }}
                              />
                            </Button>
                            <Popover
                              open={Boolean(this.state.anchorEl)}
                              anchorEl={this.state.anchorEl}
                              onClose={() => {
                                this.setState({ anchorEl: null });
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography style={{ padding: "0 5px" }}>
                                {" Copied "}
                              </Typography>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-left">
                      <div
                        className="mt-1"
                        style={{
                          maxWidth: "100%",
                          wordWrap: "break-word",
                          paddingLeft: "13px",
                        }}
                      >
                        {/* <div
                            dangerouslySetInnerHTML={{ __html: step.content }}
                          /> */}
                        {step.content && step.content.length > 300
                          ? step.content.slice(0, 300) + " ....."
                          : step.content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    ));

    return <div>{steps}</div>;
  };

  searchCollection = (reset) => {
    this.setState({ searchIsActive: true });
    var reachedEmailsLimit;

    if (reset) {
      this.resetPage();
      reachedEmailsLimit = false;
    } else {
      if (this.props.ui.searchResult !== null) {
        reachedEmailsLimit = this.props.ui.searchResult.reachedEmailLimit;
      }
    }

    const search = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      searchFieldType: this.state.selectedSearchFieldType,
      extensions: this.state.selectedExts || [],
      searchValue: this.state.searchValue,
      pageNumber: this.state.currentPage,
      reachedLimit: reachedEmailsLimit,
      userTimezoneName: this.state.userTimeZone,
      collectionId: this.state.collectionId,
      pageSize: 0,
    };

    this.state.searched = true;
    // this.state.searchIsActive = false;

    localStorage.setItem("searchState", JSON.stringify(this.state));

    this.props.searchCollection(search);
  };

  componentDidUpdate(prevProps) {
    if (this.props.ui.searchResult !== prevProps.ui.searchResult) {
      this.setState({ searchIsActive: false });
    }
  }

  handleExtensionsCheck = (e) => {
    var value = e.target.attributes["data-value"].value;
    if (!this.state.selectedExts.includes(value)) {
      this.state.selectedExts.push(value);
    } else {
      var index = this.state.selectedExts.indexOf(value);
      if (index !== -1) {
        this.state.selectedExts.splice(index, 1);
      }
    }

    this.searchCollection(true);
  };

  handleSearchFieldTypeCheck = (e) => {
    var value = e.target.attributes["data-value"].value;
    this.state.selectedSearchFieldType = value;

    this.searchCollection(true);
  };

  handleChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleStartDate = (e) => {
    this.setState({ startDate: e });
  };

  handleEndDate = (e) => {
    this.setState({ endDate: e });
  };

  static displayName = Search.name;

  requestCollectionExport = () => {
    swal({
      title: "Confirmation",
      text: `Do you really want to export the result?`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: false,
    }).then((yes) => {
      if (!yes) return;

      var reachedEmailsLimit = this.props.ui.searchResult.reachedEmailLimit;

      const search = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        searchFieldType: this.state.selectedSearchFieldType,
        extensions: this.state.selectedExts || [],
        searchValue: this.state.searchValue,
        pageNumber: this.state.currentPage,
        reachedLimit: reachedEmailsLimit,
        userTimezoneName: this.state.userTimeZone,
        collectionId: this.state.collectionId,
        pageSize: 0,
      };

      this.displaySuccessExport();
      this.setState({ isExportEnabled: false });

      this.props.requestCollectionExport(search);
      this.state.isExportEnabled = true;
    });
  };

  openDocumentModal = (text, title, contentType, attachment) => {
    this.setState({ documentModalTitle: title });
    this.setState({ documentModalText: text });
    this.setState({ documentModalContentType: contentType });
    this.setState({ documentModalAttachment: attachment });
    this.setState({ documentModalIsOpen: true });
  };
  closeDocumentModal = () => this.setState({ documentModalIsOpen: false });

  displaySuccessExport = () => {
    // this.props.ui.component = -1;
    this.props.resetUIAlert();
    // this.state.isExportEnabled = true;
    this.setState({ isExportEnabled: true });
    swal(
      "Success!",
      `Exporting of the collection has been started. The exported data will be available in the "Exported Collections" section, once it's completed.`,
      "success"
    );
  };

  displayExportError = () => {
    this.props.ui.component = -1;
    swal(
      "Error!",
      `We weren't able to start exporting your collection. Try again later.`,
      "error"
    );
  };

  render() {
    return (
      <div className="content">
        <div className="container max-container">
          <div className="row max-height">
            <Box
              sx={{
                position: "fixed",
                top: "13px",
                left: "0px",
                zIndex: "9999",
              }}
            >
              <IconButton
                onClick={() =>
                  this.setState({
                    searchSidePanelOpen: !this.state.searchSidePanelOpen,
                  })
                }
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Grid container>
              {this.state.searchSidePanelOpen && (
                <Grid item xs={2}>
                  <Drawer
                    sx={{
                      width: 240,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: 240,
                        boxSizing: "border-box",
                      },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={this.state.searchSidePanelOpen}
                    PaperProps={{
                      style: {
                        backgroundColor: "#3C3C3C",
                        color: "rgba(255, 255, 255, 0.8)",
                        paddingLeft: "24px",
                        paddingTop: "20px",
                        paddingBottom: "50px",
                        zIndex: "9999",
                      },
                    }}
                  >
                    <div
                      className="sidebar-container"
                      style={{
                        paddingLeft: "0px",
                        paddingTop: "2px",
                      }}
                    >
                      {this.generateExtensions()}

                      <div className="label" style={{ textAlign: "left" }}>
                        File Date
                      </div>
                      <div className="label-sm">From</div>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDate}
                        name="startDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />

                      <div className="label-sm mt-2">To</div>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDate}
                        name="endDate"
                        className="form-control file-date-picker"
                        dateFormat="dd/MM/yyyy"
                      />

                      <div className="padding"></div>
                    </div>
                  </Drawer>
                </Grid>
              )}

              <Grid item xs={this.state.searchSidePanelOpen ? 10 : 12} pl={2}>
                <Grid item xs={12}>
                  <Box
                    className={`app-breadcrumbs-container`}
                    sx={{
                      width: "100%",
                      paddingBottom: "5px",
                      borderBottom: "1px dotted #ccc",
                      marginBottom: "15px",
                    }}
                  >
                    <Breadcrumbs
                      className={"connections-breadcrumbs"}
                      aria-label="breadcrumb"
                      separator={<NavigateNextIcon fontSize="small" />}
                    >
                      <Typography color="text.primary">Dashboard</Typography>
                      <Typography color="text.primary">Collections</Typography>
                      <Typography color="text.primary">
                        {this.props.ui?.collectionInfo?.name}
                      </Typography>
                      <Typography color="text.primary">Search</Typography>
                    </Breadcrumbs>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="row"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="col-md-12">
                      <div className="pt-3 pb-4">
                        <div className="input-group">
                          <input
                            type="text"
                            id=""
                            name=""
                            className="form-control search-input"
                            value={this.state.searchValue}
                            onChange={this.handleChange}
                          />
                          <div className="input-group-append">
                            <Button
                              type="button"
                              variant="info"
                              className="btn waves-effect waves-light search-button"
                              onClick={(x) => this.searchCollection(true)}
                            >
                              <FontAwesomeIcon
                                icon={faSearch}
                                size="1x"
                                className="mr-2"
                              />
                              Search
                            </Button>
                          </div>
                          {this.props.ui.searchResult.files &&
                          this.props.ui.searchResult.files.length > 0 &&
                          this.state.isExportEnabled ? (
                            <div
                              className="export-button"
                              onClick={this.requestCollectionExport}
                            >
                              <IosShareIcon />
                              Export
                            </div>
                          ) : (
                            <div className="export-button disabled">
                              <IosShareIcon />
                              Export
                            </div>
                          )}
                          {this.state?.selectedItems?.length > 0 ? (
                            <div
                              className="export-button"
                              onClick={(e) => this.handleDownload()}
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                size="1x"
                                className="mr-2"
                              />
                              Download
                            </div>
                          ) : (
                            <div className="export-button disabled">
                              <FontAwesomeIcon
                                icon={faDownload}
                                size="1x"
                                className="mr-2"
                              />
                              Download
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div style={{ height: "100%" }}>
                    <div className="border-element max-content-height search-result-body">
                      <div className="search-result-box card-box">
                        {this.state.searchIsActive ? (
                          <div className="row">
                            <div className="col-lg-12 text-center">
                              <div className="loader-panel">
                                <Circles
                                  color="#61a098"
                                  height={80}
                                  width={80}
                                />
                              </div>
                            </div>
                          </div>
                        ) : this.props.ui.searchResult.files &&
                          this.props.ui.searchResult.files.length > 0 ? (
                          <div
                            className="result-label"
                            style={{ marginLeft: "31px" }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  onChange={(event) =>
                                    this.handleCheckAll(event)
                                  }
                                />
                              }
                            />
                            <span>
                              {`Search result 1 - ${this.props.ui.searchResult.files.length} of Page #${this.state.currentPage} for "`}
                              <b style={{ color: "#007bff" }}>
                                {this.state.searchValue}
                              </b>
                              "
                            </span>
                            {this.state.selectedExts?.length > 0 ? (
                              <span>
                                {" File extentions: "}
                                {this.state.selectedExts.join(", ")}
                              </span>
                            ) : (
                              <></>
                            )}

                            {this.state.startDate && this.state.endDate ? (
                              <span>
                                {" Date between: "}
                                {format(
                                  new Date(this.state.startDate),
                                  "dd/MM/yyyy"
                                )}{" "}
                                -{" "}
                                {format(
                                  new Date(this.state.endDate),
                                  "dd/MM/yyyy"
                                )}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <div className="font-md mt-3">
                            <b>
                              {this.state.searched
                                ? "Nothing found!"
                                : "Use search to find the files you want"}
                            </b>
                          </div>
                        )}

                        <div className="tab-content search-container">
                          {this.state.searchIsActive
                            ? ""
                            : this.generateFiles()}
                        </div>
                      </div>

                      <div className="text-center bottom-panel">
                        <div
                          className="btn-group button-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          {this.state.currentPage > 1 ? (
                            <a
                              onClick={(x) => this.searchPreviousPage()}
                              className="button-left"
                            >
                              Previous page
                            </a>
                          ) : (
                            <a className="button-left"></a>
                          )}
                          {this.props.ui.searchResult !== "" &&
                          this.props.ui.searchResult.isLastPage !== true ? (
                            <a
                              onClick={(x) => this.searchNextPage()}
                              className="button-right"
                            >
                              Next page
                            </a>
                          ) : (
                            <a className="button-right"></a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Modal
              show={this.state.documentModalIsOpen}
              onHide={this.closeDocumentModal}
              size="lg"
              centered
              style={{ zIndex: "9999" }}
            >
              <Modal.Title>
                <label className="main-title ml-3 mt-3">
                  {this.state.documentModalTitle}
                </label>
              </Modal.Title>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-lg-4"></div>
                            </div>
                            <div className="card shadow-none mt-3 border border-light">
                              <div
                                className="card-body"
                                style={{ maxHeight: "60vh", overflow: "auto" }}
                              >
                                {this.state.documentModalContentType?.startsWith(
                                  "image/"
                                ) !== true ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: this.state.documentModalText,
                                    }}
                                  />
                                ) : (
                                  <img
                                    style={{ maxWidth: "100%" }}
                                    src={`data:${this.state.documentModalContentType};base64,${this.state.documentModalAttachment}`}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeDocumentModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const collection = props?.ui?.collectionInfo;
  let members = collection?.searchMembers || [];
  if (collection?.createdByUserId) {
    members = [...members, collection.createdByUserId];
  }

  return (
    <SearchAccessGuard members={members} showDeniedPage={true}>
      <Component
        {...props}
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </SearchAccessGuard>
  );
};

export const SearchNav = withNavigate(Search);
