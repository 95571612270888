import { IconButton } from "@mui/material";
import { BOOLEAN_CELL_TYPE, CUSTOM } from "../../../../../constants/table";
import DeleteIcon from "@mui/icons-material/Delete";

export default function (onDeleteEvent) {
  const additionalSourcesTableHeadCells = [
    // {
    //   id: "id",
    //   label: "ID",
    //   tableCellType: CUSTOM,
    //   fn: (cell) => (
    //     <div className="guid-cell" title={cell.id}>
    //       {cell.id}
    //     </div>
    //   ),
    // },

    // {
    //   id: "displayName",
    //   label: "Title",
    //   align: "left"
    // },
    {
      id: "emailUrl",
      label: "Email/SiteUrl",
      tableCellType: CUSTOM,
      fn: (cell) =>
        cell.type === "UserSource"
          ? cell.email
          : cell.type === "SiteSource"
          ? cell.siteWebUrl
          : "",
    },
    {
      id: "type",
      label: "Type",
      tableCellType: CUSTOM,
      fn: (cell) => cell.type.replace("Source", ""),
    },
    // {
    //   id: "includeMailBox",
    //   label: "Include Mailbox",
    //   tableCellType: BOOLEAN_CELL_TYPE,
    //   contentStyle: { color: "#bbb" },
    // },
    // {
    //   id: "includeSite",
    //   label: "Include Site",
    //   tableCellType: BOOLEAN_CELL_TYPE,
    //   contentStyle: { color: "#bbb" },
    // },
    {
      id: "remove",
      label: "",
      tableCellType: CUSTOM,
      fn: (cell) => (
        <IconButton
          size="small"
          onClick={(e) => onDeleteEvent(e, cell.id)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];
  return additionalSourcesTableHeadCells;
}
