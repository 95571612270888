import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "redux-form";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
} from "@mui/material";
import swal from "sweetalert";
import CardContent from "@mui/material/CardContent";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  getCaseCustodians,
  getCaseOperations,
  getCaseSettings,
} from "../../../redux/actions/caseActions";
import CaseForm from "./form/CaseForm";
import { updateCase, fetchCaseById } from "../../../redux/actions/caseActions";
import { isObjectEmptyOrNull } from "../../../utils/validation";
import SettingsForm from "./form/SettingsForm";
import axios from "../../../utils/axios";
import api from "../../../utils/api";
import { showApiError } from "../../../utils/functions";
import RefreshIcon from "@mui/icons-material/Refresh";

const useStyles = makeStyles({
  root: {
    overflowX: "auto",
    borderRadius: 0,
  },
  tableHeader: {
    backgroundColor: "#f5f5f5",
  },
  tableHeaderCell: {
    padding: "7px",
    fontWeight: "bold",
  },
  tableCell: {
    borderBottom: "none",
    padding: "7px",
  },
});

const CaseOverview = ({ details, isLoading }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { caseId } = params;

  const [isCaseFormDrawerOpen, setIsCaseFormDrawerOpen] = useState(false);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);
  const [ocr, setOcr] = useState({
    isEnabled: null,
    maxImageSize: null,
    timeout: null,
  });

  const [redundancyDetection, setRedundancyDetection] = useState({
    isEnabled: null,
    maxWords: null,
    minWords: null,
  });

  const [topicModeling, setTopicModeling] = useState({
    dynamicallyAdjustTopicCount: null,
    ignoreNumbers: null,
    isEnabled: null,
    topicCount: null,
  });

  useEffect(() => {
    dispatch(getCaseCustodians(caseId));
    dispatch(getCaseOperations(caseId));
    dispatch(getCaseSettings(caseId));
  }, [caseId]);

  const { operationList, settings } = useSelector((state) => state.cases);
  useEffect(() => {
    if (!isObjectEmptyOrNull(settings.details)) {
      setOcr({ ...ocr, ...settings.details.ocr });
      setRedundancyDetection({
        ...redundancyDetection,
        ...settings.details.redundancyDetection,
      });
      setTopicModeling({ ...topicModeling, ...settings.details.topicModeling });
    }
  }, [settings]);

  const toggleDrawer = (event, open) => {
    setIsCaseFormDrawerOpen(open);
  };
  const toggleSettingsDrawer = (event, open) => {
    setIsSettingsDrawerOpen(open);
  };

  const handleCaseForm = useCallback(
    (formData) => {
      dispatch(updateCase({ caseId, formData })).then((res) => {
        if (res.hasOwnProperty("error")) {
          setIsCaseFormDrawerOpen(false);
        } else {
          swal("Success!", `Case updated successfully!`, "success").then(() => {
            dispatch(reset("CASE_FORM"));
            dispatch(fetchCaseById(caseId));
            setIsCaseFormDrawerOpen(false);
          });
        }
      });
    },
    [dispatch]
  );

  const handleCaseReset = () => {
    swal({
      title: "Confirmation",
      text: "Do you really want to reset the settings?",
      icon: "warning",
      buttons: ["Cancel", "Reset"],
      dangerMode: true,
    }).then((willReset) => {
      if (willReset) {
        axios.post(api.case.resetToDefault(caseId)).then((res) => {
          swal("Success!", `Case settings reset successfully!`, "success")
            .then((res) => {
              dispatch(getCaseSettings(caseId));
            })
            .catch(showApiError);
        });
      }
    });
  };

  return isObjectEmptyOrNull(details) ? (
    <Grid>Loading....</Grid>
  ) : (
    <>
      <Grid>
        <Box mb={3}>
          <Typography component={"span"} variant="h5" fontWeight="bold">
            {isLoading ? "Loading..." : details.displayName}
          </Typography>
        </Box>
      </Grid>
      <Grid mb={2}>
        <Card sx={{ border: "1px solid #C8C8C8" }}>
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography component={"span"} variant="h6" fontWeight="bold">
                  Summary
                </Typography>
                <IconButton
                  disabled={isLoading}
                  onClick={(e) => toggleDrawer(e, true)}
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
            }
            sx={{ borderBottom: "1px solid #C8C8C8", paddingY: "7px" }}
          />
          <CardContent>
            {isLoading ? (
              "Loading..."
            ) : (
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" flexDirection="column">
                    <Box fontWeight="bold">Description:</Box>
                    <Box>{details?.description || "---"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Status:
                    </Box>
                    <Box>{details?.status}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      External ID:
                    </Box>
                    <Box>{details?.externalId}</Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Created By:
                    </Box>
                    <Box>{details?.createdBy || "N/A"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Created Date:
                    </Box>
                    <Box>
                      {details?.createdDateTime
                        ? format(
                            new Date(details.createdDateTime),
                            "dd MMM yyyy"
                          )
                        : ""}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Last Modified By:
                    </Box>
                    <Box>{details?.lastModifiedBy.displayName || "N/A"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="row">
                    <Box fontWeight="bold" mr={1}>
                      Last Modified Date:
                    </Box>
                    <Box>
                      {details?.lastModifiedDateTime
                        ? format(
                            new Date(details.lastModifiedDateTime),
                            "dd MMM yyyy"
                          )
                        : ""}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid mb={2}>
        <Card sx={{ border: "1px solid #C8C8C8" }}>
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography component={"span"} variant="h6" fontWeight="bold">
                  Settings
                </Typography>
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleCaseReset()}
                  >
                    Reset
                  </Button>
                  <IconButton onClick={(e) => toggleSettingsDrawer(e, true)}>
                    <ModeEditIcon />
                  </IconButton>
                </Box>
              </Box>
            }
            sx={{ borderBottom: "1px solid #C8C8C8", paddingY: "7px" }}
          />
          <CardContent>
            {settings.isLoading ? (
              "Loading..."
            ) : (
              <Grid container>
                <Grid item xs={12} marginBottom={1}>
                  <Grid item xs={12} marginBottom={1}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ color: "#a9a9a9" }}
                    >
                      The OCR (Optical Character Recognition) settings:
                      <Typography
                        variant="span"
                        fontWeight="bold"
                        marginLeft={1}
                        sx={{ color: "#000" }}
                      >
                        {ocr.isEnabled ? "Enabled" : "Disabled"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                  >
                    <Box display="flex" flexDirection="row">
                      <Box fontWeight="bold" mr={1}>
                        Max Image Size:
                      </Box>
                      <Box>{ocr.maxImageSize || "N/A"}</Box>
                    </Box>
                    <Box display="flex" flexDirection="row" marginLeft={2}>
                      <Box fontWeight="bold" mr={1}>
                        Timeout:
                      </Box>
                      <Box>{ocr.timeout || "N/A"}</Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} marginBottom={1}>
                  <Grid item xs={12} marginBottom={1}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ color: "#a9a9a9" }}
                    >
                      The redundancy (near duplicate and email threading)
                      detection settings:
                      <Typography
                        variant="span"
                        fontWeight="bold"
                        marginLeft={1}
                        sx={{ color: "#000" }}
                      >
                        {redundancyDetection.isEnabled ? "Enabled" : "Disabled"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                  >
                    <Box display="flex" flexDirection="row">
                      <Box fontWeight="bold" mr={1}>
                        Similarity Threshold:
                      </Box>
                      <Box>
                        {redundancyDetection.similarityThreshold || "N/A"}
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" marginLeft={2}>
                      <Box fontWeight="bold" mr={1}>
                        Min Words:
                      </Box>
                      <Box>{redundancyDetection.minWords || "N/A"}</Box>
                    </Box>
                    <Box display="flex" flexDirection="row" marginLeft={2}>
                      <Box fontWeight="bold" mr={1}>
                        Max Words:
                      </Box>
                      <Box>{redundancyDetection.maxWords || "N/A"}</Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={12} marginBottom={1}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ color: "#a9a9a9" }}
                    >
                      The Topic Modeling (Themes) settings:
                      <Typography
                        variant="span"
                        fontWeight="bold"
                        marginLeft={1}
                        sx={{ color: "#000" }}
                      >
                        {topicModeling.isEnabled ? "Enabled" : "Disabled"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                  >
                    <Box display="flex" flexDirection="row">
                      <Box fontWeight="bold" mr={1}>
                        Topic Count:
                      </Box>
                      <Box>{topicModeling.topicCount || "N/A"}</Box>
                    </Box>

                    <Box display="flex" flexDirection="row" marginLeft={2}>
                      <Box fontWeight="bold" mr={1}>
                        Ignore Numbers:
                      </Box>
                      <Box>{topicModeling.ignoreNumbers ? "Yes" : "No"}</Box>
                    </Box>

                    <Box display="flex" flexDirection="row" marginLeft={2}>
                      <Box fontWeight="bold" mr={1}>
                        Dynamically Adjust Topic Count:
                      </Box>
                      <Box>
                        {topicModeling.dynamicallyAdjustTopicCount
                          ? "Yes"
                          : "No"}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid>
        <Card sx={{ border: "1px solid #C8C8C8" }}>
          <CardHeader
            title={
              <Typography component={"span"} variant="h6" fontWeight="bold">
                <Button
                  onClick={() => dispatch(getCaseOperations(caseId))}
                  style={{ padding: 0, minWidth: 0, marginRight: "5px" }}
                >
                  <RefreshIcon />
                </Button>
                Recent Jobs
              </Typography>
            }
            sx={{ borderBottom: "1px solid #C8C8C8", paddingY: "7px" }}
          />
          <CardContent>
            <TableContainer className={classes.root} sx={{ maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell align="left" className={classes.tableHeaderCell}>
                      Type
                    </TableCell>
                    <TableCell align="left" className={classes.tableHeaderCell}>
                      Status
                    </TableCell>
                    <TableCell align="left" className={classes.tableHeaderCell}>
                      Created
                    </TableCell>
                    <TableCell align="left" className={classes.tableHeaderCell}>
                      Completed
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {operationList.isLoading
                    ? "Loading..."
                    : operationList.data.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" className={classes.tableCell}>
                            {item.action}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {item.status}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {format(
                              new Date(item.createdDateTime),
                              "dd MMM yyyy HH:mm:ss"
                            )}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {item.percentProgress != 100
                              ? ""
                              : format(
                                  new Date(item.completedDateTime),
                                  "dd MMM yyyy HH:mm:ss"
                                )}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
      <CaseForm
        caseDetails={details}
        toggleDrawer={toggleDrawer}
        handleCaseForm={handleCaseForm}
        isCaseFormDrawerOpen={isCaseFormDrawerOpen}
        isEdit={true}
      />
      <SettingsForm
        settingsDetails={settings}
        toggleSettingsDrawer={toggleSettingsDrawer}
        isSettingsDrawerOpen={isSettingsDrawerOpen}
      />
    </>
  );
};

export default CaseOverview;
