import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  TextField,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviewSetsByCaseId } from "../../../../redux/actions/reviewSets/reviewSetsAction";
import { fetchCollectionsByCaseId } from "../../../../redux/actions/collections/collectionsActions";
import CustomDrawer from "../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const EditReviewSet = ({
  isDrawerOpen,
  toggleDrawer,
  reviewSetid,
  displayName,
}) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");
  const [reviewSetName, setReviewSetName] = useState(displayName);
  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = useState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    dispatch(fetchCollectionsByCaseId(params.caseId));
  }, []);

  const { collectionsList } = useSelector((state) => state.sourceCollections);

  const handleReviewSetNameChange = (e) => {
    setReviewSetName(e.target.value);
  };
  const handleCollectionChange = (e, value) => {
    setCollection(value);
  };
  const handleCollectionCheck = () => {
    setChecked(!checked);
  };
  const onEdit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    const formData = {};
    setIsLoading(true);
    formData["displayName"] = reviewSetName;
    formData["collectionId"] = collection.id;
    axiosInstance
      .patch(api.reviewSets.update(params.caseId, reviewSetid), formData)
      .then((res) => {
        dispatch(fetchReviewSetsByCaseId(params.caseId));
        setIsLoading(false);
        swal("Success!", "Updated successfully", "success").then(() => {
          toggleDrawer(e, false);
        });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <CustomDrawer
      className={"edit-reviewset-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={isLoading}
      title={"Edit reviewset"}
      wrapWithForm={true}
      onFormSubmit={onEdit}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            size="small"
            required
            id="review-set-name"
            label="Reviewset name"
            variant="outlined"
            value={reviewSetName}
            onChange={handleReviewSetNameChange}
            style={{ width: "100%" }}
          />
          <Box marginTop={3}>
            <FormControlLabel
              label="Add items from collection"
              control={
                <Checkbox checked={checked} onChange={handleCollectionCheck} />
              }
            />
          </Box>
          <Box>
            <Autocomplete
              onChange={handleCollectionChange}
              size="small"
              disabled={!checked}
              options={collectionsList.data.map((item) => {
                return { label: item.displayName, id: item.id };
              })}
              getOptionLabel={(option) => option.label}
              value={collection}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Select a collection" />
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={isLoading} variant="contained" type="submit">
          Submit
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default EditReviewSet;
