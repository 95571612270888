import { format } from "date-fns";
import { CUSTOM } from "../../../constants/table";
import OptionMenuButtons from "../../common/OptionMenuButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from '@mui/icons-material/Block';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

export default function (onClickDeleteEvent, onClickStatusEvent) {
  const caseTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
      tableCellType: "LINK_CELL_TYPE",
      align: "left",
      meta: {
        link: function (caseId) {
          return `/cases/${caseId}`;
        },
      },
    },
    {
      id: "externalId",
      label: "External ID",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "createdDateTime",
      tableCellType: "CUSTOM",
      fn: (cell) => format(new Date(cell["createdDateTime"]), "dd MMM yyyy"),
      label: "Created",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "center",
      sortable: false,
      style: { paddingLeft: 0, paddingRight: 0 },
      fn: (cell) => (
        <OptionMenuButtons
          text={"Options"}
          options={[
            {
              name: "CloseOrReopen",
              text:
                cell.status?.toLowerCase() === "active" ? "Close case" : "Reopen case",
              icon: cell.status?.toLowerCase() === "active" ? (
                <BlockIcon fontSize="small" />
              ) : (
                <WorkOutlineIcon fontSize="small" />
              ),
              onClick: (event, index) => {
                onClickStatusEvent(
                  event,
                  cell["id"],
                  cell.status?.toLowerCase() === "active" ? "close" : "reopen"
                );
              },
            },
            {
              name: "Delete",
              text: "Delete case",
              icon: <DeleteIcon fontSize="small" />,
              onClick: (event, index) => {
                onClickDeleteEvent(event, cell["id"]);
              },
            },
          ]}
        />
      ),
    },
  ];
  return caseTableHeadCells;
}
