import { fileIcon } from "./utils";
import { PreviewContentButton } from "./utils";
import { format } from "date-fns";

export default function () {
  const reviewSetsContentsTableHeadCells = [
    {
      id: "preview",
      tableCellType: "CUSTOM",
      label: "View",
      align: "center",
      sortable: false,
      style: {
        width: "30px",
        maxWidth: "81px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      fn: (cell) => {
        return <PreviewContentButton row={cell} />;
      },
    },
    {
      id: "name",
      tableCellType: "CUSTOM",
      label: "Title",
      fn: (cell) => {
        return (
          <div
            title={cell["subjectTitle"] || cell["name"]}
            style={{
              whiteSpace: "nowrap",
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span style={{ marginRight: "10px" }}>
              {cell["extension"] in fileIcon
                ? fileIcon[cell["extension"]]
                : fileIcon["unknown"]}
            </span>
            {cell["subjectTitle"] || cell["name"]}
          </div>
        );
      },
    },

    {
      id: "dateTime",
      tableCellType: "CUSTOM",
      fn: (cell) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {cell["dateTime"] && cell["dateTime"] !== "0001-01-01T00:00:00+00:00"
            ? format(new Date(cell["dateTime"]), "dd MMM yyy HH:mm:ss a")
            : ""}
        </span>
      ),
      label: "Created",
    },
    {
      id: "type",
      label: "Source",
    },
    {
      id: "size",
      label: "Size",
    },
    {
      id: "processingStatus",
      label: "Status",
    },
    {
      id: "id",
      label: "ID",
      tableCellType: "CUSTOM",
      fn: (cell) => (
        <div
          title={cell["id"]}
          style={{
            whiteSpace: "nowrap",
            maxWidth: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {cell["id"]}
        </div>
      ),
    },
  ];
  return reviewSetsContentsTableHeadCells;
}
