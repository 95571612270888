export default function () {
  const tenantSitesTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
    },
    {
      id: "webUrl",
      label: "Url",
    },
  ];
  return tenantSitesTableHeadCells;
}
