import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { fetchSubjectRightsById } from "../../../redux/actions/subjectRightsActions";
import SROverview from "./SROverview";
import SRFinalReport from "./SRFinalReport";
import SRFinalAttachment from "./SRFinalAttachment";
import SRNotes from "./SRNotes";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sr-tabpanel-${index}`}
      aria-labelledby={`sr-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: "15px 0 0 0"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `sr-tab-${index}`,
    "aria-controls": `sr-tabpanel-${index}`,
  };
}

export default function SubjectRightsDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const srId = params.srId;
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab")
    ? parseInt(searchParams.get("tab"))
    : 0;
  const [value, setValue] = useState(selectedTab);
  const currentsubjectRight = useSelector((state) => state.subjectRights.currentSubjectRight);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "sr-detail-breadcumbs",
      links: [
        { url: "/dashboard", text: "Dashboard" },
        { url: "/subjectRights", text: "Subject Rights" },
        {
          text: currentsubjectRight?.details?.displayName,
          url: selectedTab !== 0 ? `/subjectRights/${params.srId}?tab=0` : null,
        },
      ],
    };
    if (selectedTab === 0) {
      handleChange(null, 0);
    }
    if (selectedTab === 1) {
      breadcrumbs.links.push({ text: "Notes" });
    } else if (selectedTab === 2) {
      breadcrumbs.links.push({ text: "Final Report" });
    } else if (selectedTab === 3) {
      breadcrumbs.links.push({ text: "Final Attachment" });
    }
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [currentsubjectRight, selectedTab]);

  useEffect(() => {
    dispatch(fetchSubjectRightsById(srId));
  }, [srId]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="sr tabs"
          className="sr-tabs"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tab label="Overview" {...a11yProps(0)} value={0} />
          <Tab label="Notes" {...a11yProps(1)} value={1} />
          <Tab label="Final Report" {...a11yProps(2)} value={2} />
          <Tab label="Final Attachment" {...a11yProps(3)} value={3} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <SROverview
              details={currentsubjectRight.details}
              isLoading={currentsubjectRight.isLoading}
            />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SRNotes />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SRFinalReport />
        </TabPanel>
         <TabPanel value={value} index={3}>
          <SRFinalAttachment />
        </TabPanel>
      </Box>
    </>
  );
}
