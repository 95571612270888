import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { fetchCaseById } from "../../../redux/actions/caseActions";
import Collections from "./collections/Collections";
import ReviewSets from "./reviewSets/ReviewSets";
import CaseOverview from "./Overview";
import Export from "./exports/export";
import Holds from "./holds/holds";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import CaseRunningJobs from "../../common/CaseRunningJobs";
import Custodians from "./custodians/Custodians";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`case-tabpanel-${index}`}
      aria-labelledby={`case-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: "15px 0 0 0"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `case-tab-${index}`,
    "aria-controls": `case-tabpanel-${index}`,
  };
}

export default function CaseDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const caseId = params.caseId;
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab")
    ? parseInt(searchParams.get("tab"))
    : 0;
  const [value, setValue] = useState(selectedTab);
  const currentCase = useSelector((state) => state.cases.currentCase);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "cases-detail-breadcumbs",
      links: [
        { url: "/dashboard", text: "Dashboard" },
        { url: "/cases", text: "Cases" },
        {
          text: currentCase?.details?.displayName,
          url: selectedTab !== 0 ? `/cases/${params.caseId}?tab=0` : null,
        },
      ],
    };
    if (selectedTab === 0) {
      handleChange(null, 0);
    }
    if (selectedTab === 1) {
      breadcrumbs.links.push({ text: "Collections" });
    } else if (selectedTab === 2) {
      breadcrumbs.links.push({ text: "Reviewsets" });
    } else if (selectedTab === 3) {
      breadcrumbs.links.push({ text: "Exports" });
    } else if (selectedTab === 4) {
      breadcrumbs.links.push({ text: "Legal Holds" });
    }
    else if (selectedTab === 5) {
      breadcrumbs.links.push({ text: "Custodians" });
    }
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [currentCase, selectedTab]);

  useEffect(() => {
    dispatch(fetchCaseById(caseId));
  }, [caseId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <>
      {caseId ? (
        <Box sx={{ width: "100%" }}>
          <CaseRunningJobs caseId={caseId} />
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="case tabs"
          className="case-tabs"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tab label="Overview" {...a11yProps(0)} value={0} />
          <Tab label="Custodians" {...a11yProps(5)} value={5} />
          <Tab label="Collections" {...a11yProps(1)} value={1} />
          <Tab label="Reviewsets" {...a11yProps(2)} value={2} />
          <Tab label="Exports" {...a11yProps(3)} value={3} />
          <Tab label="Legal holds" {...a11yProps(4)} value={4} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <CaseOverview
            details={currentCase.details}
            isLoading={currentCase.isLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Custodians caseId={caseId} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Collections caseId={caseId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ReviewSets caseId={caseId} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Export caseId={caseId} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Holds />
        </TabPanel>
      </Box>
    </>
  );
}
