import { config } from "../config";

const homeAccountId = null;

export const signInClickHandler = (instance) => {
  instance.loginPopup({ redirectUri: config.redirectUrl, prompt: "select_account" });
};

export const signOutClickHandler = (instance) => {
  // const logoutRequest = {
  //   account: instance.getAccountByHomeId(homeAccountId),
  //   mainWindowRedirectUri: config.redirectUrl,
  //   postLogoutRedirectUri: config.redirectUrl,
  // };
   // instance.logoutPopup(logoutRequest);
  
   localStorage.clear();
   window.location = config.redirectUrl;
};
