import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import swal from "sweetalert";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HoldSummaryForm from "./form/HoldSummaryForm";
import CustomTable from "../../../../composite/table/CustomTable";
import {
  fetchHoldDetails,
  fetchUserSourcesByHold,
  fetchSiteSourcesByHold,
  deleteUserSource,
  deleteSiteSource,
} from "../../../../../redux/actions/holdActions";
import userSourcesTableHeadCells from "./userSourcesTableHeadCells";
import siteSourcesTableHeadCells from "./siteSourcesTableHeadCells";
import UserSourceSelection from "./form/UserSourceSelection";
import SiteSourceSelection from "./form/SiteSourceSelection";
import {
  fetchTenantSites,
  fetchTenantUsers,
} from "../../../../../redux/actions/tenantActions";
import { fetchCaseById } from "../../../../../redux/actions/caseActions";
import { handleBreadcrumb } from "../../../../../redux/actions/utilsActions";

const HoldDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { caseId, holdId } = params;

  const [summaryExpanded, setSummaryExpanded] = useState(true);
  const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
  const [isUserSourceDrawerOpen, setIsUserSourceDrawerOpen] = useState(false);
  const [isSiteSourceDrawerOpen, setIsSiteSourceDrawerOpen] = useState(false);
  const [dataSourcesExpanded, setDataSourcesExpanded] = useState(true);
  const [chips, setChips] = useState([]);

  useEffect(() => {
    dispatch(fetchHoldDetails({ caseId, holdId }));
    dispatch(fetchUserSourcesByHold({ caseId, holdId }));
    dispatch(fetchSiteSourcesByHold({ caseId, holdId }));
    dispatch(fetchTenantSites());
    dispatch(fetchTenantUsers());
    dispatch(fetchCaseById(caseId));
  }, []);

  const { currentHold, userSources, siteSources } = useSelector(
    (state) => state.holds
  );
  const { tenantUsersList, tenantSitesList } = useSelector(
    (state) => state.tenant
  );
  const caseDetails = useSelector((state) => state.cases.currentCase.details);
  const { isLoading, details } = currentHold;

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "collection-detail-breadcumbs",
      links: [
        { url: "/cases", text: "Cases" },
        {
          url: `/cases/${params.caseId}?tab=0`,
          text: caseDetails?.displayName,
        },
        { url: `/cases/${params.caseId}?tab=4`, text: "Legal Holds" },
        { text: details?.displayName },
      ],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [details]);

  const handleSummaryAccordion = () => {
    setSummaryExpanded(!summaryExpanded);
  };

  const handleDataSourcesAccordion = () => {
    setDataSourcesExpanded(!dataSourcesExpanded);
  };

  const toggleSummaryDrawer = (event, open) => {
    setSummaryDrawerOpen(open);
  };
  const toggleUserSourceDrawer = (event, open) => {
    setIsUserSourceDrawerOpen(open);
  };
  const toggleSiteSourceDrawer = (event, open) => {
    setIsSiteSourceDrawerOpen(open);
  };

  const handleChangeChip = (newChips) => {
    setChips(newChips);
  };

  const handleDeleteUserSource = (e, userSourceId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this Source?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        dispatch(deleteUserSource({ caseId, holdId, userSourceId })).then(
          (res) => {
            if (res.hasOwnProperty("error")) {
              dispatch(fetchUserSourcesByHold({ caseId, holdId }));
            } else {
              swal("Success!", `Source has been deleted!`, "success").then(
                () => {
                  dispatch(fetchUserSourcesByHold({ caseId, holdId }));
                }
              );
            }
          }
        );
      }
    });
  };

  const handleDeleteSiteSource = (e, siteSourceId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this Source?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        dispatch(deleteSiteSource({ caseId, holdId, siteSourceId })).then(
          (res) => {
            if (res.hasOwnProperty("error")) {
              dispatch(fetchSiteSourcesByHold({ caseId, holdId }));
            } else {
              swal("Success!", `Source has been deleted!`, "success").then(
                () => {
                  dispatch(fetchSiteSourcesByHold({ caseId, holdId }));
                }
              );
            }
          }
        );
      }
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Accordion expanded={summaryExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon onClick={handleSummaryAccordion} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box>
                  <Typography variant="h5" fontWeight="bold">
                    Summary
                  </Typography>
                </Box>
                <Box>
                  <Button onClick={(e) => toggleSummaryDrawer(e, true)}>
                    <ModeEditIcon />
                  </Button>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  padding={4}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box width="100%">
                  <Typography fontWeight="bold"> Name: </Typography>
                  <Typography> {details.displayName} </Typography>
                  <Typography fontWeight="bold"> Description: </Typography>
                  <Typography> {details.description} </Typography>

                  <Typography fontWeight="bold"> Condition: </Typography>
                  <Typography> {details.contentQuery} </Typography>

                  <Typography fontWeight="bold">Status:</Typography>
                  <Typography> {details.status} </Typography>
                  {details.errors && details.errors.length > 0 ? (
                    <>
                      <Typography fontWeight="bold">Errors:</Typography>
                      <ul>
                        {details.errors.map((error) => (
                          <li>{`${JSON.parse(error)?.Endpoint} - ${
                            JSON.parse(error)?.ResultMessage
                          }`}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={dataSourcesExpanded}
            onChange={handleDataSourcesAccordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h5" fontWeight="bold">
                User Sources
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button onClick={(e) => toggleUserSourceDrawer(e, true)}>
                + Add Source
              </Button>
              <CustomTable
                rows={userSources.data}
                rowsCount={10}
                headCells={userSourcesTableHeadCells()}
                isLoading={userSources.isLoading}
                shadow={false}
                onClickActionEvent={handleDeleteUserSource}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={dataSourcesExpanded}
            onChange={handleDataSourcesAccordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h5" fontWeight="bold">
                Site Sources
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button onClick={(e) => toggleSiteSourceDrawer(e, true)}>
                + Add Source
              </Button>
              <CustomTable
                rows={siteSources.data}
                rowsCount={10}
                headCells={siteSourcesTableHeadCells()}
                isLoading={siteSources.isLoading}
                shadow={false}
                onClickActionEvent={handleDeleteSiteSource}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <HoldSummaryForm
        toggleSummaryDrawer={toggleSummaryDrawer}
        summaryDrawerOpen={summaryDrawerOpen}
        handleChangeChip={handleChangeChip}
        collectionDetails={details}
        chips={chips}
      />
      <UserSourceSelection
        toggleUserSourceDrawer={toggleUserSourceDrawer}
        isUserSourceDrawerOpen={isUserSourceDrawerOpen}
        tenantUsersList={tenantUsersList}
        userSources={userSources}
      />
      <SiteSourceSelection
        toggleSiteSourceDrawer={toggleSiteSourceDrawer}
        isSiteSourceDrawerOpen={isSiteSourceDrawerOpen}
        tenantSitesList={tenantSitesList}
        siteSources={siteSources}
      />
    </>
  );
};

export default HoldDetails;
