import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import parse from "html-react-parser";
import { format } from "date-fns";
import CustomDrawer from "../../composite/CustomDrawer";
import CustomDrawerFooter from "../../composite/table/CustomDrawerFooter";

const AuditDetails = ({ toggleDrawer, isDrawerOpen, data }) => {
  const logLine = {
    margin: "5px 0",
  };
  if (!data) return <></>;

  return (
    <CustomDrawer
      className={"audit-detail-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={false}
      title={"Log details"}
      wrapWithForm={false}
    >
      <Grid container>
        <Grid container sx={logLine}>
          <Grid item xs={2}>
            <Typography variant="body1" fontWeight={"bolder"}>
              Type
            </Typography>
          </Grid>
          <Grid item xs={10}>
            : {data.logType}
          </Grid>
        </Grid>

        <Grid container sx={logLine}>
          <Grid item xs={2}>
            <Typography variant="body1" fontWeight={"bolder"}>
              Event
            </Typography>
          </Grid>
          <Grid item xs={10}>
            : {data.logEvent}
          </Grid>
        </Grid>
        <Grid container sx={logLine}>
          <Grid item xs={2}>
            <Typography variant="body1" fontWeight={"bolder"}>
              Time
            </Typography>
          </Grid>
          <Grid item xs={10}>
            : {format(new Date(data.logTime), "dd MMM yyyy hh:mm:ss a")}
          </Grid>
        </Grid>
        <Grid container sx={logLine}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={"bolder"}>
              Message
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <p>{parse(data.logMessage)}</p>
          </Grid>
        </Grid>
        <Grid container sx={logLine}>
          <Grid item xs={2}>
            <Typography variant="body1" fontWeight={"bolder"}>
              Tenant Id
            </Typography>
          </Grid>
          <Grid item xs={10}>
            : {data.tenantId}
          </Grid>
        </Grid>

        <Grid container sx={logLine}>
          <Grid item xs={2}>
            <Typography variant="body1" fontWeight={"bolder"}>
              User Id
            </Typography>
          </Grid>
          <Grid item xs={10}>
            : {data.userId}
          </Grid>
        </Grid>
        <Grid container sx={logLine}>
          <Grid item xs={2}>
            <Typography variant="body1" fontWeight={"bolder"}>
              IP Address
            </Typography>
          </Grid>
          <Grid item xs={10}>
            : {data.ipAddress}
          </Grid>
        </Grid>
        <Grid container sx={logLine}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={"bolder"}>
              User Agent
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <p>{data.userAgent}</p>
          </Grid>
        </Grid>
        {data.logData ? (
          <Grid container sx={logLine}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={"bolder"}>
                Data
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <p>
                <pre style={{ marginBottom: 0 }}>
                  {JSON.stringify(data.logData, null, 4)}
                </pre>
              </p>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <CustomDrawerFooter>
        <Button variant="contained" onClick={(e) => toggleDrawer(e, false)}>
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default AuditDetails;
