import axios from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sortDataByCreatedDateTime } from "../../utils/sorting";
import api from "../../utils/api";
import {
  FETCH_CASES,
  CREATE_CASES,
  UPDATE_CASES,
  DELETE_CASES,
  FETCH_CASE_BY_ID,
  GET_CASE_OPERATIONS,
  GET_CASE_SETTINGS,
  GET_CASE_RUNNING_OPERATIONS,
  CLOSE_OPEN_CASES,
} from "../actionTypes/caseTypes";

//fetch case
export const fetchCases = createAsyncThunk(FETCH_CASES, async () => {
  const response = await axios.get(api.case.get());
  return sortDataByCreatedDateTime(response.data);
});

//create case
export const createCase = createAsyncThunk(CREATE_CASES, async (data) => {
  const response = await axios.post(api.case.create(), data);
  return response.data;
});

//create case
export const updateCase = createAsyncThunk(UPDATE_CASES, async (data) => {
  const { caseId, formData } = data;
  const response = await axios.patch(api.case.update(caseId), formData);
  return response.data;
});

//delete case
export const deleteCase = createAsyncThunk(DELETE_CASES, async (caseId) => {
  const response = await axios.delete(api.case.delete(caseId));
  return response;
});

export const closeOrReopenCase = createAsyncThunk(CLOSE_OPEN_CASES, async (data) => {
  const {caseId, action} = data;
  const response = await axios.post(api.case.closeReopen(caseId, action));
  return response;
});

//fetch case by id
export const fetchCaseById = createAsyncThunk(
  FETCH_CASE_BY_ID,
  async (caseId) => {
    const response = await axios.get(api.case.getById(caseId));
    return response.data;
  }
);

//get case custodians
export const getCaseCustodians = createAsyncThunk(
  "cases/getCaseCustodians",
  async (caseId) => {
    const response = await axios.get(api.case.getCustodians(caseId));
    return response.data;
  }
);

//get case operations
export const getCaseOperations = createAsyncThunk(
  GET_CASE_OPERATIONS,
  async (caseId) => {
    const response = await axios.get(api.case.getOperations(caseId));
    return response.data;
  }
);

//get running case operations
export const getCaseRunningOperations = createAsyncThunk(
  GET_CASE_RUNNING_OPERATIONS,
  async (caseId) => {
    const response = await axios.get(api.case.getRunningOperations(caseId));
    return response.data;
  }
);

//get case settings
export const getCaseSettings = createAsyncThunk(
  GET_CASE_SETTINGS,
  async (caseId) => {
    const response = await axios.get(api.case.getSettings(caseId));
    return response.data;
  }
);
