import { IconButton } from "@mui/material";
import { format } from "date-fns";
import { CUSTOM } from "../../../constants/table";
import SearchIcon from "@mui/icons-material/Search";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand, faEye } from "@fortawesome/free-solid-svg-icons";
import SearchAccessGuard from "../../common/SearchAccessGuard";

export default function (onClickSearchEvent, onClickUnholdEvent) {
  const holdCollectionsTableHeadCells = [
    {
      id: "id",
      label: "ID",
      tableCellType: CUSTOM,
      fn: (cell) => (
        <div className="guid-cell" title={cell["id"]}>
          {cell["id"]}
        </div>
      ),
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "createdBy",
      label: "Created By",
    },
    {
      id: "createdTime",
      tableCellType: CUSTOM,
      fn: (cell) => format(new Date(cell["createdTime"]), "dd MMM yyyy"),
      label: "Created",
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "left",
      fn: (cell) => (
        <>
          <SearchAccessGuard
            members={[...cell.searchMembers, cell.createdByUserId]}
          >
            <IconButton
              size="small"
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickSearchEvent(e, cell["id"])}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          </SearchAccessGuard>
          <AuthorizeGuard module={"HOLDS"} action={"Delete"}>
            <IconButton
              size="small"
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickUnholdEvent(e, cell["id"])}
            >
              <div id="cross"></div>
              <FontAwesomeIcon icon={faHand} />
            </IconButton>
          </AuthorizeGuard>
        </>
      ),
    },
  ];
  return holdCollectionsTableHeadCells;
}
