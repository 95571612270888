import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomTable from "../../composite/table/CustomTable";
import connectionsTableHeadCells from "./connectionsTableHeadCells";
import swal from "sweetalert";
import api from "../../../utils/api";
import axios from "../../../utils/axios";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { fetchConnections } from "../../../redux/actions/connectionActions";
import { useNavigate } from "react-router-dom";
import ConnectionForm from "./ConnectionForm";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import { useLocation } from "react-router-dom";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const Connections = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchConnections());
  }, []);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "connections-breadcumbs",
      links: [
        { url: "/dashboard", text: "Dashboard" },
        { text: "Connections" },
      ],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, []);

  const toggleDrawer = (event, open) => {
    setIsDrawerOpen(open);
  };

  const { connections } = useSelector((state) => state.connections);

  const handleDelete = (e, id) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this connection?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        axios.delete(api.connections.delete(id)).then((res) => {
          if (!res?.success && res?.errorMessage) {
            swal("Error!", res.errorMessage, "error");
          } else {
            swal("Success!", "Deleted Successfully", "success");
            dispatch(fetchConnections());
          }
        });
      }
    });
  };

  const handleEdit = (e, id) => {
    navigate(`/edit-file-connection?id=${id}`);
  };

  return (
    <AuthorizeGuard
      module={"COLLECTIONS"}
      action={"View"}
      showDeniedPage={true}
    >
      <CustomTable
        showSerialNo={true}
        rows={connections.data?.length ? connections.data : []}
        headCells={connectionsTableHeadCells(handleEdit, handleDelete)}
        isLoading={connections.isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"CONNECTIONS"}
          onRefreshEvent={() => dispatch(fetchConnections())}
          addButton={
            <AuthorizeGuard module={"CONNECTIONS"} action={"Add"}>
              <Button
                onClick={(e) => {
                  toggleDrawer(e, true);
                }}
              >
                <AddIcon />
                Add new connection
              </Button>
            </AuthorizeGuard>
          }
        />
      </CustomTable>
      <ConnectionForm toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
    </AuthorizeGuard>
  );
};

export default Connections;
