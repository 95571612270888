import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import swal from "sweetalert";
import { getCaseSettings } from "../../../../redux/actions/caseActions";
import { isObjectEmptyOrNull } from "../../../../utils/validation";
import axios from "../../../../utils/axios";
import api from "../../../../utils/api";
import CustomDrawer from "../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const SettingsForm = ({
  toggleSettingsDrawer,
  isSettingsDrawerOpen,
  settingsDetails,
}) => {
  const params = useParams();
  const { caseId } = params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isSettingsDrawerOpen);
  const [ocr, setOcr] = useState({
    isEnabled: null,
    maxImageSize: null,
    timeout: null,
  });
  const [redundancyDetection, setRedundancyDetection] = useState({
    isEnabled: null,
    maxWords: null,
    minWords: null,
  });
  const [topicModeling, setTopicModeling] = useState({
    dynamicallyAdjustTopicCount: null,
    ignoreNumbers: null,
    isEnabled: null,
    topicCount: null,
  });

  useEffect(() => {
    setOpen(isSettingsDrawerOpen);
  }, [isSettingsDrawerOpen]);

  useEffect(() => {
    if (!isObjectEmptyOrNull(settingsDetails)) {
      setOcr({ ...ocr, ...settingsDetails.details.ocr });
      setRedundancyDetection({
        ...redundancyDetection,
        ...settingsDetails.details.redundancyDetection,
      });
      setTopicModeling({
        ...topicModeling,
        ...settingsDetails.details.topicModeling,
      });
    }
  }, [settingsDetails]);

  const handleSettings = (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = {};
    formData.ocr = ocr;
    formData.redundancyDetection = redundancyDetection;
    formData.topicModeling = topicModeling;
    axios
      .patch(api.case.updateSettings(caseId), formData)
      .then((res) => {
        swal("Success!", `Settings updated successfully!`, "success").then(
          () => {
            dispatch(getCaseSettings(caseId));
            setLoading(false);
            toggleSettingsDrawer(e, false);
          }
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <CustomDrawer
      className={"case-settings-drawer"}
      isDrawerOpen={isSettingsDrawerOpen}
      toggleDrawer={toggleSettingsDrawer}
      loading={loading}
      title={"Case settings"}
      wrapWithForm={true}
      onFormSubmit={handleSettings}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} marginBottom={1}>
          <Grid item xs={12} marginBottom={1}>
            <Typography variant="body1" fontWeight="bold">
              The Optical Character Recognition (OCR) settings:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <FormControlLabel
                style={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    style={{ padding: "0px 0px 0px 9px" }}
                    checked={ocr.isEnabled}
                    onChange={(e) =>
                      setOcr({ ...ocr, isEnabled: e.target.checked })
                    }
                  />
                }
                label="Enabled"
              />
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                Indicates whether or not OCR is enabled for the case.
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ width: "105px", display: "inline-block" }}>
                  Max image size
                </span>
                <TextField
                  style={{ marginLeft: "10px", marginTop: "-5px" }}
                  variant="outlined"
                  size="small"
                  value={ocr.maxImageSize}
                  onChange={(e) =>
                    setOcr({ ...ocr, maxImageSize: e.target.value })
                  }
                />
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                Maximum image size that will be processed in KB.
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ width: "105px", display: "inline-block" }}>
                  Timeout
                </span>
                <TextField
                  style={{ marginLeft: "10px", marginTop: "-5px" }}
                  variant="outlined"
                  size="small"
                  value={ocr.timeout}
                  onChange={(e) => setOcr({ ...ocr, timeout: e.target.value })}
                />
              </div>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                The timeout duration for the OCR engine. A longer timeout might
                increase success of OCR, but might add to the total processing
                time.
              </div>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} marginBottom={1}>
          <Grid item xs={12} marginBottom={1}>
            <Typography variant="body1" fontWeight="bold">
              The redundancy (near duplicate and email threading) detection
              settings:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <FormControlLabel
                style={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    style={{ padding: "0px 0px 0px 9px" }}
                    checked={redundancyDetection.isEnabled}
                    onChange={(e) =>
                      setRedundancyDetection({
                        ...redundancyDetection,
                        maxWords: e.target.checked,
                      })
                    }
                  />
                }
                label="Enabled"
              />
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                Indicates whether email threading and near duplicate detection
                are enabled.
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ width: "150px", display: "inline-block" }}>
                  Similarity threshold
                </span>
                <TextField
                  style={{ marginLeft: "10px", marginTop: "-5px" }}
                  variant="outlined"
                  size="small"
                  value={redundancyDetection.similarityThreshold}
                  onChange={(e) =>
                    setRedundancyDetection({
                      ...redundancyDetection,
                      similarityThreshold: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                Specifies the similarity level for documents to be put in the
                same near duplicate set.
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ width: "150px", display: "inline-block" }}>
                  Minimum words
                </span>
                <TextField
                  style={{ marginLeft: "10px", marginTop: "-5px" }}
                  variant="outlined"
                  size="small"
                  value={redundancyDetection.minWords}
                  onChange={(e) =>
                    setRedundancyDetection({
                      ...redundancyDetection,
                      minWords: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                Specifies the minimum number of words used for email threading
                and near duplicate detection
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ width: "150px", display: "inline-block" }}>
                  Maximum words
                </span>
                <TextField
                  style={{ marginLeft: "10px", marginTop: "-5px" }}
                  variant="outlined"
                  size="small"
                  value={redundancyDetection.maxWords}
                  onChange={(e) =>
                    setRedundancyDetection({
                      ...redundancyDetection,
                      maxWords: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                Specifies the maximum number of words used for email threading
                and near duplicate detection.
              </div>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} marginBottom={1}>
            <Typography variant="body1" fontWeight="bold">
              The Topic Modeling (Themes) settings:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <FormControlLabel
                style={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    style={{ padding: "0px 0px 0px 9px" }}
                    checked={topicModeling.isEnabled}
                    onChange={(e) =>
                      setTopicModeling({
                        ...topicModeling,
                        isEnabled: e.target.checked,
                      })
                    }
                  />
                }
                label="Enabled"
              />
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                Indicates whether themes model is enabled for the case.
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ width: "105px", display: "inline-block" }}>
                  Topic count
                </span>
                <TextField
                  style={{ marginLeft: "10px", marginTop: "-5px" }}
                  variant="outlined"
                  size="small"
                  value={topicModeling.topicCount}
                  onChange={(e) =>
                    setTopicModeling({
                      ...topicModeling,
                      topicCount: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                The total number of topics that the themes model will generate
                for a reviewset.
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <FormControlLabel
                style={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    style={{ padding: "0px 0px 0px 9px" }}
                    checked={topicModeling.ignoreNumbers}
                    onChange={(e) =>
                      setTopicModeling({
                        ...topicModeling,
                        ignoreNumbers: e.target.checked,
                      })
                    }
                  />
                }
                label="Ignore numbers"
              />
              <div
                style={{
                  marginBottom: "1rem",
                  fontStyle: "italic",
                  fontSize: "0.8rem",
                }}
              >
                Indicates whether the themes model should exclude numbers while
                parsing document texts.
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="start"
          >
            <Box marginLeft={1}>
              <FormControlLabel
                style={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    style={{ padding: "0px 0px 0px 9px" }}
                    checked={topicModeling.dynamicallyAdjustTopicCount}
                    onChange={(e) =>
                      setTopicModeling({
                        ...topicModeling,
                        dynamicallyAdjustTopicCount: e.target.checked,
                      })
                    }
                  />
                }
                label="Dynamically adjust topic count"
              />
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                Indicates whether the themes model should dynamically optimize
                the number of generated topics.
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={loading} type="submit" variant="contained">
          Submit
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleSettingsDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default SettingsForm;
