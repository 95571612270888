import { Alert, Button, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ConditionForm from "./form/conditionForm";
import { createQuerySet } from "../../../../../redux/actions/reviewSets/querySetAction";
import swal from "sweetalert";
import CustomDrawer from "../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../composite/table/CustomDrawerFooter";

const AddConditionDrawer = ({ addQueryDrawerOpen, toggleQueryDrawer }) => {
  const conditionDataRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    try {
      setErrorMessage("");
      setIsLoading(true);
      const conditionData = await conditionDataRef.current.getData();
      const formData = {
        ...conditionData.jsonObject,
        contentQuery: conditionData.queryString.kqlString,
        displayName: name,
      };

      dispatch(
        createQuerySet({
          data: formData,
          caseId: params.caseId,
          reviewSetId: params.reviewSetId,
        })
      ).then((res) => {
        setIsLoading(false);
        if (res.hasOwnProperty("error")) {
          setErrorMessage(res.error.message);
        } else {
          swal("Success!", `Query has been created!`, "success").then(() => {
            toggleQueryDrawer(e, false);
          });
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomDrawer
      className={"add-query-drawer"}
      isDrawerOpen={addQueryDrawerOpen}
      toggleDrawer={toggleQueryDrawer}
      loading={isLoading}
      title={"Add reviewset query"}
      wrapWithForm={false}
    >
      <Grid container spacing={3}>
        {errorMessage ? (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <label htmlFor="display-name">Name:</label>
          <input
            id="display-name"
            name="displayName"
            style={{
              height: "36px",
              padding: "10px",
              width: "100%",
            }}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ConditionForm ref={conditionDataRef} />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={isLoading} variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={(e) => {
            toggleQueryDrawer(e, false);
          }}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default AddConditionDrawer;
