

const SRDataSubjectType = [
    {
      name: 'Customer',
       value: "customer"
    },
    {
      name: 'Current Employee​',
       value: "currentEmployee"
    },
    {
      name: 'Former Employee​​',
       value: "formerEmployee"
    },
    {
      name: 'Prospective Employee​​',
       value: "prospectiveEmployee"
    },
    {
      name: 'Other​​',
       value: "other"
    },
  ];
  export default SRDataSubjectType;
