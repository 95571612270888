import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import api from "../../../utils/api";
import {
  FETCH_COLLECTIONS,
  CREATE_COLLECTION,
  FETCH_COLLECTION_DETAILS,
  UPDATE_COLLECTION,
  FETCH_CUSTODIAL_SOURCES,
} from "../../actionTypes/collectionsTypes";

export const fetchCollectionsByCaseId = createAsyncThunk(
  FETCH_COLLECTIONS,
  async (caseId, { dispatch }) => {
    try {
      const res = await axios.get(api.collections.get(caseId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const createCollectionByCaseId = createAsyncThunk(
  CREATE_COLLECTION,
  async ({ data, caseId }, { dispatch }) => {
    try {
      const res = await axios.post(api.collections.create(caseId), data);
      await dispatch(fetchCollectionsByCaseId(caseId));
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCollectionDetails = createAsyncThunk(
  FETCH_COLLECTION_DETAILS,
  async ({ caseId, collectionId }, { dispatch }) => {
    try {
      const res = await axios.get(
        api.collections.details(caseId, collectionId)
      );
      return { collectionDetails: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const removeAdditionalSource = createAsyncThunk(
  "collections/removeAdditionalSource",
  async (data) => {
    const { caseId, collectionId, sourceId } = data;
    const response = await axios.delete(
      api.collections.removeAdditionalSource(caseId, collectionId, sourceId)
    );
    return response;
  }
);

export const updateCollection = createAsyncThunk(
  UPDATE_COLLECTION,
  async (data) => {
    const { formData, caseId, collectionId } = data;
    try {
      await axios.patch(api.collections.update(caseId, collectionId), formData);
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCustodialSources = createAsyncThunk(
  FETCH_CUSTODIAL_SOURCES,
  async ({ caseId, collectionId }, { dispatch }) => {
    try {
      const res = await axios.get(
        api.collections.custodialSources(caseId, collectionId)
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
