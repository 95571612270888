import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Refresh } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { format } from "date-fns";
import {
  fetchCollectionDetails,
  fetchCustodialSources,
  removeAdditionalSource,
} from "../../../../../redux/actions/collections/collectionsActions";
import { fetchCaseById } from "../../../../../redux/actions/caseActions";
import SummaryForm from "./form/SummaryForm";
import CustomTable from "../../../../composite/table/CustomTable";
import AddToReviewSet from "../form/addToReviewSet";
import { isObjectEmptyOrNull } from "../../../../../utils/validation";
import { handleBreadcrumb } from "../../../../../redux/actions/utilsActions";
import additionalSourcesTableHeadCells from "./additionalSourcesTableHeadCells";
import CaseRunningJobs from "../../../../common/CaseRunningJobs";
import swal from "sweetalert";
import AdditionalSourcesForm from "./form/AdditionalSourcesForm";
import axiosInstance from "../../../../../utils/axios";
import api from "../../../../../utils/api";
import custodialSourcesTableHeadCells from "./custodialSourcesTableHeadCells";
import CustodialSourcesForm from "./form/CustodialSourcesForm";

const CollectionDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { caseId, collectionId } = params;

  const [summaryExpanded, setSummaryExpanded] = useState(true);
  const [additionalExpanded, setAdditionalExpanded] = useState(true);
  const [estimatesExpanded, setEstimatesExpanded] = useState(true);
  const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
  const [custodialExpanded, setCustodialExpanded] = useState(true);
  const [addToReviewSetDrawerOpen, setAddToReviewSetDrawerOpen] =
    useState(false);
  const [additionalSourcesDrawerOpen, setAdditionalSourcesDrawerOpen] =
    useState(false);
  const [custodialSourcesDrawerOpen, setCustodialSourcesDrawerOpen] =
    useState(false);

  useEffect(() => {
    dispatch(fetchCollectionDetails({ caseId, collectionId }));
    dispatch(fetchCaseById(caseId));
    dispatch(fetchCustodialSources({ caseId, collectionId }));
  }, [caseId, collectionId, dispatch]);

  const caseDetails = useSelector((state) => state.cases.currentCase.details);
  const { currentCollection, custodialSourceList } = useSelector(
    (state) => state.sourceCollections
  );

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "collection-detail-breadcumbs",
      links: [
        { url: "/cases", text: "Cases" },
        { url: `/cases/${caseId}?tab=0`, text: caseDetails?.displayName },
        { url: `/cases/${caseId}?tab=1`, text: "Collections" },
        { text: currentCollection.details?.displayName },
      ],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [caseDetails]);

  const handleSummaryAccordion = () => {
    setSummaryExpanded(!summaryExpanded);
  };
  const handleAdditionalAccordion = () => {
    setAdditionalExpanded(!additionalExpanded);
  };
  const handleCustodialAccordion = () => {
    setCustodialExpanded(!custodialExpanded);
  };
  const toggleSummaryDrawer = (event, open) => {
    setSummaryDrawerOpen(open);
  };
  const toggleAdditionalSourcesDrawer = (e, open) => {
    setAdditionalSourcesDrawerOpen(open);
  };
  const toggleCustodialSourcesDrawer = (e, open) => {
    setCustodialSourcesDrawerOpen(open);
  };
  const handleRemoveAdditionalSource = (e, sourceId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to remove this source?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Remove"],
    }).then((yes) => {
      if (!yes) return;
      swal("Wait!", "Deleting", "warning");
      dispatch(removeAdditionalSource({ caseId, collectionId, sourceId })).then(
        (res) => {
          if (!res.hasOwnProperty("error")) {
            swal("Success!", "Source deleted successfully", "success").then(
              () => {
                dispatch(fetchCollectionDetails({ caseId, collectionId }));
              }
            );
          }
        }
      );
    });
  };
  const reIndexCollection = () => {
    swal({
      title: "Confirmation",
      text: "Do you really want to reindex the collection?\nThis process will take some times to finish.",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Yes"],
    }).then((yes) => {
      if (!yes) return;
      axiosInstance.post(api.collections.indexing(caseId, collectionId));
    });
  };
  
    const handleDeleteCustodialSource = (e, custodialSourceId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this Source?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance
          .delete(api.collections.deleteCustodialSources(caseId, collectionId, custodialSourceId))
          .then((res) => {
            swal("Success!", `Source has been deleted!`, "success").then(
              () => {
                dispatch(fetchCustodialSources(caseId, collectionId));
              }
            );
          })
      }
    });
  };

  return (
    <>
      {caseId ? <CaseRunningJobs caseId={caseId} /> : <></>}
      <Grid container>
        <Grid item xs={12} mb={2}>
          <Accordion expanded={summaryExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon onClick={handleSummaryAccordion} />}
              aria-controls="panel-summary-content"
              id="panel-summary-header"
              className="accordian-header collection-detail-basic-header"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    onClick={handleSummaryAccordion}
                  >
                    Summary
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={() => setAddToReviewSetDrawerOpen(true)}
                    disabled={currentCollection.isLoading}
                  >
                    <AddIcon /> Add To Reviewset
                  </Button>
                  <Button
                    onClick={(event) => toggleSummaryDrawer(event, true)}
                    disabled={currentCollection.isLoading}
                  >
                    <ModeEditIcon />
                  </Button>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordian-body collection-detail-basic-body">
              {currentCollection.isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  padding={4}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box width="100%">
                  <div>
                    <Typography variant="span" fontWeight="bold" mr={1}>
                      Name:
                    </Typography>
                    <Typography variant="span">
                      {currentCollection.details.displayName}
                    </Typography>
                  </div>
                  <Typography fontWeight="bold">Description: </Typography>
                  <Typography>
                    {currentCollection.details.description}
                  </Typography>
                  <Typography fontWeight="bold">Search Conditions:</Typography>
                  <Typography>
                    {currentCollection.details.contentQuery}
                  </Typography>
                  <div>
                    <Typography variant="span" fontWeight="bold" mr={1}>
                      Created By:
                    </Typography>
                    <Typography variant="span">
                      {currentCollection.details.createdBy?.displayName}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="span" fontWeight="bold" mr={1}>
                      Last Modified By:
                    </Typography>
                    <Typography variant="span">
                      {currentCollection.details.lastModifiedBy?.displayName}
                    </Typography>
                    <Typography variant="span" fontWeight="bold">
                      on
                    </Typography>
                    <Typography variant="span">
                      {currentCollection.details.lastModifiedDateTime
                        ? format(
                            new Date(
                              currentCollection.details.lastModifiedDateTime
                            ),
                            "dd MMM yyy"
                          )
                        : ""}
                    </Typography>
                  </div>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Accordion expanded={custodialExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon onClick={handleCustodialAccordion} />}
              className="accordian-header collection-additional-header"
            >
              <Typography
                variant="h5"
                fontWeight="bolder"
                onClick={handleCustodialAccordion}
              >
                Custodial Sources
              </Typography>
              <Button
                onClick={(e) => toggleCustodialSourcesDrawer(e, true)}
                style={{ marginLeft: "auto", marginRight: "10px" }}
              >
                + Add Sources
              </Button>
            </AccordionSummary>
            <AccordionDetails className="accordian-body collection-additional-body">
              <CustomTable
                showSerialNo={true}
                rowsCount={10}
                rows={custodialSourceList.data}
                headCells={custodialSourcesTableHeadCells()}
                isLoading={currentCollection.isLoading}
                onClickActionEvent={handleDeleteCustodialSource}

              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Accordion expanded={additionalExpanded}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon onClick={handleAdditionalAccordion} />
              }
              className="accordian-header collection-additional-header"
            >
              <Typography
                variant="h5"
                fontWeight="bolder"
                onClick={handleAdditionalAccordion}
              >
                Additional Sources
              </Typography>
              <Button
                onClick={(e) => toggleAdditionalSourcesDrawer(e, true)}
                style={{ marginLeft: "auto", marginRight: "10px" }}
              >
                + Add Sources
              </Button>
            </AccordionSummary>
            <AccordionDetails className="accordian-body collection-additional-body">
              <CustomTable
                showSerialNo={true}
                rowsCount={10}
                rows={currentCollection.details.additionalDataSources}
                headCells={additionalSourcesTableHeadCells(
                  handleRemoveAdditionalSource
                )}
                isLoading={currentCollection.isLoading}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion expanded={estimatesExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="accordian-header collection-estimation-header"
            >
              <Typography variant="h5" fontWeight="bolder">
                Collection Estimates
              </Typography>

              <Button
                size="small"
                onClick={(e) => reIndexCollection()}
                style={{ marginLeft: "auto", marginRight: "10px" }}
              >
                <Refresh fontSize="small" /> Reindex
              </Button>
            </AccordionSummary>
            <AccordionDetails className="accordian-body collection-estimation-body">
              {!isObjectEmptyOrNull(
                currentCollection.details.lastEstimateStatisticsOperation
              ) ? (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box
                        width={`${currentCollection.details.lastEstimateStatisticsOperation.indexedItemCount}%`}
                        height="20px"
                        bgcolor="#BDCDD6"
                      />
                      <Box
                        width={`${currentCollection.details.lastEstimateStatisticsOperation.mailboxCount}%`}
                        height="20px"
                        bgcolor="navy"
                      />
                      <Box
                        width={`${currentCollection.details.lastEstimateStatisticsOperation.siteCount}%`}
                        height="20px"
                        bgcolor="#A084DC"
                      />
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="start"
                      width="100%"
                    >
                      <Box display="flex" alignItems="center" pr={1}>
                        <Box
                          mr={1}
                          width={15}
                          height={15}
                          borderRadius="2%"
                          bgcolor="#BDCDD6"
                        />
                        <span>
                          Indexed Item (
                          {
                            currentCollection.details
                              .lastEstimateStatisticsOperation.indexedItemCount
                          }
                          ){" "}
                        </span>
                      </Box>
                      <Box display="flex" alignItems="center" pr={1}>
                        <Box
                          mr={1}
                          width={15}
                          height={15}
                          borderRadius="2%"
                          bgcolor="navy"
                        />
                        <span>
                          Mailbox (
                          {
                            currentCollection.details
                              .lastEstimateStatisticsOperation.mailboxCount
                          }
                          )
                        </span>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box
                          mr={1}
                          width={15}
                          height={15}
                          borderRadius="2%"
                          bgcolor="#A084DC"
                        />
                        <span>
                          Site (
                          {
                            currentCollection.details
                              .lastEstimateStatisticsOperation.siteCount
                          }
                          )
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : null}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <SummaryForm
        toggleSummaryDrawer={toggleSummaryDrawer}
        summaryDrawerOpen={summaryDrawerOpen}
        collectionDetails={currentCollection.details}
      />
      <AddToReviewSet
        key={collectionId}
        isDrawerOpen={addToReviewSetDrawerOpen}
        setIsDrawerOpen={setAddToReviewSetDrawerOpen}
      />
      <AdditionalSourcesForm
        isDrawerOpen={additionalSourcesDrawerOpen}
        toggleDrawer={toggleAdditionalSourcesDrawer}
        additionalSources={[]}
      />
      <CustodialSourcesForm
        isDrawerOpen={custodialSourcesDrawerOpen}
        toggleDrawer={toggleCustodialSourcesDrawer}
      />
    </>
  );
};

export default CollectionDetails;
