import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InitialCollectionsForm from "./InitialCollectionsForm";
import LocationCollectionsForm from "./locationCollectionsForm/LocationCollectionsForm";
import collectionsFormValidate from "./collectionsFormValidate";
import {
  fetchTenantUsers,
  fetchTenantSites,
} from "../../../../../redux/actions/tenantActions";
import { COLLECTIONS_FORM } from "../../../../../constants/form";
import ConditionForm from "../../reviewSets/querySet/form/conditionForm";
import axiosInstance from "../../../../../utils/axios";
import { showApiError } from "../../../../../utils/functions";
import api from "../../../../../utils/api";
/* import ConditionsCollectionsForm from "./conditionsCollectionsForm/ConditionsCollectionsForm"; */
/* import ReviewCollectionsForm from "./ReviewCollectionsForm"; */
const CollectionsForm = ({ handleSubmit, invalid }) => {
  const conditionDataRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const caseId = params.caseId;

  const [activeStep, setActiveStep] = useState(0);
  const [selectedCustodians, setSelectedCustodians] = useState([]);
  const [selectedNonCustodians, setSelectedNonCustodians] = useState([]);
  const [mailboxItems, setMailboxItems] = useState([]);
  const [siteUrls, setSiteUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchTenantUsers());
    dispatch(fetchTenantSites());
  }, []);

  const { tenant } = useSelector((state) => state);
  const { tenantUsersList, tenantSitesList } = tenant;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleMailboxItems = (data) => {
    setMailboxItems(data);
  };
  const handleSiteUrls = (data) => {
    setSiteUrls(data);
  };
  /* const handleChangeChip = (newChips) => {
    setChips(newChips);
  }; */
  /* const handleSelectedCustodiansParent = (rows) => {
    const modifiedRows = rows.map((obj) => {
      const newObj = {};
      newObj.email = obj.mail;
      newObj.applyHoldToSources = true;
      newObj.onHold = true;
      return newObj;
    });
    setSelectedCustodians(modifiedRows);
  }; */
  /* const handleSelectedNonCustodiansParent = (rows) => {
    setSelectedNonCustodians(rows);
  }; */

  const steps = [
    {
      label: "Name and description",
    },
    {
      label: "Additional location",
    },
    {
      label: "Conditions",
    },
    /* {
      label: "Custodial data sources",
      component: (
        <CustodialCollectionsForm
          tenantUsersList={tenantUsersList}
          handleSelectedCustodiansParent={handleSelectedCustodiansParent}
        />
      ),
    }, */
    /* {
      label: "Non-Custodial data sources",
      component: (
        <NonCustodialCollectionsForm
          tenantSitesList={tenantSitesList}
          tenantUsersList={tenantUsersList}
          handleSelectedNonCustodiansParent={handleSelectedNonCustodiansParent}
        />
      ),
    }, */
    /* {
      label: "Review",
      component: <ReviewCollectionsForm />,
    }, */
  ];
  const handleCollection = async (values) => {
    if (activeStep === steps.length) {
      setLoading(true);
      var data = {};
      const conditionData = await conditionDataRef.current.getData();
      const conditions = conditionData.jsonObject;
      data.displayName = values.displayName;
      data.description = values.description;
      data.custodialSources = selectedCustodians;
      data.nonCustodialSources = selectedNonCustodians;
      data.additionalLocations = {
        ...values.additionalLocations,
        exchangeMailBoxItems: mailboxItems.map((item) => item.mail),
        sharePointSiteUrls: siteUrls.map((item) => item.webUrl),
        exchangePublicFolderItems: [],
      };
      data.conditions = conditions.conditions;

      axiosInstance
        .post(api.collections.create(caseId), data)
        .then((res) => {
          if (res.hasOwnProperty("error")) {
            setActiveStep((prevActiveStep) => 2);
            setLoading(false);
          } else {
            swal(
              "Success!",
              `Collection created successfully!`,
              "success"
            ).then((res) => {
              setLoading(false);
              navigate(`/cases/${caseId}?tab=1`);
            });
          }
        })
        .catch((error) => {
          setActiveStep((prevActiveStep) => 2);
          setLoading(false);
        });
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleCollection)}>
        <Grid container sx={{ paddingBottom: "15px" }}>
          <Typography variant="h4">
            <Link to={`/cases/${caseId}?tab=1`}>
              <Button size="small">
                <ArrowBackIosIcon />
              </Button>
            </Link>
            New Collection
          </Typography>
        </Grid>
        <Divider sx={{ marginBottom: "25px" }} />

        <Grid container>
          <Grid item xs={3}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 5 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={9}>
            <Box hidden={activeStep === 0 ? false : true}>
              <InitialCollectionsForm />
            </Box>
            <Box hidden={activeStep === 1 ? false : true}>
              <LocationCollectionsForm
                tenantUsersList={tenantUsersList}
                tenantSitesList={tenantSitesList}
                handleMailboxItems={handleMailboxItems}
                handleSiteUrls={handleSiteUrls}
              />
            </Box>
            <Box hidden={activeStep === 2 ? false : true}>
              <ConditionForm ref={conditionDataRef} />
            </Box>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress size={60} />
              </Box>
            ) : (
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={invalid}
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default reduxForm({
  form: COLLECTIONS_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate: collectionsFormValidate,
  shouldError: () => true,
})(CollectionsForm);
