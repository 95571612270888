import { Alert, Chip } from "@mui/material";
import { format } from "date-fns";

export default function (onRowClick) {
  const auditsTableHeadCells = [
    {
      id: "logTime",
      tableCellType: "CUSTOM",
      fn: (cell) => (
        <Alert
          onClick={() => {
            onRowClick(cell);
          }}
          className={`audit-log log-type-${cell["logType"]}`}
          icon={false}
          severity={
            cell["logType"]?.toLowerCase() === "info" ? "success" : "error"
          }
        >
          <div className="log-header">
            <span className="log-time">
              {format(new Date(cell["logTime"]), "hh:mm:ss a - dd MMM yyyy")}
            </span>
            <Chip
              className="log-event"
              label={cell["logEvent"]}
              style={{ marginLeft: "15px", height: "auto" }}
            />
          </div>

          <p
            className="log-body"
            dangerouslySetInnerHTML={{ __html: cell["logMessage"] }}
          ></p>
        </Alert>
      ),
      label: "Log",
      style: { padding: "1px" },
    },
  ];
  return auditsTableHeadCells;
}
