import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { fetchQuerySetByReviewSetId } from "../../../../../redux/actions/reviewSets/querySetAction";
import CustomTable from "../../../../composite/table/CustomTable";
import querySetTableHeadCells from "./querySetTableHeadCells";
import ConditionDrawer from "./addQuery";
import { fetchCaseById } from "../../../../../redux/actions/caseActions";
import { handleBreadcrumb } from "../../../../../redux/actions/utilsActions";
import CaseRunningJobs from "../../../../common/CaseRunningJobs";
import CustomTableToolbar from "../../../../composite/table/CustomTableToolbar";

const QuerySet = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const reviewSetName = queryString.get("rs");

  useEffect(() => {
    dispatch(
      fetchQuerySetByReviewSetId({
        caseId: params.caseId,
        reviewSetId: params.reviewSetId,
      })
    );
    dispatch(fetchCaseById(params.caseId));
  }, [params.caseId]);

  const { queryList } = useSelector((state) => state.queries);

  const caseDetails = useSelector((state) => state.cases.currentCase.details);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "reviewset-queres-breadcumbs",
      links: [
        { url: "/cases", text: "Cases" },
        {
          url: `/cases/${params.caseId}?tab=0`,
          text: caseDetails?.displayName,
        },
        { url: `/cases/${params.caseId}?tab=2`, text: "Reviewsets" },
        { text: reviewSetName },
        { text: "Queries" },
      ],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [caseDetails]);

  const isLoading = queryList.isLoading;
  const queryListRows = queryList.data.map((item) => ({
    ...item,
    parentId: params.caseId,
  }));

  const [addQueryDrawerOpen, setAddQueryDrawerOpen] = useState(false);

  const toggleQueryDrawer = (event, open) => {
    setAddQueryDrawerOpen(open);
  };

  return (
    <>
      {params.caseId ? <CaseRunningJobs caseId={params.caseId} /> : <></>}
      <ConditionDrawer
        addQueryDrawerOpen={addQueryDrawerOpen}
        toggleQueryDrawer={toggleQueryDrawer}
      />
      <CustomTable
        showSerialNo={true}
        rows={queryListRows}
        headCells={querySetTableHeadCells(reviewSetName)}
        isLoading={isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"QUERIES"}
          onRefreshEvent={() =>
            dispatch(
              fetchQuerySetByReviewSetId({
                caseId: params.caseId,
                reviewSetId: params.reviewSetId,
              })
            )
          }
          addButtonText={"Create New Query"}
          onAddEvent={(e) => toggleQueryDrawer(e, true)}
        />
      </CustomTable>
    </>
  );
};

export default QuerySet;
