import { createSlice } from "@reduxjs/toolkit";
import {
  fetchReviewSetsByCaseId,
  fetchReviewSetDetails,
  createReviewSetByCaseId,
  updateReviewSet
} from "../actions/reviewSets/reviewSetsAction";

import { fetchReviewSetsContentsByQuerySetId } from "../actions/reviewSets/querySetResultAction";

const reviewSetsSlice = createSlice({
  name: "reviewSets",
  initialState: {
    reviewSetList: {
      isLoading: false,
      caseId: null,
      data: [],
      error: null,
    },
    currentReviewSet: {
      isLoading: false,
      details: {},
      error: null,
    },
    createdReviewSet: {
      isLoading: false,
      status: null,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewSetsByCaseId.pending, (state) => {
        state.reviewSetList.isLoading = true;
        state.reviewSetList.error = null;
      })
      .addCase(fetchReviewSetsByCaseId.fulfilled, (state, action) => {
        state.reviewSetList.isLoading = false;
        state.reviewSetList.caseId = action.payload.caseId;
        state.reviewSetList.data = action.payload.data;
      })
      .addCase(fetchReviewSetsByCaseId.rejected, (state, action) => {
        state.reviewSetList.isLoading = false;
        state.reviewSetList.error = action.error;
      })

      .addCase(fetchReviewSetsContentsByQuerySetId.pending, (state) => {
        state.reviewSetList.isLoading = true;
        state.reviewSetList.error = null;
      })
      .addCase(fetchReviewSetsContentsByQuerySetId.fulfilled, (state, action) => {
        state.reviewSetList.isLoading = false;
        state.reviewSetList.caseId = action.payload.caseId;
        state.reviewSetList.data = action.payload.data;
      })
      .addCase(fetchReviewSetsContentsByQuerySetId.rejected, (state, action) => {
        state.reviewSetList.isLoading = false;
        state.reviewSetList.error = action.error;
        state.reviewSetList.data = [];

      })

      .addCase(createReviewSetByCaseId.pending, (state) => {
        state.createdReviewSet.isLoading = true;
        state.createdReviewSet.error = null;
      })
      .addCase(createReviewSetByCaseId.fulfilled, (state) => {
        state.createdReviewSet.isLoading = false;
        state.createdReviewSet.status = "success";
      })
      .addCase(createReviewSetByCaseId.rejected, (state, action) => {
        console.log(action, "col action");
        state.createdReviewSet.isLoading = false;
        state.createdReviewSet.error = action.error;
      })

      .addCase(fetchReviewSetDetails.pending, (state) => {
        state.currentReviewSet.isLoading = true;
        state.currentReviewSet.error = null;
      })
      .addCase(fetchReviewSetDetails.fulfilled, (state, action) => {
        state.currentReviewSet.isLoading = false;
        state.currentReviewSet.details = action.payload.collectionDetails;
      })
      .addCase(fetchReviewSetDetails.rejected, (state, action) => {
        state.currentReviewSet.isLoading = false;
        state.currentReviewSet.error = action.error;
      });
  },
});

export default reviewSetsSlice.reducer;
