import { format } from "date-fns";
import ReviewSetActions from "./reviewSetAction";

export default function () {
  const reviewSetsTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
      tableCellType: "LINK_CELL_TYPE",
      meta: {
        link: function (reviewSetId, caseId, queryString) {
          return `/cases/${caseId}/review-sets/${reviewSetId}/queries?rs=${queryString}`;
        },
      },
    },
    {
      id: "createdBy",
      label: "Created By",
      tableCellType: "CUSTOM",
      fn: (cell) => cell.createdBy?.displayName,
    },
    {
      id: "createdDateTime",
      tableCellType: "CUSTOM",
      fn: (cell) =>
        cell["createdDateTime"]
          ? format(new Date(cell["createdDateTime"]), "dd MMM yyyy")
          : "",
      label: "Created",
    },
    {
      label: "Actions",
      tableCellType: "CUSTOM",
      fn: (cell) => {
        return <ReviewSetActions cell={cell} />;
      },
    },
  ];
  return reviewSetsTableHeadCells;
}
