import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomTable from "../../composite/table/CustomTable";
import collectionsTableHeadCells from "./collectionsTableHeadCells";
import swal from "sweetalert";
import api from "../../../utils/api";
import axios from "../../../utils/axios";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import { useLocation } from "react-router-dom";
import { fetchCollections } from "../../../redux/actions/collectionActions";
import AddEditCollectionForm from "./AddEditCollectionForm";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const Collections = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    dispatch(fetchCollections());
  }, []);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "collections-breadcumbs",
      links: [
        { url: "/dashboard", text: "Dashboard" },
        { text: "Collections" },
      ],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, []);

  const toggleDrawer = (event, open) => {
    setIsDrawerOpen(open);
  };

  const { collections } = useSelector((state) => state.collections);

  const handleDelete = (e, id) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this collection?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        axios.delete(api.onpremCollections.delete(id)).then((res) => {
          if (!res?.success && res?.errorMessage) {
            swal("Error!", res.errorMessage, "error");
          } else {
            swal("Success!", "Deleted Successfully", "success");
            dispatch(fetchCollections());
          }
        });
      }
    });
  };

  const handleSearch = (e, id) => {
    window.open(`/search?id=${id}`, "_blank");
    //navigate(`/search?id=${id}`, {state: {target: "_blank"}});
  };

  const handleEdit = (e, id) => {
    setIsAdd(false);
    setIsDrawerOpen(!isDrawerOpen);
    const selectedCollection = collections.data.filter((x) => x.id === id);
    setCurrentCollection(selectedCollection?.[0]);
  };

  const handleChangeHold = (e, id, onHold) => {
    let swalPromise = null;
    if (onHold) {
      swalPromise = swal({
        title: "Confirmation",
        text: "Do you really want to remove hold from the collection?",
        icon: "warning",
        buttons: ["No, Thanks", "Yes, Remove"],
        dangerMode: true,
      });
    } else {
      swalPromise = swal({
        title: "Confirmation",
        text: "Do you really want to add the collection on hold?",
        icon: "warning",
        buttons: ["No, Thanks", "Yes, Hold"],
        dangerMode: true,
      });
    }
    swalPromise.then((ok) => {
      if (ok) {
        axios
          .patch(api.onpremCollections.changeHoldStatus(id), {
            status: !onHold,
          })
          .then((res) => {
            if (!res?.success && res?.errorMessage) {
              swal("Error!", res.errorMessage, "error");
            } else {
              swal(
                "Success!",
                onHold
                  ? "Collection has been removed from hold"
                  : "Collection has been added on hold",
                "success"
              );
              dispatch(fetchCollections());
            }
          });
      }
    });
  };

  return (
    <AuthorizeGuard
      module={"COLLECTIONS"}
      action={"View"}
      showDeniedPage={true}
    >
      <CustomTable
        showSerialNo={true}
        rows={collections.data}
        headCells={collectionsTableHeadCells(
          handleSearch,
          handleEdit,
          handleDelete,
          handleChangeHold
        )}
        isLoading={collections.isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"COLLECTIONS"}
          onRefreshEvent={() => dispatch(fetchCollections())}
          addButton={
            <AuthorizeGuard module={"COLLECTIONS"} action={"Add"}>
              <Button
                onClick={(e) => {
                  setCurrentCollection(null);
                  setIsAdd(true);
                  toggleDrawer(e, true);
                }}
              >
                <AddIcon />
                Add new collection
              </Button>
            </AuthorizeGuard>
          }
        />
      </CustomTable>

      <AddEditCollectionForm
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
        currentCollection={currentCollection}
        isAdd={isAdd}
      />
    </AuthorizeGuard>
  );
};

export default Collections;
