import React from "react";
import { useSelector } from "react-redux";
import { Breadcrumbs, Typography, Box } from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const AppBreadcrumbs = () => {
  const location = useLocation();
  const breadcrumbData = useSelector((state) => state.utils.breadcrumb);
  const links = breadcrumbData?.links;
  const className = breadcrumbData?.className;

  return breadcrumbData?.route === location.pathname && links.length > 0 ? (
    // Render breadcrumbs if the current route matches the breadcrumb route and there are links to display

    <Box
      className={`app-breadcrumbs-container ${className}`}
      sx={{
        width: "100%",
        paddingBottom: "5px",
        borderBottom: "1px dotted #ccc",
        marginBottom: "5px",
      }}
    >
      <Breadcrumbs
        className={"app-breadcrumbs"}
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {links.map((link, index) =>
          link?.url ? (
            <Link
              key={`link_${index}`}
              underline="hover"
              color="inherit"
              to={link.url}
            >
              {link.text}
            </Link>
          ) : (
            <Typography key={`link_${index}`} color="text.primary">
              {link.text}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Box>
  ) : (
    <></>
  );
};

export default AppBreadcrumbs;
