import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Typography, TextField } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import swal from "sweetalert";
import axios from "../../../utils/axios";
import api from "../../../utils/api";
import { fetchCollections } from "../../../redux/actions/collectionActions";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { loadConnections } from "../../../redux/actions/searchCollectionActions";
import { fetchTenantUsers } from "../../../redux/actions/tenantActions";
import CustomDrawer from "../../composite/CustomDrawer";
import CustomDrawerFooter from "../../composite/table/CustomDrawerFooter";

const AddEditCollectionForm = ({
  toggleDrawer,
  isDrawerOpen,
  currentCollection,
  isAdd,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isDrawerOpen);
  const [collectionName, setCollectionName] = useState(null);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  useEffect(() => {
    setOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    if (!isAdd && currentCollection) {
      setCollectionName(currentCollection.name);

      setSelectedConnections(
        currentCollection.connectionIds ? currentCollection.connectionIds : []
      );
      setSelectedMembers(
        currentCollection.searchMembers ? currentCollection.searchMembers : []
      );
    } else {
      setSelectedConnections([]);
      setSelectedMembers([]);
      setCollectionName(null);
    }
  }, [currentCollection]);

  useEffect(() => {
    dispatch(loadConnections());
    dispatch(fetchTenantUsers());
  }, []);

  const { connections } = useSelector((state) => state.ui);
  const tenantUsers = useSelector((state) => state.tenant.tenantUsersList.data);

  const onSelect = (selectedConnection) => {
    var connections = [];
    selectedConnection.forEach((element) => {
      connections.push(element.id);
    });
    setSelectedConnections(connections);
  };

  const onMembersSelect = (selectedMembers) => {
    var members = [];
    selectedMembers.forEach((element) => {
      members.push(element.id);
    });
    setSelectedMembers(members);
  };

  const connectionSelect = () => {
    var options = connections.map((step, index) => ({
      name: step.name,
      id: step.id,
    }));
    return (
      <Multiselect
        className="list-connections"
        placeholder="Select connection(s)"
        avoidHighlightFirstOption={true}
        options={options}
        onSelect={onSelect}
        onRemove={onSelect}
        displayValue="name"
        selectedValues={options.filter((item) =>
          selectedConnections.includes(item.id)
        )}
      />
    );
  };
  const membersSelect = () => {
    var options = tenantUsers.map((step, index) => ({
      name: `${step.displayName} (${step.mail})`,
      id: step.id,
    }));
    return (
      <Multiselect
        className="list-members"
        placeholder="Select user(s)"
        avoidHighlightFirstOption={true}
        options={options}
        onSelect={onMembersSelect}
        onRemove={onMembersSelect}
        displayValue="name"
        selectedValues={options.filter((item) =>
          selectedMembers.includes(item.id)
        )}
      />
    );
  };

  const handleCollection = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      collectionName: collectionName,
      connectionIds: selectedConnections,
      searchMembers: selectedMembers,
    };

    if (isAdd) {
      axios
        .post("/collections", formData)
        .then((res) => {
          swal("Success!", `Collection created successfully!`, "success").then(
            () => {
              dispatch(fetchCollections());
              setLoading(false);
              toggleDrawer(e, false);

              setCollectionName(null);
              setSelectedConnections([]);
              setSelectedMembers([]);
            }
          );
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      axios
        .patch(api.onpremCollections.update(currentCollection.id), formData)
        .then((res) => {
          swal("Success!", `Collection updated successfully!`, "success").then(
            () => {
              dispatch(fetchCollections());
              setLoading(false);
              toggleDrawer(e, false);
            }
          );
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <CustomDrawer
      className={"add-edit-collection-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={loading}
      title={isAdd ? "New Collection" : "Edit Collection"}
      wrapWithForm={true}
      onFormSubmit={handleCollection}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Collection Name
          </Typography>
          <TextField
            size="small"
            required
            variant="outlined"
            value={collectionName}
            onChange={(e) => {
              setCollectionName(e.target.value);
            }}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Connections
          </Typography>
          {connectionSelect()}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Search Members
          </Typography>
          {membersSelect()}
          <small className={"form-text text-muted"}>
            Users who can search and export the collection
          </small>
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <AuthorizeGuard module={"COLLECTIONS"} action={isAdd ? "Add" : "Edit"}>
          <Button disabled={loading} type="submit" variant="contained">
            Submit
          </Button>
        </AuthorizeGuard>

        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default AddEditCollectionForm;
