import { Button } from "@mui/material";
import { CUSTOM } from "../../../constants/table";

export default function (onClickDownloadEvent) {
  const collectionExportFilesTableHeadCells = [
    {
      id: "fileId",
      label: "ID",
      tableCellType: CUSTOM,
      fn: (cell) => (
        <div className="guid-cell" title={cell?.fileId}>
          {cell?.fileId}
        </div>
      ),
    },
    {
      id: "originalFileName",
      label: "File Name",
    },
    {
      id: "fileSizeMb",
      label: "Size",
    },
    {
      id: "type",
      label: "Type",
      tableCellType: CUSTOM,
      fn: (cell) =>
        cell.fileType === 0
          ? "Email"
          : cell.fileType === 1
          ? "File"
          : "Elasticsearch File",
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "left",
      fn: (cell) =>
        !cell.fileExists ? (
          "File is missing"
        ) : (
          <Button
            size="small"
            style={{ cursor: "pointer" }}
            onClick={(e) => onClickDownloadEvent(e, cell?.fileId, cell?.fileName)}
          >
            Download
          </Button>
        ),
    },
  ];
  return collectionExportFilesTableHeadCells;
}
