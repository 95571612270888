import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { Grid, Button, Typography, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  updateCase,
  fetchCaseById,
  fetchCases,
} from "../../../../redux/actions/caseActions";
import swal from "sweetalert";
import CustomDrawer from "../../../composite/CustomDrawer";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const CaseForm = ({
  toggleDrawer,
  isCaseFormDrawerOpen,
  createdCaseLoading,
  isEdit = false,
  caseDetails,
}) => {
  const params = useParams();
  const dispatch = useDispatch();

  const { caseId } = params;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isCaseFormDrawerOpen);
  const [caseName, setCaseName] = useState(caseDetails?.displayName);
  const [externalId, setExternalId] = useState(caseDetails?.externalId);
  const [description, setDescription] = useState(caseDetails?.description);

  useEffect(() => {
    setOpen(isCaseFormDrawerOpen);
  }, [isCaseFormDrawerOpen]);

  useEffect(() => {
    setCaseName(caseDetails?.displayName);
    setExternalId(caseDetails?.externalId);
    setDescription(caseDetails?.description);
  }, [caseDetails]);

  const handleCaseNameChange = (e) => {
    setCaseName(e.target.value);
  };

  const handleExternalIdChange = (e) => {
    setExternalId(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCaseForm = (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = {};
    formData.displayName = caseName;
    formData.externalId = externalId;
    formData.description = description;

    if (isEdit) {
      dispatch(updateCase({ caseId, formData })).then((res) => {
        if (res.hasOwnProperty("error")) {
          setLoading(false);
        } else {
          swal("Success!", `Case updated successfully!`, "success").then(() => {
            dispatch(fetchCaseById(caseId));
            setLoading(false);
            toggleDrawer(e, false);
          });
        }
      });
    } else {
      axiosInstance
        .post(api.case.create(), formData)
        .then((res) => {
          swal("Success!", `Case created successfully!`, "success").then(
            (res) => {
              dispatch(fetchCases());
              setLoading(false);
              setCaseName(null);
              setExternalId(null);
              setDescription(null);
              toggleDrawer(e, false);
            }
          );
        })
        .catch((error) => {
          setLoading(false);
          setCaseName(null);
          setExternalId(null);
          setDescription(null);
        });
    }
  };

  return (
    <CustomDrawer
      className={"add-case-drawer"}
      isDrawerOpen={open}
      toggleDrawer={toggleDrawer}
      loading={loading}
      title={isEdit ? "Edit case" : "Create new case"}
      subtitle={
        isEdit
          ? null
          : " Provide basic information about this case and a description"
      }
      wrapWithForm={true}
      onFormSubmit={handleCaseForm}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2">Name</Typography>
          <TextField
            size="small"
            required
            id="case-name"
            variant="outlined"
            value={caseName}
            onChange={handleCaseNameChange}
            style={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">External ID (Optional)</Typography>
          <TextField
            size="small"
            id="externalId"
            variant="outlined"
            value={externalId}
            onChange={handleExternalIdChange}
            style={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">Description (Optional)</Typography>
          <TextField
            size="small"
            id="caseDescription"
            variant="outlined"
            value={description}
            onChange={handleDescriptionChange}
            style={{ width: "100%" }}
            multiline
            rows={5}
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <LoadingButton
          disabled={loading}
          type="submit"
          loading={createdCaseLoading}
          variant="contained"
        >
          Submit
        </LoadingButton>
        <Button disabled={loading} variant="outlined" onClick={toggleDrawer}>
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default CaseForm;
