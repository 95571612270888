import { CUSTOM } from "../../../../../constants/table";

export default function () {
  const additionalSourcesTableHeadCells = [
    {
      id: "displayName",
      label: "Title",
      align: "left",
    },
    {
      id: "email",
      label: "Email/Url",
      tableCellType: CUSTOM,
      fn: (cell) => cell.email ? cell.email + (cell.siteWebUrl ? "(OneDrive included)" : "") : cell.siteWebUrl,
    },
    {
      id: "action",
      label: "Action",
    },
  ];
  return additionalSourcesTableHeadCells;
}
