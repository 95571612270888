import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import api from "../../../utils/api";

export const fetchExportList = createAsyncThunk(
  "export/getList",
  async ( {caseId} , { dispatch }) => {
    try {
      const res = await axios.get(api.export.getList(caseId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);

export const retrieveExportDetail = createAsyncThunk(
  "export/retrieveDetail",
  async ( {caseId,exportId} , { dispatch }) => {
    try {
      const res = await axios.get(api.export.getDetail(caseId, exportId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);