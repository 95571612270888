import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import userReducer from "./userReducer";
import caseReducer from "./caseReducer";
import dataSourcesReducer from "./dataSourcesReducer";
import sourceCollectionsReducer from "./sourceCollectionsReducer";
import tenantReducer from "./tenantReducer";
import uiReducer from "./uiReducer";
import reviewSetsReducer from "./reviewSetsReducer";
import reviewSetsContentsReducer from "./reviewSetsContentsReducer";
import queryReducer from "./queryReducer";
import exporttReducer from "./exporttReducer";
import holdsReducer from "./holdsReducer";
import utilsReducer from "./utilsReducer";
import settingsReducer from "./settingsReducer";
import userPermissionReducer from "./userPermissionReducer";
import connectionsReducer from "./connectionsReducer";
import collectionsReducer from "./collectionsReducer";
import subjectRightReducer from "./subjectRightReducer";

const rootReducer = combineReducers({
  user: userReducer,
  userPermissions: userPermissionReducer,
  cases: caseReducer,
  dataSources: dataSourcesReducer,
  sourceCollections: sourceCollectionsReducer,
  holds: holdsReducer,
  reviewSets: reviewSetsReducer,
  reviewSetsContents: reviewSetsContentsReducer,
  queries: queryReducer,
  tenant: tenantReducer,
  form: formReducer,
  ui: uiReducer,
  export: exporttReducer,
  utils: utilsReducer,
  settings: settingsReducer,
  connections: connectionsReducer,
  collections: collectionsReducer,
  subjectRights: subjectRightReducer,
});

export default rootReducer;
