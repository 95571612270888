import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  styled,
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WifiiIcon from "@mui/icons-material/Wifi";
import CollectionsIcon from "@mui/icons-material/Collections";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DoNotTouchIcon from "@mui/icons-material/DoNotTouch";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import StorageIcon from "@mui/icons-material/Storage";
import DomainIcon from "@mui/icons-material/Domain";
import { DRAWER_WIDTH } from "../../constants/theme";
import AuthorizeGuard from "./AuthorizeGuard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faWindowRestore } from "@fortawesome/free-solid-svg-icons";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const menuItems = {
  dashboard: {
    id: 0,
    name: "Dashboard",
    route: "/dashboard",
    icon: <DashboardIcon />,
    module: "DASHBOARD",
  },
  onPremise: [
    {
      id: 1,
      name: "Connections",
      route: "/connections",
      icon: <WifiiIcon />,
      module: "CONNECTIONS",
    },
    {
      id: 2,
      name: "Collections",
      route: "/collections",
      icon: <CollectionsIcon />,
      module: "COLLECTIONS",
    },
    {
      id: 3,
      name: "Exports",
      route: "/exported-collections",
      icon: <ExitToAppIcon />,
      module: "EXPORTS",
    },
    {
      id: 4,
      name: "Holds",
      route: "/collections-on-hold",
      icon: <DoNotTouchIcon />,
      module: "HOLDS",
    },
  ],
  cloud: [
    {
      id: 10,
      name: "eDiscovery",
      route: "/cases",
      icon: <ManageSearchIcon />,
      module: "CLOUD",
    },
    {
      id: 11,
      name: "Subject Rights",
      route: "/subjectRights",
      icon: <ManageSearchIcon />,
      module: "CLOUD",
    },
  ],
  setting: {
    id: 20,
    name: "Settings",
    route: "/settings",
    icon: <SettingsIcon />,
    module: "SETTINGS",
  },
  search: {
    id: 21,
    name: "search",
    route: "/global-search",
    icon: <ManageSearchIcon />,
    module: "Search",
  },
};

export default function SideNav({ handleDrawerClose, open }) {
  const theme = useTheme();
  const location = useLocation();

  return (
    <>
      {location.pathname === "/search" ? (
        <></>
      ) : (
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: DRAWER_WIDTH,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
          PaperProps={{ style: { backgroundColor: "#3C3C3C" } }}
        >
          <DrawerHeader>
            <Typography
              variant="h6"
              component="h6"
              style={{
                color: "white",
                width: "100%",
                paddingLeft: "16px",
                fontWeight: "bold",
                fontVariant: "small-caps",
              }}
            >
              SearchOne
            </Typography>
            <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <List>
            <Divider style={{ backgroundColor: "gray" }} />
            <AuthorizeGuard module={menuItems.dashboard.module} action="View">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    style={{
                      color: "white",
                      minWidth: "auto",
                      marginRight: "10px",
                    }}
                  >
                    {menuItems.dashboard.icon}
                  </ListItemIcon>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to={menuItems.dashboard.route}
                  >
                    {menuItems.dashboard.name}
                  </Link>
                </ListItemButton>
              </ListItem>
            </AuthorizeGuard>
            <Divider style={{ backgroundColor: "gray" }} />

            <ListItem key={"cloud-menus"} disablePadding>
              <Typography
                variant="h6"
                component="h6"
                style={{ paddingLeft: "15px", color: "gray" }}
              >
                Cloud
              </Typography>
            </ListItem>

            <Divider style={{ backgroundColor: "gray" }} />

            {menuItems.cloud.map((nav, index) => (
              <AuthorizeGuard module={nav.module} action={"View"} >
                <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    style={{
                      color: "white",
                      minWidth: "auto",
                      marginRight: "10px",
                    }}
                  >
                    {nav.icon || <SettingsIcon />}
                  </ListItemIcon>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to={nav.route}
                  >
                    {nav.name}
                  </Link>
                </ListItemButton>
              </ListItem>
              </AuthorizeGuard>
            ))}

            <Divider style={{ backgroundColor: "gray" }} />
            <ListItem key={"onpremise-menus"} disablePadding>
              <Typography
                variant="h6"
                component="h6"
                style={{ paddingLeft: "15px", color: "gray" }}
              >
                On-Premise
              </Typography>
            </ListItem>
            <Divider style={{ backgroundColor: "gray" }} />
            {menuItems.onPremise.map((nav, index) => (
              <AuthorizeGuard module={nav.module} action={"View"} key={nav.id}>
                <ListItem key={nav.id} disablePadding>
                  <ListItemButton>
                    <ListItemIcon
                      style={{
                        color: "white",
                        minWidth: "auto",
                        marginRight: "10px",
                      }}
                    >
                      {nav.icon || <SettingsIcon />}
                    </ListItemIcon>
                    <Link
                      style={{ color: "white", textDecoration: "none" }}
                      to={nav.route}
                    >
                      {nav.name}
                    </Link>
                  </ListItemButton>
                </ListItem>
              </AuthorizeGuard>
            ))}
            <Divider style={{ backgroundColor: "gray" }} />
            <AuthorizeGuard module={menuItems.setting.module} action="View">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    key={menuItems.setting.id}
                    style={{
                      color: "white",
                      minWidth: "auto",
                      marginRight: "10px",
                    }}
                  >
                    {menuItems.setting.icon}
                  </ListItemIcon>
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to={menuItems.setting.route}
                  >
                    {menuItems.setting.name}
                  </Link>
                </ListItemButton>
              </ListItem>
            </AuthorizeGuard>
            <Divider style={{ backgroundColor: "gray" }} />
          </List>
        </Drawer>
      )}
    </>
  );
}
