import React from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

import Case from "../components/pages/case/Case";
import CaseDetails from "../components/pages/case/CaseDetails";
import CollectionsForm from "../components/pages/case/collections/form/CollectionsForm";
import SourceCollections from "../components/pages/case/collections/Collections";
import CollectionDetails from "../components/pages/case/collections/collectionDetails/CollectionDetails";
import { DashboardNav } from "../components/pages/Dashboard";
import ReviewSetsContents from "../components/pages/case/reviewSets/reviewSetsContents/reviewSetsContents";
import { EditFileConnectionNav } from "../components/pages/connections/EditConnection";
import { SearchNav } from "../components/pages/collections/Search";
import HoldsForm from "../components/pages/case/holds/form/HoldsForm";
import Holds from "../components/pages/case/holds/holds";
import HoldDetails from "../components/pages/case/holds/holdDetails/HoldDetails";
import {
  loadDashboard,
  loadConnections,
  createCollection,
  deleteConnection,
  createServerConnection,
  loadConnectionInfo,
  loadConnectionLog,
  updateServerConnection,
  changeCrawlerStatus,
  loadCollectionInfo,
  searchCollection,
  requestCollectionExport,
  loadExportedCollections,
  changeCollectionHold,
  loadCollectionsOnHold,
  resetUIAlert,
  loadExportedCollectionFiles,
} from "../redux/actions/searchCollectionActions";
import {
  searchEmailReq
} from "../redux/actions/searchEmailActions";
import QuerySet from "../components/pages/case/reviewSets/querySet/querySet";
import AppSettings from "../components/pages/settings/AppSetting";
import Connections from "../components/pages/connections/Connections";
import CollectionExports from "../components/pages/collections/CollectionExports";
import CollectionExportFiles from "../components/pages/collections/CollectionExportFiles";
import Collections from "../components/pages/collections/Collections";
import HoldCollections from "../components/pages/collections/HoldCollections";
import { DeniedNav } from "../components/pages/Denied";
import SubjectRights from "../components/pages/subjectRight/SubjectRights";
import SubjectRightsDetails from "../components/pages/subjectRight/SubjectRightsDetails";
import { SearchEmailNav } from "../components/pages/SearchEmail";
const AppRoute = (props) => {
  const theRoutes = [
    { path: "/", element: <DashboardNav {...props} /> },
    { path: "/dashboard", element: <DashboardNav {...props} /> },
    { path: "/denied", element: <DeniedNav {...props} /> },
    { path: "/cases", element: <Case /> },
    { path: "/cases/:caseId", element: <CaseDetails /> },
    { path: "/cases/:caseId/collections/", element: <SourceCollections /> },
    { path: "/cases/:caseId/collections/create", element: <CollectionsForm /> },
    {
      path: "/case/:caseId/collection/:collectionId",
      element: <CollectionDetails />,
    },
    {
      path: "/cases/:caseId/review-sets/:reviewSetId",
      element: <ReviewSetsContents />,
    },
    {
      path: "/cases/:caseId/review-sets/:reviewSetId/queries",
      element: <QuerySet />,
    },
    {
      path: "/cases/:caseId/holds",
      element: <Holds />,
    },
    {
      path: "/cases/:caseId/holds/create",
      element: <HoldsForm />,
    },
    {
      path: "/case/:caseId/hold/:holdId",
      element: <HoldDetails />,
    },
    {
      path: "/connections",
      element: <Connections {...props} />,
    },
    {
      path: "/edit-file-connection",
      element: <EditFileConnectionNav {...props} />,
    },
    { path: "/collections", element: <Collections {...props} /> },
    {
      path: "/search",
      element: <SearchNav {...props} />,
    },
    {
      path: "/exported-collections",
      element: <CollectionExports {...props} />,
    },
    {
      path: "/exported-collection-files",
      element: <CollectionExportFiles {...props} />,
    },
    {
      path: "/collections-on-hold",
      element: <HoldCollections {...props} />,
    },
    {
      path: "/settings",
      element: <AppSettings />,
    },
    { path: "/subjectRights", element: <SubjectRights /> },
    { path: "/subjectRights/:srId", element: <SubjectRightsDetails /> },
    { path: "/searchemail", element: <SearchEmailNav {...props} /> },
  ];
  return (
    <>
      <Routes>
        {theRoutes.map((route, index) => (
          <Route
            key={`route_${index}`}
            path={route.path}
            element={route.element}
          />
        ))}
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  loadDashboard: (data) => {
    dispatch(loadDashboard(data));
  },
  loadConnections: (data) => {
    dispatch(loadConnections(data));
  },
  loadExportedCollections: () => {
    dispatch(loadExportedCollections());
  },
  loadExportedCollectionFiles: (data) => {
    dispatch(loadExportedCollectionFiles(data));
  },
  loadCollectionsOnHold: (data) => {
    dispatch(loadCollectionsOnHold(data));
  },
  loadCollectionInfo: (data) => {
    dispatch(loadCollectionInfo(data));
  },
  loadConnectionInfo: (data) => {
    dispatch(loadConnectionInfo(data));
  },
  loadConnectionLog: (data) => {
    dispatch(loadConnectionLog(data));
  },
  createServerConnection: (connection) => {
    dispatch(createServerConnection(connection));
  },
  updateServerConnection: (connection) => {
    dispatch(updateServerConnection(connection));
  },
  changeCrawlerStatus: (collection) => {
    dispatch(changeCrawlerStatus(collection));
  },
  createCollection: (token, collection) => {
    dispatch(createCollection(token, collection));
  },
  searchCollection: (data) => {
    dispatch(searchCollection(data));
  },
  changeCollectionHold: (data) => {
    dispatch(changeCollectionHold(data));
  },
  deleteConnection: (data) => {
    dispatch(deleteConnection(data));
  },
  requestCollectionExport: (data) => {
    dispatch(requestCollectionExport(data));
  },
  resetUIAlert: () => {
    dispatch(resetUIAlert());
  },
  searchEmail: (data) => {
    dispatch(searchEmailReq(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
