import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Button, Grid, FormControl } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../../../../utils/axios";
import api from "../../../../../utils/api";
import { useParams } from "react-router-dom";
import CustomDrawer from "../../../../composite/CustomDrawer";
import {
  reviewsetExportOptions,
  reviewsetExportStructures,
} from "../../../../../constants/predefinedData";
import CustomDrawerFooter from "../../../../composite/table/CustomDrawerFooter";
import { useEffect } from "react";
import swal from "sweetalert";

const ExportDrawer = ({
  openDrawer,
  toggleDrawer,
  queryId,
  selectedItemIds,
  name,
}) => {
  const { control, register, handleSubmit, getValues } = useForm({
    shouldUnregister: false,
  });

  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedItemsOption, setSelectedItemsOption] = React.useState("all");
  const [isloading, setIsLoading] = React.useState(false);
  const params = useParams();

  useEffect(() => {
    setSelectedItemsOption(selectedItemIds?.length > 0 ? "selected" : "all");
  }, [selectedItemIds]);

  const handleCheckboxChange = (option) => (e) => {
    if (e.target.checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };
  const handleExportItemsChange = (e) => {
    setSelectedItemsOption(e.target.value);
  };
  const submit = async (data) => {
    data = {
      ...data,
      selectedItemIds:
        selectedItemsOption === "selected" ? selectedItemIds : [],
    };

    if (!data.exportOptions || data.exportOptions?.length === 0) {
      swal("Error!", "Please provide export options", "warning");
      return;
    }
    if (
      selectedItemsOption === "selected" ||
      !queryId ||
      queryId === "" ||
      queryId === "Reviewset"
    ) {
      setIsLoading(true);
      axiosInstance
        .post(api.export.reviewSet(params.caseId, params.reviewSetId), data)
        .then((response) => {
          setIsLoading(false);
          swal("Success!", "Exported successfully", "success").then(() =>
            toggleDrawer(null, false)
          );
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      axiosInstance
        .post(
          api.export.querySet(params.caseId, params.reviewSetId, queryId),
          data
        )
        .then((response) => {
          setIsLoading(false);
          swal("Success!", "Exported successfully", "success").then(() =>
            toggleDrawer(null, false)
          );
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <CustomDrawer
      className={"export-reviewset-drawer"}
      isDrawerOpen={openDrawer}
      toggleDrawer={toggleDrawer}
      loading={isloading}
      title={"Export"}
      wrapWithForm={true}
      onFormSubmit={handleSubmit(submit)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <label htmlFor="outputName" style={{ fontWeight: 600 }}>
            Name:
          </label>
          <input
            id="outputName"
            {...register(`outputName`)}
            style={{
              height: "36px",
              padding: "10px",
              width: "100%",
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="description">
            <strong>Description</strong> (Optional):
          </label>
          <textarea
            id="description"
            {...register(`description`)}
            style={{
              padding: "10px",
              width: "100%",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel sx={{ fontWeight: 600, color: "black" }}>
              Export Contents
            </FormLabel>
            <RadioGroup
              name="export-items"
              value={selectedItemsOption}
              onChange={handleExportItemsChange}
            >
              <FormControlLabel
                className={"export-items"}
                value="all"
                control={<Radio />}
                label={`All Items of ${name}`}
              />
              <FormControlLabel
                className={"export-items"}
                value="selected"
                control={<Radio />}
                label={
                  selectedItemIds?.length > 0
                    ? `Selected ${selectedItemIds?.length} Items`
                    : "Selected Items"
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} id="export-options-container">
          <label style={{ fontWeight: 600 }}>Export options:</label>
          {reviewsetExportOptions.map((option) => (
            <Grid item key={option.id}>
              <input
                style={{ marginRight: "5px" }}
                type="checkbox"
                id={option.id}
                value={option.value}
                {...register("exportOptions")}
                checked={selectedOptions.includes(option.id)}
                onChange={handleCheckboxChange(option.id)}
              />
              <label style={{ marginBottom: 0 }} htmlFor={option.id}>
                {option.label}
              </label>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="exportStructure"
            control={control}
            defaultValue="Directory"
            rules={{ required: "true" }}
            render={({ field }) => (
              <>
                <RadioGroup
                  defaultValue=""
                  id="exportStructure"
                  {...register(`exportStructure`)}
                  {...field}
                  onChange={(value) => field.onChange(value)}
                >
                  <FormLabel style={{ fontWeight: 600, color: "black" }}>
                    Export structure:
                  </FormLabel>
                  {reviewsetExportStructures.map((option) => (
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      className={"export-structure"}
                    />
                  ))}
                </RadioGroup>
              </>
            )}
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={isloading} variant="contained" type="submit">
          Submit
        </Button>
        <Button
          disabled={isloading}
          variant="outlined"
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default ExportDrawer;
