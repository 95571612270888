import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCollectionsByCaseId } from "../../../../redux/actions/collections/collectionsActions";
import collectionsTableHeadCells from "./collectionsTableHeadCells";
import CustomTable from "../../../composite/table/CustomTable";
import swal from "sweetalert";
import axios from "../../../../utils/axios";
import api from "../../../../utils/api";
import { showApiError } from "../../../../utils/functions";
import CustomTableToolbar from "../../../composite/table/CustomTableToolbar";

const SourceCollections = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchCollectionsByCaseId(params.caseId));
  }, [dispatch, params.caseId]);

  const { collectionsList } = useSelector((state) => state.sourceCollections);
  const isLoading = collectionsList.isLoading;
  const collectionRows = collectionsList.data.map((item) => ({
    ...item,
    parentId: params.caseId,
  }));

  const handleDeleteCollection = (e, collectionId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this Collection?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        axios
          .delete(api.collections.delete(params.caseId, collectionId))
          .then((res) => {
            swal("Success!", `Collection has been deleted!`, "success").then(
              () => {
                setLoading(false);
                dispatch(fetchCollectionsByCaseId(params.caseId));
              }
            );
          })
          .catch((error) => {
            setLoading(false);
            showApiError(error);
          });
      }
    });
  };

  return (
    <CustomTable
      showSerialNo={true}
      rows={collectionRows}
      headCells={collectionsTableHeadCells()}
      isLoading={isLoading || loading}
      onClickActionEvent={handleDeleteCollection}
    >
      <CustomTableToolbar
        title={"COLLECTIONS"}
        onRefreshEvent={() => dispatch(fetchCollectionsByCaseId(params.caseId))}
        addButtonText={"Create new collection"}
        onAddEvent={() =>
          navigate(`/cases/${params.caseId}/collections/create`)
        }
      />
    </CustomTable>
  );
};

export default SourceCollections;
