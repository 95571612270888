import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import axios from "../../../../utils/axios";
import api from "../../../../utils/api";
import { fetchTenantUsers } from "../../../../redux/actions/tenantActions";
import CustomDrawer from "../../../composite/CustomDrawer";
import { getCaseCustodians } from "../../../../redux/actions/caseActions";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const AddCustodian = ({ caseId, toggleDrawer, isDrawerOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isDrawerOpen);
  const [selectedData, setSelectedData] = useState({});
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    dispatch(fetchTenantUsers());
  }, []);

  const { data } = useSelector((state) => state.tenant.tenantUsersList);

  const handleChange = (event, value) => {
    setSelected(value);

    const selectedItem = data.find((item) => item.id === value.id);
    setSelectedData(selectedItem);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = {
      email: selectedData.mail,
    };

    axios
      .post(api.case.addCustodian(caseId), formData)
      .then((res) => {
        swal("Success!", `Custodian added successfully!`, "success").then(
          () => {
            dispatch(getCaseCustodians(caseId));
            setLoading(false);
            toggleDrawer(e, false);
            setSelected([]);
          }
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <CustomDrawer
      className={"add-custodian-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={loading}
      title={"Add Custodian"}
      wrapWithForm={true}
      onFormSubmit={handleSubmit}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Select User
          </Typography>
          <Autocomplete
            options={data.map((item) => {
              return {
                label: `${item.displayName} (${item.mail})`,
                id: item.id,
              };
            })}
            size="small"
            getOptionLabel={(option) => option.label}
            value={selected}
            onChange={handleChange}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={loading} type="submit" variant="contained">
          Submit
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default AddCustodian;
