import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCollectionsByCaseId,
  fetchCollectionDetails,
  createCollectionByCaseId,
  fetchCustodialSources,
} from "../actions/collections/collectionsActions";

const collectionsSlice = createSlice({
  name: "sourceCollections",
  initialState: {
    collectionsList: {
      isLoading: false,
      caseId: null,
      data: [],
      error: null,
    },
    currentCollection: {
      isLoading: false,
      details: {},
      error: null,
    },
    createdCollection: {
      isLoading: false,
      status: null,
      error: null,
    },
    custodialSourceList: {
      isLoading: false,
      data: [],
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionsByCaseId.pending, (state) => {
        state.collectionsList.isLoading = true;
        state.collectionsList.error = null;
      })
      .addCase(fetchCollectionsByCaseId.fulfilled, (state, action) => {
        state.collectionsList.isLoading = false;
        state.collectionsList.caseId = action.payload.caseId;
        state.collectionsList.data = action.payload.data;
      })
      .addCase(fetchCollectionsByCaseId.rejected, (state, action) => {
        state.collectionsList.isLoading = false;
        state.collectionsList.error = action.payload;
      })

      .addCase(createCollectionByCaseId.pending, (state) => {
        state.createdCollection.isLoading = true;
        state.createdCollection.error = null;
      })
      .addCase(createCollectionByCaseId.fulfilled, (state) => {
        state.createdCollection.isLoading = false;
        state.createdCollection.status = "success";
      })
      .addCase(createCollectionByCaseId.rejected, (state, action) => {
        state.createdCollection.isLoading = false;
        state.createdCollection.error = action.error;
      })

      .addCase(fetchCollectionDetails.pending, (state) => {
        state.currentCollection.isLoading = true;
        state.currentCollection.error = null;
      })
      .addCase(fetchCollectionDetails.fulfilled, (state, action) => {
        state.currentCollection.isLoading = false;
        state.currentCollection.details = action.payload.collectionDetails;
      })
      .addCase(fetchCollectionDetails.rejected, (state, action) => {
        state.currentCollection.isLoading = false;
        state.currentCollection.error = action.payload;
      })

      .addCase(fetchCustodialSources.pending, (state) => {
        state.custodialSourceList.isLoading = true;
        state.custodialSourceList.error = null;
      })
      .addCase(fetchCustodialSources.fulfilled, (state, action) => {
        state.custodialSourceList.isLoading = false;
        state.custodialSourceList.data = action.payload;
      })
      .addCase(fetchCustodialSources.rejected, (state, action) => {
        state.custodialSourceList.isLoading = false;
        state.custodialSourceList.error = action.payload;
      });
  },
});

export default collectionsSlice.reducer;
