import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomTable from "../../composite/table/CustomTable";
import { fetchUsers } from "../../../redux/actions/settings/settingsActions";
import usersTableHeadCells from "./usersTableHeadCells";
import UserForm from "./form/UserForm";
import swal from "sweetalert";
import axios from "../../../utils/axios";
import api from "../../../utils/api";
import UserEditForm from "./form/UserEditForm";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { showApiError } from "../../../utils/functions";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const Users = () => {
  const dispatch = useDispatch();

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false);
  const [isUserEditDrawerOpen, setIsUserEditDrawerOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const toggleUserDrawer = (event, open) => {
    setIsUserDrawerOpen(open);
  };

  const toggleUserEditDrawer = (event, open) => {
    setIsUserEditDrawerOpen(open);
  };

  const handleDeleteUser = (e, userId) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this user?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Wait!", "Deleting", "warning");
        axios
          .delete(api.settings.deleteUser(userId))
          .then((response) => {
            swal("Success!", "Deleted Successfully", "success");
            dispatch(fetchUsers());
          })
          .catch((error) => {
            showApiError(error);
          });
      }
    });
  };

  const handleEditUser = (e, userId) => {
    const currentUser = userList.data.filter((item) => item.id === userId);
    setCurrentUser(currentUser[0]);
    toggleUserEditDrawer(e, true);
  };

  const { userList } = useSelector((state) => state.settings);

  return (
    <>
      <CustomTable
        showSerialNo={true}
        rows={userList.data}
        headCells={usersTableHeadCells(handleEditUser, handleDeleteUser)}
        isLoading={userList.isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"USERS"}
          onRefreshEvent={() => dispatch(fetchUsers())}
          addButton={
            <AuthorizeGuard module={"USERS"} action={"Add"}>
              <Button onClick={(e) => toggleUserDrawer(e, true)}>
                <AddIcon />
                Add user
              </Button>
            </AuthorizeGuard>
          }
        />
      </CustomTable>
      <UserForm
        toggleUserDrawer={toggleUserDrawer}
        isUserDrawerOpen={isUserDrawerOpen}
      />
      <UserEditForm
        currentUser={currentUser}
        toggleUserEditDrawer={toggleUserEditDrawer}
        isUserEditDrawerOpen={isUserEditDrawerOpen}
      />
    </>
  );
};

export default Users;
