import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import axiosInstance from "../../../../../../utils/axios";
import api from "../../../../../../utils/api";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";
import { fetchCustodiansByCaseId } from "../../../../../../redux/actions/dataSources/dataSourcesActions";
import dataSourcesTableHeadCells from "./dataSourcesTableHeadCells";
import CustomTable from "../../../../../composite/table/CustomTable";
import CustomTableToolbar from "../../../../../composite/table/CustomTableToolbar";
import { fetchCustodialSources } from "../../../../../../redux/actions/collections/collectionsActions";

const CustodialSourcesForm = ({ toggleDrawer, isDrawerOpen }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { caseId, collectionId } = params;
  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [selectedDataSources, setSelectedDataSources] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(fetchCustodiansByCaseId(caseId));
  }, []);

  const { isLoading, data, error } = useSelector(
    (state) => state.dataSources.custodiansList
  );

  const handleChange = (event, value) => {
    setSelected(value);

    const selectedItem = data.find((item) => item.id === value.id);
    setDataSources(selectedItem.dataSources);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(
        api.collections.custodialSources(caseId, collectionId),
        selectedDataSources
      )
      .then((res) => {
        swal(
          "Success!",
          `Custodial sources added successfully!`,
          "success"
        ).then(() => {
          dispatch(fetchCustodialSources({ caseId, collectionId }));
          setLoading(false);
          toggleDrawer(e, false);
        });
      })
      .catch((error) => {
        setLoading(false);
        toggleDrawer(e, false);
      });
  };

  const handleSelectedRows = (rows) => {
    setSelectedDataSources(
      rows.map((item) => {
        return {
          id: item.id,
          odataId: item.oDataId,
        };
      })
    );
  };

  return (
    <CustomDrawer
      className={"add-custodian-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={loading}
      title={"Add Custodial Sources"}
      wrapWithForm={true}
      onFormSubmit={handleSubmit}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Select custodian
          </Typography>
          <Autocomplete
            options={data.map((item) => {
              return {
                label: `${item.displayName} (${item.email})`,
                id: item.id,
              };
            })}
            size="small"
            getOptionLabel={(option) => option.label}
            value={selected}
            onChange={handleChange}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        {dataSources.length > 0 && (
          <Grid item xs={12} mt={2}>
            <CustomTable
              rows={dataSources}
              rowsCount={5000}
              headCells={dataSourcesTableHeadCells()}
              checkOption={true}
              onCheckRows={handleSelectedRows}
              isSearch={true}
              isPagination={false}
            >
              <CustomTableToolbar
                title={"Custodain's data source(s)"}
              ></CustomTableToolbar>
            </CustomTable>
          </Grid>
        )}
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={loading} type="submit" variant="contained">
          Submit
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default CustodialSourcesForm;
