import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { flattenObject } from "./utils";
import { config } from "../../../../../config";
import CustomDrawer from "../../../../composite/CustomDrawer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FilePreviewDrawer({ openState, setOpenState, row }) {
  const params = useParams();

  const [value, setValue] = useState(0);
  const [metaData, setMetaData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!("otherProperties" in row)) {
      row["otherProperties"] = {};
    }
    if (row["mediaType"]) {
      row["otherProperties"] = Object.assign({}, row["otherProperties"], {
        MediaType: row["mediaType"],
      });
    }
    if (row["extension"]) {
      row["otherProperties"] = Object.assign({}, row["otherProperties"], {
        Extension: row["extension"],
      });
    }

    if (row["id"]) {
      row["otherProperties"] = Object.assign({}, row["otherProperties"], {
        FileId: row["id"],
      });
    }

    if ("otherProperties" in row) {
      setMetaData(flattenObject(row["otherProperties"]));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleDrawer = (event, open) => {
    setOpenState(open);
  };

  const list = (anchor) => (
    <Box role="presentation">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{ paddingBottom: "5px" }}
      >
        <Tab label="Preview" {...a11yProps(0)} />
        <Tab label="Meta Data" {...a11yProps(1)} />
      </Tabs>

      <Box hidden={value === 0 ? false : true}>
        {loading ? (
          <Grid>
            <CircularProgress />
          </Grid>
        ) : (
          <div>
            <a
              style={{
                float: "right",
                borderRadius: "2px",
                padding: "2px 4px",
                marginBottom: "5px",
                textDecoration: "none",
              }}
              href={`${config.apiServerUrl}/cases/${
                params.caseId
              }/review-sets/${params.reviewSetId}/files/${
                row["id"]
              }/content?download=true&token=${localStorage.getItem(
                "accessToken"
              )}`}
            >
              <DownloadIcon /> Download
            </a>
          </div>
        )}
        <iframe
          src={`${config.apiServerUrl}/cases/${params.caseId}/review-sets/${
            params.reviewSetId
          }/files/${row["id"]}/content?token=${localStorage.getItem(
            "accessToken"
          )}#toolbar=0&navpanes=0&scrollbar=0`}
          style={{ visibility: loading ? "hidden" : "visible" }}
          onLoad={(e) => {
            setLoading(false);
          }}
          title=""
          width={"100%"}
          height={800}
        ></iframe>
      </Box>
      <Box hidden={value === 1 ? false : true}>
        {"otherProperties" in row && metaData ? (
          <Table>
            <TableBody>
              {Object.entries(metaData).map(([key, value]) =>
                !key.endsWith("@odata.type") && value ? (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )
              )}
            </TableBody>
          </Table>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <CustomDrawer
          className={"reviewset-file-preview-drawer"}
          isDrawerOpen={openState}
          toggleDrawer={toggleDrawer}
          loading={false}
          wrapWithForm={false}
          showHeader={false}
        >
          {list()}
        </CustomDrawer>
      </React.Fragment>
    </div>
  );
}
