import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  Grid,
  Button,
  Typography,
  TextField,
  Card,
  CardHeader,
  Box,
  CardContent,
  InputAdornment,
} from "@mui/material";
import swal from "sweetalert";
import { fetchSettings } from "../../../redux/actions/settings/settingsActions";
import axios from "../../../utils/axios";
import api from "../../../utils/api";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { showApiError } from "../../../utils/functions";

const Settings = () => {
  const dispatch = useDispatch();

  const [settingsData, setSettingsData] = useState({
    dateFormat: null,
    dateTimeFormat: null,
    timeFormat: null,
    language: null,
    dateTime: null
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  const { globalSettings } = useSelector((state) => state.settings);

  useEffect(() => {
    setSettingsData({ ...settingsData, ...globalSettings.data });
  }, [globalSettings]);

  const handleSettings = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(api.settings.save(), settingsData)
      .then((res) => {
        swal("Success!", `Settings updated successfully!`, "success").then(
          () => {
            dispatch(fetchSettings());
            setLoading(false);
          }
        );
      })
      .catch((error) => {
        showApiError(error);
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid xs={12}>
        <Card sx={{ border: "1px solid #C8C8C8" }}>
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography component={"span"} variant="h6" fontWeight="bold">
                  General Settings
                </Typography>
                <AuthorizeGuard module={"SETTINGS"} action={"Edit"}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSettings}
                  >
                    Save
                  </Button>
                </AuthorizeGuard>
              </Box>
            }
            sx={{ borderBottom: "1px solid #C8C8C8", paddingY: "7px" }}
          />
          <CardContent>
            {globalSettings.isLoading ? (
              "Loading..."
            ) : (
              <Grid container>
                <Grid item xs={6} marginBottom={1} paddingRight={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Date Format:
                  </Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    required
                    id="date-format"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {format(
                            new Date(),
                            settingsData.dateFormat
                              ? settingsData.dateFormat
                              : "dd MM yyyy"
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      setSettingsData({
                        ...settingsData,
                        dateFormat: e.target.value,
                      })
                    }
                    value={settingsData.dateFormat}
                  />
                </Grid>
                <Grid item xs={6} marginBottom={1} paddingRight={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Date Time Format:
                  </Typography>
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    required
                    id="date-time-format"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {format(
                            new Date(),
                            settingsData.dateTimeFormat
                              ? settingsData.dateTimeFormat
                              : "dd MM yyyy"
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      setSettingsData({
                        ...settingsData,
                        dateTimeFormat: e.target.value,
                      })
                    }
                    value={settingsData.dateTimeFormat}
                  />
                </Grid>
                <Grid item xs={6} marginBottom={1} paddingRight={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Time Format:
                  </Typography>
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    required
                    id="time-format"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {format(
                            new Date(),
                            settingsData.timeFormat
                              ? settingsData.timeFormat
                              : "dd MM yyyy"
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      setSettingsData({
                        ...settingsData,
                        timeFormat: e.target.value,
                      })
                    }
                    value={settingsData.timeFormat}
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Settings;
