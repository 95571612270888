import { CUSTOM } from "../../../../../../constants/table";

export default function () {
  const dataSourcesTableHeadCells = [
    {
      id: "displayName",
      label: "Title"
    },
    {
      id: "email",
      label: "Email/Url",
      tableCellType: CUSTOM,
      fn: (cell) => cell.email ? cell.email + (cell.siteWebUrl ? "(OneDrive included)" : "") : cell.siteWebUrl,
    }
  ];
  return dataSourcesTableHeadCells;
}
