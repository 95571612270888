import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import store from "./redux/store";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import msalInstance from "./utils/msal";

const theme = createTheme({
  typography: {
    fontFamily: ["Calibri", "Roboto", "Arial", "sans-serif"].join(","),
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);
