import { createSlice } from "@reduxjs/toolkit";
import { fetchQuerySetByReviewSetId,updateQuerySet,createQuerySet,deleteQuerySet,retrieveQuerySet } from "../actions/reviewSets/querySetAction";

const initialState = {
  queryList: {
    isLoading: false,
    data: [],
    error: null,
  },
  createdQuery: {
    isLoading: false,
    status: null,
    error: null,
  },
  updatedQuery:{
    isLoading: false,
    status: null,
    error: null,
  },
  retrievedQuery:{
    isLoading: false,
    data: [],
    error: null,
  }
};

export const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuerySetByReviewSetId.pending, (state) => {
        state.queryList.isLoading = true;
        state.queryList.error = null;
      })
      .addCase(fetchQuerySetByReviewSetId.fulfilled, (state, action) => {
        state.queryList.isLoading = false;
        state.queryList.data = action.payload.data;
      })
      .addCase(fetchQuerySetByReviewSetId.rejected, (state, action) => {
        state.queryList.isLoading = false;
        state.queryList.error = action.error;
      })

      .addCase(retrieveQuerySet.pending, (state) => {
        state.retrievedQuery.isLoading = true;
        state.retrievedQuery.error = null;
      })
      .addCase(retrieveQuerySet.fulfilled, (state, action) => {
        state.retrievedQuery.isLoading = false;
        state.retrievedQuery.data = action.payload.data;
      })
      .addCase(retrieveQuerySet.rejected, (state, action) => {
        state.retrievedQuery.isLoading = false;
        state.retrievedQuery.error = action.error;
      })

      .addCase(createQuerySet.pending, (state) => {
        state.createdQuery.isLoading = true;
        state.createdQuery.error = null;
        state.createdQuery.status = null;
      })
      .addCase(createQuerySet.fulfilled, (state) => {
        state.createdQuery.isLoading = false;
        state.createdQuery.error = null;
        state.createdQuery.status = "success";
      })
      .addCase(createQuerySet.rejected, (state, action) => {
        state.createdQuery.isLoading = false;
        state.createdQuery.status = null;
        state.createdQuery.error = action.error;
      })

      .addCase(updateQuerySet.pending, (state) => {
        state.updatedQuery.isLoading = true;
        state.updatedQuery.error = null;
      })
      .addCase(updateQuerySet.fulfilled, (state) => {
        state.updatedQuery.isLoading = false;
        state.updatedQuery.status = "success";
      })
      .addCase(updateQuerySet.rejected, (state, action) => {
        state.updatedQuery.isLoading = false;
        state.updatedQuery.error = action.error;
      })
  },
});

export default querySlice.reducer;
