import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCaseRunningOperations } from "../../redux/actions/caseActions";
import { Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function CaseRunningJobs({ caseId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getCaseRunningOperations(caseId));
    }, 7000);
    return () => clearInterval(interval);
  }, [caseId]);

  const { runningOperationList } = useSelector((state) => state.cases);
  if (!runningOperationList?.data || runningOperationList.data.length === 0) {
    return <></>;
  }
  const width = 100.0 / runningOperationList.data.length;
  return (
    <Grid container className="running-jobs">
      <Grid item xs={12}>
        {runningOperationList.data.map((op) => (
          <Box
            sx={{
              float: "left",
              margin: "0 10px",
              width: `calc(${width}% - 20px)`,
              whiteSpace: "nowrap",
            }}
          >
            <span className="action">{op.action}(<span className="status">{op.status}</span>)</span>
            {/* <ProgressBar
              now={op.percentProgress}
              label={`${op.percentProgress}%`}
            /> */}
            <LinearProgress />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
