import React, { useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { styled } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";
import Header from "./Header";
import SideNav from "./SideNav";
import AppRoute from "../../navigation/AppRoute";
import { DRAWER_WIDTH } from "../../constants/theme";
import AppBreadcrumbs from "./Breadcrumbs";
import { useSelector } from "react-redux";
import { DeniedNav } from "../pages/Denied";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Layout() {
  const [open, setOpen] = useState(true);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const currentUser = useSelector((state) => ({
    name: state.user?.profile?.name,
    email: state.user?.profile?.email,
    accessibility: state.user?.profile?.accessibility,
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header handleDrawerOpen={handleDrawerOpen} open={open} />
      <SideNav handleDrawerClose={handleDrawerClose} open={open} />

      <Main open={open}>
        <DrawerHeader />
        {currentUser?.accessibility === 403 ? (
          <DeniedNav />
        ) : (
          <>
            <AppBreadcrumbs />
            <AuthenticatedTemplate>
              <AppRoute />
            </AuthenticatedTemplate>
          </>
        )}
        <UnauthenticatedTemplate>
          <h3>Please Log in</h3>
        </UnauthenticatedTemplate>
      </Main>
    </Box>
  );
}
