import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import axios from "../../../../utils/axios";
import api from "../../../../utils/api";
import { fetchRoles } from "../../../../redux/actions/settings/settingsActions";
import AuthorizeGuard from "../../../common/AuthorizeGuard";
import CustomDrawer from "../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const RolesForm = ({ toggleDrawer, isDrawerOpen, currentRole, isAdd }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isDrawerOpen);
  const [name, setName] = useState(null);

  useEffect(() => {
    setOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    if (currentRole) {
      setName(currentRole.name);
    } else {
      setName(null);
    }
  }, [currentRole]);

  const handleRole = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = { name };

    if (isAdd) {
      axios
        .post(api.role.create(), formData)
        .then((res) => {
          swal("Success!", `Role created successfully!`, "success").then(() => {
            dispatch(fetchRoles());
            setLoading(false);
            toggleDrawer(e, false);
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      axios
        .patch(api.role.update(currentRole.id), formData)
        .then((res) => {
          swal("Success!", `Role updated successfully!`, "success").then(() => {
            dispatch(fetchRoles());
            setLoading(false);
            toggleDrawer(e, false);
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <CustomDrawer
      className={"add-edit-role-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={loading}
      title={isAdd ? "Add Role" : "Edit Role"}
      wrapWithForm={true}
      onFormSubmit={handleRole}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Name
          </Typography>
          <TextField
            size="small"
            required
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <AuthorizeGuard module={"USERS"} action={isAdd ? "Add" : "Edit"}>
          <Button disabled={loading} type="submit" variant="contained">
            Submit
          </Button>
        </AuthorizeGuard>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default RolesForm;
