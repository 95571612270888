import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sortDataByCreatedDateTime } from "../../../utils/sorting";
import api from "../../../utils/api";
import {
  FETCH_ROLES,
  FETCH_USERS,
  FETCH_SETTINGS,
  FETCH_AUDITLOGS,
  FETCH_AUDIT_LOG_EVENTS,
  FETCH_FILTERED_AUDIT_LOGS,
  FETCH_ROLES_PERMISSIONS
} from "../../actionTypes/settingsTypes";

export const fetchRoles = createAsyncThunk(FETCH_ROLES, async () => {
  const response = await axios.get(api.role.get());
  return sortDataByCreatedDateTime(response.data);
});

export const fetchUsers = createAsyncThunk(FETCH_USERS, async () => {
  const response = await axios.get(api.user.get());
  return sortDataByCreatedDateTime(response.data);
});

export const fetchSettings = createAsyncThunk(FETCH_SETTINGS, async () => {
  const response = await axios.get(api.settings.get());
  return sortDataByCreatedDateTime(response.data);
});

export const fetchAuditLogs = createAsyncThunk(FETCH_AUDITLOGS, async (data) => {
  const response = await axios.get(api.surveillance.get(), data);
  return sortDataByCreatedDateTime(response.data);
});

export const fetchAuditLogEvents = createAsyncThunk(FETCH_AUDIT_LOG_EVENTS, async () => {
  const response = await axios.get(api.surveillance.getLogEvents());
  return response.data;
});

export const fetchFilteredAuditLogs = createAsyncThunk(FETCH_FILTERED_AUDIT_LOGS, async (data) => {
  const response = await axios.post(api.surveillance.search(), data);
  return response.data;
});

export const fetchRolesPermissions = createAsyncThunk(FETCH_ROLES_PERMISSIONS, async (roleId) => {
  const response = await axios.get(api.role.permissions(roleId));
  return response.data;
});
