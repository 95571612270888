import { format } from "date-fns";
import { CUSTOM } from "../../../constants/table";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

export default function (onClickEditEvent, onClickDeleteEvent) {
  const usersTableHeadCells = [
    {
      id: "id",
      label: "ID",
      tableCellType: CUSTOM,
      fn: (cell) => (
        <div className="guid-cell" title={cell["id"]}>
          {cell["id"]}
        </div>
      ),
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    // {
    //   id: "phone",
    //   label: "Phone",
    // },
    {
      id: "roles",
      label: "Roles",
      tableCellType: CUSTOM,
      fn: (cell) =>
        cell["roles"] && cell["roles"].map((x) => x.name).join(", "),
    },
    {
      id: "createdTime",
      tableCellType: CUSTOM,
      fn: (cell) => format(new Date(cell["createdTime"]), "dd MMM yyyy"),
      label: "Created",
    },
    {
      id: "isActive",
      tableCellType: CUSTOM,
      fn: (cell) =>
        !cell["verifiedByLogin"]
          ? "Not verified"
          : cell["isActive"]
          ? "Active"
          : "Inactive",
      label: "Status",
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "left",
      fn: (cell) => (
        <>
          <AuthorizeGuard module={"USERS"} action={"Edit"}>
            <IconButton
              size="small"
              onClick={(e) => onClickEditEvent(e, cell["id"])}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </AuthorizeGuard>
          <AuthorizeGuard module={"USERS"} action={"Delete"}>
            <IconButton
              size="small"
              onClick={(e) => onClickDeleteEvent(e, cell["id"])}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </AuthorizeGuard>
        </>
      ),
    },
  ];
  return usersTableHeadCells;
}
