import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Grid, Button, Typography, TextField, Select, MenuItem } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { LoadingButton } from "@mui/lab";
import {
  updateSubjectRights,
  fetchSubjectRights,
  fetchSubjectRightsById
} from "../../../../redux/actions/subjectRightsActions";
import swal from "sweetalert";
import SubjectRightsRequestTypes from "../dataSources/SubjectRightsRequestTypes"; 
import SRDataSubjectType from "../dataSources/SRDataSubjectType"; 
import SubjectRightsRegulations from "../dataSources/SubjectRightsRegulations"; 
import SubjectRightsResidency from "../dataSources/SubjectRightsResidency"; 
import CustomDrawer from "../../../composite/CustomDrawer";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const SubjectRightsForm = ({
  toggleDrawer,
  isSubjectRightsFormDrawerOpen,
  createdSubjectRightsLoading,
  isEdit = false,
  subjectRightsDetails,
}) => {
  const params = useParams();
  const dispatch = useDispatch();

  const { srId } = params;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isSubjectRightsFormDrawerOpen);
  const [subjectRightsRequestType, setSubjectRightsRequestType] = useState("");
  const [SRdataSubjectType, setSRDataSubjectType] = useState("");
  const [subjectRightsContentQuery, setSubjectRightsContentQuery] = useState(subjectRightsDetails?.contentQuery);
  const [subjectRightsName, setSubjectRightsName] = useState(subjectRightsDetails?.displayName);
  const [subjectRightsEmail, setSubjectRightsEmail] = useState(subjectRightsDetails?.email == null ? " " : subjectRightsDetails?.email);
  const [description, setDescription] = useState(subjectRightsDetails?.description == null ? " " : subjectRightsDetails?.description);
  const [subjectRightsRegulation, setSubjectRightsRegulations] = useState(subjectRightsDetails?.regulations);
  const [subjectRightsResidency, setSubjectRightsResidency] = useState("Other / Not specified");


  useEffect(() => {
    setOpen(isSubjectRightsFormDrawerOpen);
  }, [isSubjectRightsFormDrawerOpen]);

  useEffect(() => {
    setSubjectRightsRequestType("");
    setSRDataSubjectType("");
    setSubjectRightsName(subjectRightsDetails?.displayName);
    setSubjectRightsEmail(subjectRightsDetails?.email == null ? "" : subjectRightsDetails?.email);
    setDescription(subjectRightsDetails?.description == null ? "" : subjectRightsDetails?.description);
    setSubjectRightsContentQuery(subjectRightsDetails?.contentQuery);
    setSubjectRightsRegulations(subjectRightsDetails?.regulations);
    setSubjectRightsResidency("Other / Not specified");
  }, [subjectRightsDetails]);


  const handleSubjectRightsRequestTypeChange = (e) => {
    setSubjectRightsRequestType(e.target.value);
  };
  const handleSRDataSubjectTypeChange = (e) => {
    setSRDataSubjectType(e.target.value);
  };
  const handleSubjectRightsContentQueryChange = (e) => {
    setSubjectRightsContentQuery(e.target.value);
  };

  const handleSubjectRightsNameChange = (e) => {
    setSubjectRightsName(e.target.value);
  };

  const handleSubjectRightsEmailChange = (e) => {
    setSubjectRightsEmail(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleSubjectRightsResidencyChange = (e) => {
    setSubjectRightsResidency(e.target.value);
  };

  const { regulations } = useSelector((state) => state.ui);
  const handleSubjectRightsRegulations = (selectedRegulation) => {
    var regulations = [];
    selectedRegulation.forEach((element) => {
      regulations.push(element.value);
    });
    setSubjectRightsRegulations(regulations);
  };


  const handleSubjectRightsForm = (e) => {
    e.preventDefault();
    setLoading(true);
    var dataS = new Object();
    dataS.FirstName = subjectRightsName;
    dataS.LastName = subjectRightsName;
    dataS.Email = subjectRightsEmail;
    dataS.Residency = subjectRightsResidency;

    var formData = {};
    formData.Type = subjectRightsRequestType;
    formData.dataSubjectType = SRdataSubjectType;
    formData.ContentQuery = subjectRightsContentQuery == null ? "Test" : subjectRightsContentQuery;
    formData.displayName = subjectRightsName;
    formData.email = subjectRightsEmail;
    formData.description = description;
    formData.regulations = subjectRightsRegulation;
    formData.ExternalId = "abbd9854-9c1b-42f4-9753-1d4ae0840f38";
    formData.approvers = [];
    formData.request = "post";
    formData.dataSubject = dataS;
    if (isEdit) {
      dispatch(updateSubjectRights({ srId, formData })).then((res) => {
        if (res.hasOwnProperty("error")) {
          setLoading(false);
        } else {
          swal("Success!", `Subject Rights updated successfully!`, "success").then(() => {
            dispatch(fetchSubjectRightsById(srId));
            setLoading(false);
            toggleDrawer(e, false);
          });
        }
      });
    } else {
      axiosInstance
        .post(api.subjectRights.create(), formData)
        .then((res) => {
          swal("Success!", `Subject Rights created successfully!`, "success").then(
            (res) => {
              dispatch(fetchSubjectRights());
              setLoading(false);
              setSubjectRightsRequestType("");
              setSRDataSubjectType("");
              setSubjectRightsContentQuery("");
              setSubjectRightsName(null);
              setSubjectRightsEmail(null);
              setDescription(null);
              setSubjectRightsRegulations(null);
              setSubjectRightsResidency("Other / Not specified");
              toggleDrawer(e, false);
            }
          );
        })
        .catch((error) => {
          setLoading(false);
          setSubjectRightsRequestType("");
          setSRDataSubjectType("");
          setSubjectRightsContentQuery("");
          setSubjectRightsName(null);
          setSubjectRightsEmail(null);
          setDescription(null);
          setSubjectRightsRegulations(null);
          setSubjectRightsResidency("Other / Not specified");
        });
    }
  };

  const SubjectRightsResidencySelect = () => {
    var options = SubjectRightsResidency.map((step, index) => ({
      name: step.name,
      value: step.value
    }));

    return (
      <Select id="ddSubjectRightsResidency" defaultValue=""  value={subjectRightsResidency}  onChange={handleSubjectRightsResidencyChange} style={{ width: "50%" }}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    );
  };
  const SRDataSubjectTypeSelect = () => {
    var options = SRDataSubjectType.map((step, index) => ({
      name: step.name,
      value: step.value
    }));

    return (
      <Select id="ddDataSubjectType"  defaultValue=""  value={SRdataSubjectType}  onChange={handleSRDataSubjectTypeChange} style={{ width: "50%" }}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const SubjectRightsRegulationsSelect = () => {
      var options = SubjectRightsRegulations.map((step, index) => ({
        name: step.name,
        id: step.id,
        value: step.value,
      }));
      return (
        <Multiselect
          className="list-Regulations"
          placeholder="Select Regulations"
          avoidHighlightFirstOption={true}
          options={options}
          onSelect={handleSubjectRightsRegulations}
          onRemove={handleSubjectRightsRegulations}
          displayValue="name"
          selectedValues={options.filter((item) =>
            SubjectRightsRegulations.includes(item.value)
          )}
        />
      );
    };

    const SubjectRightsRequestTypeSelect = () => {
      var options = SubjectRightsRequestTypes.map((step, index) => ({
        name: step.name,
        value: step.value
      }));
  
      return (
        <Select id="ddSubjectRightsRequestType" defaultValue=""  value={subjectRightsRequestType}  onChange={handleSubjectRightsRequestTypeChange} style={{ width: "50%" }}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      );
    };
  return ( isEdit ? (
      <CustomDrawer
        className={"add-subjectRights-drawer"}
        isDrawerOpen={open}
        toggleDrawer={toggleDrawer}
        loading={loading}
        title={"Edit Subject Rights"}
        wrapWithForm={true}
        onFormSubmit={handleSubjectRightsForm}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2">Name</Typography>
            <TextField
              size="small"
              required
              id="subjectRights-Name"
              variant="outlined"
              value={subjectRightsName}
              onChange={handleSubjectRightsNameChange}
              style={{ width: "50%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Description (Optional)</Typography>
            <TextField
              size="small"
              id="subjectRights-Description"
              variant="outlined"
              value={description}
              onChange={handleDescriptionChange}
              style={{ width: "100%" }}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
        <CustomDrawerFooter>
          <LoadingButton
            disabled={loading}
            type="submit"
            loading={createdSubjectRightsLoading}
            variant="contained"
          >
            Submit
          </LoadingButton>
          <Button disabled={loading} variant="outlined" onClick={toggleDrawer}>
            Cancel
          </Button>
        </CustomDrawerFooter>
      </CustomDrawer>
    )
  :
  (
  <CustomDrawer
    className={"add-subjectRights-drawer"}
    isDrawerOpen={open}
    toggleDrawer={toggleDrawer}
    loading={loading}
    title={"Create new Subject Rights"}
    subtitle={ " Provide basic information about this Subject Rights and a description"}
    wrapWithForm={true}
    onFormSubmit={handleSubjectRightsForm}
  >
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">Data Request templates</Typography>
        <SubjectRightsRequestTypeSelect />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">How is this person related to your organization?</Typography>
        <SRDataSubjectTypeSelect />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Search for a data subject</Typography>
        <TextField
          size="small"
          required
          id="subjectRights-contentQuery"
          variant="outlined"
          value={subjectRightsContentQuery}
          onChange={handleSubjectRightsContentQueryChange}
          style={{ width: "50%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Residency</Typography>
        <SubjectRightsResidencySelect />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Regulations</Typography>
        {SubjectRightsRegulationsSelect()}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Name</Typography>
        <TextField
          size="small"
          required
          id="subjectRights-name"
          variant="outlined"
          value={subjectRightsName}
          onChange={handleSubjectRightsNameChange}
          style={{ width: "50%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Email</Typography>
        <TextField
          size="small"
          id="subjectRights-email"
          variant="outlined"
          value={subjectRightsEmail}
          onChange={handleSubjectRightsEmailChange}
          style={{ width: "50%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Description (Optional)</Typography>
        <TextField
          size="small"
          id="subjectRightsDescription"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
          style={{ width: "100%" }}
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
    <CustomDrawerFooter>
      <LoadingButton
        disabled={loading}
        type="submit"
        loading={createdSubjectRightsLoading}
        variant="contained"
      >
        Submit
      </LoadingButton>
      <Button disabled={loading} variant="outlined" onClick={toggleDrawer}>
        Cancel
      </Button>
    </CustomDrawerFooter>
  </CustomDrawer>))
};

export default SubjectRightsForm;
