import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import { Route, Routes } from "react-router-dom";
import "./app.css";
import { config } from "./config";
import {
  setUserAccessToken,
  setUserProfile,
} from "./redux/reducers/userReducer";
import Layout from "./components/common/Layout";
import { ACCESS_TOKEN } from "./constants/localStorageKeys";
import { resolveUser } from "./redux/actions/userActions";
import DownloadApp from "./components/pages/DownloadApp";
import axiosInstance from "./utils/axios";
import api from "./utils/api";
import swal from "sweetalert";

function App() {
  const dispatch = useDispatch();
  const { instance, inProgress, accounts } = useMsal();
  const [userAccount, setUserAccount] = useState(null);

  useEffect(() => {
    if (!userAccount && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: config.scopes,
        account: accounts[0],
      };
      // localStorage.setItem(HOME_ACCOUNT_ID, accounts[0]?.homeAccountId);

      // Acquire an access token silently without prompting the user

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          // Decode the access token to extract user information

          const decodedAccessToken = jwt_decode(accessToken);
          // Dispatch actions to store the user access token and profile in Redux

          dispatch(setUserAccessToken(accessToken));
          localStorage.setItem(ACCESS_TOKEN, accessToken);

          setUserAccount(decodedAccessToken);
          //call users resolve api
          dispatch(resolveUser());
          axiosInstance
            .post(api.me.resolveUser())
            .then((res) => {
              dispatch(
                setUserProfile({
                  userId: decodedAccessToken.oid,
                  name: decodedAccessToken.name,
                  email:
                    decodedAccessToken.upn || decodedAccessToken.unique_name,
                  ...res.data,
                  accessibility: 200
                })
              );
            })
            .catch((err) => {
              if (err?.response?.status === 403) {
                dispatch(
                  setUserProfile({
                    userId: decodedAccessToken.oid,
                    name: decodedAccessToken.name,
                    email:
                      decodedAccessToken.upn || decodedAccessToken.unique_name,
                    accessibility: 403,
                  })
                );
              }
            });
        })
        .catch((error) => {
          // If an interaction is required, acquire the token through a popup window

          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                let accessToken = accessTokenResponse.accessToken;
              })
              .catch(function (error) {
                console.log(error);
              });
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, userAccount]);

  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<Layout />} />
        <Route path="/downloads" element={<DownloadApp />} />
      </Routes>
    </div>
  );
}

export default App;
