import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InitialHoldsForm from "./InitialHoldsForm";
import holdsFormValidate from "./holdsFormValidate";
import {
  fetchTenantUsers,
  fetchTenantSites,
} from "../../../../../redux/actions/tenantActions";
import { createHold } from "../../../../../redux/actions/holdActions";
import { HOLDS_FORM } from "../../../../../constants/form";
import ConditionForm from "../../reviewSets/querySet/form/conditionForm";

const HoldsForm = ({ handleSubmit, invalid }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const conditionDataRef = useRef();
  const { caseId } = params;
  const steps = [
    {
      label: "Name and description",
    },
    {
      label: "Query",
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchTenantUsers());
    dispatch(fetchTenantSites());
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleHold = async (values) => {
    if (activeStep === steps.length) {
      setLoading(true);
      var data = {};
      const conditionData = await conditionDataRef.current.getData();
      const conditions = conditionData.jsonObject;
      data.displayName = values.displayName;
      data.description = values.description;
      data.conditions = conditions.conditions;
      dispatch(createHold({ caseId, data })).then((res) => {
        if (res.hasOwnProperty("error")) {
          setLoading(false);
        } else {
          swal("Success!", `Legal hold created successfully!`, "success").then(() => {
            setLoading(false);
            navigate(`/cases/${caseId}/holds/`);
          });
        }
      });
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleHold)}>
        <Grid container sx={{ paddingBottom: "15px" }}>
          <Typography variant="h4">
            <Link to={`/cases/${caseId}?tab=4`}>
              <Button size="small">
                <ArrowBackIosIcon />
              </Button>
            </Link>
            New Hold
          </Typography>
        </Grid>
        <Divider sx={{ marginBottom: "25px" }} />
        <Grid container>
          <Grid item xs={3}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 5 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={9}>
            <Box hidden={activeStep === 0 ? false : true}>
              <InitialHoldsForm />
            </Box>
            <Box hidden={activeStep === 1 ? false : true}>
              <ConditionForm ref={conditionDataRef} />
            </Box>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress size={60} />
              </Box>
            ) : (
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={invalid}
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default reduxForm({
  form: HOLDS_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate: holdsFormValidate,
  shouldError: () => true,
})(HoldsForm);
