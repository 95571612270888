import React from "react";
import { Switch } from "@mui/material";

export default function CustomSwitch({
  input,
  label,
  meta,
  onChange,
  ...rest
}) {
  const handleChange = (event) => {
    const value = event.target.checked;
    input.onChange(value);
    onChange && onChange(value);
  };
  return (
    <Switch checked={input.value || false} onChange={handleChange} {...rest} />
  );
}
