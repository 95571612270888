import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Typography,
} from "@mui/material";
import swal from "sweetalert";
import axios from "../../../../utils/axios";
import api from "../../../../utils/api";
import {
  fetchRoles,
  fetchUsers,
} from "../../../../redux/actions/settings/settingsActions";
import { fetchTenantUsers } from "../../../../redux/actions/tenantActions";
import CustomDrawer from "../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserForm = ({ toggleUserDrawer, isUserDrawerOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isUserDrawerOpen);
  const [selectedData, setSelectedData] = useState({});
  const [selected, setSelected] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [role, setRole] = useState([]);

  useEffect(() => {
    setOpen(isUserDrawerOpen);
  }, [isUserDrawerOpen]);

  useEffect(() => {
    dispatch(fetchTenantUsers());
    dispatch(fetchRoles());
  }, []);

  const { data } = useSelector((state) => state.tenant.tenantUsersList);
  const { roleList } = useSelector((state) => state.settings);

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRole(typeof value === "string" ? value.split(",") : value);
    var selectedRole = roleList.data
      .map((item) => ({
        id: item.id,
        name: item.name,
      }))
      .filter((item) => {
        return event.target.value.includes(item.name);
      });
    setRolesData(selectedRole.map((item) => item.id));
  };
  const handleChange = (event) => {
    const selectedItem = data.find((item) => item.id === event.target.value);
    setSelected(event.target.value);
    setSelectedData(selectedItem);
  };
  const handleUser = (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = {};
    formData.userId = selectedData.id;
    formData.userName = selectedData.displayName;
    formData.email = selectedData.mail;
    formData.phone = selectedData.mobilePhone;
    formData.roles = rolesData;
    axios
      .post(api.user.add(), formData)
      .then((res) => {
        swal("Success!", `User created successfully!`, "success").then(() => {
          dispatch(fetchUsers());
          setLoading(false);
          toggleUserDrawer(e, false);
          setRole([]);
          setSelected([]);
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <CustomDrawer
      className={"add-user-drawer"}
      isDrawerOpen={isUserDrawerOpen}
      toggleDrawer={toggleUserDrawer}
      loading={loading}
      title={"Add User"}
      wrapWithForm={true}
      onFormSubmit={handleUser}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Select User
          </Typography>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <Select
              size="small"
              required
              value={selected}
              onChange={handleChange}
            >
              {data.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {`${item.displayName} (${item.mail})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Select Role
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              required
              size="small"
              id="demo-multiple-chip"
              multiple
              value={role}
              onChange={handleRoleChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {roleList.data.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={loading} type="submit" variant="contained">
          Submit
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleUserDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default UserForm;
