import { createSlice } from "@reduxjs/toolkit";
import {
  fetchHolds,
  fetchHoldDetails,
  fetchUserSourcesByHold,
  fetchSiteSourcesByHold,
  addUserSource,
  addSiteSource,
} from "../actions/holdActions";

const holdsSlice = createSlice({
  name: "holds",
  initialState: {
    holdList: {
      isLoading: false,
      data: [],
      error: null,
    },
    currentHold: {
      isLoading: false,
      details: {},
      error: null,
    },
    userSources: {
      isLoading: false,
      data: [],
      error: null,
    },
    siteSources: {
      isLoading: false,
      data: [],
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolds.pending, (state) => {
        state.holdList = { ...state.holdList, isLoading: true, error: null };
      })
      .addCase(fetchHolds.fulfilled, (state, { payload }) => {
        state.holdList = { ...state.holdList, isLoading: false, data: payload };
      })
      .addCase(fetchHolds.rejected, (state, { payload }) => {
        state.holdList = {
          ...state.holdList,
          isLoading: false,
          error: payload,
        };
      })
      .addCase(fetchHoldDetails.pending, (state) => {
        state.currentHold = {
          ...state.currentHold,
          isLoading: true,
          error: null,
        };
      })
      .addCase(fetchHoldDetails.fulfilled, (state, { payload }) => {
        state.currentHold = {
          ...state.currentHold,
          isLoading: false,
          details: payload,
        };
      })
      .addCase(fetchHoldDetails.rejected, (state, { payload }) => {
        state.currentHold = {
          ...state.currentHold,
          isLoading: false,
          error: payload,
        };
      })
      .addCase(fetchUserSourcesByHold.pending, (state) => {
        state.userSources = {
          ...state.userSources,
          isLoading: true,
          error: null,
        };
      })
      .addCase(fetchUserSourcesByHold.fulfilled, (state, { payload }) => {
        state.userSources = {
          ...state.userSources,
          isLoading: false,
          data: payload,
        };
      })
      .addCase(fetchUserSourcesByHold.rejected, (state, { payload }) => {
        state.userSources = {
          ...state.userSources,
          isLoading: false,
          error: payload,
        };
      })
      .addCase(fetchSiteSourcesByHold.pending, (state) => {
        state.siteSources = {
          ...state.siteSources,
          isLoading: true,
          error: null,
        };
      })
      .addCase(fetchSiteSourcesByHold.fulfilled, (state, { payload }) => {
        state.siteSources = {
          ...state.siteSources,
          isLoading: false,
          data: payload,
        };
      })
      .addCase(fetchSiteSourcesByHold.rejected, (state, { payload }) => {
        state.siteSources = {
          ...state.siteSources,
          isLoading: false,
          error: payload,
        };
      })
      .addCase(addUserSource.pending, (state, { error }) => {
        state.userSources = {
          ...state.userSources,
          isLoading: true,
          error: null,
        };
      })
      .addCase(addUserSource.fulfilled, (state, { error }) => {
        state.userSources = {
          ...state.userSources,
          isLoading: false,
        };
      })
      .addCase(addUserSource.rejected, (state, { error }) => {
        state.userSources = {
          ...state.userSources,
          isLoading: false,
          error: error,
        };
      })
      .addCase(addSiteSource.pending, (state, { error }) => {
        state.siteSources = {
          ...state.siteSources,
          isLoading: true,
          error: null,
        };
      })
      .addCase(addSiteSource.fulfilled, (state, { error }) => {
        state.siteSources = {
          ...state.siteSources,
          isLoading: false,
        };
      })
      .addCase(addSiteSource.rejected, (state, { error }) => {
        state.siteSources = {
          ...state.siteSources,
          isLoading: false,
          error: error,
        };
      });
  },
});

export default holdsSlice.reducer;
