export const FETCH_HOLDS = "holds/fetchHolds";
export const CREATE_HOLD = "holds/createHold";
export const UPDATE_HOLD = "holds/updateHold"
export const DELETE_HOLD = "holds/deleteHold"
export const FETCH_HOLD_DETAILS = "holds/fetchHoldDetails";
export const FETCH_USER_SOURCES_By_HOLD = "holds/fetchUserSourcesByHold"
export const FETCH_SITE_SOURCES_By_HOLD = "holds/fetchSiteSourcesByHold"
export const ADD_USER_SOURCE = "holds/addUserSource"
export const ADD_SITE_SOURCE = "holds/addSiteSource"
export const DELETE_USER_SOURCE = "holds/deleteUserSource"
export const DELETE_SITE_SOURCE = "holds/deleteSiteSource"
