import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Button, Typography, TextField } from "@mui/material";
import swal from "sweetalert";
import ConditionForm from "../../../reviewSets/querySet/form/conditionForm";
import { fetchCollectionDetails } from "../../../../../../redux/actions/collections/collectionsActions";
import axiosInstance from "../../../../../../utils/axios";
import api from "../../../../../../utils/api";
import { useState } from "react";
import { isObjectEmptyOrNull } from "../../../../../../utils/validation";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";

const SummaryForm = ({
  toggleSummaryDrawer,
  summaryDrawerOpen = false,
  collectionDetails,
}) => {
  const conditionDataRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();

  const { caseId, collectionId } = params;

  const [loading, setLoading] = useState(false);
  const [collectionData, setCollectionData] = useState({
    displayName: null,
    description: null,
    contentQuery: null,
    jsonObject: null,
  });

  useEffect(() => {
    if (!isObjectEmptyOrNull(collectionDetails)) {
      setCollectionData(collectionDetails);
    }
  }, [collectionDetails]);

  const handleSummaryForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const conditionData = await conditionDataRef.current.getData();
    const formData = {
      ...conditionData.jsonObject,
      displayName: collectionData.displayName,
      description: collectionData.description,
    };
    axiosInstance
      .patch(api.collections.update(caseId, collectionId), formData)
      .then((res) => {
        swal("Success!", `Collection updated successfully!`, "success").then(
          () => {
            setLoading(false);
            toggleSummaryDrawer(e, false);
            dispatch(fetchCollectionDetails({ caseId, collectionId }));
          }
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <CustomDrawer
      className={"edit-collection-drawer"}
      isDrawerOpen={summaryDrawerOpen}
      toggleDrawer={toggleSummaryDrawer}
      loading={loading}
      title={"Edit Collection"}
      wrapWithForm={true}
      onFormSubmit={handleSummaryForm}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2"> Name </Typography>
          <TextField
            size="small"
            variant="outlined"
            value={collectionData.displayName}
            onChange={(e) =>
              setCollectionData({
                ...collectionData,
                displayName: e.target.value,
              })
            }
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2"> Description </Typography>
          <TextField
            size="small"
            variant="outlined"
            value={collectionData.description}
            onChange={(e) =>
              setCollectionData({
                ...collectionData,
                description: e.target.value,
              })
            }
            style={{ width: "100%" }}
            rows={5}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <ConditionForm
            jsonObject={collectionData.jsonObject}
            queryString={{ kqlString: collectionData.contentQuery }}
            ref={conditionDataRef}
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button variant="contained" type="submit">
          Submit
        </Button>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={(event) => toggleSummaryDrawer(event, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default SummaryForm;
