import React, { useRef, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";
import swal from "sweetalert";
import CustomTextField from "../../../../../composite/CustomTextField";
import CustomTextArea from "../../../../../composite/CustomTextArea";
import { HOLD_SUMMARY_FORM } from "../../../../../../constants/form";
import ConditionForm from "../../../reviewSets/querySet/form/conditionForm";
import {
  updateHold,
  fetchHoldDetails,
} from "../../../../../../redux/actions/holdActions";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";

const HoldSummaryForm = ({
  toggleSummaryDrawer,
  summaryDrawerOpen = false,
  handleSubmit,
  initialValues,
}) => {
  const conditionDataRef = useRef();
  const dispatch = useDispatch();
  const params = useParams();
  const { caseId, holdId } = params;
  const [loading, setLoading] = useState(false);

  const handleSummaryForm = async (data) => {
    setLoading(true);
    const conditionData = await conditionDataRef.current.getData();
    const formData = {
      ...conditionData.jsonObject,
      displayName: data.displayName,
      description: data.description,
    };
    dispatch(updateHold({ formData, caseId, holdId })).then((res) => {
      if (res.hasOwnProperty("error")) {
        setLoading(false);
      } else {
        swal("Success!", `Hold updated successfully!`, "success").then(() => {
          setLoading(false);
          toggleSummaryDrawer(null, false);
          dispatch(fetchHoldDetails({ caseId, holdId }));
        });
      }
    });
  };

  return (
    <CustomDrawer
      className={"edit-hold-drawer"}
      isDrawerOpen={summaryDrawerOpen}
      toggleDrawer={toggleSummaryDrawer}
      loading={loading}
      title={"Edit legal hold"}
      wrapWithForm={true}
      onFormSubmit={handleSubmit(handleSummaryForm)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="bolder">
            {" "}
            Name{" "}
          </Typography>
          <Field name="displayName" component={CustomTextField} type="text" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {" "}
            <strong> Description</strong> (Optional){" "}
          </Typography>
          <Field name="description" component={CustomTextArea} type="text" />
        </Grid>
        <Grid item xs={12}>
          <ConditionForm
            jsonObject={initialValues.jsonObject}
            queryString={{ kqlString: initialValues.contentQuery }}
            ref={conditionDataRef}
          />
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button disabled={loading} variant="contained" type="submit">
          Submit
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleSummaryDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

const mapStateToProps = (state, props) => {
  const { collectionDetails } = props;
  return {
    initialValues: {
      displayName: collectionDetails.displayName,
      description: collectionDetails.description,
      contentQuery: collectionDetails.contentQuery,
      jsonObject: collectionDetails.jsonObject,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: HOLD_SUMMARY_FORM,
  })(HoldSummaryForm)
);
