export default function () {
  const mailboxTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
      meta: {},
    },
    {
      id: "mail",
      label: "Email",
    },
  ];
  return mailboxTableHeadCells;
}
