import { createSlice } from "@reduxjs/toolkit";
import { resolveUser } from "../actions/userActions";

const initialState = {
  data: [],
};

export const userPermissionsSlice = createSlice({
  name: "userPermissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resolveUser.pending, (state, action) => {
        state.data = null;
      })
      .addCase(resolveUser.fulfilled, (state, action) => {
        state.data = action.payload;
      });
  },
});

export default userPermissionsSlice.reducer;
