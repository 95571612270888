import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

class Denied extends Component {
  constructor(props) {
    super(props);
  }

  static displayName = Denied.name;

  render() {
    return (
      <div>
        <h3 className="main-title ml-4">Access Denied</h3>
        <hr className="my-4 ml-4" />

        <div className="row m-5">
          You are NOT permitted to this application. <br/>
          Please sign out and sign in with this tenant ({window.APP_CONFIG.TENANT}) account.
        </div>
      </div>
    );
  }
}

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();

  return <Component {...props} navigate={navigate} />;
};

export const DeniedNav = withNavigate(Denied);
