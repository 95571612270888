import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand } from "@fortawesome/free-solid-svg-icons";
import { CUSTOM } from "../../../../constants/table";
import DataSourceCell from "./DataSourceCell";
import OptionMenuButtons from "../../../common/OptionMenuButtons";

export default function (onClickDeleteEvent, onClickHoldEvent, onClickStatusEvent) {
  const custodiansTableHeadCells = [
    {
      id: "id",
      label: "ID",
      tableCellType: CUSTOM,
      fn: (cell) => (
        <div className="guid-cell" title={cell["id"]}>
          {cell["id"]}
        </div>
      ),
    },
    {
      id: "displayName",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "holdStatus",
      label: "Hold?",
      style: { paddingLeft: 0, paddingRight: 0 },
    },
    {
      id: "dataSources",
      tableCellType: CUSTOM,
      fn: (cell) => <DataSourceCell cell={cell} />,
      label: "Data Sources",
      style: { padding: "0px" },
      sortable: false,
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "center",
      sortable: false,
      style: { paddingLeft: 0, paddingRight: 0 },
      fn: (cell) => (
        <OptionMenuButtons
          text={"Options"}
          options={[
            {
              name: "ApplyHold",
              text: "Apply Hold",
              icon: <FontAwesomeIcon icon={faHand} />,
              onClick: (event, index) => {
                onClickHoldEvent(event, cell.id, true);
              },
            },
            {
              name: "RemoveHold",
              text: "Remove Hold",
              icon: (
                <>
                  <div id="cross"></div>
                  <FontAwesomeIcon icon={faHand} />
                </>
              ),
              onClick: (event, index) => {
                onClickHoldEvent(event, cell.id, false);
              },
            },
            {
              name: "ReleaseOrActivate",
              text: cell.status === "active" ? "Release" : "Activate",
              icon:
                cell.status === "active" ? (
                  <LockOpenIcon fontSize="small" />
                ) : (
                  <LockIcon fontSize="small" />
                ),
              onClick: (event, index) => {
                onClickStatusEvent(
                  event,
                  cell["id"],
                  cell.status === "active" ? "release" : "activate"
                );
              },
            },
            {
              name: "Remove",
              text: "Remove",
              icon: <DeleteIcon fontSize="small" />,
              onClick: (event, index) => {
                onClickDeleteEvent(event, cell["id"]);
              },
            },
          ]}
        />
      ),
    },
  ];
  return custodiansTableHeadCells;
}
