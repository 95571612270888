import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../composite/table/CustomTable";
import collectionExportsTableHeadCells from "./collectionExportsTableHeadCells";
import { useNavigate } from "react-router-dom";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import { useLocation } from "react-router-dom";
import { fetchCollectionExports } from "../../../redux/actions/collectionActions";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const CollectionExports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchCollectionExports());
  }, []);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "exports-breadcumbs",
      links: [{ url: "/dashboard", text: "Dashboard" }, { text: "Exports" }],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, []);

  const { exports } = useSelector((state) => state.collections);
  const handleView = (e, id) => {
    navigate(`/exported-collection-files?id=${id}`);
  };
  return (
    <CustomTable
      showSerialNo={true}
      rows={exports.data?.length ? exports.data : []}
      headCells={collectionExportsTableHeadCells(handleView)}
      isLoading={exports.isLoading}
      isSearch={true}
    >
      <CustomTableToolbar
        title={"COLLECTION EXPORTS"}
        onRefreshEvent={() => dispatch(fetchCollectionExports())}
      />
    </CustomTable>
  );
};

export default CollectionExports;
