import axios from "axios";
import { config } from "../config";
import { ACCESS_TOKEN } from "../constants/localStorageKeys";
import swal from "sweetalert";
import { showApiError } from "./functions";
import msalInstance from "./msal";

const axiosInstance = axios.create({
  baseURL: config.apiServerUrl,
});

axiosInstance.interceptors.request.use(
  function (reqConfig) {
    const account = msalInstance.getActiveAccount();
    if (account) {
      const accessTokenRequest = {
        scopes: config.scopes,
        account: account,
      };

      return msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          if (response?.accessToken) {
            reqConfig.headers = {
              ...reqConfig.headers,
              Authorization: `Bearer ${response?.accessToken}`,
              "Content-Type": "application/json",
            };

            localStorage.setItem(ACCESS_TOKEN, response.accessToken);
          }
          return reqConfig;
        })
        .catch((error) => {
          console.log("TOKEN ERROR", error);
          if (error.toString().includes("BrowserAuthError")) {
            localStorage.clear();
            window.location = `${window.location.origin}?session=expired`;
          }
        });
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response?.status === 401) {
      swal({
        title: "Session expired",
        text: "Your session has expired, Page will be refreshed.",
        icon: "warning",
      }).then((willRefresh) => {
        window.location.reload();
      });
    }
    return response;
  },
  function (error) {
    if (error?.response?.status === 400 && error.response?.data?.message?.includes("PK_User") === true) {
      return Promise.resolve();
    }
    else if (error?.response?.status === 401) {
      swal({
        title: "Session expired",
        text: "Your session has expired, Page will be refreshed. Please allow popup in your browser if needed.",
        icon: "warning",
      }).then((willRefresh) => {
        window.location.reload();
      });
    } else {
      showApiError(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
