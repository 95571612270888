import { createSlice } from "@reduxjs/toolkit";
import { fetchExportList } from "../actions/export/exportAction";

const initialState = {
  exportList: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const exportSlice = createSlice({
  name: "export",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExportList.pending, (state) => {
        state.exportList.isLoading = true;
        state.exportList.error = null;
      })
      .addCase(fetchExportList.fulfilled, (state, action) => {
        state.exportList.isLoading = false;
        state.exportList.data = action.payload.data;
      })
      .addCase(fetchExportList.rejected, (state, action) => {
        state.exportList.isLoading = false;
        state.exportList.error = action.error;
      })
  },
});

export default exportSlice.reducer;
