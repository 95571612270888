import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import api from "../../../utils/api";

export const fetchReviewSetsContentsByQuerySetId = createAsyncThunk(
  "reviewSetsContents/fetchByReviewSetsId",
  async ({caseId,reviewSetId,querySetId}, { dispatch }) => {
    try {
      const res = await axios.get(api.querySetResult.get(caseId,reviewSetId,querySetId));
      return { data: res.data, caseId };
    } catch (error) {
      throw error;
    }
  }
);


