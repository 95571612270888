import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CustomTable from "../../composite/table/CustomTable";
import {
  fetchSubjectRights,
} from "../../../redux/actions/subjectRightsActions";
import SubjectRightsForm from "./form/SubjectRightsForm";
import subjectRightsTableHeadCells from "./SubjectRightsTableHeadCells";
import { deleteSubjectRights } from "../../../redux/actions/subjectRightsActions";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const SubjectRights = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isSubjectRightsFormDrawerOpen, setIsSubjectRightsFormDrawerOpen] = useState(false);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "subjectRights-breadcumbs",
      links: [{ url: "/dashboard", text: "Dashboard" }, { text: "SubjectRights" }],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchSubjectRights());
  }, []);

  const { subjectRightList, createdSubjectRight } = useSelector((state) => state.subjectRights);
 
  const toggleDrawer = (event, open) => {
    setIsSubjectRightsFormDrawerOpen(open);
  };

  const handleDeleteSubjectRights = (e, srId) => {
    swal({
      title: "Confirmation",
      text: `If you delete this Subject Rights Request, all data will be lost. \nDo you really want to delete this Request?`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((yes) => {
      if (!yes) return;
      dispatch(deleteSubjectRights(srId)).then((res) => {
        if (res.hasOwnProperty("error")) {
          dispatch(fetchSubjectRights());
        } else {
          swal("Success!", `Subject Rights has been deleted!`, "success").then(() => {
            dispatch(fetchSubjectRights());
          });
        }
      });
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            showSerialNo={true}
            rows={subjectRightList.data}
            headCells={subjectRightsTableHeadCells(
              handleDeleteSubjectRights
            )}
            isLoading={subjectRightList.isLoading || createdSubjectRight.isLoading}
            isSearch={true}
          >
            <CustomTableToolbar
              title={"Subject Rights"}
              onRefreshEvent={() => dispatch(fetchSubjectRights())}
              addButtonText={"Create new Subject Rights"}
              onAddEvent={(event) => toggleDrawer(event, true)}
            />
          </CustomTable>
        </Grid>
      </Grid>
      <SubjectRightsForm
        createdSubjectRightsLoading={createdSubjectRight.isLoading}
        toggleDrawer={toggleDrawer}
        isSubjectRightsFormDrawerOpen={isSubjectRightsFormDrawerOpen}
      />
    </>
  );
};

export default SubjectRights;
