import { format } from "date-fns";

export default function () {
  const collectionsTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
      tableCellType: "LINK_CELL_TYPE",
      meta: {
        link: function (collectionId, caseId) {
          return `/case/${caseId}/collection/${collectionId}`;
        },
      },
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "contentQuery",
      label: "Query Text",
    },
    {
      id: "createdBy",
      tableCellType: "CUSTOM",
      fn: (cell) => cell["createdBy"]?.displayName,
      label: "Created By",
    },
    {
      id: "createdDateTime",
      tableCellType: "CUSTOM",
      fn: (cell) => format(new Date(cell["createdDateTime"]), 'dd MMM yyyy'),
      label: "Created",
    },
    {
      id: "action",
      label: "Action",
      align: 'left'
    },
  ];
  return collectionsTableHeadCells;
}
