import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
} from "@mui/material";
import { isObjectEmptyOrNull } from "../../../utils/validation";
import { fetchFinalAttachmentById } from "../../../redux/actions/subjectRightsActions";

const SRFinalAttachment = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { srId } = params;

  const currentFinalAttachment = useSelector((state) => state.subjectRights.currentFinalAttachment);
  useEffect(() => {
    dispatch(fetchFinalAttachmentById(srId));
  }, [srId]);

  return isObjectEmptyOrNull(currentFinalAttachment) ? (
    <Grid>Loading....</Grid>
  ) : (
    <Grid></Grid>
   
  );
};

export default SRFinalAttachment;
