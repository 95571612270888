import React, {  useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
} from "@mui/material";
import { format } from "date-fns";
import CustomTable from "../../composite/table/CustomTable";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";
import { isObjectEmptyOrNull } from "../../../utils/validation";
import { fetchSRNotesById } from "../../../redux/actions/subjectRightsActions";

const SRNotes = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { srId } = params;

  const currentNote = useSelector((state) => state.subjectRights.currentNote);

  useEffect(() => {
    dispatch(fetchSRNotesById(srId));
  }, [srId]);

  const subjectRightsTableNotesHeadCells = [
    {
      id: "content",
      label: "Note",
      align: "left",
    },
    {
      id: "createdDateTime",
      tableCellType: "CUSTOM",
      fn: (cell) => format(new Date(cell["createdDateTime"]), "dd MMM yyyy"),
      label: "Created",
    },
    {
      id: "createdBy",
      label: "createdBy",
    },
  ];
  return isObjectEmptyOrNull(currentNote) ? (
    <Grid>Loading....</Grid>
  ) : (
    <Grid item xs={12}>
      <CustomTable
        showSerialNo={true}
        rows={currentNote.details}
        headCells={subjectRightsTableNotesHeadCells}
        isLoading={currentNote.isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"Subject Rights Notes"}
          onRefreshEvent={() => dispatch(fetchSRNotesById(srId))}
        />
      </CustomTable>
    </Grid>
   
  );
};

export default SRNotes;
