import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../composite/table/CustomTable";
import holdCollectionsTableHeadCells from "./holdCollectionsTableHeadCells";
import swal from "sweetalert";
import api from "../../../utils/api";
import axios from "../../../utils/axios";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import { useLocation } from "react-router-dom";
import { fetchHoldCollections } from "../../../redux/actions/collectionActions";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const HoldCollections = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchHoldCollections());
  }, []);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "holds-breadcumbs",
      links: [{ url: "/dashboard", text: "Dashboard" }, { text: "Holds" }],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, []);

  const { holds } = useSelector((state) => state.collections);

  const handleUnhold = (e, id) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to remove hold from the collection?",
      icon: "warning",
      buttons: ["No, Thanks", "Yes, Remove"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .patch(api.onpremCollections.changeHoldStatus(id), { status: false })
          .then((res) => {
            if (!res?.success && res?.errorMessage) {
              swal("Error!", res.errorMessage, "error");
            } else {
              swal(
                "Success!",
                "Collection has been removed from hold",
                "success"
              );
              dispatch(fetchHoldCollections());
            }
          });
      }
    });
  };

  const handleSearch = (e, id) => {
    window.open(`/search?id=${id}`, "_blank");
  };

  return (
    <AuthorizeGuard module={"HOLDS"} action={"View"} showDeniedPage={true}>
      <CustomTable
        showSerialNo={true}
        rows={holds.data}
        headCells={holdCollectionsTableHeadCells(handleSearch, handleUnhold)}
        isLoading={holds.isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"COLLECTIONS ON HOLD"}
          onRefreshEvent={() => dispatch(fetchHoldCollections())}
        />
      </CustomTable>
    </AuthorizeGuard>
  );
};

export default HoldCollections;
