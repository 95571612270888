import React, { useState, useEffect } from "react";
import {
  Drawer,
  CircularProgress,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomDrawer = ({
  children,
  toggleDrawer, // Function to toggle the visibility of the drawer
  isDrawerOpen, // Boolean indicating whether the drawer is open or closed
  className,
  loading = false, // Boolean indicating whether the drawer is in a loading state
  title, // Title text for the drawer header
  subtitle = null, // Subtitle text for the drawer header
  wrapWithForm = false, // Boolean indicating whether to wrap the content with a form element
  onFormSubmit, // Event handler for form submission
  showHeader = true, // Boolean indicating whether to show the drawer header
}) => {
  const [open, setOpen] = useState(isDrawerOpen);

  useEffect(() => {
    setOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  const childArray = React.Children.toArray(children);
  const customDrawerFooterIndex = childArray.findIndex(
    (x) => x.type.displayName === "CustomDrawerFooter"
  );
  const additionalChildren = childArray.filter(
    (_, index) => index !== customDrawerFooterIndex
  );
  return (
    <Drawer
      className={`custom-drawer ${className}`}
      open={isDrawerOpen}
      anchor="right"
      onClose={(event) => toggleDrawer(event, false)}
    >
      <Card className="drawer-card">
        {showHeader ? (
          <CardHeader
            className={`drawer-card-header`}
            title={title}
            subheader={subtitle}
            action={
              <IconButton
                className={"top-close-button"}
                onClick={(event) => toggleDrawer(event, false)}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        ) : (
          <CardHeader
            className={`drawer-card-header no-title`}
            action={
              <IconButton
                className={"top-close-button"}
                onClick={(event) => toggleDrawer(event, false)}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        )}
        {wrapWithForm ? (
          <form onSubmit={onFormSubmit} className="drawer-form">
            <CardContent className="drawer-card-body">
              {loading ? <CircularProgress /> : <>{additionalChildren}</>}
            </CardContent>
            {customDrawerFooterIndex >= 0 ? (
              <CardActions className="drawer-card-footer">
                {childArray[customDrawerFooterIndex]}
              </CardActions>
            ) : (
              <></>
            )}
          </form>
        ) : (
          <>
            <CardContent className="drawer-card-body">
              {loading ? <CircularProgress /> : <>{additionalChildren}</>}
            </CardContent>
            {customDrawerFooterIndex >= 0 ? (
              <CardActions className="drawer-card-footer">
                {childArray[customDrawerFooterIndex]}
              </CardActions>
            ) : (
              <></>
            )}
          </>
        )}
      </Card>
    </Drawer>
  );
};

export default CustomDrawer;
