import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTenantSites,
  fetchTenantUsers,
  fetchTenantExchangeMails,
} from "../actions/tenantActions";

const initialState = {
  tenantUsersList: {
    isLoading: false,
    data: [],
    error: null,
  },
  tenantSitesList: {
    isLoading: false,
    data: [],
    error: null,
  },
  tenantExchangeMailList: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantUsers.pending, (state) => {
        state.tenantUsersList.isLoading = true;
        state.tenantUsersList.error = null;
      })
      .addCase(fetchTenantUsers.fulfilled, (state, action) => {
        state.tenantUsersList.isLoading = false;
        state.tenantUsersList.data = action.payload;
      })
      .addCase(fetchTenantUsers.rejected, (state, action) => {
        state.tenantUsersList.isLoading = false;
        state.tenantUsersList.error = action.error.message;
      })
      .addCase(fetchTenantSites.pending, (state) => {
        state.tenantSitesList.isLoading = true;
        state.tenantSitesList.error = null;
      })
      .addCase(fetchTenantSites.fulfilled, (state, action) => {
        state.tenantSitesList.isLoading = false;
        state.tenantSitesList.data = action.payload;
      })
      .addCase(fetchTenantSites.rejected, (state, action) => {
        state.tenantSitesList.isLoading = false;
        state.tenantSitesList.error = action.error.message;
      })
      .addCase(fetchTenantExchangeMails.pending, (state) => {
        state.tenantExchangeMailList.isLoading = true;
        state.tenantExchangeMailList.error = null;
      })
      .addCase(fetchTenantExchangeMails.fulfilled, (state, action) => {
        state.tenantExchangeMailList.isLoading = false;
        state.tenantExchangeMailList.data = action.payload;
      })
      .addCase(fetchTenantExchangeMails.rejected, (state, action) => {
        state.tenantExchangeMailList.isLoading = false;
        state.tenantExchangeMailList.error = action.error.message;
      });
  },
});

export default tenantSlice.reducer;
