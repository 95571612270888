import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchReviewSetsByCaseId } from "../../../../redux/actions/reviewSets/reviewSetsAction";
import CustomTable from "../../../composite/table/CustomTable";
import reviewSetsTableHeadCells from "./reviewSetsTableHeadCells";
import AddReviewSet from "./addReviewSets";
import { fetchCollectionsByCaseId } from "../../../../redux/actions/collections/collectionsActions";
import CustomTableToolbar from "../../../composite/table/CustomTableToolbar";

const ReviewSets = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { reviewSetList } = useSelector((state) => state.reviewSets);
  const { collectionsList } = useSelector((state) => state.sourceCollections);

  useEffect(() => {
    dispatch(fetchReviewSetsByCaseId(params.caseId));
    dispatch(fetchCollectionsByCaseId(params.caseId));
  }, []);

  const isLoading = reviewSetList.isLoading;
  const reviewSetRows = Array.isArray(reviewSetList.data)
    ? reviewSetList.data.map((item) => ({
        ...item,
        parentId: params.caseId,
      }))
    : [];

  const toggleDrawer = (event, open) => {
    setIsDrawerOpen(open);
  };

  return (
    <>
      <CustomTable
        showSerialNo={true}
        rows={reviewSetRows}
        headCells={reviewSetsTableHeadCells()}
        isLoading={isLoading}
        isSearch={true}
      >
        <CustomTableToolbar
          title={"REVIEWSETS"}
          onRefreshEvent={() =>
            dispatch(fetchReviewSetsByCaseId(params.caseId))
          }
          addButtonText={"Create new reviewset"}
          onAddEvent={(e) => toggleDrawer(e, true)}
        />
      </CustomTable>

      <AddReviewSet
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        collectionsList={collectionsList}
      />
    </>
  );
};

export default ReviewSets;
