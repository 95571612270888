export default function () {
  const sitesTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
      meta: {},
    },
    {
      id: "webUrl",
      label: "Location",
    },
  ];
  return sitesTableHeadCells;
}
