import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import CustomTable from "../../../composite/table/CustomTable";
import swal from "sweetalert";
import CustomTableToolbar from "../../../composite/table/CustomTableToolbar";
import custodiansTableHeadCells from "./custodiansTableHeadCells";
import { getCaseCustodians } from "../../../../redux/actions/caseActions";
import axios from "../../../../utils/axios";
import api from "../../../../utils/api";
import AddCustodian from "./AddCustodian";

const Custodians = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { caseId } = params;
  const [isOpenCustodianDrawer, setIsOpenCustodianDrawer] = useState(false);

  useEffect(() => {
    dispatch(getCaseCustodians(caseId));
  }, [caseId]);

  const { custodians } = useSelector((state) => state.cases);

  const handleRemoveCustodian = (e, id) => {
    swal({
      title: "Confirmation",
      text: "Do you really want to delete this custodian?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        axios.delete(api.case.removeCustodian(caseId, id)).then((res) => {
          swal("Success!", `Custodian removed successfully!`, "success").then(
            () => dispatch(getCaseCustodians(caseId))
          );
        });
      }
    });
  };

  const handleHold = (e, id, hold) => {
    const data = {
      hold,
      ids: [id],
    };
    if (hold) {
      swal({
        title: "Confirmation",
        text: "Do you really want to place the custodian on hold?",
        icon: "warning",
        buttons: ["Cancel", "Apply Hold"],
        dangerMode: true,
      }).then((yes) => {
        if (yes) {
          axios
            .post(api.case.holdUnholdCustodians(caseId), data)
            .then((res) => {
              swal(
                "Apply hold",
                `Custodian placed on hold.\nIt may take few minutes to finish the process.`,
                "success"
              ).then(() => dispatch(getCaseCustodians(caseId)));
            });
        }
      });
    } else {
      swal({
        title: "Confirmation",
        text: "Do you really want to remove hold from the custodian?",
        icon: "warning",
        buttons: ["Cancel", "Remove Hold"],
        dangerMode: true,
      }).then((yes) => {
        if (yes) {
          axios.post(api.case.holdUnholdCustodians(caseId), data).then((res) => {
            swal(
              "Apply hold",
              `Hold removed from the custodian.\nIt may take few minutes to finish the process.`,
              "success"
            ).then(() => dispatch(getCaseCustodians(caseId)));
          });
        }
      });
    }
  };

  const handleStatus = (e, id, action) => {
    if (action === "release") {
      swal({
        title: "Confirmation",
        text: "Do you really want to release the custodian?",
        icon: "warning",
        buttons: ["Cancel", "Release"],
        dangerMode: true,
      }).then((yes) => {
        if (yes) {
          axios
            .post(api.case.releaseCustodian(caseId, id))
            .then((res) => {
              swal(
                "Apply hold",
                `Custodian released successfully.\nIt may take few minutes to finish the process.`,
                "success"
              ).then(() => dispatch(getCaseCustodians(caseId)));
            });
        }
      });
    } else {
      swal({
        title: "Confirmation",
        text: "Do you really want to re-activate the custodian?",
        icon: "warning",
        buttons: ["Cancel", "Activate"],
        dangerMode: true,
      }).then((yes) => {
        if (yes) {
          axios.post(api.case.activateCustodian(caseId, id)).then((res) => {
            swal(
              "Apply hold",
              `Custodian activated successfully.\nIt may take few minutes to finish the process.`,
              "success"
            ).then(() => dispatch(getCaseCustodians(caseId)));
          });
        }
      });
    }
  };

  const toggleDrawer = (event, open) => {
    setIsOpenCustodianDrawer(open);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            showSerialNo={true}
            rows={custodians.data}
            rowsCount={10}
            headCells={custodiansTableHeadCells(
              handleRemoveCustodian,
              handleHold,
              handleStatus
            )}
            isLoading={custodians.isLoading}
          >
            <CustomTableToolbar
              title={"CUSTODIANS"}
              onRefreshEvent={() => dispatch(getCaseCustodians(caseId))}
              addButtonText={"Add Custodian"}
              onAddEvent={(event) => toggleDrawer(event, true)}
            />
          </CustomTable>
        </Grid>
      </Grid>
      <AddCustodian
        caseId={caseId}
        isDrawerOpen={isOpenCustodianDrawer}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};

export default Custodians;
