import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Button, Typography, TextField } from "@mui/material";
import swal from "sweetalert";
import axiosInstance from "../../../../../../utils/axios";
import api from "../../../../../../utils/api";
import { useState } from "react";
import CustomDrawer from "../../../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../../../composite/table/CustomDrawerFooter";
import userTableHeadCells from "./userTableHeadCells";
import sitesTableHeadCells from "./sitesTableHeadCells";
import {
  fetchTenantSites,
  fetchTenantUsers,
} from "../../../../../../redux/actions/tenantActions";
import CustomTable from "../../../../../composite/table/CustomTable";
import CustomTableToolbar from "../../../../../composite/table/CustomTableToolbar";
import { fetchCollectionDetails } from "../../../../../../redux/actions/collections/collectionsActions";

const AdditionalSourcesForm = ({
  toggleDrawer,
  isDrawerOpen,
  additionalSources,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { caseId, collectionId } = params;
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  useEffect(() => {
    dispatch(fetchTenantUsers());
    dispatch(fetchTenantSites());
  }, []);

  const { tenantUsersList, tenantSitesList } = useSelector(
    (state) => state.tenant
  );

  const handleForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    let additionalSources = [
      ...selectedUsers.map((item) => {
        return { email: item.mail };
      }),
      ...selectedSites.map((item) => {
        return { siteUrl: item.webUrl };
      }),
    ];

    axiosInstance
      .post(
        api.collections.addAdditionalSource(caseId, collectionId),
        additionalSources
      )
      .then((res) => {
        dispatch(fetchCollectionDetails({ caseId, collectionId }));
        toggleDrawer(null, false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSelectedUserRows = (rows) => {
    setSelectedUsers(rows);
  };
  const handleSelectedSiteRows = (rows) => {
    setSelectedSites(rows);
  };
  return (
    <CustomDrawer
      className={"add-additional-sources-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={loading}
      title={"Add Additional Sources To Collection"}
      wrapWithForm={true}
      onFormSubmit={handleForm}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomTable
            className={"users-table"}
            rows={tenantUsersList.data}
            rowsCount={5000}
            headCells={userTableHeadCells()}
            isLoading={tenantUsersList.isLoading}
            checkOption={true}
            onCheckRows={handleSelectedUserRows}
            isSearch={true}
            isPagination={false}
          >
            <CustomTableToolbar title={"Select user(s)"}></CustomTableToolbar>
          </CustomTable>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            className={"sites-table"}
            rows={tenantSitesList.data}
            rowsCount={5000}
            headCells={sitesTableHeadCells()}
            isLoading={tenantSitesList.isLoading}
            checkOption={true}
            onCheckRows={handleSelectedSiteRows}
            isSearch={true}
            isPagination={false}
          >
            <CustomTableToolbar title={"Select site(s)"}></CustomTableToolbar>
          </CustomTable>
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <Button variant="contained" type="submit" disabled={loading}>
          Submit
        </Button>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={(event) => toggleDrawer(event, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default AdditionalSourcesForm;
