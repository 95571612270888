import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import CustomTable from "../../composite/table/CustomTable";
import collectionExportFilesTableHeadCells from "./collectionExportFilesTableHeadCells";
import { useSearchParams } from "react-router-dom";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import { useLocation } from "react-router-dom";
import axios from "../../../utils/axios";
import { fetchCollectionExportFiles } from "../../../redux/actions/collectionActions";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const CollectionExportFiles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const exportId = searchParams.get("id");
  useEffect(() => {
    dispatch(fetchCollectionExportFiles(exportId));
  }, [exportId]);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "export-files-breadcumbs",
      links: [
        { url: "/dashboard", text: "Dashboard" },
        { url: "/exported-collections", text: "Exports" },
        { text: "Files" },
      ],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, []);

  const { exportedFiles } = useSelector((state) => state.collections);

  const downloadExportedFile = (event, fileId, fileName) => {
    axios
      .get(`/exports/${fileId}/download`, {
        responseType: "blob",
      })
      .then((response) => {
        var url = window.URL.createObjectURL(new Blob([response.data]));
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <CustomTable
      showSerialNo={true}
      rows={exportedFiles.data.files}
      headCells={collectionExportFilesTableHeadCells(downloadExportedFile)}
      isLoading={exportedFiles.isLoading}
      isSearch={true}
    >
      <CustomTableToolbar title={"EXPORTED FILES"}>
        <Typography
          variant="h8"
          fontWeight="bold"
          style={{ marginRight: "20px", paddingTop: "5px" }}
        >
          {"COLLECTION: "}
          <small>{exportedFiles?.data?.export?.collectionName}</small> {"SEARCH: "}
          <small>{exportedFiles?.data?.searchRequest?.searchValue}</small>
        </Typography>
      </CustomTableToolbar>
    </CustomTable>
  );
};

export default CollectionExportFiles;
