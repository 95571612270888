import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../../utils/axios";
import api from "../../../../utils/api";
import CustomTable from "../../../composite/table/CustomTable";
import exportDetailTableHeadCells from "./exportDetailTableHeadCells";
import CustomDrawer from "../../../composite/CustomDrawer";
import CustomDrawerFooter from "../../../composite/table/CustomDrawerFooter";

export default function ExportDetailDrawer({
  openState,
  toggleDrawer,
  exportId,
}) {
  const boxContainer = {
    width: "60vw",
    height: "80vh",
    overflowY: "scroll",
  };
  const params = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(exportId);
    axiosInstance
      .get(api.export.getDetail(params.caseId, exportId))
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
  }, []);

  return (
    <React.Fragment key={"left"}>
      <CustomDrawer
        className={"export-detail-drawer"}
        isDrawerOpen={openState}
        toggleDrawer={toggleDrawer}
        loading={isLoading}
        title={"Exported files"}
        wrapWithForm={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">
              Name
            </Typography>
            <Typography variant="body1">
              {"displayName" in data ? data["displayName"] : <></>}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">
              Status
            </Typography>
            <Typography variant="body1">
              {"status" in data ? data["status"] : "Unknown"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">
              Files
            </Typography>
          </Grid>
          {data.percentProgress !== 100 ? (
            <Grid item xs={12}>
              Export operation is not completed yet.
            </Grid>
          ) : (
            <Grid item xs={12}>
              {"exportFileMetadata" in data ? (
                <CustomTable
                  isPagination={false}
                  rows={data["exportFileMetadata"]}
                  headCells={exportDetailTableHeadCells()}
                  isLoading={isLoading}
                />
              ) : (
                <CircularProgress size={60} />
              )}
            </Grid>
          )}
        </Grid>
        <CustomDrawerFooter>
          <Button variant="outlined" onClick={(e) => toggleDrawer(e, false)}>
            Cancel
          </Button>
        </CustomDrawerFooter>
      </CustomDrawer>
    </React.Fragment>
  );
}
