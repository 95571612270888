import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CustomTable from "../../composite/table/CustomTable";
import {
  closeOrReopenCase,
  fetchCases,
} from "../../../redux/actions/caseActions";
import CaseForm from "./form/CaseForm";
import caseTableHeadCells from "./caseTableHeadCells";
import { deleteCase } from "../../../redux/actions/caseActions";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import { handleBreadcrumb } from "../../../redux/actions/utilsActions";
import CustomTableToolbar from "../../composite/table/CustomTableToolbar";

const Case = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isCaseFormDrawerOpen, setIsCaseFormDrawerOpen] = useState(false);

  useEffect(() => {
    const breadcrumbs = {
      route: location.pathname,
      className: "cases-breadcumbs",
      links: [{ url: "/dashboard", text: "Dashboard" }, { text: "Cases" }],
    };
    dispatch(handleBreadcrumb(breadcrumbs));
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchCases());
  }, []);

  const { caseList, createdCase } = useSelector((state) => state.cases);

  const toggleDrawer = (event, open) => {
    setIsCaseFormDrawerOpen(open);
  };

  const handleDeleteCase = (e, caseId) => {
    swal({
      title: "Confirmation",
      text: `If you delete this case, all holds will be turned off. Any content that was on hold will be released, and data will be lost. \nDo you really want to delete this case?`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((yes) => {
      if (!yes) return;
      dispatch(deleteCase(caseId)).then((res) => {
        if (res.hasOwnProperty("error")) {
          dispatch(fetchCases());
        } else {
          swal("Success!", `Case has been deleted!`, "success").then(() => {
            dispatch(fetchCases());
          });
        }
      });
    });
  };

  const handleOpenReopenCase = (e, id, status) => {
    const message =
      status?.toLowerCase() === "close"
        ? "If you close this case, all holds will be turned off. Any content that was on hold will be released, which might result in data loss.\nDo you really want to close this case?"
        : "Reopening a case won't automatically reinstate any holds that were in effect when the case was closed. You will need to turn on the holds again after it's reopened.\nDo you really want to reopen this case?";
    swal({
      title: "Confirmation",
      text: message,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: true,
    }).then((yes) => {
      if (!yes) return;
      dispatch(closeOrReopenCase({ caseId: id, action: status })).then(
        (res) => {
          if (res.hasOwnProperty("error")) {
            dispatch(fetchCases());
          } else {
            swal(
              "Success!",
              `Case has been ${
                status?.toLowerCase() === "close" ? "closed" : "reopened"
              }!`,
              "success"
            ).then(() => {
              dispatch(fetchCases());
            });
          }
        }
      );
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            showSerialNo={true}
            rows={caseList.data}
            headCells={caseTableHeadCells(
              handleDeleteCase,
              handleOpenReopenCase
            )}
            isLoading={caseList.isLoading || createdCase.isLoading}
            isSearch={true}
          >
            <CustomTableToolbar
              title={"CASES"}
              onRefreshEvent={() => dispatch(fetchCases())}
              addButtonText={"Create new case"}
              onAddEvent={(event) => toggleDrawer(event, true)}
            />
          </CustomTable>
        </Grid>
      </Grid>
      <CaseForm
        createdCaseLoading={createdCase.isLoading}
        toggleDrawer={toggleDrawer}
        isCaseFormDrawerOpen={isCaseFormDrawerOpen}
      />
    </>
  );
};

export default Case;
