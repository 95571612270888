import React from "react";
import DownloadIcon from '@mui/icons-material/Download';
import { fileIcon } from "../reviewSets/reviewSetsContents/utils";



export default function () {
  const exportDetailTableHeadCells = [
    {
        id: "name",
        tableCellType: "CUSTOM",
        label: "Name",
        fn: (cell) => {return(<>
        {console.log((cell["fileName"].split("."))[cell["fileName"].split(".").length-1])}
            <div style={{display:"inline-block",marginRight:"10px"}}>{(cell["fileName"].split("."))[cell["fileName"].split(".").length-1] in fileIcon?fileIcon[(cell["fileName"].split("."))[cell["fileName"].split(".").length-1]]:fileIcon["unknown"]}
            </div>
            <div style={{display:"inline-block"}}>
              <p>{cell["fileName"]}
              </p>
            </div>
          </>)},
      },
    {
      id: "size",
      label: "Size(Kb)",
    },
    {
        id: "preview",
        tableCellType: "CUSTOM",
        label: "Download",
        fn: (cell) => {return <a href={cell["downloadUrl"]} download target="_blank"><DownloadIcon/></a>}
      },
  ];
  return exportDetailTableHeadCells;
}
