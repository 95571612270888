import { Button } from "@mui/material";
import { format } from "date-fns";
import { CUSTOM } from "../../../constants/table";

export default function (onClickViewEvent) {
  const collectionExportsTableHeadCells = [
    {
      id: "export.id",
      label: "ID",
      tableCellType: CUSTOM,
      fn: (cell) => (
        <div className="guid-cell" title={cell?.export?.id}>
          {cell?.export?.id}
        </div>
      ),
    },
    {
      id: "export.collectionName",
      label: "Collection",
      tableCellType: CUSTOM,
      fn: (cell) => cell?.export?.collectionName,
    },
    {
      id: "searchRequest.searchValue",
      label: "Search Value",
      tableCellType: CUSTOM,
      fn: (cell) => cell?.searchRequest?.searchValue,
    },
    {
      id: "export.status",
      label: "Status",
      tableCellType: CUSTOM,
      fn: (cell) =>
        cell?.errorsData?.errors !== null && cell?.errorsData?.errors?.length > 0
          ? "Error"
          : cell.export.status === 0
          ? "Running"
          : "Finished",
    },
    {
      id: "export.createdTime",
      tableCellType: CUSTOM,
      fn: (cell) =>
        cell?.export?.createdTime
          ? format(new Date(cell?.export?.createdTime), "dd MMM yyyy")
          : "",
      label: "Export Date",
    },
    {
      id: "export.createdBy",
      label: "Exported By",
      tableCellType: CUSTOM,
      fn: (cell) => cell?.export?.createdBy,
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "left",
      fn: (cell) => (
        <>
          <Button
            size="small"
            style={{ cursor: "pointer" }}
            onClick={(e) => onClickViewEvent(e, cell?.export?.id)}
          >
            View Files
          </Button>
        </>
      ),
    },
  ];
  return collectionExportsTableHeadCells;
}
