import React from "react";
import { TextField, Box } from "@mui/material";

function KqlEditor({ register, defaultValues, reset }) {
  return (
    <Box>
      <form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <label htmlFor="kqlString">Query:</label>
          <TextField id="kqlString" {...register(`kqlString`)} />
        </div>
        {/* <button
          type="button"
          onClick={() => reset(defaultValues)}
          style={{ height: "36px", width: "100px" }}
        >
          Reset
        </button> */}
      </form>
    </Box>
  );
}
export default KqlEditor;
