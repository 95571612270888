import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { PropertyList, PropertyOperatorMapping } from "./utils";
import { MuiChipsInput } from "mui-chips-input";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

let renderCount = 0;

export default function Fields({ control, register, watch }) {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "conditions.filters",
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Accordion key={index} style={{ marginTop: "10px" }} expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p style={{ margin: "0px" }}>{"Condition " + (index + 1)}</p>
              <Button
                color="error"
                size="small"
                sx={{ ml: "auto" }}
                onClick={() => remove(index)}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <div style={{ display: "block" }}>
                  <input
                    id={`logical-operator${index}`}
                    hidden
                    {...register(`conditions.filters.${index}.logicalOperator`)}
                    value="AND"
                    disabled
                    style={{ height: "36px", margin: "20px", padding: "10px" }}
                  />
                </div>

                <div style={{ display: "block" }}>
                  <Select
                    id={`property-name${index}`}
                    {...register(`conditions.filters.${index}.propertyName`)}
                    sx={{
                      height: "36px",
                      margin: "7px 20px 5px",
                      width: "150px",
                    }}
                    displayEmpty
                    value={
                      watch(`conditions.filters.${index}.propertyName`) || ""
                    }
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {PropertyList.map((value) => (
                      <MenuItem
                        key={value.propertyName}
                        value={value.propertyName}
                      >
                        {value.propertyLabel}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <select
                    id={`property-name${index}`}
                    {...register(`conditions.filters.${index}.propertyName`)}
                    style={{ height: "36px", margin: "20px", width: "150px" }}
                    defaultValue={""}
                  >
                    <option disabled value=""></option>
                    {PropertyList.map((value) => (
                      <option
                        key={value.propertyName}
                        value={value.propertyName}
                      >
                        {value.propertyLabel}
                      </option>
                    ))}
                  </select> */}
                </div>

                {watch(`conditions.filters.${index}.propertyName`) && (
                  <div style={{ display: "block" }}>
                    {/* <select
                      id={`operator-name${index}`}
                      {...register(`conditions.filters.${index}.operator`)}
                      style={{ height: "36px", margin: "20px", width: "150px" }}
                      defaultValue={""}
                    >
                      <option disabled value=""></option>
                      {PropertyOperatorMapping[
                        watch(`conditions.filters.${index}.propertyName`)
                      ].map((value) => (
                        <option key={value.value} value={value.value}>
                          {value.label}
                        </option>
                      ))}
                    </select> */}

                    <Select
                      id={`operator-name${index}`}
                      {...register(`conditions.filters.${index}.operator`)}
                      style={{
                        height: "36px",
                        margin: "7px 5px",
                        width: "150px",
                      }}
                      value={
                        watch(`conditions.filters.${index}.operator`) || ""
                      }

                      // defaultValue={""}
                    >
                      <MenuItem disabled value=""></MenuItem>
                      {PropertyOperatorMapping[
                        watch(`conditions.filters.${index}.propertyName`)
                      ].map((value) => (
                        <MenuItem key={value.value} value={value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}

                {watch(`conditions.filters.${index}.operator`) &&
                  PropertyOperatorMapping[
                    watch(`conditions.filters.${index}.propertyName`)
                  ].find(
                    (value) =>
                      value.value ===
                      watch(`conditions.filters.${index}.operator`)
                  ) &&
                  PropertyOperatorMapping[
                    watch(`conditions.filters.${index}.propertyName`)
                  ]
                    .find(
                      (value) =>
                        value.value ===
                        watch(`conditions.filters.${index}.operator`)
                    )
                    .property.map((prop, i) => {
                      return (
                        <>
                          {prop["type"] === "MUIchipset" ? (
                            <>
                              <Controller
                                name={`conditions.filters.${index}.value`}
                                key={i}
                                control={control}
                                render={({ field }) => (
                                  <MuiChipsInput
                                    style={{
                                      height: "36px",
                                      width: "100%",
                                      margin: "5px 20px 20px",
                                    }}
                                    id="keywords"
                                    {...register(
                                      `conditions.filters.${index}.value`
                                    )}
                                    {...field}
                                    onChange={(value) => field.onChange(value)}
                                  />
                                )}
                              />
                            </>
                          ) : (
                            <div key={i}>
                              {/* <input
                                id={`${prop.name}${index}`}
                                style={{
                                  height: "36px",
                                  margin: "20px",
                                  padding: "10px",
                                  width: "150px",
                                }}
                                {...register(
                                  `conditions.filters.${index}.value.${i}`
                                )}
                                type={prop["type"]}
                              /> */}

                              <TextField
                                id={`${prop.name}${index}`}
                                size="small"
                                sx={{
                                  height: "36px",
                                  margin: "0px 5px",
                                  padding: "6.5px 4px",
                                  width: "150px",
                                }}
                                {...register(
                                  `conditions.filters.${index}.value.${i}`
                                )}
                                type={prop["type"]}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <section>
        <Button
          size="small"
          style={{ height: "36px", marginTop: "20px" }}
          type="button"
          onClick={() => {
            append({ logicalOperator: "AND" });
          }}
        >
          <AddIcon /> Add Condition
        </Button>
      </section>
    </>
  );
}
