const api = {
  connections: {
    get: () => "connections",
    create: () => "connections",
    update: (id) => `connections/${id}`,
    delete: (id) => `connections/${id}`,
  },
  onpremCollections: {
    get: () => "collections",
    create: () => "collections",
    update: (id) => `collections/${id}`,
    delete: (id) => `collections/${id}`,
    changeHoldStatus: (id) => `collections/${id}/change-hold`,
  },
  settings: {
    get: () => "settings",
    save: () => "settings",
    deleteUser: (userId) => `users/${userId}`,
  },
  process: {
    get: (id) => `process/${id}`,
  },
  role: {
    get: () => "roles",
    create: () => "roles",
    permissions: (roleId) => `roles/${roleId}/permissions`,
    delete: (roleId) => `roles/${roleId}`,
    update: (roleId) => `roles/${roleId}`,
  },
  user: {
    get: () => "users",
    add: () => "users",
    update: (userId) => `users/${userId}`,
  },
  me: {
    resolveUser: () => "/me/resolve",
    myPermissions: () => "/me/permissions",
  },
  surveillance: {
    get: () => "surveillance",
    search: () => "surveillance",
    getLogEvents: () => "/surveillance/events",
  },
  case: {
    get: () => "/cases",
    create: () => "/cases",
    update: (id) => `/cases/${id}`,
    delete: (id) => `/cases/${id}`,
    closeReopen: (id, action) => `/cases/${id}/${action}`,
    getById: (id) => `/cases/${id}`,
    getOperations: (id) => `/cases/${id}/operations`,
    getCustodians: (id) => `/cases/${id}/custodians`,
    addCustodian: (id) => `/cases/${id}/custodians`,
    removeCustodian: (caseId, id) => `/cases/${caseId}/custodians/${id}`,
    holdUnholdCustodians: (caseId) => `/cases/${caseId}/custodians/hold-unhold`,
    releaseCustodian: (caseId, id) =>
      `/cases/${caseId}/custodians/${id}/release`,
    activateCustodian: (caseId, id) =>
      `/cases/${caseId}/custodians/${id}/activate`,
    getRunningOperations: (id) => `/cases/${id}/running-operations`,
    getSettings: (id) => `/cases/${id}/settings`,
    updateSettings: (id) => `/cases/${id}/settings`,
    resetToDefault: (id) => `/cases/${id}/settings/reset-to-default`,
  },
  dataSources: {
    getCustodiansByCaseId: (id) => `/cases/${id}/custodians/`,
    getNonCustodiansByCaseId: (id) => `/cases/${id}/noncustodial-datasources/`,
    addOwnSource: (caseId, custodianId) =>
      `/cases/${caseId}/custodians/${custodianId}/own-source`,
    addExchangeSources: (caseId, custodianId) =>
      `/cases/${caseId}/custodians/${custodianId}/exchange-sources`,
    addSiteSources: (caseId, custodianId) =>
      `/cases/${caseId}/custodians/${custodianId}/site-sources`,
  },
  tenant: {
    getUsers: () => "/tenant/users",
    getSites: () => "/tenant/sites",
    getExchangeMails: () => "/tenant/exchange-mails",
  },
  collections: {
    get: (id) => `/cases/${id}/collections/`,
    create: (id) => `/cases/${id}/collections`,
    update: (caseId, collectionId) =>
      `/cases/${caseId}/collections/${collectionId}`,
    details: (caseId, collectionId) =>
      `/cases/${caseId}/collections/${collectionId}`,
    delete: (caseId, collectionId) =>
      `/cases/${caseId}/collections/${collectionId}`,
    removeAdditionalSource: (caseId, collectionId, sourceId) =>
      `/cases/${caseId}/collections/${collectionId}/additional-sources/${sourceId}`,
    addAdditionalSource: (caseId, collectionId) =>
      `/cases/${caseId}/collections/${collectionId}/additional-sources`,
    indexing: (caseId, collectionId) =>
      `/cases/${caseId}/collections/${collectionId}/index`,
    custodialSources: (caseId, collectionId) =>
      `/cases/${caseId}/collections/${collectionId}/custodial-sources`,
    deleteCustodialSources: (caseId, collectionId, custodialSourceId) =>
      `/cases/${caseId}/collections/${collectionId}/custodial-sources/${custodialSourceId}`,
  },
  reviewSets: {
    get: (id) => `/cases/${id}/review-sets/`,
    create: (id) => `/cases/${id}/review-sets`,
    update: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}`,
    details: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}`,
    delete: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}`,
  },
  reviewSetsContents: {
    get: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/files/`,
    create: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/files`,
  },
  querySet: {
    get: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries`,
    retrieve: (caseId, reviewSetId, queryId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries/${queryId}`,
    create: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries`,
    update: (caseId, reviewSetId, queryId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries/${queryId}`,
    delete: (caseId, reviewSetId, queryId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries/${queryId}`,
  },
  querySetResult: {
    get: (caseId, reviewSetId, queryId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries/${queryId}/files`,
    export: (caseId, reviewSetId, queryId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries/${queryId}/export`,
  },
  queryParse: {
    objectToString: () => `misc/kql`,
    stringToObject: () => `misc/kqlparse`,
  },
  holds: {
    get: (caseId) => `/cases/${caseId}/legal-holds`,
    create: (caseId) => `/cases/${caseId}/legal-holds`,
    update: (caseId, holdId) => `/cases/${caseId}/legal-holds/${holdId}`,
    details: (caseId, holdId) => `/cases/${caseId}/legal-holds/${holdId}`,
    delete: (caseId, holdId) => `/cases/${caseId}/legal-holds/${holdId}`,
    userSources: (caseId, holdId) =>
      `/cases/${caseId}/legal-holds/${holdId}/user-sources`,
    deleteUserSource: (caseId, holdId, userSourceId) =>
      `/cases/${caseId}/legal-holds/${holdId}/user-sources/${userSourceId}`,
    siteSources: (caseId, holdId) =>
      `/cases/${caseId}/legal-holds/${holdId}/site-sources`,
    deleteSiteSource: (caseId, holdId, siteSourceId) =>
      `/cases/${caseId}/legal-holds/${holdId}/site-sources/${siteSourceId}`,
  },
  export: {
    reviewSet: (caseId, reviewSetId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/export`,
    querySet: (caseId, reviewSetId, queryId) =>
      `/cases/${caseId}/review-sets/${reviewSetId}/queries/${queryId}/export`,
    getList: (caseId) => `/cases/${caseId}/exports`,
    getDetail: (caseId, exportId) => `/cases/${caseId}/exports/${exportId}`,
  },
  addToReviewSet: {
    add: (caseId, collectionId) =>
      `/cases/${caseId}/collections/${collectionId}/addToReviewSet`,
  },
  subjectRights: {
    get: () => `/subjectRights`,
    getById: (id) => `/subjectRights/${id}`,
    create: () => "/subjectRights",
    update: (id) => `/subjectRights/${id}`,
    delete: (id) => `/subjectRights/${id}`,
    getSRByIdFinalReport: (id) => `/subjectRights/finalreport/${id}`,
    getSRByIdFinalAttachment: (id) => `/subjectRights/finalattachment/${id}`,
    getSubjectRightsNotes: (id) => `/subjectRights/notes/${id}`,
  },
  emails: {
    get: () => "emails",
    searchEmail: ()=> `emails/searchEmail`,
  },
};

export default api;
