export default function () {
  const tenantMailboxTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
    },
    {
      id: "mail",
      label: "Email",
    },
  ];
  return tenantMailboxTableHeadCells;
}
