import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCustodiansByCaseId,
  fetchNonCustodiansByCaseId,
} from "../actions/dataSources/dataSourcesActions";

const dataSourcesSlice = createSlice({
  name: "dataSources",
  initialState: {
    custodiansList: {
      isLoading: false,
      caseId: null,
      data: [],
      error: null,
    },
    nonCustodiansList: {
      isLoading: false,
      caseId: null,
      data: [],
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustodiansByCaseId.pending, (state) => {
        state.custodiansList.isLoading = true;
        state.custodiansList.error = null;
      })
      .addCase(fetchCustodiansByCaseId.fulfilled, (state, action) => {
        state.custodiansList.isLoading = false;
        state.custodiansList.caseId = action.payload.caseId;
        state.custodiansList.data = action.payload.data;
      })
      .addCase(fetchCustodiansByCaseId.rejected, (state, action) => {
        state.custodiansList.isLoading = false;
        state.custodiansList.error = action.error.message;
      })
      .addCase(fetchNonCustodiansByCaseId.pending, (state) => {
        state.nonCustodiansList.isLoading = true;
        state.nonCustodiansList.error = null;
      })
      .addCase(fetchNonCustodiansByCaseId.fulfilled, (state, action) => {
        state.nonCustodiansList.isLoading = false;
        state.nonCustodiansList.caseId = action.payload.caseId;
        state.nonCustodiansList.data = action.payload.data;
      })
      .addCase(fetchNonCustodiansByCaseId.rejected, (state, action) => {
        state.nonCustodiansList.isLoading = false;
        state.nonCustodiansList.error = action.error.message;
      });
  },
});

export default dataSourcesSlice.reducer;
