
import React from "react";
import QueryTableActionCell from "./queryTableActionCell";

export default function (reviewSetName) {
  const querySetTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
    },
    {
      id: "createdBy",
      label: "Created By",
      tableCellType: "CUSTOM",
      fn: (cell) => cell.createdBy?.displayName,
    },
    {
      id: "createdDateTime",
      tableCellType: "CUSTOM",
      fn: (cell) => new Date(cell["createdDateTime"]).toLocaleDateString(),
      label: "Created",
    },
    {
      id: "query",
      label: "Query",
    },
    {
      id:"Action",
      label:"Action",
      tableCellType: "CUSTOM",
      fn:(cell) =>{
        return (
          <QueryTableActionCell reviewSetName={reviewSetName} queryName={cell["displayName"]} id={cell["id"]}/>
        )
      }
    }
  ];
  return querySetTableHeadCells;
}
