import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
} from "@mui/material";
import { isObjectEmptyOrNull } from "../../../utils/validation";
import { fetchFinalReportById } from "../../../redux/actions/subjectRightsActions";

const SRFinalReport = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { srId } = params;

  const currentFinalReport = useSelector((state) => state.subjectRights.currentFinalReport);
  useEffect(() => {
    dispatch(fetchFinalReportById(srId));
  }, [srId]);

  return isObjectEmptyOrNull(currentFinalReport) ? (
    <Grid>Loading....</Grid>
  ) : (
    <Grid></Grid>
   
  );
};

export default SRFinalReport;
