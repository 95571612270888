
const SubjectRightsRegulations = [
    {
      name: 'California Consumer Privacy Act (CCPA)',
      id: 0,
      value: "CCPA"
    },
    {
      name: 'General Data Protection Regulation (GDPR)',
      id: 1,
      value: "GDPR"
    },
    {
      name: 'UK Data Protection Act (UDPA)​',
      id: 2,
       value: "UDPA"
    },
    {
      name: 'US Patriot Act (USPA)​',
      id: 3,
       value: "USPA"
    },
    {
      name: 'US State Breach Notification Laws (USBNL)',
      id: 4,
       value: "USBNL"
    },
  ];
  export default SubjectRightsRegulations;
