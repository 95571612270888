import { IconButton } from "@mui/material";
import { format } from "date-fns";
import { CUSTOM } from "../../../constants/table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AuthorizeGuard from "../../common/AuthorizeGuard";

export default function (onClickEditEvent, onClickDeleteEvent) {
  const rolesTableHeadCells = [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "createdTime",
      tableCellType: CUSTOM,
      fn: (cell) => format(new Date(cell["createdTime"]), "dd MMM yyyy"),
      label: "Created",
    },
    {
      id: "type",
      label: "Type",
    },
    {
      tableCellType: CUSTOM,
      label: "Action",
      align: "left",
      fn: (cell) =>
        cell["type"] === "System" ? (
          <></>
        ) : (
          <>
            <AuthorizeGuard module={"ROLES"} action={"Edit"}>
              <IconButton
                size="small"
                onClick={(e) => onClickEditEvent(e, cell["id"])}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </AuthorizeGuard>
            <AuthorizeGuard module={"ROLES"} action={"Delete"}>
              <IconButton
                size="small"
                onClick={(e) => onClickDeleteEvent(e, cell["id"])}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </AuthorizeGuard>
          </>
        ),
    },
  ];
  return rolesTableHeadCells;
}
