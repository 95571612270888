import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCollectionExports,
  fetchCollectionExportFiles,
  fetchCollections,
  fetchHoldCollections,
} from "../actions/collectionActions";

const initialState = {
  collections: {
    isLoading: false,
    data: [],
    error: null,
  },
  exports: {
    isLoading: false,
    data: [],
    error: null,
  },
  exportedFiles: {
    isLoading: false,
    data: {},
    error: null,
  },
  holds: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetch collections
      .addCase(fetchCollections.pending, (state) => {
        state.collections.isLoading = true;
        state.collections.error = null;
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.collections.isLoading = false;
        if (action.payload?.success) {
          state.collections.data = action.payload?.collections;
        }
      })
      .addCase(fetchCollections.rejected, (state, action) => {
        state.collections.isLoading = false;
        state.collections.error = action.error;
      })
      //fetch exports
      .addCase(fetchCollectionExports.pending, (state) => {
        state.exports.isLoading = true;
        state.exports.error = null;
      })
      .addCase(fetchCollectionExports.fulfilled, (state, action) => {
        state.exports.isLoading = false;
        if (action.payload?.success) {
          state.exports.data = action.payload?.collections;
        }
      })
      .addCase(fetchCollectionExports.rejected, (state, action) => {
        state.exports.isLoading = false;
        state.exports.error = action.error;
      })

      //fetch export files
      .addCase(fetchCollectionExportFiles.pending, (state) => {
        state.exportedFiles.isLoading = true;
        state.exportedFiles.error = null;
      })
      .addCase(fetchCollectionExportFiles.fulfilled, (state, action) => {
        state.exportedFiles.isLoading = false;
        if (action.payload?.success) {
          state.exportedFiles.data = action.payload?.exportResults;
        }
      })
      .addCase(fetchCollectionExportFiles.rejected, (state, action) => {
        state.exportedFiles.isLoading = false;
        state.exportedFiles.error = action.error;
      })

      //fetch hold collections
      .addCase(fetchHoldCollections.pending, (state) => {
        state.holds.isLoading = true;
        state.holds.error = null;
      })
      .addCase(fetchHoldCollections.fulfilled, (state, action) => {
        state.holds.isLoading = false;
        if (action.payload?.success) {
          state.holds.data = action.payload?.collections;
        }
      })
      .addCase(fetchHoldCollections.rejected, (state, action) => {
        state.holds.isLoading = false;
        state.holds.error = action.error;
      });
  },
});

export default collectionsSlice.reducer;
