import React from "react";
import { TextField } from "@mui/material";

export default function CustomTextField({
  input,
  label,
  meta: { touched, error },
  ...custom
}) {
  return (
    <>
      <TextField
        fullWidth
        size="small"
        label={label}
        error={touched && error}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </>
  );
}
