import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCases,
  createCase,
  deleteCase,
  fetchCaseById,
  getCaseOperations,
  getCaseSettings,
  getCaseRunningOperations,
  getCaseCustodians
} from "../actions/caseActions";

const initialState = {
  caseList: {
    isLoading: false,
    data: [],
    error: null,
  },
  currentCase: {
    isLoading: false,
    details: {},
    error: null,
  },
  createdCase: {
    isLoading: false,
    status: null,
    error: null,
  },
  custodians: {
    isLoading: false,
    data: [],
    error: null,
  },
  operationList: {
    isLoading: false,
    data: [],
    error: null,
  },
  runningOperationList: {
    isLoading: false,
    data: [],
    error: null,
  },
  settings: {
    isLoading: false,
    details: {},
    error: null,
  },
};

export const caseSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // delete Case
      .addCase(deleteCase.pending, (state) => {
        state.caseList.isLoading = true;
        state.caseList.error = null;
      })
      .addCase(deleteCase.fulfilled, (state) => {
        state.caseList.isLoading = false;
      })
      //fetch Cases
      .addCase(fetchCases.pending, (state) => {
        state.caseList.isLoading = true;
        state.caseList.error = null;
      })
      .addCase(fetchCases.fulfilled, (state, action) => {
        state.caseList.isLoading = false;
        state.caseList.data = action.payload;
      })
      .addCase(fetchCases.rejected, (state, action) => {
        state.caseList.isLoading = false;
        state.caseList.error = action.error;
      })

      .addCase(createCase.pending, (state) => {
        state.createdCase.isLoading = true;
        state.createdCase.status = null;
        state.createdCase.error = null;
      })
      .addCase(createCase.fulfilled, (state) => {
        state.createdCase.isLoading = false;
        state.createdCase.status = "success";
      })
      .addCase(createCase.rejected, (state, action) => {
        state.createdCase.isLoading = false;
        state.createdCase.status = "error";
        state.createdCase.error = action.error;
      })

      .addCase(fetchCaseById.pending, (state) => {
        state.currentCase.isLoading = true;
        state.currentCase.error = null;
      })
      .addCase(fetchCaseById.fulfilled, (state, action) => {
        state.currentCase.isLoading = false;
        state.currentCase.details = action.payload;
      })
      .addCase(fetchCaseById.rejected, (state, action) => {
        state.currentCase.isLoading = false;
        state.currentCase.error = action.error;
      })

      .addCase(getCaseCustodians.pending, (state) => {
        state.custodians.isLoading = true;
        state.custodians.error = null;
      })
      .addCase(getCaseCustodians.fulfilled, (state, action) => {
        state.custodians.isLoading = false;
        state.custodians.data = action.payload;
      })
      .addCase(getCaseCustodians.rejected, (state, action) => {
        state.custodians.isLoading = false;
        state.custodians.error = action.error;
      })

      .addCase(getCaseOperations.pending, (state) => {
        state.operationList.isLoading = true;
        state.operationList.error = null;
      })
      .addCase(getCaseOperations.fulfilled, (state, action) => {
        state.operationList.isLoading = false;
        state.operationList.data = action.payload;
      })
      .addCase(getCaseOperations.rejected, (state, action) => {
        state.operationList.isLoading = false;
        state.operationList.error = action.error;
      })

      .addCase(getCaseRunningOperations.pending, (state) => {
        state.runningOperationList.isLoading = true;
        state.runningOperationList.error = null;
      })
      .addCase(getCaseRunningOperations.fulfilled, (state, action) => {
        state.runningOperationList.isLoading = false;
        state.runningOperationList.data = action.payload;
      })
      .addCase(getCaseRunningOperations.rejected, (state, action) => {
        state.runningOperationList.isLoading = false;
        state.runningOperationList.error = action.error;
      })

      .addCase(getCaseSettings.pending, (state) => {
        state.settings.isLoading = true;
        state.settings.error = null;
      })
      .addCase(getCaseSettings.fulfilled, (state, action) => {
        state.settings.isLoading = false;
        state.settings.details = action.payload;
      })
      .addCase(getCaseSettings.rejected, (state, action) => {
        state.settings.isLoading = false;
        state.settings.error = action.error;
      });
  },
});

export default caseSlice.reducer;
