import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Typography, TextField } from "@mui/material";
import swal from "sweetalert";

import axios from "../../../utils/axios";
import api from "../../../utils/api";
import AuthorizeGuard from "../../common/AuthorizeGuard";
import { fetchConnections } from "../../../redux/actions/connectionActions";
import validateConnectionInputs from "./Validator";
import CustomDrawer from "../../composite/CustomDrawer";
import CustomDrawerFooter from "../../composite/table/CustomDrawerFooter";

const ConnectionForm = ({ toggleDrawer, isDrawerOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isDrawerOpen);
  const [connectionName, setConnectionName] = useState(null);
  const [searchLocations, setSearchLocations] = useState(null);

  useEffect(() => {
    setOpen(isDrawerOpen);
    setConnectionName(null);
    setSearchLocations(null);
  }, [isDrawerOpen]);

  const handleCreate = (event) => {
    event.preventDefault();
    setLoading(true);
    var formData = {
      connectionName,
      searchLocations,
    };
    if (!validateConnectionInputs(formData)) {
      setLoading(false);
      return;
    }
    axios
      .post(api.connections.create(), formData)
      .then((res) => {
        if (!res?.success && res?.errorMessage) {
          swal("Error!", res.errorMessage, "error");
          setLoading(false);
        } else {
          swal("Success!", `Connection created successfully!`, "success").then(
            () => {
              dispatch(fetchConnections());
              setLoading(false);
              toggleDrawer(event, false);
            }
          );
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <CustomDrawer
      className={"add-connection-drawer"}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={toggleDrawer}
      loading={loading}
      title={"New Connection"}
      wrapWithForm={true}
      onFormSubmit={handleCreate}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} marginBottom={3}>
          <Typography variant="h5" fontWeight={"bold"}></Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Connection Name
          </Typography>
          <TextField
            size="small"
            required
            variant="outlined"
            value={connectionName}
            onChange={(e) => {
              setConnectionName(e.target.value);
            }}
            style={{ width: "100%" }}
          />
          <small class="form-text text-muted">
            The connection name should be unique and cannot be changed once
            created.
          </small>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bolder" marginBottom={1}>
            Directory Paths
          </Typography>
          <TextField
            size="small"
            required
            multiline
            rows={5}
            variant="outlined"
            value={searchLocations}
            onChange={(e) => {
              setSearchLocations(e.target.value);
            }}
            style={{ width: "100%" }}
          />
          <small class="form-text red-color">
            Each directory should start from a new line (do not use , or .
            symbols)
          </small>
          <small class="form-text text-muted">
            The Directory path is the starting path for the crawler. The crawler
            recursively indexes content within the folders. Enter at least one
            directory path for the collection.
          </small>
        </Grid>
      </Grid>
      <CustomDrawerFooter>
        <AuthorizeGuard module={"CONNECTIONS"} action={"Add"}>
          <Button disabled={loading} type="submit" variant="contained">
            Submit
          </Button>
        </AuthorizeGuard>
        <Button
          disabled={loading}
          variant="outlined"
          onClick={(e) => toggleDrawer(e, false)}
        >
          Cancel
        </Button>
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default ConnectionForm;
