import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import swal from "sweetalert";
import { fetchRoles } from "../../../redux/actions/settings/settingsActions";
import axios from "../../../utils/axios";
import api from "../../../utils/api";
import axiosInstance from "../../../utils/axios";
import { showApiError } from "../../../utils/functions";

const UserPermission = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [canViewAll, setCanViewAll] = useState(false);
  const [canAddAll, setCanAddAll] = useState(false);
  const [canEditAll, setCanEditAll] = useState(false);
  const [canDeleteAll, setCanDeleteAll] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(false);
  const [selectedRoleType, setSelectedRoleType] = useState("Custom");
  const [selectedRolePermissions, setSelectedRolePermissions] = useState([]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  const { roleList } = useSelector((state) => state.settings);
  const handleChange = (e) => {
    const roleId = e.target.value;
    setSelectedRoleId(roleId);
    const roleType = roleList?.data?.find((x) => x.id === roleId)?.type;
    setSelectedRoleType(roleType);
    axiosInstance.get(api.role.permissions(roleId)).then((res) => {
      setSelectedRolePermissions(res.data);
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(api.role.permissions(selectedRoleId), selectedRolePermissions)
      .then((res) => {
        swal("Success!", `Permissions saved successfully!`, "success").then(
          () => {
            setLoading(false);
          }
        );
      })
      .catch((error) => {
        showApiError(error);
        setLoading(false);
      });
  };

  return (
    <Grid container >
      <Grid item xs={12} display="flex" justifyContent="space-between"></Grid>
      <Typography variant="h6" fontWeight="bold" mb={1}>
        ROLE PERMISSIONS
      </Typography>
      <Grid xs={12}>
        <Grid container>
          <Grid item xs={1} marginBottom={2}>
            <Typography
              variant="body1"
              fontWeight="bolder"
              marginBottom={1}
              style={{ padding: "9px 0" }}
            >
              Select Role
            </Typography>
          </Grid>
          <Grid item xs={6} marginBottom={2}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <Select size="small" onChange={handleChange}>
                {roleList.data.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} marginBottom={2}>
            <Button
              disabled={selectedRoleType === "System" || selectedRolePermissions.length === 0}
              sx={{ padding: "7px 30px", marginLeft: "5px" }}
              variant="contained"
              size="small"
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>

          {selectedRolePermissions.length > 0 && (
            <Grid item xs={12} marginBottom={2}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          width: "20px",
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        rowSpan={2}
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          width: "300px",
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        rowSpan={2}
                      >
                        Module
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        colSpan={4}
                      >
                        Can permform the actions below?
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        align="left"
                      >
                        <FormControlLabel
                          style={{ marginBottom: "0px", marginRight: "5px" }}
                          control={
                            <Checkbox
                              disabled={selectedRoleType === "System"}
                              style={{ padding: "0px 0px 0px 9px" }}
                              checked={canViewAll}
                              onChange={(e) => {
                                setCanViewAll(e.target.checked);
                                setSelectedRolePermissions(
                                  selectedRolePermissions.map((item) => ({
                                    ...item,
                                    canView: e.target.checked,
                                  }))
                                );
                              }}
                            />
                          }
                        />
                        View
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        align="left"
                      >
                        <FormControlLabel
                          style={{ marginBottom: "0px", marginRight: "5px" }}
                          control={
                            <Checkbox
                              disabled={selectedRoleType === "System"}
                              style={{ padding: "0px 0px 0px 9px" }}
                              checked={canAddAll}
                              onChange={(e) => {
                                setCanAddAll(e.target.checked);
                                setSelectedRolePermissions(
                                  selectedRolePermissions.map((item) => ({
                                    ...item,
                                    canAdd: e.target.checked,
                                  }))
                                );
                              }}
                            />
                          }
                        />
                        Add
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        align="left"
                      >
                        <FormControlLabel
                          style={{ marginBottom: "0px", marginRight: "5px" }}
                          control={
                            <Checkbox
                              disabled={selectedRoleType === "System"}
                              style={{ padding: "0px 0px 0px 9px" }}
                              checked={canEditAll}
                              onChange={(e) => {
                                setCanEditAll(e.target.checked);
                                setSelectedRolePermissions(
                                  selectedRolePermissions.map((item) => ({
                                    ...item,
                                    canEdit: e.target.checked,
                                  }))
                                );
                              }}
                            />
                          }
                        />
                        Edit
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          paddingTop: "0",
                          paddingBottom: "0",
                        }}
                        align="left"
                      >
                        <FormControlLabel
                          style={{ marginBottom: "0px", marginRight: "5px" }}
                          control={
                            <Checkbox
                              disabled={selectedRoleType === "System"}
                              style={{ padding: "0px 0px 0px 9px" }}
                              checked={canDeleteAll}
                              onChange={(e) => {
                                setCanDeleteAll(e.target.checked);
                                setSelectedRolePermissions(
                                  selectedRolePermissions.map((item) => ({
                                    ...item,
                                    canDelete: e.target.checked,
                                  }))
                                );
                              }}
                            />
                          }
                        />
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRolePermissions.map((itemA, indexA) => (
                      <TableRow key={indexA}>
                        <TableCell>{indexA + 1}</TableCell>
                        <TableCell>{itemA.moduleName}</TableCell>
                        {/* <TableCell>{itemA.moduleCode}</TableCell> */}
                        <TableCell>
                          <FormControlLabel
                            style={{ marginBottom: "0px" }}
                            control={
                              <Checkbox
                                disabled={selectedRoleType === "System"}
                                style={{ padding: "0px 0px 0px 9px" }}
                                checked={
                                  selectedRolePermissions[indexA].canView
                                }
                                onChange={(e) =>
                                  setSelectedRolePermissions(
                                    selectedRolePermissions.map(
                                      (itemB, indexB) => {
                                        if (indexA === indexB) {
                                          return {
                                            ...itemB,
                                            canView: e.target.checked,
                                          };
                                        } else {
                                          return itemB;
                                        }
                                      }
                                    )
                                  )
                                }
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            style={{ marginBottom: "0px" }}
                            control={
                              <Checkbox
                                disabled={selectedRoleType === "System"}
                                style={{ padding: "0px 0px 0px 9px" }}
                                checked={selectedRolePermissions[indexA].canAdd}
                                onChange={(e) =>
                                  setSelectedRolePermissions(
                                    selectedRolePermissions.map(
                                      (itemB, indexB) => {
                                        if (indexA === indexB) {
                                          return {
                                            ...itemB,
                                            canAdd: e.target.checked,
                                          };
                                        } else {
                                          return itemB;
                                        }
                                      }
                                    )
                                  )
                                }
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            style={{ marginBottom: "0px" }}
                            control={
                              <Checkbox
                                disabled={selectedRoleType === "System"}
                                style={{ padding: "0px 0px 0px 9px" }}
                                checked={
                                  selectedRolePermissions[indexA].canEdit
                                }
                                onChange={(e) =>
                                  setSelectedRolePermissions(
                                    selectedRolePermissions.map(
                                      (itemB, indexB) => {
                                        if (indexA === indexB) {
                                          return {
                                            ...itemB,
                                            canEdit: e.target.checked,
                                          };
                                        } else {
                                          return itemB;
                                        }
                                      }
                                    )
                                  )
                                }
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            style={{ marginBottom: "0px" }}
                            control={
                              <Checkbox
                                disabled={selectedRoleType === "System"}
                                style={{ padding: "0px 0px 0px 9px" }}
                                checked={
                                  selectedRolePermissions[indexA].canDelete
                                }
                                onChange={(e) =>
                                  setSelectedRolePermissions(
                                    selectedRolePermissions.map(
                                      (itemB, indexB) => {
                                        if (indexA === indexB) {
                                          return {
                                            ...itemB,
                                            canDelete: e.target.checked,
                                          };
                                        } else {
                                          return itemB;
                                        }
                                      }
                                    )
                                  )
                                }
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPermission;
