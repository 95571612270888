export default function () {
  const siteSourcesTableHeadCells = [
    {
      id: "displayName",
      label: "Name",
    },
    {
      id: "siteWebUrl",
      label: "Url",
    },
    {
      id: "action",
      label: "Action",
      align: "right",
    },
  ];
  return siteSourcesTableHeadCells;
}
