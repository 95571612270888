import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { showApiError } from "../../utils/functions";

//reset ui alert
export const resetUIAlert = createAsyncThunk("resetUIAlert", async () => {
  return -1;
});

export const searchEmailReq = createAsyncThunk(
  "searchEmail",
  async (data) => {
    const response = await axios
    .post(`/emails/searchEmail`, data)
      .catch(showApiError);
    return {
      searchEmailResult: response.data,
    };
    
  }
);

